import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { MedicalServicesOutlined } from "@mui/icons-material";

import colours from "../../constants/colours";

const AdminSubMenuList = ({
  open,
  setSelectedIndex,
  selectedIndex,
  setSelectedPage,
}) => {
  const setting = (index, screen, url) => {
    setSelectedIndex(index);
    window.history.replaceState("", "", url);
    setSelectedPage(screen);
  };
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton
          selected={selectedIndex === 22}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(22, "Service Types", "service-types");
          }}
        >
          <ListItemIcon>
            <MedicalServicesOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Service Types" />
        </ListItemButton>
        <Divider />
        <ListItemButton
          sx={{ pl: 4 }}
          onClick={() => {
            setting(23, "Coming soon", "coming-soon");
          }}
        >
          <ListItemIcon>
            <MedicalServicesOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Coming soon" />
        </ListItemButton>
      </List>
    </Collapse>
  );
};

export default AdminSubMenuList;
