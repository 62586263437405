import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

const SupervisoryVisit = ({ data }) => {
  const [signature_image_4, setSign_4] = useState("");

  const signature_4 = useRef();

  return (
    <Box>
      <Box
        fontFamily={"Times New Roman"}
        fontSize={22}
        fontWeight={"bold"}
        textAlign={"center"}
      >
        SUPERVISORY VISIT
      </Box>
      <Box
        mt={4}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"flex-end"}
      >
        <Box fontFamily={"Times New Roman"} fontSize={16} fontWeight={"bold"}>
          Individual Name:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} mr={2}>
          <input
            disabled
            style={{
              border: "0px",
              width: 380,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
              color: "black",
            }}
            type="text"
            value={
              data?.individual?.firstname
                ? `${data?.individual?.firstname}, ${data?.individual?.lastname}`
                : ""
            }
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16} fontWeight={"bold"}>
          ID #:{" "}
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            disabled
            style={{
              border: "0px",
              width: 130,
              height: 20,
              paddingLeft: 5,
              color: "black",
              fontFamily: "Times New Roman",
            }}
            type="text"
            value={data?.individual?.medicaid}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>

      <Box
        mt={2}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"flex-end"}
      >
        <Box fontFamily={"Times New Roman"} fontSize={16} fontWeight={"bold"}>
          Sup. Visit Date:{" "}
        </Box>

        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 120,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box
          ml={0.5}
          fontFamily={"Times New Roman"}
          fontSize={16}
          fontWeight={"bold"}
        >
          Level of Sup. Visit:
        </Box>
        <Box ml={1} fontFamily={"Times New Roman"} fontSize={13}>
          NON-RN
        </Box>
        <Box
          ml={1}
          fontFamily={"Times New Roman"}
          fontSize={16}
          fontWeight={"bold"}
        >
          Freq. of Sup. Visit:
        </Box>
        <Box ml={1} fontFamily={"Times New Roman"} fontSize={16}>
          Q 6
        </Box>
        <Box ml={1} fontFamily={"Times New Roman"} fontSize={16}>
          months
        </Box>
      </Box>

      <Box
        mt={2}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"flex-end"}
      >
        <Box fontFamily={"Times New Roman"} fontSize={16} fontWeight={"bold"}>
          Attendant Name:{" "}
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 570,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
              color: "black",
            }}
            type="text"
            disabled
            value={data?.attendantA}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>

      <Box mt={2} flexDirection={"row"} display={"flex"} alignItems={"center"}>
        <Box fontFamily={"Times New Roman"} fontSize={16} fontWeight={"bold"}>
          Method of Visit (thick one):{" "}
        </Box>
      </Box>
      <Box>
        <Box display={"flex"} fontFamily={"Times New Roman"}>
          <input
            id="By Phone"
            style={{
              paddingLeft: 5,
              fontFamily: "Times New Roman",
              accentColor: "black",
            }}
            type="radio"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />

          <label for="By Phone" fontFamily={"Times New Roman"} fontSize={16}>
            By Phone
          </label>
        </Box>
        <Box display={"flex"} fontFamily={"Times New Roman"}>
          <input
            id="In person"
            style={{
              paddingLeft: 5,
              fontFamily: "Times New Roman",
              accentColor: "black",
            }}
            type="radio"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
          <label for="In person" fontFamily={"Times New Roman"} fontSize={16}>
            In Person with individual participation
          </label>
        </Box>
        <Box display={"flex"} fontFamily={"Times New Roman"}>
          <input
            id="without"
            style={{
              paddingLeft: 5,
              fontFamily: "Times New Roman",
              accentColor: "black",
            }}
            type="radio"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
          <label for="without" fontFamily={"Times New Roman"} fontSize={16}>
            In Person without individual participation
          </label>
        </Box>

        <Box display={"flex"} fontFamily={"Times New Roman"}>
          <input
            id="office"
            style={{
              paddingLeft: 5,
              fontFamily: "Times New Roman",
              accentColor: "black",
            }}
            type="radio"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
          <label for="office" fontFamily={"Times New Roman"} fontSize={16}>
            In the Office
          </label>
        </Box>
      </Box>

      <Box
        mt={1}
        flexDirection={"row"}
        display={"flex"}
        alignItems={"flex-end"}
      >
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          A. Enter the total number of hours of service that the attendant is to
          provide per week:
        </Box>
        <Box flexDirection={"row"} display={"flex"} alignItems={"flex-end"}>
          <label>{"("}</label>
          <Box borderBottom={"1px solid black"}>
            <input
              style={{
                border: "0px",
                width: 90,
                height: 20,
                paddingLeft: 5,
                fontFamily: "Times New Roman",
              }}
              type="text"
              // value={data?.individual}
              // onChange={(e) => setData({ ...data, individual: e.target.value })}
              name="individual"
            />
          </Box>
          <label style={{ fontFamily: "Times New Roman" }}>{"hrs)"}</label>
        </Box>
      </Box>

      <Box display={"flex"} mt={1} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          B. Is individual service plan adequate? Y
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          N
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16} ml={2}>
          If no, Why:
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 600,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box borderBottom={"1px solid black"} ml={2} mr={8}>
        <input
          style={{
            border: "0px",
            width: "100%",
            height: 20,
            paddingLeft: 5,
            fontFamily: "Times New Roman",
          }}
          type="text"
          // value={data?.individual}
          // onChange={(e) => setData({ ...data, individual: e.target.value })}
          name="individual"
        />
      </Box>

      <Box display={"flex"} mt={1} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          C. Does individual continue to need services? Y
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          N
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16} ml={2}>
          If no, Why:
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 600,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box borderBottom={"1px solid black"} ml={2} mr={8}>
        <input
          style={{
            border: "0px",
            width: "100%",
            height: 20,
            paddingLeft: 5,
            fontFamily: "Times New Roman",
          }}
          type="text"
          // value={data?.individual}
          // onChange={(e) => setData({ ...data, individual: e.target.value })}
          name="individual"
        />
      </Box>

      <Box display={"flex"} mt={1} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          D. Does individual need a service plan change? Y
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          N
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>

      <Box display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16} ml={2}>
          If no, Why:
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 600,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box borderBottom={"1px solid black"} ml={2} mr={8}>
        <input
          style={{
            border: "0px",
            width: "100%",
            height: 20,
            paddingLeft: 5,
            fontFamily: "Times New Roman",
          }}
          type="text"
          // value={data?.individual}
          // onChange={(e) => setData({ ...data, individual: e.target.value })}
          name="individual"
        />
      </Box>

      <Box display={"flex"} mt={1} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          E. Does attendant continue to be competent to provide the authorized
          task? Y
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 40,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          N
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 50,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          N/A
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 50,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16} ml={2}>
          If no, Why:
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 600,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box borderBottom={"1px solid black"} ml={2} mr={8}>
        <input
          style={{
            border: "0px",
            width: "100%",
            height: 20,
            paddingLeft: 5,
            fontFamily: "Times New Roman",
          }}
          type="text"
          // value={data?.individual}
          // onChange={(e) => setData({ ...data, individual: e.target.value })}
          name="individual"
        />
      </Box>

      <Box display={"flex"} mt={1} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          F. Is attendant delivering the authorized tasks? Y
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          N
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          N/A
        </Box>
        <Box borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0px",
              width: 90,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={"Times New Roman"} fontSize={16} ml={2}>
          If no, Why:
        </Box>
        <Box borderBottom={"1px solid black"} width={"80%"}>
          <input
            style={{
              border: "0px",
              width: "100%",
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
      </Box>

      <Box mt={4} display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <SignatureCanvas
              onEnd={(data) =>
                setSign_4(
                  signature_4.current.getTrimmedCanvas().toDataURL("image/png")
                )
              }
              ref={signature_4}
              penColor="black"
              dotSize={0.5}
              canvasProps={{
                width: 200,
                height: 30,
                className: "sigCanvas",
              }}
            />
            <input
              style={{
                border: "0px",
                width: 100,
                height: 20,
                paddingLeft: 5,
                fontFamily: "Times New Roman",
              }}
              type="text"
              // value={data?.individual}
              // onChange={(e) => setData({ ...data, individual: e.target.value })}
              name="individual"
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            borderTop={"1px solid black"}
          >
            <Box fontFamily={"Times New Roman"} fontSize={16}>
              Individual Signature
            </Box>
            <Box fontFamily={"Times New Roman"} fontSize={16}>
              Date
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <SignatureCanvas
              onEnd={(data) =>
                setSign_4(
                  signature_4.current.getTrimmedCanvas().toDataURL("image/png")
                )
              }
              ref={signature_4}
              penColor="black"
              dotSize={0.5}
              canvasProps={{
                width: 200,
                height: 30,
                className: "sigCanvas",
              }}
            />
            <input
              style={{
                border: "0px",
                width: 100,
                height: 20,
                paddingLeft: 5,
                fontFamily: "Times New Roman",
              }}
              type="text"
              // value={data?.individual}
              // onChange={(e) => setData({ ...data, individual: e.target.value })}
              name="individual"
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            borderTop={"1px solid black"}
          >
            <Box fontFamily={"Times New Roman"} fontSize={16}>
              Attendant Signature (Optional)
            </Box>
            <Box fontFamily={"Times New Roman"} fontSize={16}>
              Date
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={1}>
        <Box width={300} display={"flex"} alignItems={"flex-end"}>
          <SignatureCanvas
            onEnd={(data) =>
              setSign_4(
                signature_4.current.getTrimmedCanvas().toDataURL("image/png")
              )
            }
            ref={signature_4}
            penColor="black"
            dotSize={0.5}
            canvasProps={{
              width: 200,
              height: 30,
              className: "sigCanvas",
            }}
          />
          <input
            style={{
              border: "0px",
              width: 100,
              height: 20,
              paddingLeft: 5,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.individual}
            // onChange={(e) => setData({ ...data, individual: e.target.value })}
            name="individual"
          />
        </Box>
        <Box
          width={300}
          display={"flex"}
          justifyContent={"space-between"}
          borderTop={"1px solid black"}
        >
          <Box fontFamily={"Times New Roman"} fontSize={16}>
            Supervisor Signature
          </Box>
          <Box fontFamily={"Times New Roman"} fontSize={16}>
            Date
          </Box>
        </Box>
      </Box>

      <Box height={200} />
    </Box>
  );
};

export default SupervisoryVisit;
