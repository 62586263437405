import React, { useEffect, useState } from "react";
import {
  AssessmentOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  ListAltOutlined,
  MonetizationOnOutlined,
  PasswordOutlined,
  PersonOutlined,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import AdminSubMenuList from "../AdminSubMenuList";
import FormSubMenuList from "../FormSubMenuList";
import colours from "../../constants/colours";
import ReportSubMenuList from "../ReportSubMenuList";
import SettingsSubMenu from "../SettingsSubMenu";

const SideMenu = ({ setSelectedPage }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState("");

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const checkPage = (page) => {
    if (selectedItemIndex === page) {
      setSelectedItemIndex("");
    } else {
      setSelectedItemIndex(page);
    }
  };

  return (
    <div>
      <Box justifyContent={"center"} display={"flex"} pt={2}>
        <Box
          display={"flex"}
          component="img"
          alt="TurboPas"
          src="./images/logo_c.png"
          borderRadius={100}
          width={50}
          height={50}
          alignSelf={"center"}
        />
      </Box>
      <Box color={colours.white} pt={3}>
        <nav aria-label="TurboPass Dashboard">
          <List>
            <ListItem disablePadding>
              <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event) => {
                  checkPage("dashboard");
                  handleListItemClick(event, 0);
                }}
              >
                <ListItemIcon>
                  <ListAltOutlined style={{ color: colours.white }} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
                {selectedItemIndex === "dashboard" ? (
                  <ExpandLessOutlined />
                ) : (
                  <ExpandMoreOutlined />
                )}
              </ListItemButton>
            </ListItem>
            <Divider />

            <ListItem disablePadding>
              <ListItemButton
                selected={selectedIndex === 21}
                onClick={() => {
                  checkPage("admin");
                  setSelectedIndex(21);
                }}
              >
                <ListItemIcon>
                  <ListAltOutlined style={{ color: colours.white }} />
                </ListItemIcon>
                <ListItemText primary={"Admin"} />
                {selectedItemIndex === "admin" ? (
                  <ExpandLessOutlined />
                ) : (
                  <ExpandMoreOutlined />
                )}
              </ListItemButton>
            </ListItem>
            <Divider />
            <AdminSubMenuList
              open={selectedItemIndex === "admin"}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              setSelectedPage={setSelectedPage}
            />
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                selected={selectedIndex === 31}
                onClick={() => {
                  checkPage("form");
                  setSelectedIndex(31);
                }}
              >
                <ListItemIcon>
                  <PersonOutlined style={{ color: colours.white }} />
                </ListItemIcon>
                <ListItemText primary="Form" />
                {selectedItemIndex === "form" ? (
                  <ExpandLessOutlined />
                ) : (
                  <ExpandMoreOutlined />
                )}
              </ListItemButton>
            </ListItem>
            <Divider />
            <FormSubMenuList
              open={selectedItemIndex === "form"}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              setSelectedPage={setSelectedPage}
            />

            <ListItem disablePadding>
              <ListItemButton
                selected={selectedIndex === 4}
                onClick={(event) => {
                  handleListItemClick(event, 4);
                  checkPage("reports");
                }}
              >
                <ListItemIcon>
                  <AssessmentOutlined style={{ color: colours.white }} />
                </ListItemIcon>
                <ListItemText primary="Reports" />
                {selectedItemIndex === "reports" ? (
                  <ExpandLessOutlined />
                ) : (
                  <ExpandMoreOutlined />
                )}
              </ListItemButton>
            </ListItem>
            <Divider />
            <ReportSubMenuList
              open={selectedItemIndex === "reports"}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              setSelectedPage={setSelectedPage}
            />
            <Divider />

            <ListItem disablePadding>
              <ListItemButton
                selected={selectedIndex === 5}
                onClick={(event) => {
                  handleListItemClick(event, 5);
                }}
              >
                <ListItemIcon>
                  <MonetizationOnOutlined style={{ color: colours.white }} />
                </ListItemIcon>
                <ListItemText primary="Billings" />
              </ListItemButton>
            </ListItem>
            <Divider />

            <ListItem disablePadding>
              <ListItemButton
                selected={selectedIndex === 6}
                onClick={(event) => {
                  checkPage("settings");
                  handleListItemClick(event, 6);
                }}
              >
                <ListItemIcon>
                  <Settings style={{ color: colours.white }} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {selectedItemIndex === "settings" ? (
                  <ExpandLessOutlined />
                ) : (
                  <ExpandMoreOutlined />
                )}
              </ListItemButton>
            </ListItem>
            <Divider />
            <SettingsSubMenu
              open={selectedItemIndex === "settings"}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              setSelectedPage={setSelectedPage}
            />
            <Divider />
          </List>
        </nav>
      </Box>
    </div>
  );
};

export default SideMenu;
