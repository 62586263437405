import React, { useContext } from "react";
import colours from "../../constants/colours";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { PasswordOutlined, PersonOutlined } from "@mui/icons-material";
import { UserContext } from "../../context/userContext";

const SettingsSubMenu = ({
  open,
  setSelectedIndex,
  selectedIndex,
  setSelectedPage,
}) => {
  const { loginDetails } = useContext(UserContext);
  const setting = (index, screen, url) => {
    setSelectedIndex(index);
    window.history.replaceState("", screen, url);
    if (setSelectedPage) setSelectedPage(screen);
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {loginDetails?.user[0]?.user_role === process.env.REACT_APP_COMPANY && (
          <ListItemButton
            selected={selectedIndex === 52}
            sx={{ pl: 4 }}
            onClick={() => {
              setting(52, "Users", "Users");
            }}
          >
            <ListItemIcon>
              <PersonOutlined style={{ color: colours.white, fontSize: 16 }} />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        )}
        <Divider />
        <ListItemButton
          selected={selectedIndex === 53}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(53, "Password", "Password");
          }}
        >
          <ListItemIcon>
            <PasswordOutlined style={{ color: colours.white, fontSize: 16 }} />
          </ListItemIcon>
          <ListItemText primary="Password" />
        </ListItemButton>{" "}
        <Divider />
      </List>
    </Collapse>
  );
};

export default SettingsSubMenu;
