import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import { Add, RemoveRedEyeOutlined } from "@mui/icons-material";

import colours from "../../constants/colours";
// import FormDialog from "./form";
import {
  getEmployee,
  getServiceTypes,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";
import EmployeeForm from "./Form";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 20,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    headerClassName: "super-app-theme--header",
    valueGetter: (params) =>
      `${params.row.firstname || ""} ${params.row.lastname || ""}`,
  },
  // { field: "title", headerName: "Role" },
  {
    field: "address",
    headerName: "Address",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "city",
    headerName: "City",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "state",
    headerName: "State",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "phone",
    headerName: "Phone",
    headerClassName: "super-app-theme--header",
  }, //checkbox
  {
    field: "email",
    headerName: "Email",
    headerClassName: "super-app-theme--header",
    width: 150,
  },
  {
    field: "maritalStatus",
    headerName: "Marital Status",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "licenseStatus",
    headerName: "License Status",
    headerClassName: "super-app-theme--header",
    width: 120,
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <Typography>
          <RemoveRedEyeOutlined
            style={{ color: colours.primary, cursor: "pointer" }}
          />
        </Typography>
      );
    },
  },
];

const Employee = () => {
  const { loginDetails } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const { setGetData, getData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenForm = () => {
    setOpen(!open);
  };

  const getEmployeeData = () => {
    getEmployee(loginDetails?.user[0]?.user_id, loginDetails.token, setData);
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <Box mt={4}>
      <Box display={"flex"} justifyContent={"right"} mb={2}>
        <Button
          variant="contained"
          style={{ backgroundColor: colours.primary }}
          startIcon={<Add />}
          onClick={() => handleOpenForm()}
        >
          Add Employee
        </Button>
      </Box>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            color: colours.primary,
          },
          "& .super-app-theme--header": {
            backgroundColor: colours.secondary,
          },
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        // checkboxSelection
      />
      <EmployeeForm open={open} handleOpenForm={handleOpenForm} />
    </Box>
  );
};

export default Employee;
