import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, TextField } from "@mui/material";

import colours from "../../constants/colours";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PayerForm = ({ open, handleOpenForm }) => {
  const [data, setData] = useState({});

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Payer
            </Typography>
            <Button autoFocus color="inherit" onClick={handleOpenForm}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          padding={4}
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"30%"}>
            <Box mb={1}>Name</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="name"
              label="Name"
              variant="outlined"
              onChange={handleChange}
              name="name"
              value={data?.name}
            />

            <Box mt={3} mb={1}>
              Primary Identifier Number
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="primaryID"
              label="Primary Identifier Number"
              variant="outlined"
              value={data?.primaryID}
              onChange={handleChange}
              name="primaryID"
            />
            <Box mt={3} mb={1}>
              Entity ID Code
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="entityIdCode"
              label="Entity ID Code"
              variant="outlined"
              value={data?.entityIdCode}
              onChange={handleChange}
              name="entityIdCode"
            />
            <Box mt={3} mb={1}>
              ID Code Qualifier
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="IdCodeQualifier"
              label="ID Code Qualifier"
              variant="outlined"
              value={data?.IdCodeQualifier}
              onChange={handleChange}
              name="IdCodeQualifier"
            />
          </Box>
          <Box width={"30%"}>
            <Box mb={1}>Address</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="address"
              label="Address"
              variant="outlined"
              onChange={handleChange}
              name="address"
              value={data?.address}
            />

            <Box mt={3} mb={1}>
              City
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="city"
              label="City"
              variant="outlined"
              value={data?.city}
              onChange={handleChange}
              name="city"
            />
            <Box mt={3} mb={1}>
              State
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="state"
              label="State"
              variant="outlined"
              value={data?.state}
              onChange={handleChange}
              name="state"
            />
            <Box mt={3} mb={1}>
              Zip Code
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="zipCode"
              label="Zip Code"
              variant="outlined"
              value={data?.zipCode}
              onChange={handleChange}
              name="zipCode"
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default PayerForm;
