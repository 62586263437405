import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Alert, Box, Container, Step, StepLabel, Stepper } from "@mui/material";

import colours from "../../constants/colours";
import { UserContext } from "../../context/userContext";
import EmployeeInfo from "./EmployeeInfo";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import Emmergency from "./Emergency";
import Dates from "./Dates";
import ExpirationDates from "./ExpirationDates";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmployeeForm = ({ open, handleOpenForm }) => {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setGetData } = useContext(UserContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = [
    "Employee Info",
    "Emergency Contact",
    "Dates",
    "Expiration Dates",
  ];
  const [data, setData] = useState({});

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };
  const handleCreateServiceType = () => {
    handleOpenForm();
  };

  useEffect(() => {
    if (success) {
      setGetData(true);
      const timer = setTimeout(() => {
        setSuccess(false);
        handleOpenForm();
      }, 5000);

      // return clearTimeout(timer);
    }
  }, [success]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Emoloyee
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateServiceType}>
              {isLoading ? "Please wait..." : "Save"}
            </Button>
          </Toolbar>
        </AppBar>
        {success && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="success">New Employee Created Successfully.</Alert>
          </Box>
        )}
        {errorMessage && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="error">
              New Employee Was Not Created. All Fields Are required!
            </Alert>
          </Box>
        )}
        <Box mt={3} />
        <Container>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Container>

        {activeStep === 0 && (
          <EmployeeInfo data={data} handleChange={handleChange} />
        )}
        {activeStep === 1 && (
          <Emmergency data={data} handleChange={handleChange} />
        )}
        {activeStep === 2 && <Dates data={data} handleChange={handleChange} />}
        {activeStep === 3 && (
          <ExpirationDates data={data} handleChange={handleChange} />
        )}

        <Box mr={"20%"} ml={"20%"} mb={10}>
          {activeStep !== steps.length && (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography> */}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  startIcon={<ArrowBackIosNewOutlined />}
                  color="primary"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    activeStep === steps.length - 1
                      ? console.log("")
                      : handleNext
                  }
                  endIcon={
                    activeStep === steps.length - 1 ? (
                      ""
                    ) : (
                      <ArrowForwardIosOutlined />
                    )
                  }
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default EmployeeForm;

const styles = {};
