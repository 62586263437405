import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Dates = ({ data, handleChange }) => {
  return (
    <Box
      padding={4}
      flexDirection={"row"}
      justifyContent={"space-evenly"}
      display={"flex"}
    >
      <Box>
        <Box mt={3}>Application</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Application"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Ref. Verification</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Ref. Verification"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Hire</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Hire"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Signed Job Description</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Signed Job Description"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Orientation</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Orientation"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Box>
        <Box mt={3}>Assigned Task Eval</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Assigned Task Eval"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Crime Check</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Crime Check"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Misconduct Reg</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Misconduct Reg"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Last Evaluation</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Last Evaluation"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Policy/Procedure Statement</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Policy/Procedure Statement"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Box>
        <Box mt={3}>OIG Date</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="OIG Date"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>OIG Reported to State</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="OIG Reported to State"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Start Date</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Start Date"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>End Date</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="End Date"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default Dates;
