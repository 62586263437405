import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import { Add, RemoveRedEyeOutlined } from "@mui/icons-material";

import colours from "../../constants/colours";
import { getServiceTypes } from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";
import Form from "./Form";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 20,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "individual",
    headerName: "Individual",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "endDate",
    headerName: "End Date",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <Typography>
          <RemoveRedEyeOutlined
            style={{ color: colours.primary, cursor: "pointer" }}
          />
        </Typography>
      );
    },
  },
];

const HospitalStay = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([
    {
      id: 1,
      individual: "John Doe",
      startDate: "12/12/23",
      endDate: "12/12/23",
    },
    {
      id: 2,
      individual: "Jane Doe",
      startDate: "12/12/23",
      endDate: "12/12/23",
    },
  ]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const { setGetData, getData } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [token, setTokens] = useState(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjAsImlhdCI6MTY5NTY2NzM4NSwiZXhwIjoxNjk1NzUzNzg1fQ.TkJeV0nJzp7rUugP_zjyGbFsB1cdhJEydyOwrDPcjV4"
  );

  const handleOpenForm = () => {
    setOpen(!open);
  };

  return (
    <Box mt={4}>
      <Box display={"flex"} justifyContent={"right"} mb={2}>
        <Button
          variant="contained"
          style={{ backgroundColor: colours.primary }}
          startIcon={<Add />}
          onClick={() => handleOpenForm()}
        >
          Add Individual
        </Button>
      </Box>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            color: colours.primary,
          },
          "& .super-app-theme--header": {
            backgroundColor: colours.secondary,
          },
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        checkboxSelection
      />
      <Form open={open} handleOpenForm={handleOpenForm} />
    </Box>
  );
};

export default HospitalStay;
