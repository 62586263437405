import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Alert,
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";

import colours from "../../constants/colours";
import { UserContext } from "../../context/userContext";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { formatPhoneNumber } from "../FormatPhoneNumber";
import {
  createCaseworker,
  getCaseworkers,
} from "../../pages/main/Dashboard/business";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CaseWorkerForm = ({ open, handleOpenForm, setCaseworkerData }) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { loginDetails } = useContext(UserContext);
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    address: "",
    state: "",
    email: "",
    mail_code: "",
    fax: "",
    comment: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "phone" || e.target.name === "fax") {
      let value = formatPhoneNumber(e.target.value);
      data[e.target.name] = value;
    } else {
      data[e.target.name] = e.target.value;
    }
    setData({ ...data });
  };

  const handleCreateCaseworker = () => {
    setSuccess("");
    for (let field in data) {
      if (!data[field]) {
        setSuccess("All fields are required!");
        return;
      }
      if (data[field] === null) {
        setSuccess("Please check your phone or fax number");
        return;
      }
    }

    createCaseworker(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails?.token,
      setSuccess,
      setIsLoading
    );
  };

  useEffect(() => {
    if (success === "New caseworker created successfully") {
      setData({
        firstname: "",
        lastname: "",
        phone: "",
        address: "",
        state: "",
        email: "",
        mail_code: "",
        fax: "",
        comment: "",
      });
    }
  }, [success]);

  useEffect(() => {
    if (success === "New caseworker created successfully") {
      getCaseworkers(
        loginDetails?.user[0]?.user_id,
        loginDetails.token,
        setCaseworkerData
      );
    }
  }, [success]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Case Worker
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleCreateCaseworker()}
            >
              {isLoading ? "Please wait..." : "Save"}
            </Button>
          </Toolbar>
        </AppBar>
        {success && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert
              severity={
                success === "New caseworker created successfully"
                  ? "success"
                  : "error"
              }
            >
              {success}
            </Alert>
          </Box>
        )}
        <Box mt={3} />
        <Box
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              Firstname
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="firstname"
              label="Firstname"
              variant="outlined"
              onChange={handleChange}
              name="firstname"
              value={data.firstname}
            />
            <Box mt={3} mb={1}>
              Last Name
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="lastname"
              label="Last name"
              variant="outlined"
              onChange={handleChange}
              name="lastname"
              value={data.lastname}
            />
            <Box mt={3} mb={1}>
              Phone
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="phone"
              label="Phone"
              variant="outlined"
              onChange={handleChange}
              name="phone"
              value={data.phone}
            />
          </Box>

          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              Address
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="address"
              label="Address"
              variant="outlined"
              onChange={handleChange}
              name="address"
              value={data.address}
            />

            <Box mt={3} mb={1}>
              State
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="state"
              label="State"
              variant="outlined"
              onChange={handleChange}
              name="state"
              value={data.state}
            />
            <Box mt={3} mb={1}>
              Email
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="email"
              label="Email"
              variant="outlined"
              onChange={handleChange}
              name="email"
              value={data.email}
            />
          </Box>
          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              Mail code
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="mail_code"
              label="Mail Code"
              variant="outlined"
              onChange={handleChange}
              name="mail_code"
              value={data.mail_code}
            />

            <Box mt={3} mb={1}>
              Fax
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="fax"
              label="Fax"
              variant="outlined"
              onChange={handleChange}
              name="fax"
              value={data.fax}
            />
            <Box mt={3} mb={1}>
              Comment
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="comment"
              label="Comment"
              variant="outlined"
              onChange={handleChange}
              name="comment"
              value={data.comment}
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default CaseWorkerForm;

const styles = {};
