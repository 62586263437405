import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Alert, Box, TextField } from "@mui/material";

import colours from "../../constants/colours";
import { UserContext } from "../../context/userContext";
import { createDoctor, getDoctors } from "../../pages/main/Dashboard/business";
import { formatPhoneNumber } from "../FormatPhoneNumber";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DoctorForm = ({ open, handleOpenForm, setDoctorData }) => {
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { loginDetails } = useContext(UserContext);
  const [data, setData] = useState({
    upin_number: "",
    license_number: "",
    firstname: "",
    lastname: "",
    address: "",
    suit: "",
    city: "",
    state: "",
    zip_code: "",
    phone: "",
    fax: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "phone" || e.target.name === "fax") {
      let value = formatPhoneNumber(e.target.value);
      data[e.target.name] = value;
    } else {
      data[e.target.name] = e.target.value;
    }
    setData({ ...data });
  };

  const handleCreateDoctor = () => {
    setSuccess("");
    for (let field in data) {
      if (!data[field]) {
        setSuccess("All fields are required!");
        return;
      }
      if (data[field] === null) {
        setSuccess("Please check your phone or fax number");
        return;
      }
    }

    createDoctor(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails?.token,
      setSuccess,
      setIsLoading
    );

    // handleOpenForm();
  };

  useEffect(() => {
    if (success === "New doctor created successfully") {
      setData({
        upin_number: "",
        license_number: "",
        firstname: "",
        lastname: "",
        address: "",
        suit: "",
        city: "",
        state: "",
        zip_code: "",
        phone: "",
        fax: "",
      });
    }
  }, [success]);

  useEffect(() => {
    if (success === "New doctor created successfully") {
      getDoctors(
        loginDetails?.user[0]?.user_id,
        loginDetails.token,
        setDoctorData
      );
    }
  }, [success]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Doctor
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleCreateDoctor()}
            >
              {isLoading ? "Please wait..." : "Save"}
            </Button>
          </Toolbar>
        </AppBar>
        {success && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert
              severity={
                success === "New doctor created successfully"
                  ? "success"
                  : "error"
              }
            >
              {success}
            </Alert>
          </Box>
        )}

        <Box mt={3} />
        <Box
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              UPIN Number
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="upin_number"
              label="UPIN Number"
              variant="outlined"
              onChange={(e) => handleChange(e)}
              name="upin_number"
              value={data?.upin_number}
            />
            <Box mt={3} mb={1}>
              License Number
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="license_number"
              label="License Number"
              variant="outlined"
              onChange={handleChange}
              name="license_number"
              value={data.license_number}
            />
            <Box mt={3} mb={1}>
              Firstname
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="firstname"
              label="Firstname"
              variant="outlined"
              onChange={handleChange}
              name="firstname"
              value={data.firstname}
            />
            <Box mt={3} mb={1}>
              Last Name
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="lastname"
              label="Last name"
              variant="outlined"
              onChange={handleChange}
              name="lastname"
              value={data.lastname}
            />
          </Box>
          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              Address
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="address"
              label="Address"
              variant="outlined"
              onChange={handleChange}
              name="address"
              value={data.address}
            />
            <Box mt={3} mb={1}>
              Suit
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="suit"
              label="Suit"
              variant="outlined"
              onChange={handleChange}
              name="suit"
              value={data.suit}
            />
            <Box mt={3} mb={1}>
              City
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="city"
              label="City"
              variant="outlined"
              onChange={handleChange}
              name="city"
              value={data.city}
            />
            <Box mt={3} mb={1}>
              State
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="state"
              label="State"
              variant="outlined"
              onChange={handleChange}
              name="state"
              value={data.state}
            />
          </Box>
          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              Zip code
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="zip_code"
              label="Zip Code"
              variant="outlined"
              onChange={handleChange}
              name="zip_code"
              value={data.zip_code}
            />
            <Box mt={3} mb={1}>
              Phone
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="phone"
              label="Phone"
              variant="outlined"
              onChange={handleChange}
              name="phone"
              value={data.phone}
            />
            <Box mt={3} mb={1}>
              Fax
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="fax"
              label="Fax"
              variant="outlined"
              onChange={handleChange}
              name="fax"
              value={data.fax}
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default DoctorForm;

const styles = {};
