import React, { useEffect } from "react";
import colours from "../../constants/colours";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  LocalHospitalOutlined,
  MedicalServicesOutlined,
  Person2Outlined,
  Person3Outlined,
} from "@mui/icons-material";

const FormSubMenuList = ({
  open,
  setSelectedIndex,
  selectedIndex,
  setSelectedPage,
}) => {
  const setting = (index, screen, url) => {
    setSelectedIndex(index);
    window.history.replaceState("", screen, url);
    if (setSelectedPage) setSelectedPage(screen);
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton
          selected={selectedIndex === 32}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(32, "Individual List", "individual-lists");
          }}
        >
          <ListItemIcon>
            <MedicalServicesOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Individual List" />
        </ListItemButton>
        <Divider />
        <ListItemButton
          selected={selectedIndex === 33}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(33, "Employee", "Employee");
          }}
        >
          <ListItemIcon>
            <Person3Outlined style={{ color: colours.white, fontSize: 16 }} />
          </ListItemIcon>
          <ListItemText primary="Employee List" />
        </ListItemButton>
        <Divider />
        <ListItemButton
          selected={selectedIndex === 34}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(34, "Doctor", "Doctor");
          }}
        >
          <ListItemIcon>
            <LocalHospitalOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Doctor" />
        </ListItemButton>
        {/* <Divider />
        <ListItemButton
          selected={selectedIndex === 35}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(35, "Hospital Stay", "Hospital Stay");
          }}
        >
          <ListItemIcon>
            <MedicalServicesOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Hospital Stay" />
        </ListItemButton> */}
        <Divider />
        <ListItemButton
          selected={selectedIndex === 36}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(36, "Case Worker", "Case Worker");
          }}
        >
          <ListItemIcon>
            <Person3Outlined style={{ color: colours.white, fontSize: 16 }} />
          </ListItemIcon>
          <ListItemText primary="Case Worker" />
        </ListItemButton>
        <Divider />
      </List>
    </Collapse>
  );
};

export default FormSubMenuList;
