import React, { useRef, useContext } from "react";
import { EmailOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { UserContext } from "../../context/userContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import colours from "../../constants/colours";
import { useNavigate } from "react-router-dom";

const TopNav = ({ screen }) => {
  const navigate = useNavigate();

  const { loginDetails } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    if (action === "logout") {
      navigate("/", { replace: true });
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        flexDirection={"row"}
        justifyContent={"space-between"}
        display={"flex"}
        pt={2}
      >
        <Box
          fontFamily={"MyFont"}
          color={colours.primary}
          fontWeight={"bold"}
          fontSize={20}
        >
          {screen}
        </Box>
        <Box>
          <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
            <EmailOutlined
              style={{ color: colours.primary, verticalAlign: "center" }}
            />
            {/* <Box
              ml={4}
              display={"flex"}
              component="img"
              alt="Turbopas Logo"
              src="./images/img.png"
              borderRadius={100}
              width={30}
              height={30}
            /> */}

            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                {loginDetails?.user[0]?.firstname?.substr(0, 1)}
                {loginDetails?.user[0]?.name?.substr(0, 1)}
              </Avatar>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another user
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={() => handleClose("logout")}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

            <Box ml={0.5} fontFamily={"MyFont"} fontSize={12}>
              {loginDetails?.user[0]?.firstname}
              {` `}
              {loginDetails?.user[0]?.lastname} {` `}
              {loginDetails?.user[0]?.name}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default TopNav;
