import axios from "axios";
import routes from "../../../constants/routes";
import API_URL from "../../../constants/API_URL";

export const loginUser = async (
  data,
  setIsLoading,
  setStatus,
  setErrorMessage,
  setLoginDetails
) => {
  setIsLoading(true);
  setStatus(false);

  let config = {
    url: `${API_URL.url}${routes.route.login}`,
    method: "POST",
    data: {
      userID: data?.userID,
      password: data?.password,
      isCompany: data?.isCompany,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      role: data?.role,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsLoading(false);
      setStatus(true);
      setLoginDetails(data);
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error, "error");
      if (error?.response?.data?.message === "This user is not found!") {
        setErrorMessage(true);
      }
      if (error?.response?.data?.message === "Incorrect UserID or Password") {
        setErrorMessage(true);
      }
    });
};
