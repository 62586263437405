import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Login from "./pages/auth/Login";
import Dashboard from "./pages/main/Dashboard";
import ServiceTypes from "./pages/main/Admin/ServiceTypes";
import IndividualList from "./pages/main/Form/IndividualList";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/service-types" element={<ServiceTypes />} />
          <Route path="/individual-lists" element={<IndividualList />} /> */}
        </Routes>
      </div>
    </>
  );
}

export default App;
