import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  getSchedulesUnderAuthorization,
  getTasksUnderAuthorization,
} from "../../../../pages/main/Dashboard/business";
import { UserContext } from "../../../../context/userContext";

const CBA = ({
  data,
  setData,
  tasks,
  setTasks,
  setSchedules,
  schedules,
  isHideFooter,
  formName,
  selectedDate,
}) => {
  const serviceUnit = ["Hours", "Days", "N/A"];
  const deliveryMethods = [
    "Employee-Name of this Employee/Subcontractor:",
    "Personal Service Agreement-Name of Individual:",
    "Direct Purchase (use with service 15, 16, or 22 only)",
  ];
  const authorized = [
    "7-Occupational Therapy",
    "8-Physical Therapy",
    "9-Speech Pathology",
    "11-Respite Care, In Home",
    "13-Nursing Service",
    "15-Adaptive Aids",
    "16-Minor Home Modifications",
    "17-Personal Assistance Service",
    "22-Medical Supplies",
    "41-C-Specifications - Sdaptive Aids",
    "41-D-Specifications - Minor Home Modifications",
  ];
  const [selectedPrint, setSelectedPrint] = useState({});
  const label = { inputProps: { "aria-label": "This is area" } };
  const { loginDetails } = useContext(UserContext);
  const [selectedSchedulePrint, setSelectedSchedulePrint] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("");

  useEffect(() => {
    if (tasks?.length > 0) {
      setSelectedPrint(tasks[0]);
    }
  }, [data?.authorzation_id, tasks]);

  //set initial schedule
  useEffect(() => {
    if (schedules?.length > 0) {
      selectPrint(0);
    } else {
      setSelectedSchedulePrint({});
    }
  }, [data?.authorzation_id, schedules]);

  useEffect(() => {
    //get all tasks, weekly schedules under this authorization id

    getTasksUnderAuthorization(
      loginDetails?.user[0]?.user_id,
      data?.authorzation_id,
      loginDetails.token,
      setTasks
    );

    getSchedulesUnderAuthorization(
      loginDetails?.user[0]?.user_id,
      data?.authorzation_id,
      loginDetails.token,
      setSchedules
    );
  }, [data?.authorzation_id]);

  //Task and Schedule
  const selectPrint = (index) => {
    let result = {};
    let result_ = {};
    setSelectedIndex(index);

    for (
      let i = 0;
      i < (tasks.length >= schedules.length ? tasks.length : schedules.length);
      i++
    ) {
      if (index === i) {
        // result = tasks[i];
        result_ = schedules[i];
      }
    }

    // setSelectedPrint(result);
    setSelectedSchedulePrint(result_);
  };

  const timeDiff = (timeIn, timeOut) => {
    if (!timeIn) return "-";
    const diff = moment.duration(
      moment(timeIn, "HH:mm:ss a").diff(moment(timeOut, "HH:mm:ss a"))
    );

    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    const result = `${hours} : ${minutes} : ${seconds}`;

    return moment(result, "HH:mm").format("HH:mm");
  };

  return (
    <Box fontFamily={"Times New Roman"}>
      <Box
        fontWeight={"bold"}
        textAlign={"center"}
        fontSize={24}
        fontFamily={""}
      >
        Amazing Healthcare Agency
      </Box>
      <Box
        textTransform={"uppercase"}
        fontSize={18}
        mt={0.5}
        textAlign={"center"}
      >
        Service Delivery Record (HGAAA PAS)
      </Box>
      <Box mt={1}>
        <Box fontWeight={"bold"} fontSize={14}>
          SECTION A-PARTICIPANT INFORMATION
        </Box>
        <Box border={"1px solid black"} display={"flex"}>
          <Box fontSize={14} flex={3} pl={0.5}>
            <Box>1 Name</Box>
            <Box fontWeight={"bold"} ml={1}>
              {data?.individual?.firstname} {data?.individual?.lastname}
            </Box>
          </Box>
          <Box fontSize={14} flex={1} borderLeft={"1px solid black"} pl={0.5}>
            <Box>Medicaid#:</Box>
            <Box fontWeight={"bold"} ml={1}>
              {data?.individual?.medicaid}
            </Box>
          </Box>
          <Box fontSize={14} flex={1} borderLeft={"1px solid black"} pl={0.5}>
            <Box>2 Service Month and Year</Box>
            <Box fontWeight={"bold"} ml={1}>
              2/2/2024
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={1}>
        <Box fontWeight={"bold"} fontSize={14}>
          SECTION B-SERVICES
        </Box>
        <Box border={"1px solid black"}>
          <Box display={"flex"} pb={0.5} borderBottom={"1px solid black"}>
            <Box flex={3}>
              <Box ml={0.5}>3 Program Provider Name</Box>
              <Box ml={1} fontWeight={"bold"} fontSize={14}>
                Amazing Healthcare Agency
              </Box>
            </Box>
            <Box flex={1} borderLeft={"1px solid black"} pl={0.5}>
              4. Vendor No.
            </Box>
          </Box>
          <Box pl={0.5}>5. Method of Delivery (check only one)</Box>
          <Box paddingLeft={2}>
            <RadioGroup>
              {deliveryMethods.map((item) => {
                return (
                  <Box display={"flex"} mb={0.5}>
                    <FormControlLabel
                      style={{ padding: 0 }}
                      value={item}
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "black",
                            },
                          }}
                          size="small"
                          style={{ borderColor: "black", padding: 2 }}
                        />
                      }
                      label={item}
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 14,
                        },
                      }}
                    />
                    <Box borderBottom={"1px solid black"} flex={1}>
                      {item?.includes("Employee") &&
                        data?.employee?.firstname &&
                        data?.employee?.firstname +
                          ", " +
                          data?.employee?.lastname}
                      {item?.includes("Personal Service") &&
                        data?.individual?.firstname &&
                        data?.individual?.firstname +
                          ", " +
                          data?.individual?.lastname}
                    </Box>
                  </Box>
                );
              })}
            </RadioGroup>
          </Box>
          <Box pl={0.5} borderTop={"1px solid black"}>
            6. Authorized Service check only one)
          </Box>
          <Box paddingLeft={2}>
            <RadioGroup row>
              {authorized.map((item, index) => {
                return (
                  <FormControlLabel
                    style={{ padding: 0 }}
                    value={item}
                    control={
                      <Radio
                        sx={{
                          "&, &.Mui-checked": {
                            color: "black",
                          },
                        }}
                        size="small"
                        style={{ borderColor: "black", padding: 2 }}
                      />
                    }
                    label={item}
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: 14,
                        // fontWeight: "bold",
                      },
                    }}
                  />
                );
              })}
            </RadioGroup>
          </Box>
        </Box>
        <Box>
          <Box>
            <Box>
              <table
                border={1}
                style={{ borderCollapse: "collapse" }}
                width={"100%"}
              >
                <tr>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                      paddingTop: -10,
                      paddingBottom: -10,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="bathing"
                            id="bathing"
                            value={data?.bathing}
                            onChange={(e) =>
                              setData({ ...data, bathing: e.target.checked })
                            }
                            checked={selectedPrint?.bathing == 1 ? true : false}
                          />
                        }
                        label="Bathing/Banar"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="routine_hair_skin_care"
                            id="routine_hair_skin_care"
                            value={data?.routine_hair_skin_care}
                            onChange={(e) =>
                              setData({
                                ...data,
                                routine_hair_skin_care: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.routine_hair_skin_care == 1
                                ? true
                                : false
                            }
                          />
                        }
                        label="Routine Hair & Skin Care/Cuidado Rutinario de Pelo/Cutis"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="laundry"
                            id="laundry"
                            value={data?.laundry}
                            onChange={(e) =>
                              setData({
                                ...data,
                                laundry: e.target.checked,
                              })
                            }
                            checked={selectedPrint?.laundry == 1 ? true : false}
                          />
                        }
                        label="Laundry/Lavar Ropa"
                      />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="dressing"
                            id="dressing"
                            value={data?.dressing}
                            onChange={(e) =>
                              setData({
                                ...data,
                                dressing: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.dressing == 1 ? true : false
                            }
                          />
                        }
                        label="Dressing/Vestir"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="toilet"
                            id="toilet"
                            value={data?.toilet}
                            onChange={(e) =>
                              setData({
                                ...data,
                                toilet: e.target.checked,
                              })
                            }
                            checked={selectedPrint?.toilet == 1 ? true : false}
                          />
                        }
                        label="Toileting/Necesidades del Cuerpo"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="meal_preparation"
                            id="meal_preparation"
                            value={data?.meal_preparation}
                            onChange={(e) =>
                              setData({
                                ...data,
                                meal_preparation: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.meal_preparation == 1
                                ? true
                                : false
                            }
                          />
                        }
                        label="Meal Preparation/Preparar Comidas"
                      />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="excercising"
                            id="excercising"
                            value={data?.excercising}
                            onChange={(e) =>
                              setData({
                                ...data,
                                excercising: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.excercising == 1 ? true : false
                            }
                          />
                        }
                        label="Exercise/Hacer Ejercicios"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="walking"
                            id="walking"
                            value={data?.walking}
                            onChange={(e) =>
                              setData({
                                ...data,
                                walking: e.target.checked,
                              })
                            }
                            checked={selectedPrint?.walking == 1 ? true : false}
                          />
                        }
                        label="Walking/Caminando"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="escort"
                            id="escort"
                            value={data?.escort}
                            onChange={(e) =>
                              setData({
                                ...data,
                                escort: e.target.checked,
                              })
                            }
                            checked={selectedPrint?.escort == 1 ? true : false}
                          />
                        }
                        label="Escort/Acompanar"
                      />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="feeding"
                            id="feeding"
                            value={data?.feeding}
                            onChange={(e) =>
                              setData({
                                ...data,
                                feeding: e.target.checked,
                              })
                            }
                            checked={selectedPrint?.feeding == 1 ? true : false}
                          />
                        }
                        label="Feeding/Dar de Comer"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="ambulation"
                            id="ambulation"
                            value={data?.ambulation}
                            onChange={(e) =>
                              setData({
                                ...data,
                                ambulation: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.ambulation == 1 ? true : false
                            }
                          />
                        }
                        label="Ambulation/Ambulacion"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="shopping"
                            id="shopping"
                            value={data?.shopping}
                            onChange={(e) =>
                              setData({
                                ...data,
                                shopping: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.shopping == 1 ? true : false
                            }
                          />
                        }
                        label="Shopping/Hacer Compras"
                      />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="shaving_oral_care"
                            id="shaving_oral_care"
                            value={data?.shaving_oral_care}
                            onChange={(e) =>
                              setData({
                                ...data,
                                shaving_oral_care: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.shaving_oral_care == 1
                                ? true
                                : false
                            }
                          />
                        }
                        label="Shaving, Oral Care/Aseo"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="cleaning"
                            id="cleaning"
                            value={data?.cleaning}
                            onChange={(e) =>
                              setData({
                                ...data,
                                cleaning: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.cleaning == 1 ? true : false
                            }
                          />
                        }
                        label="Cleaning/Limpiar"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{
                              color: "black",
                            }}
                            size="small"
                            {...label}
                            name="assistance_with_self_administered_medications"
                            id="assistance_with_self_administered_medications"
                            value={
                              selectedPrint?.assistance_with_self_administered_medications
                            }
                            onChange={(e) =>
                              setData({
                                ...data,
                                assistance_with_self_administered_medications:
                                  e.target.value,
                              })
                            }
                            checked={
                              selectedPrint?.assistance_with_self_administered_medications ==
                              1
                                ? true
                                : false
                            }
                          />
                        }
                        label="Ass. with Med/Ayudar con Medicinas"
                      />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    <Box p={0} mt={-1} mb={-1}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            ...style.fontFamily,
                            ...style.fontSize,
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            disabled
                            color="default"
                            sx={{ color: "black" }}
                            size="small"
                            {...label}
                            name="transfer"
                            id="transfer"
                            value={data?.transfer}
                            onChange={(e) =>
                              setData({
                                ...data,
                                transfer: e.target.checked,
                              })
                            }
                            checked={
                              selectedPrint?.transfer == 1 ? true : false
                            }
                          />
                        }
                        label="Transfer/Cambiar de Postura"
                      />
                    </Box>
                  </td>
                  <td
                    style={{
                      padding: 1,
                      paddingLeft: 5,
                      ...style.fontSize,
                      ...style.fontFamily,
                    }}
                  >
                    Others/Otra
                    <input
                      style={{
                        border: "0",
                        width: 230,
                        height: 18,
                        fontFamily: "Times New Roman",
                        ...style.fontSize,
                        paddingLeft: 5,
                        paddingBottom: 0,
                        marginLeft: 5,
                      }}
                      type="text"
                      value={data?.otherTasks}
                      onChange={(e) =>
                        setData({ ...data, otherTasks: e.target.value })
                      }
                      name="otherTasks"
                    />
                  </td>
                </tr>
              </table>
            </Box>
          </Box>

          <Box>
            <table
              style={{ borderCollapse: "collapse", width: "100%" }}
              border={1}
            >
              <tr>
                <td>&nbsp;&nbsp;</td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    padding: 2,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Sunday
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    padding: 2,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Monday
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    padding: 2,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Tuesday
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    padding: 2,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Wednesday
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    padding: 2,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Thursday
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    padding: 2,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Friday
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    padding: 2,
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  Saturday
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    fontWeight: "bold",
                    padding: 2,
                  }}
                >
                  In
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.sunday?.split("-")[0]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.monday?.split("-")[0]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.thursday?.split("-")[0]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.friday?.split("-")[0]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.saturday?.split("-")[0]?.trim()}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    fontWeight: "bold",
                    padding: 2,
                  }}
                >
                  Out
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.sunday?.split("-")[1]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.monday?.split("-")[1]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.tuesday?.split("-")[1]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.wednesday?.split("-")[1]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.thursday?.split("-")[1]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.friday?.split("-")[1]?.trim()}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                  }}
                >
                  {selectedSchedulePrint?.saturday?.split("-")[1]?.trim()}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    fontWeight: "bold",
                    padding: 2,
                  }}
                >
                  Total Hours
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {timeDiff(
                    selectedSchedulePrint?.sunday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.sunday?.split("-")[0]?.trim()
                  )}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {timeDiff(
                    selectedSchedulePrint?.monday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.monday?.split("-")[0]?.trim()
                  )}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {timeDiff(
                    selectedSchedulePrint?.tuesday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()
                  )}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {timeDiff(
                    selectedSchedulePrint?.wednesday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()
                  )}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {timeDiff(
                    selectedSchedulePrint?.thursday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.thursday?.split("-")[0]?.trim()
                  )}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {timeDiff(
                    selectedSchedulePrint?.friday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.friday?.split("-")[0]?.trim()
                  )}
                </td>
                <td
                  style={{
                    ...style.fontFamily,
                    ...style.fontSize,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {timeDiff(
                    selectedSchedulePrint?.saturday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.saturday?.split("-")[0]?.trim()
                  )}
                </td>
              </tr>
            </table>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"center"} mt={1}>
        <Box fontWeight={"bold"}>SECTION C-RECORD OF TIME</Box>
        <Box ml={1} mr={1}>
          UNIT OF SERVICE:{" "}
        </Box>
        <Box ml={1}>
          <RadioGroup row>
            {serviceUnit.map((item) => {
              return (
                <FormControlLabel
                  style={{ padding: 0 }}
                  value={item}
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "black",
                        },
                      }}
                      size="small"
                      style={{ borderColor: "black", padding: 2 }}
                    />
                  }
                  label={item}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 10,
                    },
                  }}
                />
              );
            })}
          </RadioGroup>
        </Box>
      </Box>
      <Box>
        <Box>
          <table border={1} style={{ borderCollapse: "collapse" }}>
            <tr>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
              <td style={{ padding: 5, fontSize: 12 }}>DAY</td>
              <td
                style={{
                  padding: 5,
                  fontSize: 12,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                UNITS
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>1</td>
              <td></td>
              <td style={{ textAlign: "center" }}>5</td>
              <td></td>
              <td style={{ textAlign: "center" }}>9</td>
              <td></td>
              <td style={{ textAlign: "center" }}>13</td>
              <td></td>
              <td style={{ textAlign: "center" }}>17</td>
              <td></td>
              <td style={{ textAlign: "center" }}>21</td>
              <td></td>
              <td style={{ textAlign: "center" }}>25</td>
              <td></td>
              <td style={{ textAlign: "center" }}>29</td>
              <td></td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>2</td>
              <td></td>
              <td style={{ textAlign: "center" }}>6</td>
              <td></td>
              <td style={{ textAlign: "center" }}>10</td>
              <td></td>
              <td style={{ textAlign: "center" }}>14</td>
              <td></td>
              <td style={{ textAlign: "center" }}>18</td>
              <td></td>
              <td style={{ textAlign: "center" }}>22</td>
              <td></td>
              <td style={{ textAlign: "center" }}>26</td>
              <td></td>
              <td style={{ textAlign: "center" }}>30</td>
              <td></td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>3</td>
              <td></td>
              <td style={{ textAlign: "center" }}>7</td>
              <td></td>
              <td style={{ textAlign: "center" }}>11</td>
              <td></td>
              <td style={{ textAlign: "center" }}>15</td>
              <td></td>
              <td style={{ textAlign: "center" }}>19</td>
              <td></td>
              <td style={{ textAlign: "center" }}>23</td>
              <td></td>
              <td style={{ textAlign: "center" }}>27</td>
              <td></td>
              <td style={{ textAlign: "center" }}>31</td>
              <td></td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>4</td>
              <td></td>
              <td style={{ textAlign: "center" }}>8</td>
              <td></td>
              <td style={{ textAlign: "center" }}>12</td>
              <td></td>
              <td style={{ textAlign: "center" }}>16</td>
              <td></td>
              <td style={{ textAlign: "center" }}>20</td>
              <td></td>
              <td style={{ textAlign: "center" }}>24</td>
              <td></td>
              <td style={{ textAlign: "center" }}>28</td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                }}
                colSpan={3}
              >
                TOTAL UNITS:
              </td>
              {/* <td></td> */}
            </tr>
          </table>
        </Box>
      </Box>
      <Box mt={2} fontWeight={"bold"}>
        SECTION D-CERTIFICATIONS
      </Box>
      <Box border={"1px solid black"} p={1}>
        <Box display={"flex"}>
          <Box flex={1}>
            This is to certify that I, the timekeeper, have cerified that the
            units of service were delivered as documented.
          </Box>
          <Box flex={1}>
            This is to certify thta I, the employee, provided the services
            recorded above, or that I completed all work required to all
            specifications.
          </Box>
        </Box>
        <Box display={"flex"} mt={5}>
          <Box display={"flex"} flex={1} borderTop={"1px solid black"}>
            <Box flex={1}>Signature-Individual</Box>
            <Box flex={1}>Date</Box>
          </Box>
          <Box ml={2} display={"flex"} flex={1} borderTop={"1px solid black"}>
            <Box flex={1}>Signature-Timekeeper</Box>
            <Box flex={1}>Date</Box>
          </Box>
          <Box ml={2} display={"flex"} flex={1} borderTop={"1px solid black"}>
            <Box flex={1}>Signature-Employee</Box>
            <Box flex={1}>Date</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CBA;
const style = {
  fontSize: {
    fontSize: 10,
  },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
};
