import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import colours from "../../constants/colours";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ContractForm = ({ open, handleOpenForm }) => {
  const [serviceType, setServiceType] = React.useState("");
  const [data, setData] = useState({});

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Vesta Contract
            </Typography>
            <Button autoFocus color="inherit" onClick={handleOpenForm}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          padding={4}
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"20%"}>
            <Box mb={1}>Vesta Contract</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="vestaContract"
              label="Vesta Contract"
              variant="outlined"
              onChange={handleChange}
              name="vestaContract"
              value={data?.vestaContract}
            />
            <Box mt={3} mb={1}>
              Unique ID
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="uniqueId"
              label="Unique ID"
              variant="outlined"
              value={data?.uniqueId}
              onChange={handleChange}
              name="uniqueId"
            />
            <Box mt={3} mb={1}>
              Contract Name
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="contractName"
              label="Contract Name"
              variant="outlined"
              value={data?.contractName}
              onChange={handleChange}
              name="contractName"
            />
            <Box mt={3} mb={1}>
              Payer
            </Box>
            <Box>
              <FormControl fullWidth size="small">
                <InputLabel id="payer">Payer</InputLabel>
                <Select
                  labelId="payer"
                  id="payer"
                  value={data?.payer}
                  label="Payer"
                  onChange={handleChange}
                  name="payer"
                  defaultValue={""}
                >
                  <MenuItem value="">Payer coming soon</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box width={"20%"}>
            <Box mb={1}>Payer Code</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="payerCode"
              label="Payer Code"
              variant="outlined"
              name="payerCode"
              onChange={handleChange}
            />
            <Box mt={3} mb={1}>
              TIN
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="tin"
              label="TIN"
              variant="outlined"
              name="tin"
              onChange={handleChange}
            />

            <Box mt={3} mb={1}>
              NPI
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="npi"
              label="NPI"
              variant="outlined"
              name="npi"
              onChange={handleChange}
            />
            <Box mt={3} mb={1}>
              API
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="api"
              label="API"
              variant="outlined"
              name="api"
              onChange={handleChange}
            />
          </Box>
          <Box width={"20%"}>
            <Box mb={1}>Taxonomy</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="taxonomy"
              label="Taxonomy"
              variant="outlined"
              name="taxonomy"
              onChange={handleChange}
            />
            <Box mt={3} mb={1}>
              Status
            </Box>
            <Box>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={serviceType}
                  label="Service Type"
                  onChange={handleChange}
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default ContractForm;

const styles = {
  addIcon: {
    color: colours.primary,
    cursor: "pointer",
    position: "absolute",
    marginTop: 15,
    marginLeft: 3,
  },
};
