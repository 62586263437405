export const calendarData = [
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
  {
    sunday: {
      in: "",
      out: "",
      total: "",
      sumTotal: "", //this the sum total of all the times
    },
    monday: {
      in: "",
      out: "",
      total: "",
    },
    tuesday: {
      in: "",
      out: "",
      total: "",
    },
    wednesday: {
      in: "",
      out: "",
      total: "",
    },
    thursday: {
      in: "",
      out: "",
      total: "",
    },
    friday: {
      in: "",
      out: "",
      total: "",
    },
    saturday: {
      in: "",
      out: "",
      total: "",
    },
    startDate: "",
    endDate: "",
    status: "",
  },
];

export const taskData = {
  bathing: false,
  dressing: false,
  excercising: false,
  feeding: false,
  shaving_oral_care: false,
  laundry: false,
  toilet: false,
  transfer: false,
  cleaning: false,
  routine_hair_skin_care: false,
  meal_preparation: false,
  escort: false,
  shopping: false,
  walking: false,
  ambulation: false,
  assistance_with_self_administered_medications: false,
  others: "",
};
