import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";

import colours from "../../constants/colours";
import {
  getAuthorizations,
  getAuthorizationsUnderIndividual,
  getCompany,
  getEmployee,
  getIndividualList,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";
import RelationshipEvidence from "../RelationshipEvidence";
import AttendantOrientation from "../AttendantOrientation";
import SupervisoryVisit from "../SupervisoryVisit";
import ServiceDeliveryPlan from "../ServiceDeliveryPlan";
import IndividualEvaluation from "../IndividualEvaluation";
import CoordinationCare from "../CoordinationCare";
import AttendantIndividual from "../AttendantIndividual";
import AttendantCalendar from "../AttendantCalendar";
const ServiceFormReports = () => {
  const [signature_image, setSign_1] = useState("");
  const [signature_image_2, setSign_2] = useState("");
  const [signature_image_3, setSign_3] = useState("");
  const { loginDetails } = useContext(UserContext);
  const [individual_id, setIndividual_id] = useState("");
  const [individualList, setIndividualList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [authorzations, setAuthorizations] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [isHideFooter, setIsHideFooter] = useState(false);
  const [serviceType, setServiceType] = useState("Evidence of Relationship");
  const [data, setData] = useState({
    date: "",
    date_2: "",
    date_3: "",
    date_4: "",
  });

  const formArea = useRef();
  const signature_1 = useRef();
  const signature_2 = useRef();
  const signature_3 = useRef();

  const handlePrint = useReactToPrint({
    onBeforePrint: () => setIsHideFooter(true),
    onAfterPrint: () => setIsHideFooter(false),
    content: () => formArea.current,
  });

  useEffect(() => {
    getIndividualList(
      loginDetails?.user[0]?.user_id || loginDetails?.user[0]?.company_id,
      loginDetails.token,
      setIndividualList
    );
  }, []);

  useEffect(() => {
    getEmployee(
      loginDetails?.user[0]?.user_id || loginDetails?.user[0]?.company_id,
      loginDetails.token,
      setEmployeeList
    );
  }, []);

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const getCompanyDetails = () => {
    getCompany(
      loginDetails?.user[0]?.company_id,
      loginDetails.token,
      setCompanyData
    );
  };

  const getAuthorization = () => {
    getAuthorizationsUnderIndividual(
      loginDetails?.user[0]?.user_id || loginDetails?.user[0]?.company_id,
      individual_id,
      loginDetails.token,
      setAuthorizations
    );
  };

  useEffect(() => {
    if (individual_id) getAuthorization();
  }, [individual_id]);

  return (
    <Box flexDirection={"row"} display={"flex"}>
      <Box width={"23%"}>
        <Box>
          <Box mb={1}>Individual name</Box>
          <Autocomplete
            // defaultValue={""}
            value={data?.individual_name}
            onChange={(event, newValue) => {
              setIndividual_id(newValue?.id);
              setData({
                ...data,
                individual: newValue,
                individual_name: newValue?.firstname
                  ? `${newValue?.firstname} ${newValue?.lastname}`
                  : "",
              });
            }}
            size="small"
            style={{ width: "100%" }}
            disablePortal
            id="individual_name"
            options={individualList}
            getOptionLabel={(option) =>
              option?.id ? `${option?.firstname} ${option?.lastname}` : "Select"
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              return <TextField {...params} label="Individual name" />;
            }}
          />
        </Box>
        <Box mt={2}>
          <Box mb={1}>Authorizations</Box>
          <Autocomplete
            // defaultValue={""}
            // value={data?.employee_name}
            onChange={(event, newValue) => {
              setData({
                ...data,
                authorization_start_date: newValue?.authorization_start_date,
                // authorzation_id: newValue?.id,
                authorzation_id: newValue?.auth_id,
                authorizedTime: newValue?.units,
              });
            }}
            size="small"
            style={{ width: "100%" }}
            disablePortal
            id="authorzation_id"
            options={authorzations}
            getOptionLabel={(option) =>
              option?.id
                ? `${option?.service_code} - ${option?.service_type} (${moment(
                    option?.service_start_date
                  ).format("MMM Do YYYY")} - ${moment(
                    option?.service_end_date
                  ).format("MMM Do YYYY")})`
                : "Select"
            }
            sx={{ width: 300, fontSize: 12 }}
            renderInput={(params) => {
              return <TextField {...params} label="Authorizations" />;
            }}
          />
        </Box>

        <Box mt={2}>
          <Box mb={1}>Attendant</Box>
          <Autocomplete
            // defaultValue={""}
            value={data?.employee_name}
            onChange={(event, newValue) => {
              setData({
                ...data,
                employee: newValue,
                attendantA: newValue?.firstname
                  ? `${newValue?.firstname} ${newValue?.lastname}`
                  : "",
                employee_name: newValue?.firstname
                  ? `${newValue?.firstname} ${newValue?.lastname}`
                  : "",
              });
            }}
            size="small"
            style={{ width: "100%" }}
            disablePortal
            id="employee_name"
            options={employeeList}
            getOptionLabel={(option) =>
              option?.id ? `${option?.firstname} ${option?.lastname}` : "Select"
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              return <TextField {...params} label="Attendant name" />;
            }}
          />
        </Box>

        <Box mt={2}>
          <fieldset
            style={{
              color: colours.primary,
              borderWidth: 1,
              borderColor: "lightgrey",
              borderRadius: 3,
            }}
          >
            <legend
              style={{
                color: colours.primary,
              }}
            >
              Select Report Type
            </legend>
            <FormControl>
              {/* <FormLabel id="demo-radio-buttons-group-label">
                
              </FormLabel> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Evidence of Relationship"
                  checked={serviceType === "Evidence of Relationship" && true}
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Evidence of Relationship"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  checked={serviceType === "Attendant Orientation" && true}
                  value="Attendant Orientation"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Attendant Orientation"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Attendant Orientation MDCP"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Attendant Orientation MDCP"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Supervisory Visit"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Supervisory Visit"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Service Delivery Plan"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Service Delivery Plan"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Service Delivery Plan MDCP"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Service Delivery Plan MDCP"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Individual Evaluation"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Individual Evaluation"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Coordination of Care"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Coordination of Care"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Attendant Individual"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Attendant Individual"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />

                {serviceType === "Attendant Individual Emma" && (
                  <Box>
                    <fieldset
                      style={{
                        color: "blue",
                        borderWidth: 1,
                        borderColor: "dodgerblue",
                        borderRadius: 3,
                      }}
                    >
                      <legend
                        style={{
                          color: "dodgerblue",
                        }}
                      >
                        Filters
                      </legend>
                      <Box>
                        <Box mb={0.5}>Proirity</Box>
                        <Autocomplete
                          // defaultValue={""}
                          value={data?.attendantProirity}
                          onChange={(event, newValue) => {
                            setData({ ...data, attendantProirity: newValue });
                          }}
                          size="small"
                          style={{ width: "100%" }}
                          disablePortal
                          id="attendantProirity"
                          options={[
                            { id: "1", attendantProirity: "None" },
                            { id: "2", attendantProirity: "1" },
                            { id: "3", attendantProirity: "2" },
                            { id: "4", attendantProirity: "3" },
                          ]}
                          getOptionLabel={(option) =>
                            option?.id
                              ? `${option?.attendantProirity}`
                              : "Select"
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => {
                            return <TextField {...params} label="Proirity" />;
                          }}
                        />
                      </Box>
                      <Box mt={1}>
                        <Box mb={0.5}>Status</Box>
                        <Autocomplete
                          // defaultValue={""}
                          value={data?.attendantStatus}
                          onChange={(event, newValue) => {
                            setData({ ...data, attendantStatus: newValue });
                          }}
                          size="small"
                          style={{ width: "100%" }}
                          disablePortal
                          id="attendantStatus"
                          options={[
                            { id: "1", attendantStatus: "Active" },
                            { id: "2", attendantStatus: "Not Active" },
                            { id: "3", attendantStatus: "Pending" },
                          ]}
                          getOptionLabel={(option) =>
                            option?.id ? `${option?.attendantStatus}` : "Select"
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => {
                            return <TextField {...params} label="Status" />;
                          }}
                        />
                      </Box>
                      <Box mt={1}>
                        <Box mb={0.5}>Group</Box>
                        <Autocomplete
                          value={data?.attendantGroup}
                          onChange={(event, newValue) => {
                            setData({ ...data, attendantGroup: newValue });
                          }}
                          size="small"
                          style={{ width: "100%" }}
                          disablePortal
                          id="attendantGroup"
                          options={[
                            { id: "1", attendantGroup: "A" },
                            { id: "2", attendantGroup: "B" },
                            { id: "3", attendantGroup: "C" },
                          ]}
                          getOptionLabel={(option) =>
                            option?.id ? `${option?.attendantGroup}` : "Select"
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => {
                            return <TextField {...params} label="Group" />;
                          }}
                        />
                      </Box>
                      <Box mt={1}>
                        <Box mb={0.5}>Type</Box>
                        <Autocomplete
                          // defaultValue={""}
                          value={data?.attendantType}
                          onChange={(event, newValue) => {
                            setData({ ...data, attendantType: newValue });
                          }}
                          size="small"
                          style={{ width: "100%" }}
                          disablePortal
                          id="attendantType"
                          options={[{ id: "1", attendantType: "All" }]}
                          getOptionLabel={(option) =>
                            option?.id ? `${option?.attendantType}` : "Select"
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => {
                            return <TextField {...params} label="Type" />;
                          }}
                        />
                      </Box>
                    </fieldset>
                  </Box>
                )}

                <FormControlLabel
                  value="Calendar"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Calendar"
                />
              </RadioGroup>
            </FormControl>
          </fieldset>
        </Box>
        <Box display={"flex"} mt={2} justifyContent={"center"}>
          {/* <Button
            style={{ marginRight: 20 }}
            variant="outlined"
            onClick={() => handlePrint()}
          >
            View
          </Button> */}
          <Button
            variant="contained"
            onClick={() => {
              setIsHideFooter(true);
              setTimeout(() => {
                handlePrint();
              }, 200);
            }}
          >
            Print
          </Button>
        </Box>
        <Box
          textAlign={"center"}
          mt={1}
          color={"red"}
          fontSize={12}
          fontStyle={"italic"}
        >
          {(serviceType === "Attendant Orientation" ||
            serviceType === "Service Delivery Plan" ||
            serviceType === "Attendant Orientation MDCP" ||
            serviceType === "Service Delivery Plan MDCP") &&
            "Please select printable pages at the footer"}
        </Box>
      </Box>
      <Box width={"77%"} bgcolor={"white"} height={"92%"} ml={2} p={2}>
        <Box bgcolor={"#F2F2F2"} height={"92vh"} p={2}>
          <Box bgcolor={"white"} height={"92vh"} p={5} ref={formArea}>
            {serviceType === "Evidence of Relationship" && (
              <RelationshipEvidence
                signature_1={signature_1}
                signature_2={signature_2}
                signature_3={signature_3}
                setSign_2={setSign_2}
                setSign_3={setSign_3}
                setSign_1={setSign_1}
                companyData={companyData}
                data={data}
                setData={setData}
              />
            )}

            {(serviceType === "Attendant Orientation" ||
              serviceType === "Attendant Orientation MDCP") && (
              <AttendantOrientation
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
              />
            )}

            {serviceType === "Supervisory Visit" && (
              <SupervisoryVisit data={data} />
            )}

            {(serviceType === "Service Delivery Plan" ||
              serviceType === "Service Delivery Plan MDCP") && (
              <ServiceDeliveryPlan
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
              />
            )}
            {serviceType === "Individual Evaluation" && (
              <IndividualEvaluation
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
              />
            )}
            {serviceType === "Coordination of Care" && (
              <CoordinationCare
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
              />
            )}

            {serviceType === "Attendant Individual" && (
              <AttendantIndividual
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
              />
            )}

            {serviceType === "Calendar" && (
              <AttendantCalendar
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceFormReports;
