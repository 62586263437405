import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import colours from "../../../constants/colours";
import { UserContext } from "../../../context/userContext";
import { loginUser } from "./business";
import { CheckBox } from "@mui/icons-material";

const Login = () => {
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [isCompany, setIsCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { setUser, setLoginDetails } = useContext(UserContext);

  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "This is for company" } };

  const login = () => {
    setErrorMessage(false);
    if (userID && password) {
      const data = {
        userID,
        password,
        role: isCompany ? "company" : "user",
        isCompany,
      };

      loginUser(
        data,
        setIsLoading,
        setStatus,
        setErrorMessage,
        setLoginDetails
      );
    }
  };

  useEffect(() => {
    if (status) {
      setUser({ isActive: true });
      navigate("/dashboard", { replace: true });
    }
  }, [status, setUser, navigate]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <Grid item>
            <Box>
              <Box justifyContent={"center"} display={"flex"}>
                <Box
                  display={"flex"}
                  component="img"
                  alt="TurboPas Logo"
                  src="./images/logo_c.png"
                  borderRadius={100}
                  width={80}
                  height={80}
                />
              </Box>

              <Box style={styles.header}>Welcome, please login.</Box>
              {errorMessage && (
                <Alert severity="error">
                  Username / Password is incorrect!
                </Alert>
              )}

              <Box mt={2}>
                <Box mb={1}>User-ID</Box>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    size="small"
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                    fullWidth
                    label="User-ID"
                    id="userID"
                  />
                </Box>
              </Box>
              <Box m={2} />
              <Box>
                <Box mb={1}>Password</Box>
                <Box
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    size="small"
                    value={password}
                    type="password"
                    fullWidth
                    label="Password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
              </Box>
              <Box m={2} />

              <FormControlLabel
                control={
                  <Checkbox
                    {...label}
                    name="isCompany"
                    id="isCompany"
                    value={isCompany}
                    onChange={(e) => setIsCompany(e.target.checked)}
                    checked={isCompany}
                  />
                }
                label="Login as company"
              />
              <Box m={5} />
              <Box style={styles.btnWrapper}>
                <Button
                  onClick={() => login()}
                  variant="outlined"
                  style={styles.btn}
                >
                  {isLoading ? "Please wait..." : "Login"}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Login;

const styles = {
  wrapper: {
    height: "100vh",
    textAlign: "center",
  },
  header: {
    color: "rgb(87 37 68)",
    fontSize: 24,
    marginBottom: 20,
    textAlign: "center",
  },
  width: { width: 200 },
  btn: {
    color: colours.white,
    borderColor: colours.primary,
    borderWidth: 1,
    backgroundColor: colours.primary,
    alignSelf: "centre",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  signUpbtn: {
    color: colours.primary,
    borderColor: colours.primary,
    borderWidth: 1,
    alignSelf: "centre",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    // width: "88%",
  },
};
