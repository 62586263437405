import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";

const EmployeeInfo = ({ data, handleChange }) => {
  return (
    <Box
      padding={4}
      flexDirection={"row"}
      justifyContent={"space-around"}
      display={"flex"}
    >
      <Box width={"20%"}>
        <Box mb={1}>Title</Box>
        <Box>
          <FormControl fullWidth size="small">
            <InputLabel id="title">Title</InputLabel>
            <Select
              labelId="title"
              id="title"
              value={data?.title}
              label="Title"
              onChange={handleChange}
              defaultValue={""}
              name="title"
            >
              <MenuItem value={10}>Attendant</MenuItem>
              <MenuItem value={10}>CNA</MenuItem>
              <MenuItem value={10}>Management</MenuItem>
              <MenuItem value={10}>Office Staff</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mt={3} mb={1}>
          First Name
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="firstname"
          label="First name"
          variant="outlined"
          onChange={handleChange}
          name="firstname"
        />
        <Box mt={3} mb={1}>
          Last Name
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="lastname"
          label="Last name"
          variant="outlined"
          onChange={handleChange}
          name="lastname"
        />
        <Box mt={3} mb={1}>
          Employment Status
        </Box>
        <Box>
          <FormControl fullWidth size="small">
            <InputLabel id="employmentStatus">Employment Status</InputLabel>
            <Select
              labelId="employmentStatus"
              id="employmentStatus"
              value={data?.employmentStatus}
              label="Employment Status"
              onChange={handleChange}
              defaultValue={""}
              name="employmentStatus"
            >
              <MenuItem value={10}>Current</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mt={3} mb={1}>
          License Status
        </Box>
        <Box>
          <FormControl fullWidth size="small">
            <InputLabel id="licenseStatus">License Status</InputLabel>
            <Select
              labelId="licenseStatus"
              id="licenseStatus"
              value={data?.licenseStatus}
              label="License Status"
              onChange={handleChange}
              defaultValue={""}
              name="licenseStatus"
            >
              <MenuItem value={10}>Contractor</MenuItem>
              <MenuItem value={10}>Un-Skilled</MenuItem>
              <MenuItem value={10}>Skilled</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mt={3} mb={1}>
          Gender
        </Box>
        <Box>
          <FormControl fullWidth size="small">
            <InputLabel id="gender">Gender</InputLabel>
            <Select
              labelId="gender"
              id="gender"
              value={data?.licenseStatus}
              label="Gender"
              onChange={handleChange}
              defaultValue={""}
              name="gender"
            >
              <MenuItem value={10}>Male</MenuItem>
              <MenuItem value={10}>Female</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box width={"20%"}>
        <Box mb={1}>Address</Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="address"
          label="Address"
          variant="outlined"
          onChange={handleChange}
          name="address"
        />
        <Box mt={3} mb={1}>
          City
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="city"
          label="City"
          variant="outlined"
          onChange={handleChange}
          name="city"
        />
        <Box mt={3} mb={1}>
          State
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="state"
          label="State"
          variant="outlined"
          onChange={handleChange}
          name="state"
        />
        <Box mt={3} mb={1}>
          Zip Code
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="zipCode"
          label="Zip Code"
          variant="outlined"
          onChange={handleChange}
          name="zipCode"
        />
        <Box mt={3} mb={1}>
          Mobile Phone Number
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="phone"
          label="Phone"
          variant="outlined"
          onChange={handleChange}
          name="phone"
        />
        <Box mt={3} mb={1}>
          Other Phone Number
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="otherPhone"
          label="Other Phone"
          variant="outlined"
          onChange={handleChange}
          name="otherPhone"
        />
      </Box>
      <Box width={"20%"}>
        <Box mb={1}>Email Address</Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="emailAddress"
          label="Email Address"
          variant="outlined"
          onChange={handleChange}
          name="emailAddress"
        />

        <Box mt={3}>Date of Birth</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Date of Birth"
            />
          </DemoContainer>
        </LocalizationProvider>

        <Box mt={3} mb={1}>
          Age
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="age"
          label="Age"
          variant="outlined"
          onChange={handleChange}
          name="age"
        />
        <Box mt={3} mb={1}>
          Marital Status
        </Box>
        <Box>
          <FormControl fullWidth size="small">
            <InputLabel id="maritalStatus">Marital Status</InputLabel>
            <Select
              labelId="maritalStatus"
              id="maritalStatus"
              value={data?.licenseStatus}
              label="Marital Status"
              onChange={handleChange}
              defaultValue={""}
              name="maritalStatus"
            >
              <MenuItem value={"Single"}>Single</MenuItem>
              <MenuItem value={"Married"}>Married</MenuItem>
              <MenuItem value={"Divorced"}>Divorced</MenuItem>
              <MenuItem value={"Widowed"}>Widowed</MenuItem>
              <MenuItem value={"Unknown"}>Unknown</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box mt={3} mb={1}>
          Number of Dependants
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="dependants"
          label="Number of Dependants"
          variant="outlined"
          onChange={handleChange}
          name="dependants"
        />
        <Box mt={3} mb={1}>
          Number of Verified Ref.
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="ref"
          label="Number of Verified Ref."
          variant="outlined"
          onChange={handleChange}
          name="ref"
        />
      </Box>
    </Box>
  );
};

export default EmployeeInfo;
