import React from "react";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import colours from "../../../constants/colours";

const Deactivate = ({
  openModal,
  handleModal,
  isLoading,
  deactivateUserAccount,
}) => {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
          height={"100vh"}
        >
          <Box bgcolor={"white"} borderRadius={3} p={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <Box color={colours.primary}>Deactivate Account</Box>
              <Divider />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Box color={colours.primary}>
                Are you sure you want to deactivate this account?
              </Box>
              <Box mt={2} justifyContent={"flex-end"} display={"flex"}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => deactivateUserAccount()}
                >
                  {isLoading ? "Please wait..." : "Yes"}
                </Button>
                <Button variant="text" onClick={() => handleModal()}>
                  No
                </Button>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Deactivate;
