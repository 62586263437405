import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import API_URL from "../../../constants/API_URL";

export const createServiceType = async (
  data,
  token,
  userid,
  companyid,
  setIsLoading,
  setSuccess,
  setErrorMessage
) => {
  setSuccess(false);
  setErrorMessage(false);

  if (setIsLoading) {
    setIsLoading(true);
  }

  let config = {
    url: `${API_URL.url}/service-types/create-service-type`,
    method: "POST",
    data: {
      service_id: data?.serviceId,
      service_name: data?.name,
      contract_number: data?.contractNo,
      region: data?.region,
      service_type: data?.serviceType,
      company_id: companyid,
      program_type: data?.programType,
      unit_rate: data?.unitRate,
      service_group: data?.group,
      receiver_id: data?.receiver,
      payer_id: data?.payer,
      evv_contract: data?.vestaContract,
      status: data?.status,
      evv: data?.evv,
    },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
      companyid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setErrorMessage(false);

      // console.log("Data", data);
      if (setIsLoading) {
        setIsLoading(false);
      }
      if (data?.message === "Service type created successfully") {
        setSuccess(true);
      }
    })
    .catch((error) => {
      setErrorMessage(true);

      // console.log(
      //   JSON.stringify(error?.response?.data?.message),
      //   "error creating a service type"
      // );
      setSuccess(false);

      if (setIsLoading) {
        setIsLoading(false);
      }
    });
};

export const getServiceTypes = async (
  userid,
  token,
  setData,
  setIsSuccessful
) => {
  setData([]);
  setIsSuccessful(false);
  let config = {
    url: `${API_URL.url}/service-types/get-all-service-type-created`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data) {
        setIsSuccessful(true);

        const result = [];
        const newData = data?.serviceTypes;

        for (let i = 0; i < newData.length; i++) {
          result.push({
            id: newData[i]["id"],
            name: newData[i]["service_name"],
            contractNo: newData[i]["contract_number"],
            region: newData[i]["region"],
            serviceType: newData[i]["service_type"],
            web: "Yes",
            programType: newData[i]["program_type"],
            unitRate: newData[i]["unit_rate"],
            group: newData[i]["service_group"],
            receiver: newData[i]["receiver_id"],
            payer: newData[i]["payer_id"],
            vestaContract: newData[i]["evv_contract"],
            status: newData[i]["status"],
            evv: newData[i]["evv"],
          });
        }

        setData([...result]);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getContract = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/contract/get-contract`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.vestaContract) {
        setData(data?.vestaContract);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getCompanyUsers = async (company_id, token, setData) => {
  let config = {
    url: `${API_URL.url}/companies/users/${company_id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.companyUsers) {
        setData(data?.companyUsers);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getCompany = async (company_id, token, setData) => {
  let config = {
    url: `${API_URL.url}/companies/company/${company_id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.company) {
        setData(data?.company);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getServiceCodes = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/service-code/get-service-codes`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      // console.log(data?.serviceCodes);
      if (data?.serviceCodes) {
        setData(data?.serviceCodes);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getProgramTypes = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/program-types/get-program-types`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      // console.log(data?.programTypes);
      if (data?.programTypes) {
        setData(data?.programTypes);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getGroupTypes = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/group-types/get-group-types`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      // console.log(data?.programTypes);
      if (data?.programTypes) {
        setData(data?.programTypes);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getReceivers = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/receivers/get-receivers`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      // console.log(data?.receivers);
      if (data?.receivers) {
        setData(data?.receivers);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getPayers = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/payers/get-payers`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      // console.log(data?.payers);
      if (data?.payers) {
        setData(data?.payers);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getCaseworkers = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/case-worker/get-case-worker`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.caseworkers) {
        setData(data?.caseworkers);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const createAuthorization = async (
  data,
  userid,
  token,
  setData,
  setIsLoading,
  setCurrentAuthId
) => {
  setData("");
  setIsLoading(true);
  const auth_id = uuidv4();
  setCurrentAuthId(auth_id);
  let obj = {
    auth_id,
    service_start_date: "",
    service_end_date: "",
    last_spervisor_visit_date: "",
    authorization_received_date: "",
    practioner_statement_sent: "",
    service_type: "",
    place_service_id: "",
    liason: "",
    discharge_reason: "",
    case_worker: "",
    practioner_statement_received: "",
    authorization_end_date: "",
    priority: "",
    dob: "",
    assessment_date: "",
    sup_visit_freq: "",
    bill_code: "",
    service_initial_reported_cw: "",
    authorization_start_date: "",
    procedure_code_id: "",
    diagnosis: "",
    procedure_code_qualifier: "",
    units: "",
    status: "",
    authorization_ref_number: "",
    supplies_equipment: "",
    doctor: "",
    billing_diagnosis_code_1: "",
    billing_diagnosis_code_2: "",
    billing_diagnosis_code_3: "",
    billing_diagnosis_code_4: "",
    service_group: "",
    service_code: "",
    service_code_description: "",
    hcpcs: "",
    modifier_1: "",
    modifier_2: "",
    modifier_3: "",
    modifier_4: "",
  };

  let newData = {
    ...obj,
    ...data,
    units: data?.hour ? `${data?.hour}:${data?.minutes} - ${data?.days}` : "",
  };

  let config = {
    url: `${API_URL.url}/authorization/create-authorization`,
    method: "POST",
    data: newData,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsLoading(false);

      if (data?.message) {
        setData("success");
      }
    })
    .catch((error) => {
      setIsLoading(false);
      setData("error");
      console.log(JSON.stringify(error.response), "error");
    });
};

export const updateAuthorization = async (
  data,
  userid,
  token,
  setData,
  setIsLoading
) => {
  setData("");
  setIsLoading(true);

  let obj = {
    authId: data?.id,
    service_start_date: "",
    service_end_date: "",
    last_spervisor_visit_date: "",
    authorization_received_date: "",
    practioner_statement_sent: "",
    service_type: "",
    place_service_id: "",
    liason: "",
    discharge_reason: "",
    case_worker: "",
    practioner_statement_received: "",
    authorization_end_date: "",
    priority: "",
    dob: "",
    assessment_date: "",
    sup_visit_freq: "",
    bill_code: "",
    service_initial_reported_cw: "",
    authorization_start_date: "",
    procedure_code_id: "",
    diagnosis: "",
    procedure_code_qualifier: "",
    units: "",
    status: "",
    authorization_ref_number: "",
    supplies_equipment: "",
    doctor: "",
    billing_diagnosis_code_1: "",
    billing_diagnosis_code_2: "",
    billing_diagnosis_code_3: "",
    billing_diagnosis_code_4: "",
    service_group: "",
    service_code: "",
    service_code_description: "",
    hcpcs: "",
    modifier_1: "",
    modifier_2: "",
    modifier_3: "",
    modifier_4: "",
  };

  let newData = {
    ...obj,
    ...data,
    units: data?.hour ? `${data?.hour}:${data?.minutes} - ${data?.days}` : "",
    priority: `${data?.priority}`,
  };

  let config = {
    url: `${API_URL.url}/authorization/update-authorization`,
    method: "PUT",
    data: newData,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsLoading(false);

      if (data?.message) {
        setData("success");
      }
    })
    .catch((error) => {
      setIsLoading(false);
      setData("error");
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getDoctors = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/doctor/get-doctors`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.doctors) {
        setData(data?.doctors);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getAuthorizations = async (
  userid,
  token,
  setData,
  setAuthorizationsCopy
) => {
  let config = {
    url: `${API_URL.url}/authorization/get-authorization`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.authorizations) {
        setData(data?.authorizations);
        setAuthorizationsCopy(data?.authorizations);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getAuthorizationsUnderIndividual = async (
  userid,
  individual_id,
  token,
  setData
) => {
  console.log(individual_id, "individual_id");
  let config = {
    url: `${API_URL.url}/authorization/get-authorization/${individual_id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.authorizations) {
        setData(data?.authorizations);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getTasksUnderAuthorization = async (
  userid,
  authorization_id,
  token,
  setData
) => {
  let config = {
    url: `${API_URL.url}/report/get-authorization-tasks/${authorization_id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.tasks) {
        setData(data?.tasks);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getSchedulesUnderAuthorization = async (
  userid,
  authorization_id,
  token,
  setData
) => {
  let config = {
    url: `${API_URL.url}/report/get-authorization-schedules/${authorization_id}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.schedules) {
        setData(data?.schedules);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const saveCalendar = async (
  userid,
  token,
  data,
  setError,
  setIsLoading
) => {
  setIsLoading(true);
  if (setError) setError("");
  let config = {
    url: `${API_URL.url}/weekly-schedule/create-weekly-schedule`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsLoading(false);

      if (setError) setError("success");

      console.log(data?.message);
      if (data?.message) {
      }
    })
    .catch((error) => {
      setIsLoading(false);
      if (setError) setError("error");

      console.log(JSON.stringify(error.response), "error");
    });
};

export const updateCalendar = async (
  userid,
  token,
  data,
  setError,
  setIsLoading
) => {
  setIsLoading(true);
  if (setError) setError("");
  let config = {
    url: `${API_URL.url}/weekly-schedule/update-weekly-schedule`,
    method: "PUT",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsLoading(false);

      if (setError) setError("success");

      console.log(data?.message);
      if (data?.message) {
      }
    })
    .catch((error) => {
      setIsLoading(false);
      if (setError) setError("error");

      console.log(JSON.stringify(error.response), "error");
    });
};

export const createTasks = async (
  userid,
  token,
  data,
  setError,
  setIsLoading
) => {
  setIsLoading(true);
  if (setError) setError("");
  let config = {
    url: `${API_URL.url}/tasks/create-tasks`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsLoading(false);

      if (setError) setError("success");

      if (data?.message) {
      }
    })
    .catch((error) => {
      setIsLoading(false);
      if (setError) setError("error");

      console.log(JSON.stringify(error.response), "error");
    });
};

export const updateTasks = async (
  userid,
  token,
  data,
  setError,
  setIsLoading
) => {
  setIsLoading(true);
  if (setError) setError("");
  let config = {
    url: `${API_URL.url}/tasks/update-tasks`,
    method: "PUT",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsLoading(false);

      if (setError) setError("success");

      if (data?.message) {
      }
    })
    .catch((error) => {
      setIsLoading(false);
      if (setError) setError("error");

      console.log(JSON.stringify(error.response), "error");
    });
};

export const getDiagnosisCodes = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/diagnosis-codes/get-diagnosis-codes`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.diagnosisCodes) {
        setData(data?.diagnosisCodes);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getEmployee = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/employees/get-employees`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.employees) {
        setData(data?.employees);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getIndividualList = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/individuals/get-individuals`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      // console.log(data?.individualList);
      if (data?.individualList) {
        setData(data?.individualList);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getAuthorizationSchedule = async (
  userid,
  token,
  setData,
  individual_id,
  selectedCurrentAuthId
) => {
  if (!selectedCurrentAuthId) return;
  let config = {
    url: `${API_URL.url}/weekly-schedule/get-weekly-schedule/${selectedCurrentAuthId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.weeklySchedules) {
        setData(data?.weeklySchedules);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getCaseInfos = async (
  userid,
  token,
  setData,
  individual_id,
  selectedCurrentAuthId
) => {
  if (!selectedCurrentAuthId) return;
  let config = {
    url: `${API_URL.url}/case-info/get-case-infos/${selectedCurrentAuthId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.caseInforData) {
        setData(data?.caseInforData);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getComplains = async (
  userid,
  token,
  setData,
  individual_id,
  selectedCurrentAuthId
) => {
  if (!selectedCurrentAuthId) return;
  let config = {
    url: `${API_URL.url}/complains/get-complain/${selectedCurrentAuthId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.complains) {
        setData(data?.complains);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getComments = async (
  userid,
  token,
  setData,
  individual_id,
  selectedCurrentAuthId
) => {
  if (!selectedCurrentAuthId) return;
  let config = {
    url: `${API_URL.url}/comments/get-comment/${selectedCurrentAuthId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.comments) {
        setData(data?.comments);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getTasks = async (
  userid,
  token,
  setData,
  individual_id,
  selectedCurrentAuthId
) => {
  if (!selectedCurrentAuthId) return;
  let config = {
    url: `${API_URL.url}/tasks/get-tasks/${selectedCurrentAuthId}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.tasks) {
        setData(data?.tasks);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};

export const createIndividualList = async (
  data,
  userid,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/individuals/create-individual`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "New individual created successfully") {
        // console.log(data, "result");
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const createCaseInfo = async (
  data,
  userid,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/case-info/create-case-info`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "New case-info created successfully") {
        // console.log(data, "result");
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const updateCaseInformation = async (
  data,
  userid,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/case-info/update-case-info`,
    method: "PUT",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "Case-info updated successfully") {
        // console.log(data, "result");
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const createComplainLog = async (
  data,
  userid,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/complains/create-complain`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "New Complaint log created successfully") {
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const updateComplainLog = async (
  data,
  userid,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/complains/update-complain`,
    method: "PUT",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "Complaint log updated successfully") {
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const updateComments = async (
  data,
  userid,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/comments/update-comment`,
    method: "PUT",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "Comment updated successfully") {
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const createComments = async (
  data,
  userid,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/comments/create-comment`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "New Comment created successfully") {
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

//for company
export const createUser = async (
  data,
  userid,
  token,
  setSuccess,
  setIsloading,
  setErrorMessage
) => {
  setErrorMessage("");
  setSuccess("");
  setIsloading(true);
  let config = {
    url: `${API_URL.url}/user/create-user`,
    method: "POST",
    data: { ...data, company_id: userid },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "Created successfully") {
        setSuccess("success");
      }
    })
    .catch((error) => {
      setErrorMessage(error.response?.data?.message);
      setSuccess("error");
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

//for company
export const deleteUser = async (
  data,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);

  let config = {
    url: `${API_URL.url}/companies/users/delete`,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      user_id: data?.user_id,
      company_id: data?.company_id,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "User deleted successfully") {
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

//for company
export const deactivateUser = async (
  data,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);

  let config = {
    url: `${API_URL.url}/companies/users/deactivate`,
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      user_id: data?.user_id,
      company_id: data?.company_id,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "User deactivated successfully") {
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

//for company
export const activateUser = async (
  data,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful(false);
  setIsloading(true);

  let config = {
    url: `${API_URL.url}/companies/users/activate`,
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      user_id: data?.user_id,
      company_id: data?.company_id,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "User activated successfully") {
        setIsSuccessful(true);
      }
    })
    .catch((error) => {
      setIsSuccessful(false);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

// for users
export const changePassword = async (
  data,
  company_id,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful("");
  setIsloading(true);

  let config = {
    url: `${API_URL.url}/companies/change-password`,
    method: "PUT",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      company_id,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "Password changed successfully") {
        setIsSuccessful(data?.message);
      }
    })
    .catch((error) => {
      setIsSuccessful(error.response?.data?.message);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

// for users
export const changePasswordUser = async (
  data,
  user_id,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful("");
  setIsloading(true);

  let config = {
    url: `${API_URL.url}/user/change-password`,
    method: "PUT",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      user_id,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "Password changed successfully") {
        setIsSuccessful(data?.message);
      }
    })
    .catch((error) => {
      setIsSuccessful(error.response?.data?.message);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const createDoctor = async (
  data,
  user_id,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful("");
  setIsloading(true);

  let config = {
    url: `${API_URL.url}/doctor/create-doctor`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid: user_id,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "New doctor created successfully") {
        setIsSuccessful(data?.message);
      }
    })
    .catch((error) => {
      setIsSuccessful(error.response?.data?.message);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const createCaseworker = async (
  data,
  user_id,
  token,
  setIsSuccessful,
  setIsloading
) => {
  setIsSuccessful("");
  setIsloading(true);

  let config = {
    url: `${API_URL.url}/case-worker/create-case-worker`,
    method: "POST",
    data,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid: user_id,
    },
  };

  await axios(config)
    .then(({ data }) => {
      setIsloading(false);
      if (data?.message === "New caseworker created successfully") {
        setIsSuccessful(data?.message);
      }
    })
    .catch((error) => {
      setIsSuccessful(error.response?.data?.message);
      setIsloading(false);
      console.log(JSON.stringify(error.response), "error");
    });
};

export const getCaseworker = async (userid, token, setData) => {
  let config = {
    url: `${API_URL.url}/case-worker/get-case-worker`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      userid,
    },
  };

  await axios(config)
    .then(({ data }) => {
      if (data?.caseworkers) {
        setData(data?.caseworkers);
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response), "error");
    });
};
