import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Alert, Box, Button, Divider, Modal, Typography } from "@mui/material";
import {
  Add,
  CheckBox,
  DeleteOutline,
  DisabledByDefaultOutlined,
} from "@mui/icons-material";

import colours from "../../constants/colours";
// import FormDialog from "./form";
import {
  activateUser,
  deactivateUser,
  deleteUser,
  getCompanyUsers,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";
import DeleteModal from "../ActionModals/DeleteModal";
import Deactivate from "../ActionModals/Deactivate";
import ActivateAccount from "../ActionModals/ActivateAccount";
import Form from "./Form";
// import EmployeeForm from "./Form";

const Users = () => {
  const { loginDetails } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [user_id, setUserId] = useState("");

  const handleOpenForm = () => {
    setOpen(!open);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleModal2 = () => {
    setOpenModal2(!openModal2);
  };

  const handleModal3 = () => {
    setOpenModal3(!openModal3);
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 20 },
    {
      field: "firstname",
      headerClassName: "super-app-theme--header",
      headerName: "Firstname",
      width: 120,
    },
    {
      field: "lastname",
      headerClassName: "super-app-theme--header",
      headerName: "Lastname",
      width: 120,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      width: 170,
    },
    {
      field: "phone",
      headerClassName: "super-app-theme--header",
      headerName: "Phone",
      width: 100,
    },
    {
      field: "address",
      headerClassName: "super-app-theme--header",
      headerName: "Address",
      width: 200,
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      renderCell: (params) => (
        <Box color={params.row.status === 1 ? "green" : "red"}>
          {params.row.status === 1 ? "Active" : "Inactive"}
        </Box>
      ),
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      width: 370,
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <Typography>
            <DeleteOutline
              onClick={() => {
                setUserId(params?.row?.id);
                handleModal();
              }}
              style={{ fontSize: "30", color: "red", cursor: "pointer" }}
            />
            {params?.row?.status !== 0 ? (
              <DisabledByDefaultOutlined
                onClick={() => {
                  setUserId(params?.row?.id);
                  handleModal2();
                }}
                style={{
                  fontSize: "30",
                  color: colours.primary,
                  cursor: "pointer",
                }}
              />
            ) : (
              <CheckBox
                onClick={() => {
                  setUserId(params?.row?.id);
                  handleModal3();
                }}
                style={{ fontSize: "30", color: "green", cursor: "pointer" }}
              />
            )}
          </Typography>
        );
      },
    },
  ];

  const getCompanyUsersData = () => {
    getCompanyUsers(
      loginDetails?.user[0]?.company_id,
      loginDetails.token,
      setData
    );
  };

  useEffect(() => {
    getCompanyUsersData();
  }, []);

  useEffect(() => {
    if (refresh) getCompanyUsersData();
  }, [refresh]);

  useEffect(() => {
    if (isSuccessful) getCompanyUsersData();
  }, [isSuccessful]);

  const deleteUserAccount = () => {
    let data = { user_id, company_id: loginDetails?.user[0]?.company_id };
    deleteUser(data, loginDetails?.token, setIsSuccessful, setIsloading);
  };

  const deactivateUserAccount = () => {
    let data = { user_id, company_id: loginDetails?.user[0]?.company_id };
    deactivateUser(data, loginDetails?.token, setIsSuccessful, setIsloading);
  };

  const activateUserAccount = () => {
    let data = { user_id, company_id: loginDetails?.user[0]?.company_id };
    activateUser(data, loginDetails?.token, setIsSuccessful, setIsloading);
  };

  useEffect(() => {
    if (isSuccessful) {
      setOpenModal(false);
      setOpenModal2(false);
      setOpenModal3(false);
    }
  }, [isSuccessful]);

  return (
    <Box mt={4}>
      <Box display={"flex"} justifyContent={"right"} mb={2}>
        <Button
          variant="contained"
          style={{ backgroundColor: colours.primary }}
          startIcon={<Add />}
          onClick={() => handleOpenForm()}
        >
          Add New User
        </Button>
      </Box>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            color: colours.primary,
          },
          "& .super-app-theme--header": {
            backgroundColor: colours.secondary,
          },
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        // checkboxSelection
      />
      <Form
        open={open}
        handleOpenForm={handleOpenForm}
        setRefresh={setRefresh}
      />
      <DeleteModal
        isLoading={isLoading}
        openModal={openModal}
        handleModal={handleModal}
        handleDelete={deleteUserAccount}
      />
      <Deactivate
        isLoading={isLoading}
        openModal={openModal2}
        handleModal={handleModal2}
        deactivateUserAccount={deactivateUserAccount}
      />
      <ActivateAccount
        isLoading={isLoading}
        openModal={openModal3}
        handleModal={handleModal3}
        activateUserAccount={activateUserAccount}
      />
    </Box>
  );
};

export default Users;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "100%",
};
