import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import colours from "../../constants/colours";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import ContractForm from "../ContractForm";
import ServiceCodeForm from "../ServiceCodeForm";
import ProgramTypeForm from "../ProgramTypeForm";
import GroupForm from "../GroupForm";
import ReceiverForm from "../ReceiverForm";
import PayerForm from "../PayerForm";
import {
  createServiceType,
  getContract,
  getGroupTypes,
  getPayers,
  getProgramTypes,
  getReceivers,
  getServiceCodes,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const regionCode = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
];

const FormDialog = ({ open, handleOpenForm }) => {
  const [serviceType, setServiceType] = React.useState("");
  const [data, setData] = useState({});
  const [openContract, setOpenContract] = useState(false);
  const [serviceCodeOpen, setServiceCodeOpen] = useState(false);
  const [programTypeOpen, setProgramTypeOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [receiverOpen, setReceiverOpen] = useState(false);
  const [payerOpen, setPayerOpen] = useState(false);
  const [unitRateBy4, setUnitRateBy4] = useState(false);
  const [evv, setEVV] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [serviceCodes, setServiceCodes] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const [groupTypes, setGroupTypes] = useState([]);
  const [receivers, setReceivers] = useState([]);
  const [payers, setPayers] = useState([]);
  const { setGetData } = useContext(UserContext);
  const { loginDetails } = useContext(UserContext);

  const handlePayerOpen = () => {
    setPayerOpen(!payerOpen);
  };

  const handleGroupOpen = () => {
    setGroupOpen(!groupOpen);
  };

  const handleReceiverOpen = () => {
    setReceiverOpen(!receiverOpen);
  };

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleOpenContractForm = () => {
    setOpenContract(!openContract);
  };

  const handleServiceCode = () => {
    setServiceCodeOpen(!serviceCodeOpen);
  };

  const handleProgramTypeOpen = () => {
    setProgramTypeOpen(!programTypeOpen);
  };

  const handleCreateServiceType = () => {
    const formData = {
      ...data,
      evv,
      unitRateBy4,
      serviceId: Math.floor(Math.random() * 1000000000),
    };
    const userid = loginDetails?.user[0]?.user_id;
    const companyid = 1234; //Replace ASAP
    console.log(formData);
    createServiceType(
      formData,
      loginDetails?.token,
      userid,
      companyid,
      setIsLoading,
      setSuccess,
      setErrorMessage
    );
  };

  useEffect(() => {
    getContract(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setContracts
    );
  }, []);

  useEffect(() => {
    getServiceCodes(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setServiceCodes
    );
  }, []);

  useEffect(() => {
    getProgramTypes(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setProgramTypes
    );
  }, []);

  useEffect(() => {
    getGroupTypes(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setGroupTypes
    );
  }, []);

  useEffect(() => {
    getReceivers(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setReceivers
    );
  }, []);

  useEffect(() => {
    getPayers(loginDetails?.user[0]?.user_id, loginDetails.token, setPayers);
  }, []);

  useEffect(() => {
    if (success) {
      setGetData(true);
      const timer = setTimeout(() => {
        setSuccess(false);
        handleOpenForm();
      }, 5000);

      // return clearTimeout(timer);
    }
  }, [success]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Agency Service Type
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateServiceType}>
              {isLoading ? "Please wait..." : "Save"}
            </Button>
          </Toolbar>
        </AppBar>
        {success && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="success">
              New Service Type Created Successfully.
            </Alert>
          </Box>
        )}
        {errorMessage && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="error">
              New Service Type Was Not Created. All Fields Are required!
            </Alert>
          </Box>
        )}

        <Box
          padding={4}
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"20%"}>
            <Box mb={1}>Name</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="name"
              label="Name"
              variant="outlined"
              onChange={handleChange}
              name="name"
            />
            <Box mt={3} mb={1}>
              Contract Number
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="contractNo"
              label="Contract Number"
              variant="outlined"
              value={data?.contractNo}
              onChange={handleChange}
              name="contractNo"
            />
            <Box mt={3} mb={1}>
              Region
            </Box>
            <Box>
              <FormControl fullWidth size="small">
                <InputLabel id="region">Region</InputLabel>
                <Select
                  labelId="region"
                  id="region"
                  value={data?.region || ""}
                  label="Region"
                  onChange={handleChange}
                  name="region"
                  defaultValue={""}
                >
                  {regionCode.map((code) => (
                    <MenuItem value={code}>{code}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <AddCircleOutlineOutlined style={styles.addIcon} /> */}
            </Box>
            <Box mt={3} mb={1}>
              EVV Contract
            </Box>
            <Box display={"flex"}>
              <Autocomplete
                value={data?.vestaContract}
                onChange={(event, newValue) => {
                  setData({ ...data, vestaContract: newValue?.id });
                }}
                size="small"
                style={{ width: "100%" }}
                disablePortal
                id="contracts"
                options={contracts}
                getOptionLabel={(option) =>
                  option?.contract_name ? option?.contract_name : "Select"
                }
                sx={{ width: 300 }}
                renderInput={(params) => {
                  console.log(params?.id, "--params");
                  return <TextField {...params} label="Contracts" />;
                }}
              />

              <AddCircleOutlineOutlined
                style={styles.addIcon}
                onClick={handleOpenContractForm}
              />
            </Box>
          </Box>
          <Box width={"20%"}>
            <Box mb={1}>Service Types</Box>
            <Box display={"flex"}>
              <Autocomplete
                value={data?.serviceCode}
                onChange={(event, newValue) => {
                  setData({ ...data, serviceType: newValue?.id });
                }}
                size="small"
                style={{ width: "100%" }}
                disablePortal
                id="serviceCode"
                options={serviceCodes}
                getOptionLabel={(option) =>
                  option?.description
                    ? `${option?.service_code} - ${option?.description}`
                    : "Select"
                }
                sx={{ width: 300 }}
                renderInput={(params) => {
                  console.log(params?.id, "--params");
                  return <TextField {...params} label="Service Types" />;
                }}
              />

              <AddCircleOutlineOutlined
                style={styles.addIcon}
                onClick={handleServiceCode}
              />
            </Box>

            <Box mt={3} mb={1}>
              Program Type
            </Box>
            <Box display={"flex"}>
              <Autocomplete
                value={data?.programType}
                onChange={(event, newValue) => {
                  setData({ ...data, programType: newValue?.id });
                }}
                size="small"
                style={{ width: "100%" }}
                disablePortal
                id="programType"
                options={programTypes}
                getOptionLabel={(option) =>
                  option?.program_description
                    ? option?.program_description
                    : "Select"
                }
                sx={{ width: 300 }}
                renderInput={(params) => {
                  console.log(params?.id, "--params");
                  return <TextField {...params} label="Program Type" />;
                }}
              />

              <AddCircleOutlineOutlined
                style={styles.addIcon}
                onClick={handleProgramTypeOpen}
              />
            </Box>

            <Box mb={1} mt={3}>
              Unit Rate
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="unitRate"
              label="Unit Rate"
              variant="outlined"
              onChange={handleChange}
              value={data?.unitRate}
              name={"unitRate"}
            />
            <Box
              mt={-0.7}
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
            >
              <Checkbox
                id="isUnit"
                value={unitRateBy4}
                onChange={(value) => setUnitRateBy4(!unitRateBy4)}
                {...label}
                style={{ paddingLeft: 0 }}
              />
              <label for="isUnit" fontSize={12}>
                Unit * 4 when billing?{" "}
              </label>
            </Box>

            <Box mt={1} mb={1}>
              Status
            </Box>
            <FormControl fullWidth size="small">
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                label="Status"
                onChange={handleChange}
                value={data?.status}
                name={"status"}
                defaultValue={""}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box width={"20%"}>
            <Box mb={1}>Group</Box>
            <Box display={"flex"}>
              <Autocomplete
                value={data?.group}
                onChange={(event, newValue) => {
                  setData({ ...data, group: newValue?.id });
                }}
                size="small"
                style={{ width: "100%" }}
                disablePortal
                id="group"
                options={groupTypes}
                getOptionLabel={(option) =>
                  option?.group_description
                    ? option?.group_description
                    : "Select"
                }
                sx={{ width: 300 }}
                renderInput={(params) => {
                  console.log(params?.id, "--params");
                  return <TextField {...params} label="Group Type" />;
                }}
              />

              <AddCircleOutlineOutlined
                style={styles.addIcon}
                onClick={handleGroupOpen}
              />
            </Box>

            <Box mt={3} mb={1}>
              Receiver
            </Box>
            <Box display={"flex"}>
              <Autocomplete
                value={data?.receiver}
                onChange={(event, newValue) => {
                  setData({ ...data, receiver: newValue?.id });
                }}
                size="small"
                style={{ width: "100%" }}
                disablePortal
                id="group"
                options={receivers}
                getOptionLabel={(option) =>
                  option?.receiver_name ? option?.receiver_name : "Select"
                }
                sx={{ width: 300 }}
                renderInput={(params) => {
                  console.log(params?.id, "--params");
                  return <TextField {...params} label="Receiver" />;
                }}
              />
              <AddCircleOutlineOutlined
                style={styles.addIcon}
                onClick={handleReceiverOpen}
              />
            </Box>

            <Box mt={3} mb={1}>
              Payer
            </Box>
            <Box display={"flex"}>
              <Autocomplete
                value={data?.payer}
                onChange={(event, newValue) => {
                  setData({ ...data, payer: newValue?.id });
                }}
                size="small"
                style={{ width: "100%" }}
                disablePortal
                id="payer"
                options={payers}
                getOptionLabel={(option) =>
                  option?.name ? option?.name : "Select"
                }
                sx={{ width: 300 }}
                renderInput={(params) => {
                  console.log(params?.id, "--params");
                  return <TextField {...params} label="Payer" />;
                }}
              />

              <AddCircleOutlineOutlined
                style={styles.addIcon}
                onClick={handlePayerOpen}
              />
            </Box>
            <Box
              mt={3}
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
            >
              <Checkbox
                onChange={(value) => setEVV(!evv)}
                value={evv}
                {...label}
                style={{ paddingLeft: 0 }}
                id="evv"
              />
              <label for="evv" style={{ cursor: "pointer" }}>
                Electroninic Visit Verification
              </label>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <ContractForm
        handleOpenForm={handleOpenContractForm}
        open={openContract}
      />
      <ServiceCodeForm
        open={serviceCodeOpen}
        handleOpenForm={handleServiceCode}
      />
      <ProgramTypeForm
        open={programTypeOpen}
        handleOpenForm={handleProgramTypeOpen}
      />
      <GroupForm open={groupOpen} handleOpenForm={handleGroupOpen} />
      <ReceiverForm open={receiverOpen} handleOpenForm={handleReceiverOpen} />
      <PayerForm open={payerOpen} handleOpenForm={handlePayerOpen} />
    </div>
  );
};

export default FormDialog;

const styles = {
  addIcon: {
    color: colours.primary,
    cursor: "pointer",
    // position: "absolute",
    marginTop: 8,
    marginLeft: 3,
  },
};
