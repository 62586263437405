import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ExpirationDates = () => {
  return (
    <Box
      padding={4}
      flexDirection={"row"}
      justifyContent={"space-evenly"}
      display={"flex"}
    >
      <Box>
        <Box mt={3}>Prof License</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Prof License"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Prof Liab Insurance</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Prof Liab Ins"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Driver's License</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Driver's License"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Box>
        <Box mt={3}>Auto Insurance</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Auto Insurance"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>CPR Card</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="CPR Card"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Work Authorization</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Work Authorization"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Box>
        <Box mt={3}>TB Test</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="TB Test"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>Health Statement</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="Health Statement"
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box mt={3}>First Aid Card</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              label="First Aid Card"
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default ExpirationDates;
