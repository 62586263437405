import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import colours from "../../constants/colours";
import {
  changePassword,
  changePasswordUser,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";

const ChangePassword = () => {
  const { loginDetails } = useContext(UserContext);
  const [data, setData] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState("");

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleClickShowPassword3 = () => setShowPassword3(!showPassword3);

  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword3 = () => setShowPassword3(!showPassword3);

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  const handleChangePassword = () => {
    setIsSuccessful("");

    if (loginDetails?.user[0]?.role === "user") {
      //user
      if (
        !data?.newPassword ||
        !data?.currentPassword ||
        !data?.confirm_password ||
        data?.newPassword !== data?.confirm_password
      ) {
        setIsSuccessful("Incorrect Details!");
      } else {
        changePasswordUser(
          data,
          loginDetails?.user[0]?.user_id,
          loginDetails?.token,
          setIsSuccessful,
          setIsloading
        );
      }
    } else {
      //company
      changePassword(
        data,
        loginDetails?.user[0]?.company_id,
        loginDetails?.token,
        setIsSuccessful,
        setIsloading
      );
    }
  };

  useEffect(() => {
    if (isSuccessful?.includes("successfully")) {
      setData({ newPassword: "", currentPassword: "", confirm_password: "" });
    }
  }, [isSuccessful]);

  return (
    <Box justifyContent={"center"} display={"flex"}>
      <Box width={"30%"}>
        <Box
          fontSize={18}
          color={colours.secondary}
          textAlign={"center"}
          fontWeight={"bold"}
          mb={1}
        >
          Change Password
        </Box>
        <Box>
          {isSuccessful && (
            <Alert
              severity={
                isSuccessful === "Password changed successfully"
                  ? "success"
                  : "error"
              }
            >
              {isSuccessful === "Password changed successfully"
                ? isSuccessful
                : "Incorrect Password!"}
            </Alert>
          )}
        </Box>
        <Box mt={3} mb={1}>
          Current Password
        </Box>
        <TextField
          value={data?.currentPassword}
          type={showPassword ? "text" : "password"} // <-- This is where the magic happens
          size="small"
          style={{ width: "100%" }}
          id="currentPassword"
          placeholder="Current Password"
          variant="outlined"
          onChange={handleChange}
          name="currentPassword"
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { width: "100%" },
          }}
        />
        <Box mt={3} mb={1}>
          New Password
        </Box>
        <TextField
          value={data?.newPassword}
          type={showPassword2 ? "text" : "password"} // <-- This is where the magic happens
          size="small"
          style={{ width: "100%" }}
          id="newPassword"
          placeholder="New Password"
          variant="outlined"
          onChange={handleChange}
          name="newPassword"
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  onMouseDown={handleMouseDownPassword2}
                >
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { width: "100%" },
          }}
        />

        <Box mb={1} mt={3}>
          Confirm Password
        </Box>
        <TextField
          value={data?.confirm_password}
          type={showPassword3 ? "text" : "password"} // <-- This is where the magic happens
          size="small"
          style={{ width: "100%" }}
          id="confirm_password"
          placeholder="Confirm Password"
          variant="outlined"
          onChange={handleChange}
          name="confirm_password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword3}
                  onMouseDown={handleMouseDownPassword3}
                >
                  {showPassword3 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: { width: "100%" },
          }}
        />
        <Box display={"flex"} justifyContent={"center"} mt={2}>
          <Button
            onClick={() => handleChangePassword()}
            style={{
              backgroundColor: colours.primary,
              paddingRight: 20,
              paddingLeft: 20,
              marginTop: 20,
            }}
            variant="contained"
          >
            {isLoading ? "Please wait..." : "Change Paswword"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
