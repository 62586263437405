import { Box, Checkbox, FormControlLabel } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  getSchedulesUnderAuthorization,
  getTasksUnderAuthorization,
} from "../../../../pages/main/Dashboard/business";
import { UserContext } from "../../../../context/userContext";

const DailySchedule = ({
  data,
  setData,
  tasks,
  setTasks,
  setSchedules,
  schedules,
  isHideFooter,
  formName,
  selectedDate,
}) => {
  const label = { inputProps: { "aria-label": "This is area" } };
  const [selectedPrint, setSelectedPrint] = useState({});
  const [selectedSchedulePrint, setSelectedSchedulePrint] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("");
  const { loginDetails } = useContext(UserContext);
  const [totalHours, setTotalHours] = useState("00:00");
  const currentMonthDates = Array.from(
    Array(moment(selectedDate).daysInMonth()),
    (_, i) => i + 1
  );

  useEffect(() => {
    if (tasks?.length > 0) {
      setSelectedPrint(tasks[0]);
    }
  }, [data?.authorzation_id, tasks]);

  //set initial schedule
  useEffect(() => {
    if (schedules?.length > 0) {
      selectPrint(0);
    } else {
      setSelectedSchedulePrint({});
    }
  }, [data?.authorzation_id, schedules]);

  useEffect(() => {
    //get all tasks, weekly schedules under this authorization id

    getTasksUnderAuthorization(
      loginDetails?.user[0]?.user_id,
      data?.authorzation_id,
      loginDetails.token,
      setTasks
    );

    getSchedulesUnderAuthorization(
      loginDetails?.user[0]?.user_id,
      data?.authorzation_id,
      loginDetails.token,
      setSchedules
    );
  }, [data?.authorzation_id]);

  //Task and Schedule
  const selectPrint = (index) => {
    let result = {};
    let result_ = {};
    setSelectedIndex(index);

    for (
      let i = 0;
      i < (tasks.length >= schedules.length ? tasks.length : schedules.length);
      i++
    ) {
      if (index === i) {
        // result = tasks[i];
        result_ = schedules[i];
      }
    }

    // setSelectedPrint(result);
    setSelectedSchedulePrint(result_);
  };

  const timeDiff = (timeIn, timeOut) => {
    if (!timeIn) return "-";
    const diff = moment.duration(
      moment(timeIn, "HH:mm:ss a").diff(moment(timeOut, "HH:mm:ss a"))
    );

    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    const result = `${hours} : ${minutes} : ${seconds}`;

    return moment(result, "HH:mm").format("HH:mm");
  };
  return (
    <Box fontFamily={"Times New Roman"}>
      <Box
        fontWeight={"bold"}
        textAlign={"center"}
        fontSize={24}
        fontFamily={""}
      >
        Amazing Healthcare Agency
      </Box>
      <Box
        textTransform={"uppercase"}
        fontSize={18}
        mt={0.5}
        textAlign={"center"}
      >
        Service Delivery Record (CA PHC)
      </Box>

      <Box display={"flex"} alignItems={"flex-end"} alignSelf={"center"}>
        <Box width={"25%"} border={"1px solid black"} height={20} mr={2} />
        <Box fontSize={18} mt={1} textAlign={"center"}>
          RECORD DE SERVICIOS ENTREGADOS
        </Box>
        <Box ml={3} textAlign={"right"} fontStyle={"italic"}>
          Emp Phone <label>{data?.employee?.phone}</label>
        </Box>
      </Box>

      <Box
        pl={0.5}
        pb={0.5}
        fontSize={16}
        border={"1px solid black"}
        mt={0.1}
        pt={1}
      >
        <Box display={"flex"}>
          <Box fontWeight={"bold"} fontSize={14}>
            Employee Name/Nombre del Empleado:
          </Box>
          <Box
            fontSize={14}
            ml={0.5}
            mr={0.5}
            borderBottom={"1px solid black"}
            width={"20%"}
          >
            {data?.employee?.firstname}, {data?.employee?.lastname}
          </Box>
          <Box fontWeight={"bold"} fontSize={14}>
            Month & Year of Service:{" "}
          </Box>
          <Box ml={1} borderBottom={"1px solid black"} flex={1}>
            <input style={{ border: "0px" }} type="text" width={70} />
          </Box>
        </Box>
        <Box display={"flex"} mt={1}>
          <Box fontWeight={"bold"} fontSize={14}>
            Mailing Address/Direction postal del empleado:
          </Box>
          <Box fontSize={14} ml={0.5} borderBottom={"1px solid black"} flex={1}>
            {data?.employee?.address}, {data?.employee?.mail_code}
          </Box>
        </Box>
        <Box display={"flex"} mt={1}>
          <Box fontWeight={"bold"} fontSize={14}>
            Individual name/Nombre del Individuo:
          </Box>
          <Box
            fontSize={14}
            ml={0.5}
            mr={0.5}
            borderBottom={"1px solid black"}
            width={"25%"}
          >
            {data?.individual?.firstname}, {data?.individual?.lastname}
          </Box>
          <Box fontSize={14} fontWeight={"bold"} ml={0.5}>
            Number/Numero
          </Box>
          <Box fontSize={14} borderBottom={"1px solid black"} ml={0.5} flex={1}>
            {data?.individual?.medicaid}
          </Box>
        </Box>
        <Box display={"flex"} mt={1}>
          <Box fontSize={14} fontWeight={"bold"}>
            Total Hours Authorized Per Week/Total de Horas Authorizadas Por
            Semana:{" "}
          </Box>
          <Box fontSize={14} borderBottom={"1px solid black"} ml={0.5} flex={1}>
            {data?.authorizedTime?.split("-")[0]}
          </Box>
        </Box>
      </Box>

      <Box mt={1}>
        <Box fontWeight={"bold"}>Tasks Assigned/Tareas Assignadas</Box>
        <Box>
          <Box>
            <table
              border={1}
              style={{ borderCollapse: "collapse" }}
              width={"100%"}
            >
              <tr>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                    paddingTop: -10,
                    paddingBottom: -10,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="bathing"
                          id="bathing"
                          value={data?.bathing}
                          onChange={(e) =>
                            setData({ ...data, bathing: e.target.checked })
                          }
                          checked={selectedPrint?.bathing == 1 ? true : false}
                        />
                      }
                      label="Bathing/Banar"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="routine_hair_skin_care"
                          id="routine_hair_skin_care"
                          value={data?.routine_hair_skin_care}
                          onChange={(e) =>
                            setData({
                              ...data,
                              routine_hair_skin_care: e.target.checked,
                            })
                          }
                          checked={
                            selectedPrint?.routine_hair_skin_care == 1
                              ? true
                              : false
                          }
                        />
                      }
                      label="Routine Hair & Skin Care/Cuidado Rutinario de Pelo/Cutis"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="laundry"
                          id="laundry"
                          value={data?.laundry}
                          onChange={(e) =>
                            setData({
                              ...data,
                              laundry: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.laundry == 1 ? true : false}
                        />
                      }
                      label="Laundry/Lavar Ropa"
                    />
                  </Box>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="dressing"
                          id="dressing"
                          value={data?.dressing}
                          onChange={(e) =>
                            setData({
                              ...data,
                              dressing: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.dressing == 1 ? true : false}
                        />
                      }
                      label="Dressing/Vestir"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="toilet"
                          id="toilet"
                          value={data?.toilet}
                          onChange={(e) =>
                            setData({
                              ...data,
                              toilet: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.toilet == 1 ? true : false}
                        />
                      }
                      label="Toileting/Necesidades del Cuerpo"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="meal_preparation"
                          id="meal_preparation"
                          value={data?.meal_preparation}
                          onChange={(e) =>
                            setData({
                              ...data,
                              meal_preparation: e.target.checked,
                            })
                          }
                          checked={
                            selectedPrint?.meal_preparation == 1 ? true : false
                          }
                        />
                      }
                      label="Meal Preparation/Preparar Comidas"
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="excercising"
                          id="excercising"
                          value={data?.excercising}
                          onChange={(e) =>
                            setData({
                              ...data,
                              excercising: e.target.checked,
                            })
                          }
                          checked={
                            selectedPrint?.excercising == 1 ? true : false
                          }
                        />
                      }
                      label="Exercise/Hacer Ejercicios"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="walking"
                          id="walking"
                          value={data?.walking}
                          onChange={(e) =>
                            setData({
                              ...data,
                              walking: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.walking == 1 ? true : false}
                        />
                      }
                      label="Walking/Caminando"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="escort"
                          id="escort"
                          value={data?.escort}
                          onChange={(e) =>
                            setData({
                              ...data,
                              escort: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.escort == 1 ? true : false}
                        />
                      }
                      label="Escort/Acompanar"
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="feeding"
                          id="feeding"
                          value={data?.feeding}
                          onChange={(e) =>
                            setData({
                              ...data,
                              feeding: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.feeding == 1 ? true : false}
                        />
                      }
                      label="Feeding/Dar de Comer"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="ambulation"
                          id="ambulation"
                          value={data?.ambulation}
                          onChange={(e) =>
                            setData({
                              ...data,
                              ambulation: e.target.checked,
                            })
                          }
                          checked={
                            selectedPrint?.ambulation == 1 ? true : false
                          }
                        />
                      }
                      label="Ambulation/Ambulacion"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="shopping"
                          id="shopping"
                          value={data?.shopping}
                          onChange={(e) =>
                            setData({
                              ...data,
                              shopping: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.shopping == 1 ? true : false}
                        />
                      }
                      label="Shopping/Hacer Compras"
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="shaving_oral_care"
                          id="shaving_oral_care"
                          value={data?.shaving_oral_care}
                          onChange={(e) =>
                            setData({
                              ...data,
                              shaving_oral_care: e.target.checked,
                            })
                          }
                          checked={
                            selectedPrint?.shaving_oral_care == 1 ? true : false
                          }
                        />
                      }
                      label="Shaving, Oral Care/Aseo"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="cleaning"
                          id="cleaning"
                          value={data?.cleaning}
                          onChange={(e) =>
                            setData({
                              ...data,
                              cleaning: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.cleaning == 1 ? true : false}
                        />
                      }
                      label="Cleaning/Limpiar"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{
                            color: "black",
                          }}
                          size="small"
                          {...label}
                          name="assistance_with_self_administered_medications"
                          id="assistance_with_self_administered_medications"
                          value={
                            selectedPrint?.assistance_with_self_administered_medications
                          }
                          onChange={(e) =>
                            setData({
                              ...data,
                              assistance_with_self_administered_medications:
                                e.target.value,
                            })
                          }
                          checked={
                            selectedPrint?.assistance_with_self_administered_medications ==
                            1
                              ? true
                              : false
                          }
                        />
                      }
                      label="Ass. with Med/Ayudar con Medicinas"
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  <Box p={0} mt={-1} mb={-1}>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          ...style.fontFamily,
                          ...style.fontSize,
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      control={
                        <Checkbox
                          disabled
                          color="default"
                          sx={{ color: "black" }}
                          size="small"
                          {...label}
                          name="transfer"
                          id="transfer"
                          value={data?.transfer}
                          onChange={(e) =>
                            setData({
                              ...data,
                              transfer: e.target.checked,
                            })
                          }
                          checked={selectedPrint?.transfer == 1 ? true : false}
                        />
                      }
                      label="Transfer/Cambiar de Postura"
                    />
                  </Box>
                </td>
                <td
                  style={{
                    padding: 1,
                    paddingLeft: 5,
                    ...style.fontSize,
                    ...style.fontFamily,
                  }}
                >
                  Others/Otra
                  <input
                    style={{
                      border: "0",
                      width: 230,
                      height: 18,
                      fontFamily: "Times New Roman",
                      ...style.fontSize,
                      paddingLeft: 5,
                      paddingBottom: 0,
                      marginLeft: 5,
                    }}
                    type="text"
                    value={data?.otherTasks}
                    onChange={(e) =>
                      setData({ ...data, otherTasks: e.target.value })
                    }
                    name="otherTasks"
                  />
                </td>
              </tr>
            </table>
          </Box>
        </Box>
      </Box>

      <Box
        mt={2}
        fontWeight={"bold"}
        style={{ ...style.fontFamily, ...style.fontSize }}
      >
        SCHEDULE OR AUTHORIZED HOURS/PROGRAMA DE HORAS AUTORIZADAS
      </Box>
      <Box>
        <table style={{ borderCollapse: "collapse", width: "100%" }} border={1}>
          <tr>
            <td>&nbsp;&nbsp;</td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Sunday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Monday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Tuesday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Wednesday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Thursday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Friday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Saturday
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                fontWeight: "bold",
                padding: 2,
              }}
            >
              In
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.sunday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.monday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.thursday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.friday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.saturday?.split("-")[0]?.trim()}
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                fontWeight: "bold",
                padding: 2,
              }}
            >
              Out
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.sunday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.monday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.tuesday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.wednesday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.thursday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.friday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.saturday?.split("-")[1]?.trim()}
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                fontWeight: "bold",
                padding: 2,
              }}
            >
              Total Hours
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.sunday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.sunday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.monday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.monday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.tuesday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.wednesday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.thursday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.thursday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.friday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.friday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.saturday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.saturday?.split("-")[0]?.trim()
              )}
            </td>
          </tr>
        </table>
      </Box>

      <Box display={"flex"}>
        <Box mt={1} mr={2}>
          <table border={1} style={{ borderCollapse: "collapse" }}>
            <tr style={{ fontWeight: "bold", textAlign: "center" }}>
              <td>&nbsp;</td>
              <td style={{ paddingRight: 2, paddingLeft: 2, fontSize: 10 }}>
                TIME IN
              </td>
              <td style={{ paddingRight: 2, paddingLeft: 2, fontSize: 10 }}>
                TIME OUT
              </td>
              <td style={{ paddingRight: 5, paddingLeft: 5, fontSize: 10 }}>
                HOURS:Mins
              </td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td style={{ paddingRight: 10, paddingLeft: 10, fontSize: 10 }}>
                Date/Dia
              </td>
              <td
                style={{
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                  fontSize: 10,
                }}
              >
                Entro
              </td>
              <td
                style={{
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                  fontSize: 10,
                }}
              >
                Salio
              </td>
              <td
                style={{
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                  fontSize: 10,
                }}
              >
                Horas:Mins
              </td>
            </tr>
            {currentMonthDates.map((date, index) => {
              const day = moment(
                `${selectedDate?.split("-")[1]}-${currentMonthDates[index]}-${
                  selectedDate?.split("-")[0]
                }`
              ).format("M/D ddd");
              const totalHours = moment
                .duration()
                .add(
                  timeDiff(
                    selectedSchedulePrint?.sunday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.sunday?.split("-")[0]?.trim()
                  )
                )
                .add(
                  timeDiff(
                    selectedSchedulePrint?.monday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.monday?.split("-")[0]?.trim()
                  )
                )
                .add(
                  timeDiff(
                    selectedSchedulePrint?.tuesday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()
                  )
                )
                .add(
                  timeDiff(
                    selectedSchedulePrint?.wednesday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()
                  )
                )
                .add(
                  timeDiff(
                    selectedSchedulePrint?.thursday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.thursday?.split("-")[0]?.trim()
                  )
                )
                .add(
                  timeDiff(
                    selectedSchedulePrint?.friday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.friday?.split("-")[0]?.trim()
                  )
                )
                .add(
                  timeDiff(
                    selectedSchedulePrint?.saturday?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.saturday?.split("-")[0]?.trim()
                  )
                );

              return (
                parseInt(currentMonthDates[index]) <=
                  parseInt(selectedDate?.split("-")[2]) && (
                  <tr>
                    <td
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        fontSize: 12,
                      }}
                    >
                      {moment(
                        `${selectedDate?.split("-")[1]}-${
                          currentMonthDates[index]
                        }-${selectedDate?.split("-")[0]}`
                      ).format("M/D ddd")}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        paddingRight: 10,
                        paddingLeft: 10,
                        fontSize: 12,
                      }}
                    >
                      {day?.includes("Sun") &&
                        selectedSchedulePrint?.sunday?.split("-")[0]?.trim()}
                      {day?.includes("Mon") &&
                        selectedSchedulePrint?.monday?.split("-")[0]?.trim()}
                      {day?.includes("Tue") &&
                        selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()}
                      {day?.includes("Wed") &&
                        selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()}
                      {day?.includes("Thu") &&
                        selectedSchedulePrint?.thursday?.split("-")[0]?.trim()}
                      {day?.includes("Fri") &&
                        selectedSchedulePrint?.friday?.split("-")[0]?.trim()}
                      {day?.includes("Sat") &&
                        selectedSchedulePrint?.saturday?.split("-")[0]?.trim()}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        paddingRight: 10,
                        paddingLeft: 10,
                        fontSize: 12,
                      }}
                    >
                      {day?.includes("Sun") &&
                        selectedSchedulePrint?.sunday?.split("-")[1]?.trim()}
                      {day?.includes("Mon") &&
                        selectedSchedulePrint?.monday?.split("-")[1]?.trim()}
                      {day?.includes("Tue") &&
                        selectedSchedulePrint?.tuesday?.split("-")[1]?.trim()}
                      {day?.includes("Wed") &&
                        selectedSchedulePrint?.wednesday?.split("-")[1]?.trim()}
                      {day?.includes("Thu") &&
                        selectedSchedulePrint?.thursday?.split("-")[1]?.trim()}
                      {day?.includes("Fri") &&
                        selectedSchedulePrint?.friday?.split("-")[1]?.trim()}
                      {day?.includes("Sat") &&
                        selectedSchedulePrint?.saturday?.split("-")[1]?.trim()}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        paddingRight: 10,
                        paddingLeft: 10,
                        fontSize: 12,
                      }}
                    >
                      {day?.includes("Sun") &&
                        timeDiff(
                          selectedSchedulePrint?.sunday?.split("-")[1]?.trim(),
                          selectedSchedulePrint?.sunday?.split("-")[0]?.trim()
                        )}
                      {day?.includes("Mon") &&
                        timeDiff(
                          selectedSchedulePrint?.monday?.split("-")[1]?.trim(),
                          selectedSchedulePrint?.monday?.split("-")[0]?.trim()
                        )}
                      {day?.includes("Tue") &&
                        timeDiff(
                          selectedSchedulePrint?.tuesday?.split("-")[1]?.trim(),
                          selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()
                        )}
                      {day?.includes("Wed") &&
                        timeDiff(
                          selectedSchedulePrint?.wednesday
                            ?.split("-")[1]
                            ?.trim(),
                          selectedSchedulePrint?.wednesday
                            ?.split("-")[0]
                            ?.trim()
                        )}
                      {day?.includes("Thu") &&
                        timeDiff(
                          selectedSchedulePrint?.thursday
                            ?.split("-")[1]
                            ?.trim(),
                          selectedSchedulePrint?.thursday?.split("-")[0]?.trim()
                        )}
                      {day?.includes("Fri") &&
                        timeDiff(
                          selectedSchedulePrint?.friday?.split("-")[1]?.trim(),
                          selectedSchedulePrint?.friday?.split("-")[0]?.trim()
                        )}
                      {day?.includes("Sat") &&
                        timeDiff(
                          selectedSchedulePrint?.saturday
                            ?.split("-")[1]
                            ?.trim(),
                          selectedSchedulePrint?.saturday?.split("-")[0]?.trim()
                        )}
                    </td>
                  </tr>
                )
              );
            })}
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 10 }} colSpan={3}>
                Total Hours:Mins/Total de Horas:Mins
              </td>
              <td style={{ fontWeight: "bold", fontSize: 10 }}>
                {/* {[
                  "sunday",
                  "monday",
                  "tuesday",
                  "wednesday",
                  "thursday",
                  "friday",
                  "saturday",
                ].map((item) => {
                  let total_hours = "";
                  let inm = selectedSchedulePrint?.item?.split("-")[1]?.trim()
                  total_hours = timeDiff(
                    selectedSchedulePrint?.item?.split("-")[1]?.trim(),
                    selectedSchedulePrint?.item?.split("-")[0]?.trim()
                  );
                  let result = moment(totalHours).add(total_hours);
                  setTotalHours(result);
                  return "Emma";
                })} */}
                {/* {moment(totalHours).format("HH:ss")} */}
                {/* TODO */}
              </td>
            </tr>
          </table>
        </Box>
        <Box mt={1}>
          <table border={1} style={{ borderCollapse: "collapse" }}>
            <tr style={{ fontWeight: "bold", textAlign: "center" }}>
              <td>&nbsp;</td>
              <td style={{ paddingRight: 2, paddingLeft: 2, fontSize: 10 }}>
                TIME IN
              </td>
              <td style={{ paddingRight: 2, paddingLeft: 2, fontSize: 10 }}>
                TIME OUT
              </td>
              <td style={{ paddingRight: 5, paddingLeft: 5, fontSize: 10 }}>
                HOURS:Mins
              </td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td style={{ paddingRight: 10, paddingLeft: 10, fontSize: 10 }}>
                Date/Dia
              </td>
              <td
                style={{
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                  fontSize: 10,
                }}
              >
                Entro
              </td>
              <td
                style={{
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                  fontSize: 10,
                }}
              >
                Salio
              </td>
              <td
                style={{
                  textAlign: "center",
                  paddingRight: 10,
                  paddingLeft: 10,
                  fontSize: 10,
                }}
              >
                Horas:Mins
              </td>
            </tr>
            {currentMonthDates.map((date, index) => {
              return (
                parseInt(currentMonthDates[index]) <=
                  parseInt(selectedDate?.split("-")[2]) && (
                  <tr>
                    <td
                      style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        fontSize: 10,
                        paddingBottom: 2,
                        paddingTop: 2,
                      }}
                    >
                      &nbsp;
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "center",
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "center",
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                    ></td>
                  </tr>
                )
              );
            })}
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 10 }} colSpan={3}>
                Total Hours:Mins/Total de Horas:Mins
              </td>
            </tr>
          </table>
        </Box>
      </Box>

      <Box mt={2} border={"1px solid black"} p={1} width={"97%"}>
        <Box display={"flex"} alignItems={"center"}>
          <Box flex={1}>
            <Box fontSize={12} fontWeight={"bold"}>
              This is to certify that I worked the hours recorded and completed
              the work tasks assigned
            </Box>
            <Box fontSize={12} fontWeight={"bold"}>
              Con esto certifico que segun mi leal saber, el empleado trabajo
              las horas anotadas ehizo las tareas asignadas.
            </Box>
          </Box>
          <Box flex={1}>
            <Box
              fontSize={12}
              fontWeight={"bold"}
              borderTop={"1px solid black"}
              textAlign={"center"}
            >
              Signature-Employee/Firma-Empleado
            </Box>
          </Box>
        </Box>

        <Box display={"flex"} mt={2} alignItems={"center"}>
          <Box flex={1}>
            <Box fontSize={12} fontWeight={"bold"}>
              This is to certify that to the best of my knowledge the employee
              has worked the hours recorded and completed the tasks
            </Box>
            <Box fontSize={12} fontWeight={"bold"}>
              Con esto certifico que segun mi leal saber, el empleado trabajo
              las horas anotadas ehizo las tareas asignadas.
            </Box>
          </Box>
          <Box flex={1} display={"flex"} ml={1}>
            <Box
              fontSize={12}
              fontWeight={"bold"}
              borderTop={"1px solid black"}
              textAlign={"center"}
            >
              Signature-Timekeeper/Firma-Revisor deHoras Trabajadas
            </Box>
            <Box
              ml={1}
              fontSize={12}
              fontWeight={"bold"}
              borderTop={"1px solid black"}
              textAlign={"center"}
            >
              Date/Fecha
            </Box>
          </Box>
        </Box>

        <Box display={"flex"} alignItems={"center"}>
          <Box
            flex={1}
            width={"50%"}
            mt={3}
            fontSize={12}
            fontWeight={"bold"}
            borderTop={"1px solid black"}
            textAlign={"center"}
          >
            Signature-Individual/Individuo
          </Box>
          <Box flex={1} fontSize={12} ml={1}>
            *The date indicated here must not be before the day provider worked.{" "}
            <br /> *Esta fecha no debe ser ni un dia antes del ultimo dia que
            trabajo el empleado
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DailySchedule;
const style = {
  fontSize: {
    fontSize: 10,
  },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
};
