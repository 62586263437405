import React from "react";
import { createContext, useState } from "react";
export const AuthorizationContext = createContext("");

export default function AuthorizationContextProvider({ children }) {
  const [currentAuthId, setCurrentAuthId] = useState("");
  const [authData, setAuthData] = useState({
    individual_id: "",
    service_start_date: "",
    service_end_date: "",
    last_spervisor_visit_date: "",
    authorization_received_date: "",
    practioner_statement_sent: "",
    service_type: "",
    place_service_id: "",
    liason: "",
    discharge_reason: "",
    case_worker: "",
    practioner_statement_received: "",
    authorization_end_date: "",
    priority: "",
    dob: "2024-01-07T23:00:00.000Z", //! not required anymore
    assessment_date: "",
    sup_visit_freq: "",
    bill_code: "",
    service_initial_reported_cw: "",
    authorization_start_date: "",
    procedure_code_id: "",
    diagnosis: "",
    procedure_code_qualifier: "",
    units: "",
    status: "",
    authorization_ref_number: "",
    supplies_equipment: "",
    doctor: "",
    billing_diagnosis_code_1: "",
    billing_diagnosis_code_2: "",
    billing_diagnosis_code_3: "",
    billing_diagnosis_code_4: "",
    service_group: "",
    service_code: "",
    service_code_description: "",
    hcpcs: "",
    modifier_1: "",
    modifier_2: "",
    modifier_3: "",
    modifier_4: "",
  });

  return (
    <AuthorizationContext.Provider
      value={{ authData, setAuthData, currentAuthId, setCurrentAuthId }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
}
