import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { formatPhoneNumber } from "../FormatPhoneNumber";
import {
  getSchedulesUnderAuthorization,
  getTasksUnderAuthorization,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
import colours from "../../constants/colours";

const AttendantOrientation = ({
  data,
  setData,
  tasks,
  setTasks,
  setSchedules,
  schedules,
  isHideFooter,
  formName,
}) => {
  const label = { inputProps: { "aria-label": "This is area" } };
  const { loginDetails } = useContext(UserContext);
  const [selectedPrint, setSelectedPrint] = useState({});
  const [selectedSchedulePrint, setSelectedSchedulePrint] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("");
  const [signature_image_4, setSign_4] = useState("");
  const [signature_image_5, setSign_5] = useState("");
  const [signature_image_6, setSign_6] = useState("");

  const signature_4 = useRef();
  const signature_5 = useRef();
  const signature_6 = useRef();

  //set initial task
  useEffect(() => {
    if (tasks?.length > 0) {
      setSelectedPrint(tasks[0]);
    }
  }, [data?.authorzation_id, tasks]);

  //set initial schedule
  useEffect(() => {
    if (schedules?.length > 0) {
      selectPrint(0);
    } else {
      setSelectedSchedulePrint({});
    }
  }, [data?.authorzation_id, schedules]);

  useEffect(() => {
    //get all tasks, weekly schedules under this authorization id

    getTasksUnderAuthorization(
      loginDetails?.user[0]?.user_id,
      data?.authorzation_id,
      loginDetails.token,
      setTasks
    );

    getSchedulesUnderAuthorization(
      loginDetails?.user[0]?.user_id,
      data?.authorzation_id,
      loginDetails.token,
      setSchedules
    );
  }, [data?.authorzation_id]);

  //Task and Schedule
  const selectPrint = (index) => {
    let result = {};
    let result_ = {};
    setSelectedIndex(index);

    for (
      let i = 0;
      i < (tasks.length >= schedules.length ? tasks.length : schedules.length);
      i++
    ) {
      if (index === i) {
        // result = tasks[i];
        result_ = schedules[i];
      }
    }

    // setSelectedPrint(result);
    setSelectedSchedulePrint(result_);
  };

  // task only
  const selectPrint_ = (index) => {
    let result = {};
    setSelectedIndex(index);

    for (let i = 0; i < tasks.length; i++) {
      if (index === i) {
        result = tasks[i];
      }
    }

    setSelectedPrint(result);
  };

  // Schedule only
  const selectPrint__ = (index) => {
    let result_ = {};
    setSelectedIndex(index);

    for (let i = 0; i < schedules.length; i++) {
      if (index === i) {
        result_ = schedules[i];
      }
    }

    setSelectedSchedulePrint(result_);
  };

  const timeDiff = (timeIn, timeOut) => {
    if (!timeIn) return "-";
    const diff = moment.duration(
      moment(timeIn, "HH:mm:ss a").diff(moment(timeOut, "HH:mm:ss a"))
    );

    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    const result = `${hours} : ${minutes} : ${seconds}`;

    return moment(result, "HH:mm").format("HH:mm");
  };

  return (
    <Box>
      <Box
        textAlign={"center"}
        fontFamily={"Times New Roman"}
        fontSize={18.6}
        fontWeight={"bold"}
      >
        ATTENDANT ORIENTATION
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Individual Name:&nbsp;
          </Box>
          <Box
            style={{ ...style.fontSize, ...style.fontFamily }}
            borderBottom={1}
            width={300}
            // mt={data?.individual_name ? -1 : 0}
          >
            {data?.individual?.firstname}
            {data?.individual?.firstname && ","} {data?.individual?.lastname}
          </Box>
        </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Individual No:&nbsp;
          </Box>
          <Box
            borderBottom={1}
            width={150}
            style={style.fontSize}
            fontFamily={"Times New Roman"}
          >
            {data?.individual?.medicaid}
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={-1.5}
      >
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Address:&nbsp;
          </Box>
          <Box
            borderBottom={1}
            width={400}
            style={style.fontSize}
            fontFamily={"Times New Roman"}
          >
            {data?.individual?.address}
          </Box>
        </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Country:&nbsp;
          </Box>
          <Box
            borderBottom={1}
            width={150}
            style={style.fontSize}
            fontFamily={"Times New Roman"}
          >
            {data?.individual?.country}
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        mt={-1.5}
      >
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Phone:&nbsp;
          </Box>
          <Box
            borderBottom={1}
            width={100}
            style={style.fontSize}
            fontFamily={"Times New Roman"}
          >
            {formatPhoneNumber(data?.individual?.phone)}
          </Box>
        </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Alt #:&nbsp;
          </Box>
          <Box
            borderBottom={1}
            width={100}
            style={style.fontSize}
            fontFamily={"Times New Roman"}
          >
            {formatPhoneNumber(data?.individual?.other_phone)}
          </Box>
        </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Sex:&nbsp;
          </Box>
          <Box
            borderBottom={1}
            width={50}
            style={style.fontSize}
            fontFamily={"Times New Roman"}
            textTransform={"capitalize"}
          >
            {data?.individual?.gender}
          </Box>
        </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Sup. Visit Freq:&nbsp;
          </Box>
          <Box
            style={style.fontSize}
            fontFamily={"Times New Roman"}
            borderBottom={1}
            width={50}
            textAlign={"center"}
          >
            Q 6
          </Box>
        </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"}>
          <Box style={style.fontSize} fontFamily={"Times New Roman"}>
            Months Sup. Visit Level:&nbsp;
          </Box>
          <Box borderBottom={1} width={50} mt={-1}></Box>
        </Box>
      </Box>
      <Box mt={1}>
        <table width={"100%"} border={1} style={{ borderCollapse: "collapse" }}>
          <tr>
            <td
              width={"50%"}
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 5,
              }}
            >
              <Box display={"flex"}>
                <Box style={{ ...style.fontFamily, ...style.fontSize }}>
                  Attendant Name A: &nbsp;
                </Box>
                <input
                  style={{
                    ...style.fontSize,
                    border: "0",
                    width: 200,
                    height: 15,
                    textAlign: "left",
                    fontFamily: "Times New Roman",
                    paddingLeft: 5,
                  }}
                  type="text"
                  value={data?.attendantA}
                  onChange={(e) =>
                    setData({ ...data, attendantA: e.target.value })
                  }
                  name="attendantA"
                />
              </Box>
            </td>
            <td style={{ padding: 3, ...style.fontFamily, ...style.fontSize }}>
              <Box display={"flex"}>
                <Box style={{ ...style.fontFamily, ...style.fontSize }}>
                  B: &nbsp;
                </Box>
                <input
                  style={{
                    ...style.fontSize,
                    border: "0",
                    width: 200,
                    height: 15,
                    textAlign: "left",
                    fontFamily: "Times New Roman",
                    paddingLeft: 5,
                  }}
                  type="text"
                  value={data?.attendantB}
                  onChange={(e) =>
                    setData({ ...data, attendantB: e.target.value })
                  }
                  name="attendantB"
                />
              </Box>
            </td>
          </tr>
        </table>
      </Box>
      <Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Box mt={0.5} display={"flex"} flexDirection={"row"}>
            <Box
              style={style.fontSize}
              fontFamily={"Times New Roman"}
              fontWeight={"bold"}
            >
              Date of Attendant Orientation: &nbsp;
            </Box>
            <Box
              borderBottom={1}
              width={300}
              style={style.fontSize}
              fontFamily={"Times New Roman"}
              // mt={-1}
            >
              <input
                style={{
                  border: "0",
                  width: 300,
                  height: 15,
                  textAlign: "left",
                  fontFamily: "Times New Roman",
                  paddingLeft: 10,
                }}
                type="text"
                value={data?.orientationDate}
                onChange={(e) =>
                  setData({ ...data, orientationDate: e.target.value })
                }
                name="orientationDate"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={0.5}>
        <Box style={style.fontSize} fontFamily={"Times New Roman"}>
          <Box fontWeight={"bold"}>Orientation Was Done:</Box> [ ] In Person
          with individual participation [ ] In Person without individual
          participation [ ] In the Office{" "}
        </Box>
      </Box>
      <Box mt={0.3}>
        <Box style={style.fontSize} fontFamily={"Times New Roman"}>
          [ ] By Phone (attendant already worked for individual)
        </Box>
      </Box>
      <Box mt={0.3}>
        <Box style={style.fontSize} fontFamily={"Times New Roman"}>
          [ ] Attendant instructed about individual’s health condition and how
          it may affect provision of task
        </Box>
      </Box>
      <Box mt={0.3}>
        <Box style={style.fontSize} fontFamily={"Times New Roman"}>
          [ ] Attendant instructed about tasks to be provided, work schedule,
          safety and emergency procedures including universal precautions.
        </Box>
      </Box>
      <Box mt={1}>
        <Box
          style={style.fontSize}
          fontFamily={"Times New Roman"}
          fontWeight={"bold"}
        >
          Task to Be Performed & Frequency:
        </Box>
      </Box>
      <Box>
        <table border={1} style={{ borderCollapse: "collapse" }} width={"100%"}>
          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
                paddingTop: -10,
                paddingBottom: -10,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="bathing"
                      id="bathing"
                      value={data?.bathing}
                      onChange={(e) =>
                        setData({ ...data, bathing: e.target.checked })
                      }
                      checked={selectedPrint?.bathing == 1 ? true : false}
                    />
                  }
                  label="Bathing"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="routine_hair_skin_care"
                      id="routine_hair_skin_care"
                      value={data?.routine_hair_skin_care}
                      onChange={(e) =>
                        setData({
                          ...data,
                          routine_hair_skin_care: e.target.checked,
                        })
                      }
                      checked={
                        selectedPrint?.routine_hair_skin_care == 1
                          ? true
                          : false
                      }
                    />
                  }
                  label="Routine Hair & Skin Care"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="laundry"
                      id="laundry"
                      value={data?.laundry}
                      onChange={(e) =>
                        setData({
                          ...data,
                          laundry: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.laundry == 1 ? true : false}
                    />
                  }
                  label="Laundry"
                />
              </Box>
            </td>
          </tr>

          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="dressing"
                      id="dressing"
                      value={data?.dressing}
                      onChange={(e) =>
                        setData({
                          ...data,
                          dressing: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.dressing == 1 ? true : false}
                    />
                  }
                  label="Dressing"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="toilet"
                      id="toilet"
                      value={data?.toilet}
                      onChange={(e) =>
                        setData({
                          ...data,
                          toilet: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.toilet == 1 ? true : false}
                    />
                  }
                  label="Toileting"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="meal_preparation"
                      id="meal_preparation"
                      value={data?.meal_preparation}
                      onChange={(e) =>
                        setData({
                          ...data,
                          meal_preparation: e.target.checked,
                        })
                      }
                      checked={
                        selectedPrint?.meal_preparation == 1 ? true : false
                      }
                    />
                  }
                  label="Meal Preparation"
                />
              </Box>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="excercising"
                      id="excercising"
                      value={data?.excercising}
                      onChange={(e) =>
                        setData({
                          ...data,
                          excercising: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.excercising == 1 ? true : false}
                    />
                  }
                  label="Exercise"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="walking"
                      id="walking"
                      value={data?.walking}
                      onChange={(e) =>
                        setData({
                          ...data,
                          walking: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.walking == 1 ? true : false}
                    />
                  }
                  label="Walking"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="escort"
                      id="escort"
                      value={data?.escort}
                      onChange={(e) =>
                        setData({
                          ...data,
                          escort: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.escort == 1 ? true : false}
                    />
                  }
                  label="Escort"
                />
              </Box>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="feeding"
                      id="feeding"
                      value={data?.feeding}
                      onChange={(e) =>
                        setData({
                          ...data,
                          feeding: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.feeding == 1 ? true : false}
                    />
                  }
                  label="Feeding"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="ambulation"
                      id="ambulation"
                      value={data?.ambulation}
                      onChange={(e) =>
                        setData({
                          ...data,
                          ambulation: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.ambulation == 1 ? true : false}
                    />
                  }
                  label="Ambulation"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="shopping"
                      id="shopping"
                      value={data?.shopping}
                      onChange={(e) =>
                        setData({
                          ...data,
                          shopping: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.shopping == 1 ? true : false}
                    />
                  }
                  label="Shopping"
                />
              </Box>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="shaving_oral_care"
                      id="shaving_oral_care"
                      value={data?.shaving_oral_care}
                      onChange={(e) =>
                        setData({
                          ...data,
                          shaving_oral_care: e.target.checked,
                        })
                      }
                      checked={
                        selectedPrint?.shaving_oral_care == 1 ? true : false
                      }
                    />
                  }
                  label="Shaving, Oral Care"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="cleaning"
                      id="cleaning"
                      value={data?.cleaning}
                      onChange={(e) =>
                        setData({
                          ...data,
                          cleaning: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.cleaning == 1 ? true : false}
                    />
                  }
                  label="Cleaning"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{
                        color: "black",
                      }}
                      size="small"
                      {...label}
                      name="assistance_with_self_administered_medications"
                      id="assistance_with_self_administered_medications"
                      value={
                        selectedPrint?.assistance_with_self_administered_medications
                      }
                      onChange={(e) =>
                        setData({
                          ...data,
                          assistance_with_self_administered_medications:
                            e.target.value,
                        })
                      }
                      checked={
                        selectedPrint?.assistance_with_self_administered_medications ==
                        1
                          ? true
                          : false
                      }
                    />
                  }
                  label="Assist with Medication"
                />
              </Box>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <Box p={0} mt={-1} mb={-1}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      ...style.fontFamily,
                      ...style.fontSize,
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  control={
                    <Checkbox
                      disabled
                      color="default"
                      sx={{ color: "black" }}
                      size="small"
                      {...label}
                      name="transfer"
                      id="transfer"
                      value={data?.transfer}
                      onChange={(e) =>
                        setData({
                          ...data,
                          transfer: e.target.checked,
                        })
                      }
                      checked={selectedPrint?.transfer == 1 ? true : false}
                    />
                  }
                  label="Transfer"
                />
              </Box>
            </td>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              Others
              <input
                style={{
                  border: "0",
                  width: 230,
                  height: 18,
                  fontFamily: "Times New Roman",
                  ...style.fontSize,
                  paddingLeft: 5,
                  paddingBottom: 0,
                  marginLeft: 5,
                }}
                type="text"
                value={data?.otherTasks}
                onChange={(e) =>
                  setData({ ...data, otherTasks: e.target.value })
                }
                name="otherTasks"
              />
            </td>
          </tr>
        </table>
      </Box>
      <Box mt={2}>
        <table border={1} style={{ borderCollapse: "collapse" }} width={"100%"}>
          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              Number of Hours of Services that the Individual is authorized to
              receive per week:
            </td>
            <td
              style={{
                padding: 1,
                paddingRight: 10,
                textAlign: "right",
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                {data?.authorizedTime?.split("-")[0]}
              </span>
              Hrs
              {/* &nbsp; x &nbsp;
              <span
                style={{
                  fontWeight: "bold",
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                {data?.authorizedTime?.split("-")[1]}
              </span>
              days{" = "}
              <span
                style={{
                  fontWeight: "bold",
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                {parseInt(data?.authorizedTime?.split("-")[1]) *
                  parseInt(data?.authorizedTime?.split("-")[0])}
              </span>
              {"Hrs"} */}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: 1,
                paddingLeft: 10,
                ...style.fontSize,
                ...style.fontFamily,
              }}
            >
              Total Number of hours Attendant is to provide:
            </td>
            <td
              style={{
                padding: 1,
                paddingRight: 10,
                ...style.fontSize,
                ...style.fontFamily,
                textAlign: "right",
              }}
            >
              <input
                style={{
                  border: "0",
                  width: 100,
                  height: 20,
                  fontFamily: "Times New Roman",
                  ...style.fontSize,
                  paddingLeft: 5,
                  paddingBottom: 0,
                  textAlign: "right",
                  fontWeight: "bold",
                }}
                type="text"
                value={data?.numberHours}
                onChange={(e) =>
                  setData({ ...data, numberHours: e.target.value })
                }
                name="supervisor"
              />
              Hrs
            </td>
          </tr>
        </table>
      </Box>
      <Box mt={1} flexDirection={"row"} display={"flex"}>
        <Box
          style={{ ...style.fontFamily, ...style.fontSize }}
          flexDirection={"row"}
          display={"flex"}
        >
          <Box fontWeight={"bold"}>Note: </Box> &nbsp; Attendant instructed to
          notify Agency Supervisor
        </Box>
        <Box borderBottom={1} width={130} mt={-1}>
          <input
            style={{
              border: "0",
              width: 120,
              height: 20,
              fontFamily: "Times New Roman",
              // ...style.fontSize,
              paddingLeft: 5,
              paddingBottom: 0,
            }}
            type="text"
            value={data?.supervisor}
            onChange={(e) => setData({ ...data, supervisor: e.target.value })}
            name="supervisor"
          />
        </Box>
        <Box style={{ ...style.fontFamily, ...style.fontSize }}>at</Box>
        <Box borderBottom={1} width={100} mt={-1}>
          <input
            style={{
              border: "0",
              width: 90,
              height: 20,
              fontFamily: "Times New Roman",
              ...style.fontSize,
              paddingLeft: 5,
              paddingBottom: 0,
            }}
            type="text"
            value={data?.supervisorPhone}
            onChange={(e) =>
              setData({ ...data, supervisorPhone: e.target.value })
            }
            name="supervisor"
          />
        </Box>
        <Box style={{ ...style.fontFamily, ...style.fontSize }}>
          as soon as possible if:-{" "}
        </Box>
      </Box>
      <Box mt={-2}>
        <ul>
          <li style={{ ...style.fontFamily, ...style.fontSize }}>
            There are changes in Individual’s needs
          </li>
          <li style={{ ...style.fontFamily, ...style.fontSize }}>
            Individual did not receive monthly Medicaid Card (if PHC)
          </li>
          <li style={{ ...style.fontFamily, ...style.fontSize }}>
            Incidents that affect Individual’s conditions
          </li>
          <li style={{ ...style.fontFamily, ...style.fontSize }}>
            Hospitalization of the Individual
          </li>
          <li style={{ ...style.fontFamily, ...style.fontSize }}>
            The Individual’s absence or relocation from home
          </li>
          <li style={{ ...style.fontFamily, ...style.fontSize }}>
            The attendant’s inability to work scheduled hours
          </li>
          <li style={{ ...style.fontFamily, ...style.fontSize }}>
            Suspicions or allegations of abuse, neglect, or exploitation of the
            individual.
          </li>
          <li
            style={{
              ...style.fontFamily,
              ...style.fontSize,
            }}
          >
            <Box flexDirection={"row"} display={"flex"}>
              <Box
                style={{
                  ...style.fontFamily,
                  ...style.fontSize,
                }}
              >
                Others:
              </Box>{" "}
              <Box borderBottom={1} width={600}>
                <input
                  style={{
                    border: "0",
                    width: 600,
                    height: 15,
                    fontFamily: "Times New Roman",
                    ...style.fontSize,
                    paddingLeft: 5,
                    paddingBottom: 0,
                  }}
                  type="text"
                  value={data?.others}
                  onChange={(e) => setData({ ...data, others: e.target.value })}
                  name="others"
                />
              </Box>
            </Box>
          </li>
        </ul>
      </Box>
      <Box mt={-1.5}>
        <Box style={{ ...style.fontFamily, ...style.fontSize }}>
          The Individual is provided with a verbal explanation and written copy
          of Agency’s complaint procedure (for non special attendants).
        </Box>
        <Box style={{ ...style.fontFamily, ...style.fontSize }}>
          Attendant has been oriented on FVV & EVV.
        </Box>
        <Box style={{ ...style.fontFamily, ...style.fontSize }}>
          Description of Primary Home Care Client’s Symptoms and functional
          limitations which cause a need for personal
        </Box>
      </Box>
      <Box flexDirection={"row"} display={"flex"}>
        <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={0.5}>
          Care:
        </Box>{" "}
        <Box borderBottom={1} width={600}>
          <input
            style={{
              border: "0",
              width: 600,
              height: 15,
              fontFamily: "Times New Roman",
              ...style.fontSize,
              paddingLeft: 5,
              paddingBottom: 0,
            }}
            type="text"
            value={data?.cares}
            onChange={(e) => setData({ ...data, cares: e.target.value })}
            name="cares"
          />
        </Box>
      </Box>
      {/* Dsiplay only when Attendant Orientation MDCP is selected */}
      {formName === "Attendant Orientation MDCP" && (
        <Box
          style={{ ...style.fontFamily, fontSize: 11, fontWeight: "bold" }}
          mt={0.5}
        >
          The HCSSA RN determined the unlicensed attendant competent to provide
          services according to the individual's IPC. <br /> The HCSSA agency
          provided in-home training specific to the child's needs.
        </Box>
      )}

      <Box
        mt={1}
        fontWeight={"bold"}
        style={{ ...style.fontFamily, ...style.fontSize }}
      >
        Attendant Service Schedule:
      </Box>
      <Box>
        <table style={{ borderCollapse: "collapse", width: "100%" }} border={1}>
          <tr>
            <td>&nbsp;&nbsp;</td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Sunday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Monday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Tuesday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Wednesday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Thursday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Friday
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                padding: 2,
                fontWeight: "bold",
                paddingLeft: 10,
              }}
            >
              Saturday
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                fontWeight: "bold",
                padding: 2,
              }}
            >
              In
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.sunday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.monday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.thursday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.friday?.split("-")[0]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.saturday?.split("-")[0]?.trim()}
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                fontWeight: "bold",
                padding: 2,
              }}
            >
              Out
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.sunday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.monday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.tuesday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.wednesday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.thursday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.friday?.split("-")[1]?.trim()}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
              }}
            >
              {selectedSchedulePrint?.saturday?.split("-")[1]?.trim()}
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                fontWeight: "bold",
                padding: 2,
              }}
            >
              Total Hours
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.sunday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.sunday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.monday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.monday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.tuesday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.wednesday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.thursday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.thursday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.friday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.friday?.split("-")[0]?.trim()
              )}
            </td>
            <td
              style={{
                ...style.fontFamily,
                ...style.fontSize,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {timeDiff(
                selectedSchedulePrint?.saturday?.split("-")[1]?.trim(),
                selectedSchedulePrint?.saturday?.split("-")[0]?.trim()
              )}
            </td>
          </tr>
        </table>
      </Box>
      <Box>
        <ol type="1">
          <li>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Box style={{ ...style.fontFamily, ...style.fontSize }}>
                Attendant’s Signature: A.
              </Box>
              <Box
                width={200}
                borderBottom={1}
                flexDirection={"row"}
                display={"flex"}
                mt={-2}
              >
                <SignatureCanvas
                  onEnd={(data) =>
                    setSign_4(
                      signature_4.current
                        .getTrimmedCanvas()
                        .toDataURL("image/png")
                    )
                  }
                  ref={signature_4}
                  penColor="black"
                  dotSize={0.5}
                  canvasProps={{
                    width: 200,
                    height: 30,
                    className: "sigCanvas",
                  }}
                />
              </Box>
              <Box style={{ ...style.fontFamily, ...style.fontSize }}>
                Date:{" "}
              </Box>
              <Box width={200} borderBottom={1} mt={-1}>
                <input
                  style={{
                    border: "0",
                    width: 200,
                    height: 20,
                    fontFamily: "Times New Roman",
                    paddingLeft: 5,
                  }}
                  type="text"
                  value={data?.date}
                  onChange={(e) => setData({ ...data, date: e.target.value })}
                  name="date"
                />
              </Box>
            </Box>
          </li>
          <li>
            <Box style={{ display: "flex", flexDirection: "row" }} mt={1}>
              <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={1}>
                Attendant’s Signature: B.
              </Box>
              <Box width={200} borderBottom={1} mt={-1}>
                <SignatureCanvas
                  onEnd={(data) =>
                    setSign_4(
                      signature_4.current
                        .getTrimmedCanvas()
                        .toDataURL("image/png")
                    )
                  }
                  ref={signature_4}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 30,
                    className: "sigCanvas",
                  }}
                />
              </Box>
              <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={1}>
                Date:{" "}
              </Box>
              <Box width={200} borderBottom={1}>
                <input
                  style={{
                    border: "0",
                    width: 200,
                    height: 20,
                    fontFamily: "Times New Roman",
                    paddingLeft: 5,
                  }}
                  type="text"
                  value={data?.date_2}
                  onChange={(e) => setData({ ...data, date_2: e.target.value })}
                  name="date_2"
                />
              </Box>
            </Box>
          </li>
        </ol>
      </Box>
      <Box>
        <Box flexDirection={"row"} display={"flex"}>
          <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={1}>
            Agency Supervisor’s Signature:{" "}
          </Box>
          <Box width={200} borderBottom={1} mt={-1.5}>
            <SignatureCanvas
              onEnd={(data) =>
                setSign_5(
                  signature_5.current.getTrimmedCanvas().toDataURL("image/png")
                )
              }
              ref={signature_5}
              penColor="black"
              canvasProps={{
                width: 200,
                height: 30,
                className: "sigCanvas",
              }}
            />
          </Box>
          <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={1}>
            Date:
          </Box>
          <Box width={200} borderBottom={1}>
            <input
              style={{
                border: "0",
                width: 200,
                height: 20,
                paddingLeft: 5,
                fontFamily: "Times New Roman",
              }}
              type="text"
              value={data?.date_3}
              onChange={(e) => setData({ ...data, date_3: e.target.value })}
              name="date"
            />
          </Box>
        </Box>
        <Box flexDirection={"row"} display={"flex"} mt={1}>
          <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={2}>
            Individual’s Signature:
          </Box>
          <Box width={200} borderBottom={1} mt={-1}>
            <SignatureCanvas
              onEnd={(data) =>
                setSign_6(
                  signature_6.current.getTrimmedCanvas().toDataURL("image/png")
                )
              }
              ref={signature_6}
              penColor="black"
              canvasProps={{
                width: 200,
                height: 30,
                className: "sigCanvas",
              }}
            />
          </Box>
          <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={2}>
            Date:
          </Box>
          <Box width={200} borderBottom={1} mt={1}>
            <input
              style={{
                border: "0",
                width: 200,
                height: 20,
                // textAlign: "center",
                paddingLeft: 5,
                fontFamily: "Times New Roman",
              }}
              type="text"
              value={data?.date_4}
              onChange={(e) => setData({ ...data, date_4: e.target.value })}
              name="date"
            />
          </Box>
        </Box>
        {formName === "Attendant Orientation MDCP" && (
          <Box flexDirection={"row"} display={"flex"} mt={0}>
            <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={2}>
              HCSSA RN Signature
            </Box>
            <Box width={200} borderBottom={1} mt={-1}>
              <SignatureCanvas
                onEnd={(data) =>
                  setSign_6(
                    signature_6.current
                      .getTrimmedCanvas()
                      .toDataURL("image/png")
                  )
                }
                ref={signature_6}
                penColor="black"
                canvasProps={{
                  width: 200,
                  height: 30,
                  className: "sigCanvas",
                }}
              />
            </Box>
            <Box style={{ ...style.fontFamily, ...style.fontSize }} mt={2}>
              Date:
            </Box>
            <Box width={200} borderBottom={1} mt={1}>
              <input
                style={{
                  border: "0",
                  width: 200,
                  height: 20,
                  // textAlign: "center",
                  paddingLeft: 5,
                  fontFamily: "Times New Roman",
                }}
                type="text"
                value={data?.date_4}
                onChange={(e) => setData({ ...data, date_4: e.target.value })}
                name="date"
              />
            </Box>
          </Box>
        )}
      </Box>
      {!isHideFooter && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box>
            {tasks >= schedules ? (
              <Box
                textAlign={"center"}
                mt={2}
                color={"blue"}
                fontWeight={"bold"}
                style={{ ...style.fontFamily, ...style.fontSize }}
              >
                Printable Page(s):{" "}
                {tasks.map((task, index) => {
                  // console.log(task, "--+-");
                  return (
                    <span
                      onClick={() => selectPrint(index)}
                      style={{
                        textDecoration: "underline",
                        marginRight: 10,
                        cursor: "pointer",
                        fontWeight:
                          selectedIndex === index ? "bolder" : "normal",
                        fontSize: selectedIndex === index ? 18 : 14,
                      }}
                    >{`${index + 1}`}</span>
                  );
                })}
              </Box>
            ) : (
              <Box
                textAlign={"center"}
                // mt={2}
                color={"blue"}
                fontWeight={"bold"}
                style={{ ...style.fontFamily, ...style.fontSize }}
              >
                Printable Page(s):{" "}
                {schedules.map((page, index) => {
                  return (
                    <span
                      onClick={() => selectPrint(index)}
                      style={{
                        textDecoration: "underline",
                        marginRight: 10,
                        cursor: "pointer",
                        fontWeight:
                          selectedIndex === index ? "bolder" : "normal",
                        fontSize: selectedIndex === index ? 18 : 14,
                      }}
                    >{`${index + 1}`}</span>
                  );
                })}
              </Box>
            )}
          </Box>

          {/* <Box color={"blue"}>
            <Box
              fontWeight={"bold"}
              style={{ ...style.fontFamily, ...style.fontSize }}
            >
              Customize Printable Pages:{" "}
            </Box>
            <Box
              // display={"flex"}
              // flexDirection={"row"}
              // alignItems={"flex-end"}
              style={{ ...style.fontFamily, ...style.fontSize }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"flex-end"}
              >
                <Box
                  fontWeight={"bold"}
                  style={{ ...style.fontFamily, ...style.fontSize }}
                >
                  Tasks: &nbsp;
                </Box>
                <Box>
                  <Box>
                    {tasks.map((page, index) => {
                      return (
                        <span
                          onClick={() => selectPrint_(index)}
                          style={{
                            textDecoration: "underline",
                            marginRight: 10,
                            cursor: "pointer",
                            fontWeight:
                              selectedIndex === index ? "bolder" : "normal",
                            fontSize: selectedIndex === index ? 18 : 14,
                          }}
                        >{`${index + 1}`}</span>
                      );
                    })}
                  </Box>
                </Box>
              </Box>

              <Box display={"flex"} alignItems={"flex-end"}>
                <Box
                  fontWeight={"bold"}
                  style={{ ...style.fontFamily, ...style.fontSize }}
                >
                  Schedules: &nbsp;
                </Box>
                <Box>
                  {schedules.map((page, index) => {
                    return (
                      <span
                        onClick={() => selectPrint__(index)}
                        style={{
                          textDecoration: "underline",
                          marginRight: 10,
                          cursor: "pointer",
                          fontWeight:
                            selectedIndex === index ? "bolder" : "normal",
                          fontSize: selectedIndex === index ? 18 : 14,
                        }}
                      >{`${index + 1}`}</span>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box> */}
        </Box>
      )}

      <Box width={5} height={50} />
    </Box>
  );
};

const style = {
  fontSize: {
    fontSize: 13.3,
  },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
};

export default AttendantOrientation;
