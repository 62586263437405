import { createContext, useState } from "react";
export const UserContext = createContext("");

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [getData, setGetData] = useState(false);
  const [loginDetails, setLoginDetails] = useState({});
  const [authEdit, setAuthEdit] = useState({});
  const [caseInfoData, setCaseInfoData] = useState({
    sent_to: "",
    caseworker_mail_code: "",
    sent_from: "",
    employee_mail_code: "",
    individual_name: "",
    service_category: "",
    individual_state_id: "",
    individual_address: "",
    individual_areacode: "",
    absent_parent: false,
    change_in_address_telephone: false,
    change_in_circumstances: false,
    child_care: false,
    community_placement_resources: false,
    deductions: false,
    employment_services: false,
    epsdt: false,
    family_health_services_nurse: false,
    family_planning: false,
    household_composition: false,
    income: false,
    ltss_information_shared: false,
    medicaid: false,
    medical_disability: false,
    merp_shared: false,
    nursing_care_level_of_care: false,
    protective_services: false,
    refugee_services: false,
    resources: false,
    support_services: false,
    tanf: false,
    other: false,
    comment_response: "",
    signature_image: "",
    authorization_start_date: "",
    company_phone: "",
  });
  const [complainantData, setComplaintData] = useState({
    complainant: "",
    regarding: "",
    others_involved: "",
    reported_by: "",
    nature_of_problem: "",
    person_receiving_complainant: "",
    action_taken: "",
    person_completing_report: "",
    agrees_with_resolution: false,
    disagrees_with_resolution: false,
  });

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getData,
        setGetData,
        loginDetails,
        setLoginDetails,
        caseInfoData,
        setCaseInfoData,
        complainantData,
        setComplaintData,
        authEdit,
        setAuthEdit,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
