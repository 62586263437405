import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import colours from "../../../constants/colours";
import SideMenu from "../../../component/SideMenu";
import TopNav from "../../../component/TopNav";
import components from "../screens";
import DashboardPage from "../../../component/DashboardPage";

const Dashboard = () => {
  const [selectedPage, setSelectedPage] = useState("");
  const [PageComponent, setPageComponent] = useState(DashboardPage);

  useEffect(() => {
    if (selectedPage) {
      let result = components.find((comp) => comp.id === selectedPage);
      if (result?.component) setPageComponent(result.component);
    }
  }, [selectedPage]);

  return (
    <div>
      <Box style={styles.mainBg}>
        <Grid container spacing={2}>
          <Grid item xs={2} bgcolor={colours.primary}>
            <SideMenu setSelectedPage={setSelectedPage} />
          </Grid>
          <Grid item xs={10} pr={2}>
            <TopNav screen={selectedPage} />

            <Box mt={2} />
            {PageComponent}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Dashboard;

const styles = {
  mainBg: {
    backgroundColor: colours.white,
    height: "100vh",
    display: "flex",
    borderRadius: 7,
  },
};
