import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { formatPhoneNumber } from "../FormatPhoneNumber";
import SignatureCanvas from "react-signature-canvas";
import { UserContext } from "../../context/userContext";
import { getTasksUnderAuthorization } from "../../pages/main/Dashboard/business";

const CoordinationCare = ({
  data,
  setData,
  tasks,
  setTasks,
  setSchedules,
  schedules,
  isHideFooter,
  formName,
}) => {
  const [selectedPrint, setSelectedPrint] = useState({});
  const label = { inputProps: { "aria-label": "This is area" } };
  const signature = useRef();
  const { loginDetails } = useContext(UserContext);

  useEffect(() => {
    if (tasks?.length > 0) {
      setSelectedPrint(tasks[0]);
    }
  }, [data?.authorzation_id, tasks]);
  //set initial task
  useEffect(() => {
    if (tasks?.length > 0) {
      setSelectedPrint(tasks[0]);
    }
  }, [data?.authorzation_id, tasks]);

  useEffect(() => {
    //get all tasks under this authorization id

    getTasksUnderAuthorization(
      loginDetails?.user[0]?.user_id,
      data?.authorzation_id,
      loginDetails.token,
      setTasks
    );
  }, [data?.authorzation_id]);

  return (
    <Box>
      <Box
        textAlign={"center"}
        fontFamily={"Times New Roman"}
        fontSize={18.6}
        fontWeight={"bold"}
      >
        Licensed Home Health/PAS <br />
        Home Health Care Coordination
      </Box>

      <Box display={"flex"} mt={4} alignItems={"flex-end"}>
        <Box fontSize={16} fontFamily={"Times New Roman"}>
          Individual:{" "}
        </Box>
        <Box
          width={"28%"}
          fontFamily={"Times New Roman"}
          fontSize={16}
          borderBottom={"1px solid black"}
        >
          {data?.individual?.firstname}
          {data?.individual?.firstname && ","} {data?.individual?.lastname}
        </Box>
        <Box ml={1} fontSize={16} fontFamily={"Times New Roman"}>
          Indiv. #:{" "}
        </Box>
        <Box
          width={"18%"}
          mr={1}
          fontSize={16}
          fontFamily={"Times New Roman"}
          borderBottom={"1px solid black"}
        >
          {data?.individual?.medicaid}
        </Box>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          Date:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: 200,
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="date"
          />
        </Box>
      </Box>

      <Box mt={2} alignItems={"flex-end"} display={"flex"}>
        <Box mr={0.5} fontFamily={"Times New Roman"} fontSize={16}>
          Address:
        </Box>
        <Box
          borderBottom={"1px solid black"}
          fontFamily={"Times New Roman"}
          fontSize={16}
          width={"60%"}
        >
          {data?.individual?.address}
        </Box>
        <Box ml={1} mr={0.5} fontFamily={"Times New Roman"} fontSize={16}>
          Phone
        </Box>
        <Box
          borderBottom={"1px solid black"}
          fontFamily={"Times New Roman"}
          fontSize={16}
          flex={1}
        >
          {formatPhoneNumber(data?.individual?.phone)}
        </Box>
      </Box>

      <Box mt={2} alignItems={"flex-end"} display={"flex"}>
        <Box fontFamily={"Times New Roman"} fontSize={16}>
          Start of Care date:{" "}
        </Box>
        <Box ml={0.5} width={"15%"} borderBottom={"1px solid black"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 15,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="authDate"
          />
        </Box>
        <Box ml={1} fontFamily={"Times New Roman"} fontSize={16}>
          Discharge date:{" "}
        </Box>
        <Box ml={0.5} width={"15%"} borderBottom={"1px solid black"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 15,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="dischargeDate"
          />
        </Box>
        <Box ml={1} fontFamily={"Times New Roman"} fontSize={16}>
          SS#:{" "}
        </Box>
        <Box ml={0.5} flex={1} borderBottom={"1px solid black"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: 200,
              height: 15,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            value={data?.individual?.medicaid}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="dischargeDate"
          />
        </Box>
      </Box>

      <Box mt={4}>
        <Box>
          <table
            border={1}
            style={{ borderCollapse: "collapse" }}
            width={"100%"}
          >
            <tr>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                  paddingTop: -10,
                  paddingBottom: -10,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="bathing"
                        id="bathing"
                        value={data?.bathing}
                        onChange={(e) =>
                          setData({ ...data, bathing: e.target.checked })
                        }
                        checked={selectedPrint?.bathing == 1 ? true : false}
                      />
                    }
                    label="Bathing"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="routine_hair_skin_care"
                        id="routine_hair_skin_care"
                        value={data?.routine_hair_skin_care}
                        onChange={(e) =>
                          setData({
                            ...data,
                            routine_hair_skin_care: e.target.checked,
                          })
                        }
                        checked={
                          selectedPrint?.routine_hair_skin_care == 1
                            ? true
                            : false
                        }
                      />
                    }
                    label="Routine Hair & Skin Care"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="laundry"
                        id="laundry"
                        value={data?.laundry}
                        onChange={(e) =>
                          setData({
                            ...data,
                            laundry: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.laundry == 1 ? true : false}
                      />
                    }
                    label="Laundry"
                  />
                </Box>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="dressing"
                        id="dressing"
                        value={data?.dressing}
                        onChange={(e) =>
                          setData({
                            ...data,
                            dressing: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.dressing == 1 ? true : false}
                      />
                    }
                    label="Dressing"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="toilet"
                        id="toilet"
                        value={data?.toilet}
                        onChange={(e) =>
                          setData({
                            ...data,
                            toilet: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.toilet == 1 ? true : false}
                      />
                    }
                    label="Toileting"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="meal_preparation"
                        id="meal_preparation"
                        value={data?.meal_preparation}
                        onChange={(e) =>
                          setData({
                            ...data,
                            meal_preparation: e.target.checked,
                          })
                        }
                        checked={
                          selectedPrint?.meal_preparation == 1 ? true : false
                        }
                      />
                    }
                    label="Meal Preparation"
                  />
                </Box>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="excercising"
                        id="excercising"
                        value={data?.excercising}
                        onChange={(e) =>
                          setData({
                            ...data,
                            excercising: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.excercising == 1 ? true : false}
                      />
                    }
                    label="Exercise"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="walking"
                        id="walking"
                        value={data?.walking}
                        onChange={(e) =>
                          setData({
                            ...data,
                            walking: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.walking == 1 ? true : false}
                      />
                    }
                    label="Walking"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="escort"
                        id="escort"
                        value={data?.escort}
                        onChange={(e) =>
                          setData({
                            ...data,
                            escort: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.escort == 1 ? true : false}
                      />
                    }
                    label="Escort"
                  />
                </Box>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="feeding"
                        id="feeding"
                        value={data?.feeding}
                        onChange={(e) =>
                          setData({
                            ...data,
                            feeding: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.feeding == 1 ? true : false}
                      />
                    }
                    label="Feeding"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="ambulation"
                        id="ambulation"
                        value={data?.ambulation}
                        onChange={(e) =>
                          setData({
                            ...data,
                            ambulation: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.ambulation == 1 ? true : false}
                      />
                    }
                    label="Ambulation"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="shopping"
                        id="shopping"
                        value={data?.shopping}
                        onChange={(e) =>
                          setData({
                            ...data,
                            shopping: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.shopping == 1 ? true : false}
                      />
                    }
                    label="Shopping"
                  />
                </Box>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="shaving_oral_care"
                        id="shaving_oral_care"
                        value={data?.shaving_oral_care}
                        onChange={(e) =>
                          setData({
                            ...data,
                            shaving_oral_care: e.target.checked,
                          })
                        }
                        checked={
                          selectedPrint?.shaving_oral_care == 1 ? true : false
                        }
                      />
                    }
                    label="Shaving, Oral Care"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="cleaning"
                        id="cleaning"
                        value={data?.cleaning}
                        onChange={(e) =>
                          setData({
                            ...data,
                            cleaning: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.cleaning == 1 ? true : false}
                      />
                    }
                    label="Cleaning"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{
                          color: "black",
                        }}
                        size="small"
                        {...label}
                        name="assistance_with_self_administered_medications"
                        id="assistance_with_self_administered_medications"
                        value={
                          selectedPrint?.assistance_with_self_administered_medications
                        }
                        onChange={(e) =>
                          setData({
                            ...data,
                            assistance_with_self_administered_medications:
                              e.target.value,
                          })
                        }
                        checked={
                          selectedPrint?.assistance_with_self_administered_medications ==
                          1
                            ? true
                            : false
                        }
                      />
                    }
                    label="Assist with Medication"
                  />
                </Box>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                <Box p={0} mt={-1} mb={-1}>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        ...style.fontFamily,
                        ...style.fontSize,
                      },
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        color: "black",
                      },
                    }}
                    control={
                      <Checkbox
                        disabled
                        color="default"
                        sx={{ color: "black" }}
                        size="small"
                        {...label}
                        name="transfer"
                        id="transfer"
                        value={data?.transfer}
                        onChange={(e) =>
                          setData({
                            ...data,
                            transfer: e.target.checked,
                          })
                        }
                        checked={selectedPrint?.transfer == 1 ? true : false}
                      />
                    }
                    label="Transfer"
                  />
                </Box>
              </td>
              <td
                style={{
                  padding: 1,
                  paddingLeft: 10,
                  ...style.fontSize,
                  ...style.fontFamily,
                }}
              >
                Others
                <input
                  style={{
                    border: "0",
                    width: 230,
                    height: 18,
                    fontFamily: "Times New Roman",
                    ...style.fontSize,
                    paddingLeft: 5,
                    paddingBottom: 0,
                    marginLeft: 5,
                  }}
                  type="text"
                  value={data?.otherTasks}
                  onChange={(e) =>
                    setData({ ...data, otherTasks: e.target.value })
                  }
                  name="otherTasks"
                />
              </td>
            </tr>
          </table>
        </Box>
      </Box>

      <Box mt={3} alignItems={"flex-end"} display={"flex"}>
        <Box fontSize={16} fontFamily={"Times New Roman"}>
          Frequency:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} width={"30%"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 15,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="frequency"
          />
        </Box>
      </Box>
      <Box alignItems={"flex-end"} display={"flex"}>
        <Box fontSize={16} fontFamily={"Times New Roman"}>
          Comments:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} width={"100%"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="frequency"
          />
        </Box>
      </Box>
      <Box>
        <Box borderBottom={"1px solid black"} width={"100%"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="frequency"
          />
        </Box>
      </Box>
      <Box fontFamily={'"Times New Roman"'} fontSize={16} textAlign={"justify"}>
        **** This is a care coordination sheet. It serves to assist in
        preventing duplication of services and ensures that individual’s needs
        are met. The sheet also serves as case conference. Please feel free to
        write in comments section those things you feel are necessary. The
        objectives are to meet individual needs and avoid service duplication.
        The top portion is/are service(s) we provide. The bottom section is for
        your Agency. Please, fill and fax back to our office. Thanks
      </Box>

      <Box mt={1} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Nurse/PAS Coordinator:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} width={"25%"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
        <Box ml={1} fontFamily={'"Times New Roman"'} fontSize={16}>
          Signature:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} width={"25%"}>
          <SignatureCanvas
            onEnd={
              (data) => console.log("")
              // setSign(
              //   signature.current.getTrimmedCanvas().toDataURL("image/png")
              // )
            }
            ref={signature}
            penColor="black"
            canvasProps={{
              width: 200,
              height: 30,
              className: "sigCanvas",
            }}
          />
        </Box>
        <Box ml={1} fontFamily={'"Times New Roman"'} fontSize={16}>
          Date:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="date_"
          />
        </Box>
      </Box>

      <Box mt={2} fontFamily={'"Times New Roman"'} fontSize={16}>
        Call the above number for any changes/question regarding individual
        /services including discharge.
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Agency Name:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>
      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Phone #:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} width={"40%"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
        <Box ml={1} fontFamily={'"Times New Roman"'} fontSize={16}>
          Fax #:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Service Providing:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Staff Hours / Schedule:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Frequency:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Comments:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>
      <Box borderBottom={"1px solid black"} flex={1}>
        <input
          style={{
            fontSize: 16,
            border: "0",
            width: "100%",
            height: 20,
            textAlign: "left",
            fontFamily: "Times New Roman",
            paddingLeft: 5,
          }}
          type="text"
          // value={data?.attendantA}
          // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
          name="pasCoord"
        />
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Contact Person:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Phone #:{" "}
        </Box>
        <Box borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>

      <Box mt={2} display={"flex"} alignItems={"flex-end"}>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Name of Agency Representative/Title:{" "}
        </Box>
        <Box mr={1} borderBottom={"1px solid black"} width={"20%"}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
        <Box fontFamily={'"Times New Roman"'} fontSize={16}>
          Date:{" "}
        </Box>
        <Box mr={1} borderBottom={"1px solid black"} flex={1}>
          <input
            style={{
              fontSize: 16,
              border: "0",
              width: "100%",
              height: 20,
              textAlign: "left",
              fontFamily: "Times New Roman",
              paddingLeft: 5,
            }}
            type="text"
            // value={data?.attendantA}
            // onChange={(e) => setData({ ...data, attendantA: e.target.value })}
            name="pasCoord"
          />
        </Box>
      </Box>

      <Box height={100} />
    </Box>
  );
};

export default CoordinationCare;

const style = {
  fontSize: {
    fontSize: 13.3,
  },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
};
