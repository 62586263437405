import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { UserContext } from "../../context/userContext";

const CaseInformation = React.forwardRef((prop, ref) => {
  const [signature_image, setSign_1] = useState("");
  const [sign_2, setSign_2] = useState("");
  const { loginDetails, caseInfoData, setCaseInfoData } =
    useContext(UserContext);

  const handleChange = (e, type) => {
    if (type) {
      caseInfoData[e.target.name] = e.target.checked;
    } else {
      caseInfoData[e.target.name] = e.target.value;
    }
    setCaseInfoData({ ...caseInfoData });
  };

  const signature_1 = useRef();
  const signature_2 = useRef();

  const clearSignature = () => {
    signature_1.current.clear();
    setSign_1("");
  };

  const clearSignature2 = () => {
    signature_2.current.clear();
    setSign_2("");
  };

  useEffect(() => {
    if (prop?.selectedCaseWorker) {
      const value = `TEXAS DEPARTMENT OF HEALTH AND HUMAN SERVICE CCAD: ${prop?.selectedCaseWorker?.firstname}, ${prop?.selectedCaseWorker?.lastname} \n${prop?.selectedCaseWorker?.address} `;
      caseInfoData["sent_to"] = value;
      caseInfoData["caseworker_mail_code"] =
        prop?.selectedCaseWorker?.mail_code;
      caseInfoData["company_phone"] = loginDetails?.user[0]?.company_phone;

      setCaseInfoData({ ...caseInfoData });
    }
  }, [prop?.selectedCaseWorker]);

  useEffect(() => {
    if (prop?.selectedEmployee) {
      const value = `${prop?.selectedEmployee?.firstname}, ${prop?.selectedEmployee?.lastname} \n${prop?.selectedEmployee?.address} `;
      caseInfoData["sent_from"] = value;
      caseInfoData["employee_mail_code"] = prop?.selectedEmployee?.mail_code;
      setCaseInfoData({ ...caseInfoData });
    }

    if (prop?.selectedIndividual) {
      const value = `${prop?.selectedIndividual?.firstname}, ${prop?.selectedIndividual?.lastname}`;
      const address = `${prop?.selectedIndividual?.address}, ${prop?.selectedIndividual?.city}, ${prop?.selectedIndividual?.state}, ${prop?.selectedIndividual?.zipCode}`;
      const area_code = prop?.selectedIndividual?.phone;

      caseInfoData["individual_name"] = value;
      caseInfoData["individual_address"] = address;
      caseInfoData["individual_areacode"] = area_code;
      caseInfoData["individual_state_id"] = prop?.selectedIndividual?.stateId;
      setCaseInfoData({ ...caseInfoData });
    }

    if (prop?.selectedAuthorization) {
      const value = `${prop?.selectedAuthorization?.service_type}`;
      caseInfoData["service_category"] = value !== "undefined" ? value : "";
      caseInfoData["authorization_start_date"] = moment(
        prop?.selectedAuthorization?.authorization_start_date
      ).format("D-MM-YYYY");
      setCaseInfoData({ ...caseInfoData });
    }

    if (prop?.formComment) {
      caseInfoData["comment_response"] = prop?.formComment;
      setCaseInfoData({ ...caseInfoData });
    }
  }, [
    prop?.selectedEmployee,
    prop?.formComment,
    prop?.selectedAuthorization,
    prop?.selectedIndividual,
    prop?.selectedCaseWorker,
  ]);

  useEffect(() => {
    if (prop?.setClear) {
      prop?.setClear(false);
    }
    if (signature_image) {
      caseInfoData["signature_image"] = signature_image;
      setCaseInfoData({ ...caseInfoData });
    }
  }, [signature_image]);

  useEffect(() => {
    if (!prop?.view) {
      let data = {
        absent_parent: false,
        change_in_address_telephone: false,
        change_in_circumstances: false,
        child_care: false,
        community_placement_resources: false,
        deductions: false,
        employment_services: false,
        epsdt: false,
        family_health_services_nurse: false,
        family_planning: false,
        household_composition: false,
        income: false,
        ltss_information_shared: false,
        medicaid: false,
        medical_disability: false,
        merp_shared: false,
        nursing_care_level_of_care: false,
        protective_services: false,
        refugee_services: false,
        resources: false,
        support_services: false,
        tanf: false,
        other: false,
      };

      setCaseInfoData({ ...caseInfoData, ...data });
    }
  }, []);

  return (
    <div ref={ref} style={{ justifyContent: "center" }}>
      {/* <img src={`${signature_image}`} /> */}
      <div
        style={{
          backgroundColor: "white",
          height: 1200,
          width: 1000,
          padding: 50,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontFamily: "arial", fontSize: 8 }}>
              Texas Health and
              <br />
              Human Services
            </div>
            <div
              style={{ fontSize: 12, fontWeight: "bold", fontFamily: "arial" }}
            >
              Case Information
            </div>
            <div
              style={{
                fontFamily: "Times New Roman",
                textAlign: "right",
                fontSize: 9,
              }}
            >
              Form 2067 <br />
              December 2012-E
            </div>
          </div>
        </div>
        <div
          style={{
            margin: 20,
            marginLeft: 0,
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <div>
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <div style={{ marginRight: 10 }}>To:</div>
              <textarea
                style={{
                  width: 400,
                  resize: "none",
                  fontFamily: "arial",
                  fontSize: 12,
                  padding: 10,
                  fontWeight: "bold",
                }}
                rows={5}
                type="text"
                value={caseInfoData?.sent_to}
                onChange={(e) => handleChange(e)}
                name="sent_to"
              />
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginLeft: 30 }}
            >
              <div>Mail Code:</div>
              <input
                style={{
                  border: "0",
                  borderBottomWidth: 1,
                  paddingLeft: 5,
                  borderBottomStyle: "solid",
                  width: 250,
                }}
                type="text"
                value={caseInfoData?.caseworker_mail_code}
                onChange={(e) => handleChange(e)}
                name="caseworker_mail_code"
              />
            </div>
          </div>
          <div>
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <div style={{ marginRight: 10 }}>From:</div>
              <textarea
                style={{
                  width: 400,
                  resize: "none",
                  fontFamily: "arial",
                  fontSize: 12,
                  padding: 10,
                  fontWeight: "bold",
                }}
                rows={5}
                type="text"
                value={caseInfoData?.sent_from}
                onChange={(e) => handleChange(e)}
                name="sent_from"
              />
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginLeft: 50 }}
            >
              <div>Mail Code:</div>
              <input
                style={{
                  border: "0",
                  borderBottomWidth: 1,
                  paddingLeft: 5,
                  borderBottomStyle: "solid",
                  width: 250,
                }}
                type="text"
                value={caseInfoData?.employee_mail_code}
                onChange={(e) => handleChange(e)}
                name="employee_mail_code"
              />
            </div>
          </div>
        </div>
        <div>
          <table
            width={"100%"}
            border={1}
            style={{ borderCollapse: "collapse" }}
          >
            <tr>
              <td style={{ paddingLeft: 10 }}>
                <div>Case Name</div>
                <textarea
                  style={{
                    resize: "none",
                    fontFamily: "arial",
                    fontSize: 12,
                    padding: 10,
                    fontWeight: "bold",
                    border: 0,
                    width: 150,
                  }}
                  rows={3}
                  type="text"
                  value={caseInfoData?.individual_name}
                  onChange={(e) => handleChange(e)}
                  name="individual_name"
                />
              </td>
              <td style={{ paddingLeft: 10 }}>
                <div>Category</div>
                <textarea
                  style={{
                    resize: "none",
                    fontFamily: "arial",
                    fontSize: 12,
                    padding: 10,
                    fontWeight: "bold",
                    border: 0,
                    width: 150,
                  }}
                  rows={3}
                  type="text"
                  value={caseInfoData?.service_category}
                  onChange={(e) => handleChange(e)}
                  name="service_category"
                />
              </td>
              <td style={{ paddingLeft: 10 }}>
                <div>Case No.</div>
                <textarea
                  style={{
                    resize: "none",
                    fontFamily: "arial",
                    fontSize: 12,
                    padding: 10,
                    fontWeight: "bold",
                    border: 0,
                    width: 150,
                  }}
                  rows={3}
                  type="text"
                  value={caseInfoData?.individual_state_id}
                  onChange={(e) => handleChange(e)}
                  name="individual_state_id"
                />
              </td>
              <td style={{ paddingLeft: 10 }}>
                <div>Category</div>
                <textarea
                  style={{
                    resize: "none",
                    fontFamily: "arial",
                    fontSize: 12,
                    padding: 10,
                    fontWeight: "bold",
                    border: 0,
                    width: 150,
                  }}
                  rows={3}
                  type="text"
                  value={caseInfoData?.category_2}
                  onChange={(e) => handleChange(e)}
                  name="category_2"
                />
              </td>
              <td style={{ paddingLeft: 10 }}>
                <div>Case No.</div>
                <textarea
                  style={{
                    resize: "none",
                    fontFamily: "arial",
                    fontSize: 12,
                    padding: 10,
                    fontWeight: "bold",
                    border: 0,
                    width: 150,
                  }}
                  rows={3}
                  type="text"
                  value={caseInfoData?.caseNumber_2}
                  onChange={(e) => handleChange(e)}
                  name="caseNumber_2"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div style={{ paddingLeft: 10 }}>
                  Address (Street, City, State, ZIP Code)
                </div>
                <input
                  style={{
                    border: "0",
                    paddingLeft: 5,
                    width: "90%",
                    fontWeight: "bold",
                  }}
                  type="text"
                  value={caseInfoData?.individual_address}
                  onChange={(e) => handleChange(e)}
                  name="individual_address"
                />
              </td>
              <td colSpan={2}>
                <div style={{ paddingLeft: 10 }}>
                  Area Code and Telephone No.
                </div>
                <input
                  style={{
                    border: "0",
                    paddingLeft: 5,
                    width: "90%",
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                  type="text"
                  value={caseInfoData?.individual_areacode}
                  onChange={(e) => handleChange(e)}
                  name="individual_areacode"
                />
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div style={{ fontWeight: "bold", marginTop: 20 }}>
            Please check all that apply
          </div>
          <table border={1} style={{ borderCollapse: "collapse" }}>
            <tr>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.absent_parent}
                  onChange={(e) => handleChange(e, "check")}
                  name="absent_parent"
                  id="absent_parent"
                  checked={
                    caseInfoData?.absent_parent == 1
                      ? true
                      : caseInfoData?.absent_parent
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="absent_parent"
                >
                  Absent Parent
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.change_in_address_telephone}
                  onChange={(e) => handleChange(e, "check")}
                  name="change_in_address_telephone"
                  id="change_in_address_telephone"
                  checked={
                    caseInfoData?.change_in_address_telephone == 1
                      ? true
                      : caseInfoData?.change_in_address_telephone
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="change_in_address_telephone"
                >
                  Change in Address/ <br />
                  Telephone
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.change_in_circumstances}
                  onChange={(e) => handleChange(e, "check")}
                  name="change_in_circumstances"
                  id="change_in_circumstances"
                  checked={
                    caseInfoData?.change_in_circumstances == 1
                      ? true
                      : caseInfoData?.change_in_circumstances
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="change_in_circumstances"
                >
                  Change in <br />
                  Circumstances
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.child_care}
                  onChange={(e) => handleChange(e, "check")}
                  name="child_care"
                  id="child_care"
                  checked={
                    caseInfoData?.child_care == 1
                      ? true
                      : caseInfoData?.child_care
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="child_care"
                >
                  Child Care
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  value={caseInfoData?.community_placement_resources}
                  type="checkbox"
                  onChange={(e) => handleChange(e, "check")}
                  name="community_placement_resources"
                  id="community_placement_resources"
                  checked={
                    caseInfoData?.community_placement_resources == 1
                      ? true
                      : caseInfoData?.community_placement_resources
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="community_placement_resources"
                >
                  Community Placement Resources
                </label>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.deductions}
                  onChange={(e) => handleChange(e, "check")}
                  name="deductions"
                  id="deductions"
                  checked={
                    caseInfoData?.deductions == 1
                      ? true
                      : caseInfoData?.deductions
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="deductions"
                >
                  Deductions
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.employment_services}
                  onChange={(e) => handleChange(e, "check")}
                  name="employment_services"
                  id="employment_services"
                  checked={
                    caseInfoData?.employment_services == 1
                      ? true
                      : caseInfoData?.employment_services
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="employment_services"
                >
                  Employment Services
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.epsdt}
                  onChange={(e) => handleChange(e, "check")}
                  name="epsdt"
                  id="epsdt"
                  checked={
                    caseInfoData?.epsdt == 1 ? true : caseInfoData?.epsdt
                  }
                />
                <label style={{ fontWeight: "bold", fontSize: 12 }} for="epsdt">
                  EPSDT
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.family_health_services_nurse}
                  onChange={(e) => handleChange(e, "check")}
                  name="family_health_services_nurse"
                  id="family_health_services_nurse"
                  checked={
                    caseInfoData?.family_health_services_nurse == 1
                      ? true
                      : caseInfoData?.family_health_services_nurse
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="family_health_services_nurse"
                >
                  Family Health Services Nurse
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.family_planning}
                  onChange={(e) => handleChange(e, "check")}
                  name="family_planning"
                  id="family_planning"
                  checked={
                    caseInfoData?.family_planning == 1
                      ? true
                      : caseInfoData?.family_planning
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="family_planning"
                >
                  Family Planning
                </label>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.household_composition}
                  onChange={(e) => handleChange(e, "check")}
                  name="household_composition"
                  id="household_composition"
                  checked={
                    caseInfoData?.household_composition == 1
                      ? true
                      : caseInfoData?.household_composition
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="household_composition"
                >
                  Household Composition
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.income}
                  onChange={(e) => handleChange(e, "check")}
                  name="income"
                  id="income"
                  checked={
                    caseInfoData?.income == 1 ? true : caseInfoData?.income
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="income"
                >
                  Income
                </label>
              </td>

              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.ltss_information_shared}
                  onChange={(e) => handleChange(e, "check")}
                  name="ltss_information_shared"
                  id="ltss_information_shared"
                  checked={
                    caseInfoData?.ltss_information_shared == 1
                      ? true
                      : caseInfoData?.ltss_information_shared
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="ltss_information_shared"
                >
                  LTSS Information Shared
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.medicaid}
                  onChange={(e) => handleChange(e, "check")}
                  name="medicaid"
                  id="medicaid"
                  checked={
                    caseInfoData?.medicaid == 1 ? true : caseInfoData?.medicaid
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="medicaid"
                >
                  Medicaid
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.medical_disability}
                  onChange={(e) => handleChange(e, "check")}
                  name="medical_disability"
                  id="medical_disability"
                  checked={
                    caseInfoData?.medical_disability == 1
                      ? true
                      : caseInfoData?.medical_disability
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="medical_disability"
                >
                  Medical/Disability
                </label>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.merp_shared}
                  onChange={(e) => handleChange(e, "check")}
                  name="merp_shared"
                  id="merp_shared"
                  checked={
                    caseInfoData?.merp_shared == 1
                      ? true
                      : caseInfoData?.merp_shared
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="merp_shared"
                >
                  MERP Shared
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.nursing_care_level_of_care}
                  onChange={(e) => handleChange(e, "check")}
                  name="nursing_care_level_of_care"
                  id="nursing_care_level_of_care"
                  checked={
                    caseInfoData?.nursing_care_level_of_care == 1
                      ? true
                      : caseInfoData?.nursing_care_level_of_care
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="nursing_care_level_of_care"
                >
                  Nursing Care/Level of Care
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.protective_services}
                  onChange={(e) => handleChange(e, "check")}
                  name="protective_services"
                  id="protective_services"
                  checked={
                    caseInfoData?.protective_services == 1
                      ? true
                      : caseInfoData?.protective_services
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="protective_services"
                >
                  Protective Services
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.refugee_services}
                  onChange={(e) => handleChange(e, "check")}
                  name="refugee_services"
                  id="refugee_services"
                  checked={
                    caseInfoData?.refugee_services == 1
                      ? true
                      : caseInfoData?.refugee_services
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="refugee_services"
                >
                  Refugee Services
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.resources}
                  onChange={(e) => handleChange(e, "check")}
                  name="resources"
                  id="resources"
                  checked={
                    caseInfoData?.resources == 1
                      ? true
                      : caseInfoData?.resources
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="resources"
                >
                  Resources
                </label>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.support_services}
                  onChange={(e) => handleChange(e, "check")}
                  name="support_services"
                  id="support_services"
                  checked={
                    caseInfoData?.support_services == 1
                      ? true
                      : caseInfoData?.support_services
                  }
                />
                <label
                  style={{ fontWeight: "bold", fontSize: 12 }}
                  for="support_services"
                >
                  Support Services
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.tanf}
                  onChange={(e) => handleChange(e, "check")}
                  name="tanf"
                  id="tanf"
                  checked={caseInfoData?.tanf == 1 ? true : caseInfoData?.tanf}
                />
                <label style={{ fontWeight: "bold", fontSize: 12 }} for="tanf">
                  TANF
                </label>
              </td>
              <td style={{ padding: 5, width: "21%" }}>
                <input
                  type="checkbox"
                  value={caseInfoData?.other}
                  onChange={(e) => handleChange(e, "check")}
                  name="other"
                  id="other"
                  checked={
                    caseInfoData?.other == 1 ? true : caseInfoData?.other
                  }
                />
                <label style={{ fontWeight: "bold", fontSize: 12 }} for="other">
                  Other
                </label>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div style={{ border: "1px solid black", marginTop: 20 }}>
            <label style={{ fontWeight: "bold", paddingLeft: 10 }}>
              Comments/Response
            </label>
            <textarea
              style={{
                width: "90%",
                resize: "none",
                fontFamily: "arial",
                fontSize: 16,
                padding: 10,
                // fontWeight: "bold",
                border: 0,
              }}
              rows={5}
              type="text"
              value={caseInfoData?.comment_response}
              onChange={(e) => handleChange(e)}
              name="comment_response"
            />
          </div>
        </div>
        <div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginTop: 10 }}>
              {prop?.view && caseInfoData?.signature_image && (
                <img
                  src={
                    caseInfoData?.signature_image?.includes(
                      "data:image/png;base64,"
                    )
                      ? caseInfoData?.signature_image
                      : "data:image/png;base64," + caseInfoData?.signature_image
                  }
                  height={50}
                  style={{ resizeMode: "cover" }}
                  // width={100}
                />
              )}

              {!prop?.view && (
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <SignatureCanvas
                    onEnd={(data) =>
                      setSign_1(
                        signature_1.current
                          .getTrimmedCanvas()
                          .toDataURL("image/png")
                      )
                    }
                    ref={signature_1}
                    penColor="black"
                    canvasProps={{
                      width: 400,
                      height: 50,
                      className: "sigCanvas",
                    }}
                  />
                  {signature_image && !prop?.clear && (
                    <button
                      style={{
                        backgroundColor: "white",
                        border: "0",
                        fontSize: 12,
                      }}
                      onClick={() => clearSignature()}
                    >
                      Clear
                    </button>
                  )}
                </div>
              )}

              <div
                style={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  // width: "45%",
                  marginTop: caseInfoData?.signature_image ? 0 : 50,
                  textAlign: "center",
                }}
              >
                Signature{" "}
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <input
                style={{
                  border: "0",
                  // width: "95%",
                  fontWeight: "bold",
                  height: 30,
                  textAlign: "center",
                }}
                type="text"
                value={caseInfoData?.authorization_start_date}
                onChange={(e) => handleChange(e)}
                name="authorization_start_date"
              />
              <div
                style={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  textAlign: "center",
                }}
              >
                Date{" "}
              </div>
            </div>

            <div
              style={{
                border: "1px solid black",
                marginRight: 5,
                marginTop: 10,
                paddingBottom: 0,
                height: 50,
              }}
            >
              <div style={{ paddingLeft: 10 }}>Area Code and Telephone No.</div>
              <input
                style={{
                  border: "0",
                  width: "95%",
                  fontWeight: "bold",
                  marginBottom: 0,
                  paddingBottom: 0,
                  textAlign: "center",
                }}
                type="text"
                value={caseInfoData?.company_phone}
                onChange={(e) => handleChange(e)}
                name="company_phone"
              />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              margin: 20,
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div>
              <textarea
                style={{
                  width: 400,
                  resize: "none",
                  fontFamily: "arial",
                  fontSize: 12,
                  padding: 10,
                  fontWeight: "bold",
                }}
                rows={5}
                type="text"
                value={caseInfoData?.textarea_11}
                onChange={(e) => handleChange(e)}
                name="textarea_11"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 20,
                }}
              >
                <div>Mail Code:</div>
                <input
                  style={{
                    border: "0",
                    borderBottomWidth: 1,
                    paddingLeft: 5,
                    borderBottomStyle: "solid",
                    width: 250,
                  }}
                  type="text"
                  value={caseInfoData?.mailCode_11}
                  onChange={(e) => handleChange(e)}
                  name="mailCode_11"
                />
              </div>
            </div>
            <div>
              <textarea
                style={{
                  width: 400,
                  resize: "none",
                  fontFamily: "arial",
                  fontSize: 12,
                  padding: 10,
                  fontWeight: "bold",
                }}
                rows={5}
                type="text"
                value={caseInfoData?.textarea_22}
                onChange={(e) => handleChange(e)}
                name="textarea_22"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 20,
                }}
              >
                <div>Mail Code:</div>
                <input
                  style={{
                    border: "0",
                    borderBottomWidth: 1,
                    paddingLeft: 5,
                    borderBottomStyle: "solid",
                    width: 250,
                  }}
                  type="text"
                  value={caseInfoData?.mailCode_22}
                  onChange={(e) => handleChange(e)}
                  name="mailCode_22"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div style={{ border: "1px solid black", marginTop: 20 }}>
              <label style={{ fontWeight: "bold", paddingLeft: 10 }}>
                Comments/Response
              </label>
              <textarea
                style={{
                  width: "90%",
                  resize: "none",
                  fontFamily: "arial",
                  fontSize: 16,
                  padding: 10,
                  // fontWeight: "bold",
                  border: 0,
                }}
                rows={5}
                type="text"
                value={caseInfoData?.commentResponse2}
                onChange={(e) => handleChange(e)}
                name="commentResponse2"
              />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginTop: 10 }}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <SignatureCanvas
                  onEnd={(data) =>
                    setSign_2(
                      signature_2.current
                        .getTrimmedCanvas()
                        .toDataURL("image/png")
                    )
                  }
                  ref={signature_2}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 50,
                    className: "sigCanvas",
                  }}
                />
                {sign_2 && (
                  <button
                    style={{ backgroundColor: "white", border: "0" }}
                    onClick={() => clearSignature2()}
                  >
                    Clear
                  </button>
                )}
              </div>

              <div
                style={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  // width: "45%",
                  // marginTop: 70,
                  textAlign: "center",
                }}
              >
                Signature{" "}
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <input
                style={{
                  border: "0",
                  // width: "95%",
                  fontWeight: "bold",
                  height: 30,
                }}
                type="text"
                value={caseInfoData?.date2}
                onChange={(e) => handleChange(e)}
                name="date2"
              />
              <div
                style={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: "black",
                  textAlign: "center",
                }}
              >
                Date{" "}
              </div>
            </div>

            <div
              style={{
                border: "1px solid black",
                marginRight: 5,
                marginTop: 10,
                paddingBottom: 0,
                height: 50,
              }}
            >
              <div style={{ paddingLeft: 10 }}>Area Code and Telephone No.</div>
              <input
                style={{
                  border: "0",
                  width: "95%",
                  fontWeight: "bold",
                  marginBottom: 0,
                  paddingBottom: 0,
                  textAlign: "center",
                }}
                type="text"
                value={caseInfoData?.areacode3}
                onChange={(e) => handleChange(e)}
                name="areacode3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CaseInformation;
