import { Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

const AttendantCalendar = ({
  data,
  setData,
  tasks,
  setTasks,
  setSchedules,
  schedules,
  isHideFooter,
  formName,
}) => {
  const [selectedSchedulePrint, setSelectedSchedulePrint] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("");

  const timeDiff = (timeIn, timeOut) => {
    if (!timeIn) return "-";
    const diff = moment.duration(
      moment(timeIn, "HH:mm:ss a").diff(moment(timeOut, "HH:mm:ss a"))
    );

    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    const result = `${hours} : ${minutes} : ${seconds}`;

    return moment(result, "HH:mm").format("HH:mm");
  };

  useEffect(() => {
    if (schedules?.length > 0) {
      selectPrint(0);
    } else {
      setSelectedSchedulePrint({});
    }
  }, [data?.authorzation_id, schedules]);

  //Task and Schedule
  const selectPrint = (index) => {
    let result = {};
    let result_ = {};
    setSelectedIndex(index);

    for (
      let i = 0;
      i < (tasks.length >= schedules.length ? tasks.length : schedules.length);
      i++
    ) {
      if (index === i) {
        // result = tasks[i];
        result_ = schedules[i];
      }
    }

    // setSelectedPrint(result);
    setSelectedSchedulePrint(result_);
  };

  return (
    <Box>
      <Box
        fontFamily={"Times New Roman"}
        fontWeight={"bold"}
        fontSize={24}
        textAlign={"center"}
      >
        Amazing Healthcare Agency
      </Box>
      <Box
        fontFamily={"Times New Roman"}
        mt={1}
        fontSize={20}
        textAlign={"center"}
        mb={1}
      >
        Individual/Attendant Calendar
      </Box>
      <Box style={styles.splitRow} mb={1}>
        <Box style={styles.row}>
          <Box style={styles.fontFamily} fontWeight={"bold"}>
            Individual:
          </Box>
          <Box fontFamily={"Times New Roman"}> All</Box>
        </Box>
        <Box style={styles.row} width={"30%"}>
          <Box fontWeight={"bold"} fontFamily={"Times New Roman"}>
            Individual Status:
          </Box>
          <Box ml={2} fontFamily={"Times New Roman"}>
            Active
          </Box>
        </Box>
      </Box>
      <Box style={styles.splitRow} mb={1}>
        <Box style={styles.row}>
          <Box style={styles.fontFamily} fontWeight={"bold"}>
            Attendant:
          </Box>
          <Box fontFamily={"Times New Roman"}>All</Box>
        </Box>
        <Box style={styles.row} width={"30%"}>
          <Box fontWeight={"bold"} fontFamily={"Times New Roman"}>
            Type:
          </Box>
          <Box ml={2} fontFamily={"Times New Roman"}>
            All
          </Box>
        </Box>
      </Box>
      <Box style={styles.splitRow} mb={1}>
        <Box style={styles.row}>
          <Box style={styles.fontFamily} fontWeight={"bold"}>
            Priority:
          </Box>
          <Box fontFamily={"Times New Roman"}>All</Box>
        </Box>
      </Box>
      <Box mt={2} flexDirection={"row"} display={"flex"}>
        <Box fontWeight={"bold"}>Individual: </Box>
        <Box ml={1}>{data?.individual?.firstname}</Box>
        <Box ml={0.5}>{data?.individual?.lastname}</Box>
      </Box>
      <Box mt={2} flexDirection={"row"} display={"flex"}>
        <Box fontWeight={"bold"}>Attendant: </Box>
        <Box ml={1}>{data?.employee?.firstname}</Box>{" "}
        <Box ml={0.5}> {data?.employee?.lastname}</Box>
      </Box>

      <Box mt={2}>
        <Box
          mt={1}
          fontWeight={"bold"}
          fontSize={20}
          style={{ ...styles.fontFamily }}
        >
          Attendant Service Schedule:
        </Box>
        <Box>
          <table
            style={{ borderCollapse: "collapse", width: "100%" }}
            border={1}
          >
            <tr>
              <td>&nbsp;&nbsp;</td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  padding: 2,
                  fontWeight: "bold",
                  paddingLeft: 10,
                }}
              >
                Sunday
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  padding: 2,
                  fontWeight: "bold",
                  paddingLeft: 10,
                }}
              >
                Monday
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  padding: 2,
                  fontWeight: "bold",
                  paddingLeft: 10,
                }}
              >
                Tuesday
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  padding: 2,
                  fontWeight: "bold",
                  paddingLeft: 10,
                }}
              >
                Wednesday
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  padding: 2,
                  fontWeight: "bold",
                  paddingLeft: 10,
                }}
              >
                Thursday
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  padding: 2,
                  fontWeight: "bold",
                  paddingLeft: 10,
                }}
              >
                Friday
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  padding: 2,
                  fontWeight: "bold",
                  paddingLeft: 10,
                }}
              >
                Saturday
              </td>
            </tr>
            <tr>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  fontWeight: "bold",
                  padding: 2,
                }}
              >
                In
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.sunday?.split("-")[0]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.monday?.split("-")[0]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.thursday?.split("-")[0]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.friday?.split("-")[0]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.saturday?.split("-")[0]?.trim()}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  fontWeight: "bold",
                  padding: 2,
                }}
              >
                Out
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.sunday?.split("-")[1]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.monday?.split("-")[1]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.tuesday?.split("-")[1]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.wednesday?.split("-")[1]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.thursday?.split("-")[1]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.friday?.split("-")[1]?.trim()}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                }}
              >
                {selectedSchedulePrint?.saturday?.split("-")[1]?.trim()}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  fontWeight: "bold",
                  padding: 2,
                }}
              >
                Total Hours
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {timeDiff(
                  selectedSchedulePrint?.sunday?.split("-")[1]?.trim(),
                  selectedSchedulePrint?.sunday?.split("-")[0]?.trim()
                )}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {timeDiff(
                  selectedSchedulePrint?.monday?.split("-")[1]?.trim(),
                  selectedSchedulePrint?.monday?.split("-")[0]?.trim()
                )}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {timeDiff(
                  selectedSchedulePrint?.tuesday?.split("-")[1]?.trim(),
                  selectedSchedulePrint?.tuesday?.split("-")[0]?.trim()
                )}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {timeDiff(
                  selectedSchedulePrint?.wednesday?.split("-")[1]?.trim(),
                  selectedSchedulePrint?.wednesday?.split("-")[0]?.trim()
                )}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {timeDiff(
                  selectedSchedulePrint?.thursday?.split("-")[1]?.trim(),
                  selectedSchedulePrint?.thursday?.split("-")[0]?.trim()
                )}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {timeDiff(
                  selectedSchedulePrint?.friday?.split("-")[1]?.trim(),
                  selectedSchedulePrint?.friday?.split("-")[0]?.trim()
                )}
              </td>
              <td
                style={{
                  ...styles.fontFamily,
                  ...styles.fontSize,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {timeDiff(
                  selectedSchedulePrint?.saturday?.split("-")[1]?.trim(),
                  selectedSchedulePrint?.saturday?.split("-")[0]?.trim()
                )}
              </td>
            </tr>
          </table>
        </Box>
      </Box>
      <Box mt={2}>
        <label style={{ fontWeight: "bold" }}>Schedule Start:</label>{" "}
        {moment(data?.authorization_start_date).format("MM/DD/YY")}
      </Box>
    </Box>
  );
};

export default AttendantCalendar;
const styles = {
  fontSize: {
    fontSize: 13.3,
  },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  splitRow: { justifyContent: "space-between", display: "flex" },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
};
