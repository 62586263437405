import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";

import colours from "../../constants/colours";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import ContractForm from "../ContractForm";
import ServiceCodeForm from "../ServiceCodeForm";
import ProgramTypeForm from "../ProgramTypeForm";
import GroupForm from "../GroupForm";
import ReceiverForm from "../ReceiverForm";
import PayerForm from "../PayerForm";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import {
  createIndividualList,
  getIndividualList,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = ["Individual Info", "Emergency Contact", "Vesta"];

const FormDialog = ({ open, handleOpenForm, setIndividualList }) => {
  const [serviceType, setServiceType] = React.useState("");
  const [data, setData] = useState({
    address: "",
    arNumber: "",
    billCode: "",
    city: "",
    country: "",
    dob: "",
    evvPriority: "",
    firstname: "",
    gender: "",
    individualEVVId: "",
    insuranceNumber: "",
    landPhone: "",
    lastname: "",
    maritalStatus: "",
    memberId: "",
    name_1: "",
    name_2: "",
    otherPhone: "",
    phone: "",
    phone_1: "",
    phone_2: "",
    procCode: "",
    relationship_1: "",
    relationship_2: "",
    state: "",
    stateId: "",
    stear: "",
    supervisor: "",
    vestaId: "",
  });
  const [openContract, setOpenContract] = useState(false);
  const [serviceCodeOpen, setServiceCodeOpen] = useState(false);
  const [programTypeOpen, setProgramTypeOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [receiverOpen, setReceiverOpen] = useState(false);
  const [payerOpen, setPayerOpen] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { loginDetails } = useContext(UserContext);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const handlePayerOpen = () => {
    setPayerOpen(!payerOpen);
  };

  const handleGroupOpen = () => {
    setGroupOpen(!groupOpen);
  };

  const handleReceiverOpen = () => {
    setReceiverOpen(!receiverOpen);
  };

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  const handleSubmitData = () => {
    setIsSuccessful(false);
    if (!data?.firstname || !data?.name_1) return;
    data.dob = moment(data?.dob).format("D-MM-YYYY");
    createIndividualList(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setIsSuccessful,
      setIsloading
    );
  };

  useEffect(() => {
    if (isSuccessful) {
      setData({
        address: "",
        arNumber: "",
        billCode: "",
        city: "",
        country: "",
        dob: "",
        evvPriority: "",
        firstname: "",
        gender: "",
        individualEVVId: "",
        insuranceNumber: "",
        landPhone: "",
        lastname: "",
        maritalStatus: "",
        memberId: "",
        name_1: "",
        name_2: "",
        otherPhone: "",
        phone: "",
        phone_1: "",
        phone_2: "",
        procCode: "",
        relationship_1: "",
        relationship_2: "",
        state: "",
        stateId: "",
        stear: "",
        supervisor: "",
        vestaId: "",
      });
    }
  }, [isSuccessful]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (isSuccessful)
      getIndividualList(
        loginDetails?.user[0]?.user_id,
        loginDetails.token,
        setIndividualList
      );
  }, [isSuccessful]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Individual
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleSubmitData()}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        {isSuccessful && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="success">
              New Individual List Created Successfully.
            </Alert>
          </Box>
        )}
        <Box
          // padding={4}
          pt={4}
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <Box
            padding={4}
            flexDirection={"row"}
            justifyContent={"space-around"}
            display={"flex"}
          >
            <Box width={"20%"}>
              <Box mb={1}>First Name</Box>
              <TextField
                style={{ width: "100%" }}
                id="firstname"
                label="First Name"
                variant="outlined"
                onChange={handleChange}
                name="firstname"
                size="small"
                value={data?.firstname}
              />
              <Box mt={3} mb={1}>
                Last Name
              </Box>
              <TextField
                style={{ width: "100%" }}
                id="lastname"
                label="Last Name"
                variant="outlined"
                value={data?.lastname}
                onChange={handleChange}
                name="lastname"
                size="small"
              />
              <Box mt={3} mb={1}>
                State ID (Medicaid)
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="stateId"
                label="State ID (Medicaid)"
                variant="outlined"
                value={data?.stateId}
                onChange={handleChange}
                name="stateId"
              />
              <Box mt={3} mb={1}>
                Address
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="address"
                label="Address"
                variant="outlined"
                value={data?.address}
                onChange={handleChange}
                name="address"
              />
              <Box mt={3} mb={1}>
                City
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="city"
                label="City"
                variant="outlined"
                value={data?.city}
                onChange={handleChange}
                name="city"
              />
            </Box>

            <Box width={"20%"}>
              <Box display={"flex"}>
                <Box>
                  <Box mb={1}>State</Box>
                  <TextField
                    size="small"
                    style={{ width: "100%" }}
                    id="state"
                    label="State"
                    variant="outlined"
                    value={data?.state}
                    onChange={handleChange}
                    name="state"
                  />
                </Box>
                <Box ml={1}>
                  <Box mb={1}>Zip Code</Box>
                  <TextField
                    size="small"
                    style={{ width: "100%" }}
                    id="zip_code"
                    label="Zip code"
                    variant="outlined"
                    value={data?.zip_code}
                    onChange={handleChange}
                    name="zip_code"
                  />
                </Box>
              </Box>

              <Box mt={3} mb={1}>
                Country
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="country"
                label="country"
                variant="outlined"
                value={data?.country}
                onChange={handleChange}
                name="country"
              />

              <Box mt={3} mb={1}>
                Phone
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="phone"
                label="Phone"
                variant="outlined"
                value={data?.phone}
                onChange={handleChange}
                name="phone"
              />
              <Box mt={3} mb={1}>
                Other Phone
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="otherPhone"
                label="Other Phone"
                variant="outlined"
                value={data?.otherPhone}
                onChange={handleChange}
                name="otherPhone"
              />
              <Box mt={3}>Date of Birth...</Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer size="small" components={["DatePicker"]}>
                    <DatePicker
                      value={data?.dob}
                      onChange={(newValue) =>
                        setData({
                          ...data,
                          dob: newValue,
                        })
                      }
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      label="Date of Birth"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            <Box width={"20%"}>
              <Box mb={1}>Gender</Box>
              <FormControl fullWidth size="small">
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  value={data?.gender}
                  label="Gender"
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                </Select>
              </FormControl>
              <Box mt={3} mb={1}>
                Marital Status
              </Box>
              <FormControl fullWidth size="small">
                <InputLabel id="maritalStatus">Marital Status</InputLabel>
                <Select
                  labelId="maritalStatus"
                  id="maritalStatus"
                  value={data?.maritalStatus}
                  label="Marital Status"
                  onChange={handleChange}
                  name="maritalStatus"
                >
                  <MenuItem value={"single"}>Single</MenuItem>
                  <MenuItem value={"married"}>Married</MenuItem>
                  <MenuItem value={"divorced"}>Divorced</MenuItem>
                  <MenuItem value={"widowed"}>Widowed</MenuItem>
                  <MenuItem value={"UNKNOWN"}>UNKNOWN</MenuItem>
                </Select>
              </FormControl>
              <Box mt={3} mb={1}>
                S.T.E.A.R / 211
              </Box>
              <FormControl fullWidth size="small">
                <InputLabel id="gender">S.T.E.A.R / 211</InputLabel>
                <Select
                  labelId="stear"
                  id="stear"
                  value={data?.gestearnder}
                  label="S.T.E.A.R"
                  onChange={handleChange}
                  name="stear"
                >
                  <MenuItem value={"declined"}>Declined</MenuItem>
                  <MenuItem value={"self-registered"}>Self-Registered</MenuItem>
                  <MenuItem value={"requested agency to register"}>
                    Requested Agency to Register
                  </MenuItem>
                </Select>
              </FormControl>
              <Box mt={3} mb={1}>
                Supervisor
              </Box>
              <Box>
                <FormControl fullWidth size="small">
                  <InputLabel id="Supervisor">Supervisor</InputLabel>
                  <Select
                    labelId="Supervisor"
                    id="supervisor"
                    value={data?.supervisor}
                    label="Supervisor"
                    onChange={handleChange}
                    name="supervisor"
                  >
                    <MenuItem value={"John Doe"}>John Doe</MenuItem>
                    <MenuItem value={"Jane Doe"}>Jane Doe</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={3} mb={1}>
                Insurance number
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="insuranceNumber"
                label="Insurance Number"
                variant="outlined"
                value={data?.insuranceNumber}
                onChange={handleChange}
                name="insuranceNumber"
              />
            </Box>
          </Box>
        )}

        {activeStep === 1 && (
          <Box
            padding={4}
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            display={"flex"}
          >
            <Box width={"20%"}>
              <Box>Contact Person - 1</Box>

              <Box mt={3} mb={1}>
                Name
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="name_1"
                label="Name"
                variant="outlined"
                onChange={handleChange}
                name="name_1"
                value={data?.name_1}
              />
              <Box mt={3} mb={1}>
                Phone
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="phone_1"
                label="Phone"
                variant="outlined"
                value={data?.phone_1}
                onChange={handleChange}
                name="phone_1"
              />
              <Box mt={3} mb={1}>
                Relationship
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="relationship_1"
                label="Relationship"
                variant="outlined"
                value={data?.relationship_1}
                onChange={handleChange}
                name="relationship_1"
              />
            </Box>

            <Box width={"20%"}>
              <Box>Contact Person - 2</Box>
              <Box mt={3} mb={1}>
                Name
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="name_2"
                label="Name"
                variant="outlined"
                onChange={handleChange}
                name="name_2"
                value={data?.name_2}
              />
              <Box mt={3} mb={1}>
                Phone
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="phone_2"
                label="Phone"
                variant="outlined"
                value={data?.phone_2}
                onChange={handleChange}
                name="phone_2"
              />
              <Box mt={3} mb={1}>
                Relationship
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="relationship_2"
                label="Relationship"
                variant="outlined"
                value={data?.relationship_2}
                onChange={handleChange}
                name="relationship_2"
              />
            </Box>
          </Box>
        )}

        {activeStep === 2 && (
          <Box
            padding={4}
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            display={"flex"}
          >
            <Box width={"20%"}>
              <Box mb={1}>AR Number</Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="arNumber"
                label="AR Number"
                variant="outlined"
                onChange={handleChange}
                name="arNumber"
                value={data?.arNumber}
              />
              <Box mt={3} mb={1}>
                EVV Priority
              </Box>
              <Box>
                <FormControl fullWidth size="small">
                  <InputLabel id="EVV Priority">EVV Priority</InputLabel>
                  <Select
                    labelId="EVV Priority"
                    id="evvPriority"
                    value={data?.evvPriority}
                    label="EVV Priority"
                    onChange={handleChange}
                    name="evvPriority"
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={3} mb={1}>
                Bill Code
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="billCode"
                label="Bill Code"
                variant="outlined"
                value={data?.billCode}
                onChange={handleChange}
                name="billCode"
              />
              <Box mt={3} mb={1}>
                Proc Code Qualifier
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="procCode"
                label="Proc Code Qualifier"
                variant="outlined"
                value={data?.procCode}
                onChange={handleChange}
                name="procCode"
              />
            </Box>

            <Box width={"20%"}>
              <Box mb={1}>Land Phone</Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="landPhone"
                label="Land Phone"
                variant="outlined"
                onChange={handleChange}
                name="landPhone"
                value={data?.landPhone}
              />
              <Box mt={3} mb={1}>
                Vesta Client ID
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="vestaId"
                label="Vesta Client ID"
                variant="outlined"
                value={data?.vestaId}
                onChange={handleChange}
                name="vestaId"
              />
              <Box mt={3} mb={1}>
                Member Unique ID
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="memberId"
                label="Member Unique ID"
                variant="outlined"
                value={data?.memberId}
                onChange={handleChange}
                name="memberId"
              />
              <Box mt={3} mb={1}>
                Individual EVV ID
              </Box>
              <TextField
                size="small"
                style={{ width: "100%" }}
                id="individualEVVId"
                label="Individual EVV ID"
                variant="outlined"
                value={data?.individualEVVId}
                onChange={handleChange}
                name="individualEVVId"
              />
            </Box>
          </Box>
        )}

        <Box mr={"20%"} ml={"20%"} mb={10}>
          {activeStep !== steps.length && (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography> */}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  startIcon={<ArrowBackIosNewOutlined />}
                  color="primary"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />

                <Button
                  variant="contained"
                  style={{ display: activeStep === 2 ? "flex" : "none" }}
                  onClick={() => handleSubmitData()}
                >
                  {isloading ? "Please wait..." : "Submit Now"}
                </Button>

                <Button
                  style={{ display: activeStep === 2 ? "none" : "flex" }}
                  variant="contained"
                  color="primary"
                  onClick={
                    activeStep === steps.length - 1
                      ? console.log("")
                      : handleNext
                  }
                  endIcon={
                    activeStep === steps.length - 1 ? (
                      ""
                    ) : (
                      <ArrowForwardIosOutlined />
                    )
                  }
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default FormDialog;

const styles = {
  addIcon: {
    color: colours.primary,
    cursor: "pointer",
    position: "absolute",
    marginTop: 15,
    marginLeft: 3,
  },
};
