import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, TextField } from "@mui/material";

import colours from "../../constants/colours";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ProgramTypeForm = ({ open, handleOpenForm }) => {
  const [data, setData] = useState({});

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Program Type
            </Typography>
            <Button autoFocus color="inherit" onClick={handleOpenForm}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          padding={4}
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"20%"}>
            <Box mb={1}>Program name</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="programName"
              label="Program name"
              variant="outlined"
              onChange={handleChange}
              name="programName"
              value={data?.programName}
            />

            <Box mt={3} mb={1}>
              Program Description
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="programDescription"
              label="Program Description"
              variant="outlined"
              value={data?.programDescription}
              onChange={handleChange}
              name="programDescription"
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default ProgramTypeForm;

const styles = {
  addIcon: {
    color: colours.primary,
    cursor: "pointer",
    position: "absolute",
    marginTop: 15,
    marginLeft: 3,
  },
};
