import React from "react";
import { Box } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";

const RelationshipEvidence = ({
  data,
  setData,
  setSign_1,
  setSign_2,
  setSign_3,
  signature_1,
  signature_2,
  signature_3,
  companyData,
}) => {
  return (
    <Box>
      <Box
        mt={8}
        textAlign={"center"}
        fontWeight={"bold"}
        fontFamily={"Times New Roman"}
      >
        EVIDENCE OF RELATIONSHIP REQUIREMENT
      </Box>
      <Box fontFamily={"Times New Roman"} mt={2} fontSize={16}>
        RULE 47.23
      </Box>
      <Box fontFamily={"Times New Roman"} fontSize={16} mt={2}>
        In addition to the requirements described in §97.404 of this title
        (relating to Standards Specific to Agencies Licensed to Provide Personal
        Assistance Services), an attendant must:
      </Box>
      <Box fontFamily={"Times New Roman"} fontSize={16} mt={5}>
        I am (please initial)
      </Box>
      <Box fontFamily={"Times New Roman"} fontSize={16} mt={3}>
        (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) not a legal parent,
        foster parent, or spouse of a parent of a minor who receives the service
      </Box>
      <Box fontFamily={"Times New Roman"} fontSize={16} mt={4}>
        (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) not the spouse
        (including: common law) of the individual who receives the service,
        except for FC services
      </Box>
      <Box fontFamily={"Times New Roman"} fontSize={16} mt={4}>
        (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) not designated by a
        DADS case manager on DADS' authorization for community care services
        form as "Do not hire."
      </Box>
      <Box fontFamily={"Times New Roman"} fontSize={16} mt={8}>
        By signing below I certify that these statements are true.
      </Box>

      <Box flexDirection={"row"} display={"flex"} mt={10}>
        <Box mr={2} mt={data?.employee_name ? -2 : 0}>
          <Box
            fontFamily={"Times New Roman"}
            borderBottom={1}
            width={200}
            textAlign={"center"}
            fontSize={15}
          >
            {data?.employee_name}
          </Box>
          <Box
            fontSize={16}
            fontFamily={"Times New Roman"}
            textAlign={"center"}
          >
            {" "}
            Attendant Name
          </Box>
        </Box>

        <Box mt={-8}>
          <SignatureCanvas
            onEnd={(data) =>
              setSign_1(
                signature_1.current.getTrimmedCanvas().toDataURL("image/png")
              )
            }
            ref={signature_1}
            penColor="black"
            canvasProps={{
              width: 200,
              height: 60,
              className: "sigCanvas",
            }}
          />
          <Box
            width={200}
            borderTop={1}
            borderColor={"FAFAFA"}
            fontFamily={"Times New Roman"}
            mr={2}
            textAlign={"center"}
          >
            {" "}
            Signature{" "}
          </Box>
        </Box>

        <Box mt={-4}>
          <input
            style={{
              border: "0",
              width: 200,
              height: 30,
              textAlign: "center",
              fontFamily: "Times New Roman",
            }}
            type="text"
            value={data?.date}
            onChange={(e) => setData({ ...data, date: e.target.value })}
            name="date"
          />
          <Box
            width={200}
            borderTop={1}
            borderColor={"FAFAFA"}
            fontFamily={"Times New Roman"}
            textAlign={"center"}
          >
            Date
          </Box>
        </Box>
      </Box>

      <Box flexDirection={"row"} display={"flex"} mt={10}>
        <Box mr={2} mt={data?.employee_name && companyData[0]?.name ? -2 : 0}>
          <Box
            fontFamily={"Times New Roman"}
            borderBottom={1}
            width={200}
            textAlign={"center"}
            fontSize={15}
          >
            {data?.employee_name && companyData[0]?.name}
          </Box>
          <Box
            fontSize={16}
            fontFamily={"Times New Roman"}
            textAlign={"center"}
          >
            {" "}
            Agency Representative Name
          </Box>
        </Box>

        <Box mt={-8}>
          <SignatureCanvas
            onEnd={(data) =>
              setSign_2(
                signature_2.current.getTrimmedCanvas().toDataURL("image/png")
              )
            }
            ref={signature_2}
            penColor="black"
            canvasProps={{
              width: 200,
              height: 60,
              className: "sigCanvas",
            }}
          />
          <Box
            width={200}
            borderTop={1}
            borderColor={"FAFAFA"}
            fontFamily={"Times New Roman"}
            mr={2}
            textAlign={"center"}
          >
            {" "}
            Signature{" "}
          </Box>
        </Box>

        <Box mt={-4}>
          <input
            style={{
              border: "0",
              width: 200,
              height: 30,
              textAlign: "center",
              fontFamily: "Times New Roman",
            }}
            type="text"
            value={data?.date_2}
            onChange={(e) => setData({ ...data, date_2: e.target.value })}
            name="date"
          />
          <Box
            width={200}
            borderTop={1}
            borderColor={"FAFAFA"}
            fontFamily={"Times New Roman"}
            textAlign={"center"}
          >
            Date
          </Box>
        </Box>
      </Box>

      <Box flexDirection={"row"} display={"flex"} mt={10}>
        <Box mr={2} mt={data?.individual_name ? -2 : 0}>
          <Box
            fontFamily={"Times New Roman"}
            borderBottom={1}
            width={200}
            textAlign={"center"}
            fontSize={15}
          >
            {data?.individual_name}
          </Box>
          <Box
            fontSize={16}
            fontFamily={"Times New Roman"}
            textAlign={"center"}
          >
            {" "}
            (Optional) Individual Name
          </Box>
        </Box>

        <Box mt={-8}>
          <SignatureCanvas
            onEnd={(data) =>
              setSign_3(
                signature_3.current.getTrimmedCanvas().toDataURL("image/png")
              )
            }
            ref={signature_3}
            penColor="black"
            canvasProps={{
              width: 200,
              height: 60,
              className: "sigCanvas",
            }}
          />
          <Box
            width={200}
            borderTop={1}
            borderColor={"FAFAFA"}
            fontFamily={"Times New Roman"}
            mr={2}
            textAlign={"center"}
          >
            {" "}
            Signature{" "}
          </Box>
        </Box>

        <Box mt={-4}>
          <input
            style={{
              border: "0",
              width: 200,
              height: 30,
              textAlign: "center",
              fontFamily: "Times New Roman",
            }}
            type="text"
            value={data?.date_3}
            onChange={(e) => setData({ ...data, date_3: e.target.value })}
            name="date"
          />
          <Box
            width={200}
            borderTop={1}
            borderColor={"FAFAFA"}
            fontFamily={"Times New Roman"}
            textAlign={"center"}
          >
            Date
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RelationshipEvidence;
