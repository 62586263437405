import { Box, TextField } from "@mui/material";
import React from "react";

const Emmergency = ({ data, handleChange }) => {
  return (
    <Box
      padding={4}
      flexDirection={"row"}
      justifyContent={"space-evenly"}
      display={"flex"}
    >
      <Box width={"20%"}>
        <Box>Contact Person - 1</Box>

        <Box mt={3} mb={1}>
          Name
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="name"
          label="Name"
          variant="outlined"
          onChange={handleChange}
          name="name"
          value={data?.name}
        />
        <Box mt={3} mb={1}>
          Phone
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="phone"
          label="Phone"
          variant="outlined"
          value={data?.phone}
          onChange={handleChange}
          name="phone"
        />
        <Box mt={3} mb={1}>
          Relationship
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="relationship"
          label="Relationship"
          variant="outlined"
          value={data?.relationship}
          onChange={handleChange}
          name="relationship"
        />
      </Box>

      <Box width={"20%"}>
        <Box>Contact Person - 2</Box>
        <Box mt={3} mb={1}>
          Name
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="name"
          label="Name"
          variant="outlined"
          onChange={handleChange}
          name="name"
          value={data?.name}
        />
        <Box mt={3} mb={1}>
          Phone
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="phone"
          label="Phone"
          variant="outlined"
          value={data?.phone}
          onChange={handleChange}
          name="phone"
        />
        <Box mt={3} mb={1}>
          Relationship
        </Box>
        <TextField
          size="small"
          style={{ width: "100%" }}
          id="relationship"
          label="Relationship"
          variant="outlined"
          value={data?.relationship}
          onChange={handleChange}
          name="relationship"
        />
      </Box>
    </Box>
  );
};

export default Emmergency;
