import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Alert,
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";

import colours from "../../constants/colours";
import { UserContext } from "../../context/userContext";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HospitalForm = ({ open, handleOpenForm }) => {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setGetData } = useContext(UserContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [data, setData] = useState({});

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };
  const handleCreateServiceType = () => {
    handleOpenForm();
  };

  useEffect(() => {
    if (success) {
      setGetData(true);
      const timer = setTimeout(() => {
        setSuccess(false);
        handleOpenForm();
      }, 5000);

      // return clearTimeout(timer);
    }
  }, [success]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Individual
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateServiceType}>
              {isLoading ? "Please wait..." : "Save"}
            </Button>
          </Toolbar>
        </AppBar>
        {success && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="success">New Doctor Created Successfully.</Alert>
          </Box>
        )}
        {errorMessage && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="error">
              New Doctor Was Not Created. All Fields Are required!
            </Alert>
          </Box>
        )}
        <Box mt={3} />
        <Box
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              Individual
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="individual"
              label="Individual"
              variant="outlined"
              onChange={handleChange}
              name="individual"
            />
            <Box mt={3}>Start Date</Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="Start Date"
                />
              </DemoContainer>
            </LocalizationProvider>
            <Box mt={3}>End Date</Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="End Date"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default HospitalForm;

const styles = {};
