import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import { Add, RemoveRedEyeOutlined } from "@mui/icons-material";

import colours from "../../constants/colours";
import FormDialog from "./form";
import { getServiceTypes } from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";

const columns = [
  // { field: "id", headerName: "ID", width: 20 },
  {
    field: "name",
    headerClassName: "super-app-theme--header",
    headerName: "Name",
  },
  {
    field: "contractNo",
    headerClassName: "super-app-theme--header",
    headerName: "Contract No",
  },
  {
    field: "region",
    headerName: "Region",
    headerClassName: "super-app-theme--header",
    // type: "number",
    // width: 90,
  },
  {
    field: "serviceType",
    headerName: "Service Type",
    headerClassName: "super-app-theme--header",
    // description: "This column has a value getter and is not sortable.",
    // sortable: false,
    width: 160,
    // valueGetter: (params) =>
    // `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
  {
    field: "evv",
    headerName: "EVV",
    width: 30,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return params?.row?.evv === 0 ? "Yes" : "No";
    },
  }, //checkbox
  {
    field: "programType",
    headerClassName: "super-app-theme--header",
    headerName: "Program Type",
  },
  {
    field: "unitRate",
    headerClassName: "super-app-theme--header",
    headerName: "Unit Rate",
  },
  {
    field: "group",
    headerClassName: "super-app-theme--header",
    headerName: "Group",
  },
  {
    field: "receiver",
    headerClassName: "super-app-theme--header",
    headerName: "Receiver",
  },
  {
    field: "payer",
    headerClassName: "super-app-theme--header",
    headerName: "Payer",
  },
  {
    field: "vestaContract",
    headerClassName: "super-app-theme--header",
    headerName: "Vesta Contract",
  },
  {
    field: "status",
    headerClassName: "super-app-theme--header",
    headerName: "Status",
  },
  // {
  //   field: "actions",
  //   headerName: "Actions",
  //   renderCell: (params) => {
  //     return (
  //       <Typography>
  //         <RemoveRedEyeOutlined
  //           style={{ color: colours.primary, cursor: "pointer" }}
  //         />
  //       </Typography>
  //     );
  //   },
  // },
];

const ServiceForm = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const { setGetData, getData, loginDetails } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenForm = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const userid = loginDetails?.user[0]?.user_id;
    getServiceTypes(userid, loginDetails?.token, setData, setIsSuccessful);
  }, []);

  useEffect(() => {
    if (getData) {
      const userid = loginDetails?.user[0]?.user_id;
      getServiceTypes(userid, loginDetails?.token, setData, setIsSuccessful);
    }
  }, [getData]);

  return (
    <Box mt={4}>
      <Box display={"flex"} justifyContent={"right"} mb={2}>
        <Button
          variant="contained"
          style={{ backgroundColor: colours.primary }}
          startIcon={<Add />}
          onClick={() => handleOpenForm()}
        >
          Add Agency Service Type
        </Button>
      </Box>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            color: colours.primary,
          },
          "& .super-app-theme--header": {
            backgroundColor: colours.secondary,
          },
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        // checkboxSelection
      />
      <FormDialog open={open} handleOpenForm={handleOpenForm} />
    </Box>
  );
};

export default ServiceForm;
