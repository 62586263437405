import React, { useEffect } from "react";
import colours from "../../constants/colours";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  AssessmentOutlined,
  Book,
  BookOnline,
  ChatOutlined,
  GraphicEqOutlined,
  Report,
} from "@mui/icons-material";

const ReportSubMenuList = ({
  open,
  setSelectedIndex,
  selectedIndex,
  setSelectedPage,
}) => {
  const setting = (index, screen, url) => {
    setSelectedIndex(index);
    window.history.replaceState("", screen, url);
    if (setSelectedPage) setSelectedPage(screen);
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {/* <ListItemButton
          selected={selectedIndex === 42}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(42, "Care Summary", "Care-Summary");
          }}
        >
          <ListItemIcon>
            <AssessmentOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Care Summary" />
        </ListItemButton>
        <Divider /> */}
        {/* <ListItemButton
          selected={selectedIndex === 43}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(43, "Care Summary Extentive", "Care-Summary-Extentive");
          }}
        >
          <ListItemIcon>
            <AssessmentOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Care Summary (Extensive)" />
        </ListItemButton>
        <Divider /> */}
        {/* <ListItemButton
          selected={selectedIndex === 44}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(44, "Individual Report", "Individual-Report");
          }}
        >
          <ListItemIcon>
            <AssessmentOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Individual Report" />
        </ListItemButton>
        <Divider /> */}

        {/* <ListItemButton
          selected={selectedIndex === 45}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(45, "Doctor", "Doctor");
          }}
        >
          <ListItemIcon>
            <AssessmentOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Doctor" />
        </ListItemButton>
        <Divider /> */}
        {/* <ListItemButton
          selected={selectedIndex === 46}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(46, "Employee Report", "Employee-Report");
          }}
        >
          <ListItemIcon>
            <AssessmentOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="Employee" />
        </ListItemButton>
        <Divider /> */}
        <ListItemButton
          selected={selectedIndex === 47}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(47, "Service Forms & Reports", "Service-Forms-Reports");
          }}
        >
          <ListItemIcon>
            <Book style={{ color: colours.white, fontSize: 16 }} />
          </ListItemIcon>
          <ListItemText primary="Service Forms" />
        </ListItemButton>
        <Divider />
        <ListItemButton
          selected={selectedIndex === 48}
          sx={{ pl: 4 }}
          onClick={() => {
            setting(48, "TimeSheets", "TimeSheets");
          }}
        >
          <ListItemIcon>
            <AssessmentOutlined
              style={{ color: colours.white, fontSize: 16 }}
            />
          </ListItemIcon>
          <ListItemText primary="TimeSheets" />
        </ListItemButton>
        <Divider />
      </List>
    </Collapse>
  );
};

export default ReportSubMenuList;
