import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";

import colours from "../../../constants/colours";
import {
  getAuthorizations,
  getAuthorizationsUnderIndividual,
  getCompany,
  getEmployee,
  getIndividualList,
} from "../../../pages/main/Dashboard/business";
import { UserContext } from "../../../context/userContext";
import RelationshipEvidence from "../../RelationshipEvidence";
import Regular from "../AllTimesheetReports/Regular";
import MSCP from "../AllTimesheetReports/MDCP";
import CBA from "../AllTimesheetReports/CBA";
import DailySchedule from "../AllTimesheetReports/DailySchedule";

const TimeSheetsForm = () => {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [signature_image, setSign_1] = useState("");
  const [signature_image_2, setSign_2] = useState("");
  const [signature_image_3, setSign_3] = useState("");
  const { loginDetails } = useContext(UserContext);
  const [individual_id, setIndividual_id] = useState("");
  const [individualList, setIndividualList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [authorzations, setAuthorizations] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [isHideFooter, setIsHideFooter] = useState(false);
  const [serviceType, setServiceType] = useState("Evidence of Relationship");
  const [data, setData] = useState({
    date: "",
    date_2: "",
    date_3: "",
    date_4: "",
  });

  const formArea = useRef();
  const signature_1 = useRef();
  const signature_2 = useRef();
  const signature_3 = useRef();

  const handlePrint = useReactToPrint({
    onBeforePrint: () => setIsHideFooter(true),
    onAfterPrint: () => setIsHideFooter(false),
    content: () => formArea.current,
  });

  useEffect(() => {
    getIndividualList(
      loginDetails?.user[0]?.user_id || loginDetails?.user[0]?.company_id,
      loginDetails.token,
      setIndividualList
    );
  }, []);

  useEffect(() => {
    getEmployee(
      loginDetails?.user[0]?.user_id || loginDetails?.user[0]?.company_id,
      loginDetails.token,
      setEmployeeList
    );
  }, []);

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const getCompanyDetails = () => {
    getCompany(
      loginDetails?.user[0]?.company_id,
      loginDetails.token,
      setCompanyData
    );
  };

  const getAuthorization = () => {
    getAuthorizationsUnderIndividual(
      loginDetails?.user[0]?.user_id || loginDetails?.user[0]?.company_id,
      individual_id,
      loginDetails.token,
      setAuthorizations
    );
  };

  useEffect(() => {
    if (individual_id) getAuthorization();
  }, [individual_id]);

  return (
    <Box flexDirection={"row"} display={"flex"}>
      <Box width={"23%"}>
        <Box>
          <Box mb={1}>Individual name</Box>
          <Autocomplete
            // defaultValue={""}
            value={data?.individual_name}
            onChange={(event, newValue) => {
              setIndividual_id(newValue?.id);
              setData({
                ...data,
                individual: newValue,
                individual_name: newValue?.firstname
                  ? `${newValue?.firstname} ${newValue?.lastname}`
                  : "",
              });
            }}
            size="small"
            style={{ width: "100%" }}
            disablePortal
            id="individual_name"
            options={individualList}
            getOptionLabel={(option) =>
              option?.id ? `${option?.firstname} ${option?.lastname}` : "Select"
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              return <TextField {...params} label="Individual name" />;
            }}
          />
        </Box>
        <Box mt={2}>
          <Box mb={1}>Authorizations</Box>
          <Autocomplete
            // defaultValue={""}
            // value={data?.employee_name}
            onChange={(event, newValue) => {
              setData({
                ...data,
                authorization_start_date: newValue?.authorization_start_date,
                // authorzation_id: newValue?.id,
                authorzation_id: newValue?.auth_id,
                authorizedTime: newValue?.units,
              });
            }}
            size="small"
            style={{ width: "100%" }}
            disablePortal
            id="authorzation_id"
            options={authorzations}
            getOptionLabel={(option) =>
              option?.id
                ? `${option?.service_code} - ${option?.service_type} (${moment(
                    option?.service_start_date
                  ).format("MMM Do YYYY")} - ${moment(
                    option?.service_end_date
                  ).format("MMM Do YYYY")})`
                : "Select"
            }
            sx={{ width: 300, fontSize: 12 }}
            renderInput={(params) => {
              return <TextField {...params} label="Authorizations" />;
            }}
          />
        </Box>

        <Box mt={2}>
          <Box mb={1}>Attendant</Box>
          <Autocomplete
            // defaultValue={""}
            value={data?.employee_name}
            onChange={(event, newValue) => {
              setData({
                ...data,
                employee: newValue,
                attendantA: newValue?.firstname
                  ? `${newValue?.firstname} ${newValue?.lastname}`
                  : "",
                employee_name: newValue?.firstname
                  ? `${newValue?.firstname} ${newValue?.lastname}`
                  : "",
              });
            }}
            size="small"
            style={{ width: "100%" }}
            disablePortal
            id="employee_name"
            options={employeeList}
            getOptionLabel={(option) =>
              option?.id ? `${option?.firstname} ${option?.lastname}` : "Select"
            }
            sx={{ width: 300 }}
            renderInput={(params) => {
              return <TextField {...params} label="Attendant name" />;
            }}
          />
        </Box>

        <Box mt={2}>
          <fieldset
            style={{
              color: colours.primary,
              borderWidth: 1,
              borderColor: "lightgrey",
              borderRadius: 3,
            }}
          >
            <legend
              style={{
                color: colours.primary,
              }}
            >
              Select Timesheet Report Type
            </legend>
            <Box pb={1} mt={2}>
              <input
                style={{
                  padding: 3,
                  border: "1px solid #572544",
                  borderRadius: 5,
                  alignSelf: "center",
                }}
                placeholder="Select Date"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Box>

            <FormControl>
              {/* <FormLabel id="demo-radio-buttons-group-label">
                
              </FormLabel> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Regular"
                  checked={serviceType === "Regular" && true}
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Regular"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="Daily Schedule"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="Daily Schedule"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="MDCP"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="MDCP"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
                <FormControlLabel
                  value="CBA"
                  control={
                    <Radio onChange={(e) => setServiceType(e.target.value)} />
                  }
                  label="CBA"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </fieldset>
        </Box>
        <Box display={"flex"} mt={2} justifyContent={"center"}>
          {/* <Button
            style={{ marginRight: 20 }}
            variant="outlined"
            onClick={() => handlePrint()}
          >
            View
          </Button> */}
          <Button
            variant="contained"
            onClick={() => {
              setIsHideFooter(true);
              setTimeout(() => {
                handlePrint();
              }, 200);
            }}
          >
            Print
          </Button>
        </Box>
      </Box>
      <Box width={"77%"} bgcolor={"white"} height={"92%"} ml={2} p={2}>
        <Box bgcolor={"#F2F2F2"} height={"92vh"} p={2}>
          <Box bgcolor={"white"} height={"92vh"} p={5} ref={formArea}>
            {serviceType === "Regular" && (
              <Regular
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
                selectedDate={selectedDate}
              />
            )}
            {serviceType === "MDCP" && (
              <MSCP
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
                selectedDate={selectedDate}
              />
            )}
            {serviceType === "CBA" && (
              <CBA
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
                selectedDate={selectedDate}
              />
            )}
            {serviceType === "Daily Schedule" && (
              <DailySchedule
                data={data}
                setData={setData}
                tasks={tasks}
                setTasks={setTasks}
                setSchedules={setSchedules}
                schedules={schedules}
                isHideFooter={isHideFooter}
                formName={serviceType}
                selectedDate={selectedDate}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeSheetsForm;
