import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Alert, Box, InputAdornment, TextField } from "@mui/material";
import { UserContext } from "../../context/userContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import colours from "../../constants/colours";
import { createUser } from "../../pages/main/Dashboard/business";
import { formatPhoneNumber } from "../FormatPhoneNumber";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Form = ({ open, handleOpenForm, setRefresh }) => {
  const [success, setSuccess] = useState("");
  // const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [data, setData] = useState({});
  const { loginDetails } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const handleChange = (e) => {
    let value = "";
    if (e.target.name === "phone") {
      value = formatPhoneNumber(e.target.value);
      data[e.target.name] = value;
    } else {
      data[e.target.name] = e.target.value;
    }
    setData({ ...data });
  };

  useEffect(() => {
    if (success === "success") {
      setData({
        firstname: "",
        lastname: "",
        user_id: "",
        phone: "",
        email: "",
        address: "",
        confirm_password: "",
        password: "",
      });

      setTimeout(() => {
        setRefresh(true);
      }, 200);
    }
  }, [success]);

  const handleCreateUser = () => {
    setSuccess("");
    setRefresh(false);
    const {
      firstname,
      lastname,
      email,
      phone,
      address,
      user_id,
      password,
      confirm_password,
    } = data;

    console.log(phone, "phone");

    if (
      firstname &&
      lastname &&
      email &&
      phone &&
      address &&
      user_id &&
      password === confirm_password &&
      phone !== null
    ) {
      data["company_phone"] = loginDetails?.user[0]?.phone;

      createUser(
        data,
        loginDetails?.user[0]?.company_id,
        loginDetails?.token,
        setSuccess,
        setIsLoading,
        setErrorMessage
      );
    } else {
      setSuccess("error");
      setErrorMessage("Something went wrong. Please review inputs!");
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create User
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleCreateUser()}
            >
              {isLoading ? "Please wait..." : "Save"}
            </Button>
          </Toolbar>
        </AppBar>
        {success === "success" && (
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Alert severity="success">New User Created Successfully.</Alert>
          </Box>
        )}
        {success === "error" && (
          <Box>
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              <Alert severity="error">New User Was Not Created.</Alert>
            </Box>
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          </Box>
        )}
        <Box mt={3} />
        <Box flexDirection={"row"} justifyContent={"center"} display={"flex"}>
          <Box width={"20%"}>
            <Box mt={3} mb={1}>
              Firstname
            </Box>
            <TextField
              value={data?.firstname}
              size="small"
              style={{ width: "100%" }}
              id="firstname"
              placeholder="Firstname"
              variant="outlined"
              onChange={handleChange}
              name="firstname"
            />

            <Box mt={3} mb={1}>
              Lastname
            </Box>
            <TextField
              value={data?.lastname}
              size="small"
              style={{ width: "100%" }}
              id="lastname"
              placeholder="Lastname"
              variant="outlined"
              onChange={handleChange}
              name="lastname"
            />

            <Box mt={3} mb={1}>
              Email
            </Box>
            <TextField
              value={data?.email}
              size="small"
              style={{ width: "100%" }}
              id="email"
              placeholder="Email"
              variant="outlined"
              onChange={handleChange}
              name="email"
            />
            <Box mt={3} mb={1}>
              Address
            </Box>
            <TextField
              value={data?.address}
              size="small"
              style={{ width: "100%" }}
              id="address"
              placeholder="Address"
              variant="outlined"
              onChange={handleChange}
              name="address"
            />
          </Box>
          <Box width={"20%"} ml={2}>
            <Box mt={3} mb={1}>
              Phone
            </Box>
            <TextField
              value={data?.phone}
              size="small"
              style={{ width: "100%" }}
              id="phone"
              placeholder="Phone"
              variant="outlined"
              onChange={handleChange}
              name="phone"
            />

            <Box mt={3} mb={1}>
              User ID
            </Box>
            <TextField
              value={data?.user_id}
              size="small"
              style={{ width: "100%" }}
              id="user_id"
              placeholder="User ID"
              variant="outlined"
              onChange={handleChange}
              name="user_id"
            />

            <Box mt={3} mb={1}>
              Password
            </Box>
            <TextField
              value={data?.password}
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              size="small"
              style={{ width: "100%" }}
              id="password"
              placeholder="Password"
              variant="outlined"
              onChange={handleChange}
              name="password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { width: "100%" },
              }}
            />

            <Box mt={3} mb={1}>
              Confirm Password
            </Box>
            <TextField
              value={data?.confirm_password}
              type={showPassword2 ? "text" : "password"} // <-- This is where the magic happens
              size="small"
              style={{ width: "100%" }}
              id="confirm_password"
              placeholder="Confirm Password"
              variant="outlined"
              onChange={handleChange}
              name="confirm_password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { width: "100%" },
              }}
            />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Button
            onClick={() => handleCreateUser()}
            style={{
              backgroundColor: colours.primary,
              paddingRight: 20,
              paddingLeft: 20,
              marginTop: 20,
            }}
            variant="contained"
          >
            {isLoading ? "Please wait..." : "Save"}
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default Form;
