import ServiceForm from "../../../component/ServiceForm";
import IndividualForm from "../../../component/IndividualForm";
import Employee from "../../../component/Employee";
import TimeSheetsForm from "../../../component/ReportForms/TimesheetReportForm";
import AttendantOrientation from "../../../component/ReportForms/AttendantOrientation";
import Doctor from "../../../component/Doctor";
import HospitalStay from "../../../component/Hospital";
import CaseWorker from "../../../component/CaseWorker";
import Users from "../../../component/Users";
import ChangePassword from "../../../component/ChangePassword";
import ServiceFormReports from "../../../component/ServiceFormReports";

const components = [
  {
    id: "Service Types",
    component: <ServiceForm />,
  },
  {
    id: "Individual List",
    component: <IndividualForm />,
  },
  {
    id: "Employee",
    component: <Employee />,
  },
  {
    id: "Doctor",
    component: <Doctor />,
  },
  {
    id: "Hospital Stay",
    component: <HospitalStay />,
  },
  {
    id: "Case Worker",
    component: <CaseWorker />,
  },
  {
    id: "TimeSheets",
    component: <TimeSheetsForm />,
  },
  {
    id: "Users",
    component: <Users />,
  },
  {
    id: "Password",
    component: <ChangePassword />,
  },
  {
    id: "Service Forms & Reports",
    component: <ServiceFormReports />,
  },
];

export default components;
