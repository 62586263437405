import React, { useContext, useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Autocomplete,
  Alert,
} from "@mui/material";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import DatePickerNew from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Add,
  Close,
  Edit,
  Remove,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useReactToPrint } from "react-to-print";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

import colours from "../../constants/colours";
import FormDialog from "./form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  createAuthorization,
  createCaseInfo,
  createComments,
  createComplainLog,
  createTasks,
  getAuthorizationSchedule,
  getAuthorizations,
  getAuthorizationsUnderIndividual,
  getCaseInfos,
  getCaseworkers,
  getComments,
  getComplains,
  getDiagnosisCodes,
  getDoctors,
  getEmployee,
  getIndividualList,
  getServiceCodes,
  getServiceTypes,
  getTasks,
  saveCalendar,
  updateAuthorization,
  updateCalendar,
  updateCaseInformation,
  updateComments,
  updateComplainLog,
  updateTasks,
} from "../../pages/main/Dashboard/business";
import { UserContext } from "../../context/userContext";
import { AuthorizationContext } from "../../context/authorizationContext";
import { calendarData, taskData } from "./dataStructure";
import CaseInformation from "../Docs/Case_information";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CustomTabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

CustomTabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const IndividualForm = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open_, setOpen_] = useState(false);
  const [open__, setOpen__] = useState(false);
  const [value, setValue] = useState(0);
  const [valueSub, setValueSub] = useState(0);
  const [valueEdit, setValueEdit] = useState(0); //edit authorization
  const [value2, setValue2] = useState(0);
  const [value_, setValue_] = useState(0);
  const [data, setData] = useState({});
  const [tasks, setTasks] = useState({});
  const [complaint, setComplaint] = useState({});
  const [sundayTotal, setSundayTotal] = useState("");
  const [mondayTotal, setMondayTotal] = useState("");
  const [tuesdayTotal, setTuesdayTotal] = useState("");
  const [wednesdayTotal, setWednesdayTotal] = useState("");
  const [thursdayTotal, setThursdayTotal] = useState("");
  const [fridayTotal, setFridayTotal] = useState("");
  const [saturdayTotal, setSaturdayTotal] = useState("");
  const {
    caseInfoData,
    loginDetails,
    complainantData,
    setComplaintData,
    setCaseInfoData,
    setAuthEdit,
    authEdit,
  } = useContext(UserContext);
  const { currentAuthId, setCurrentAuthId } = useContext(AuthorizationContext);
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [weeklyCalendar, setWeeklyCalendar] = useState(1);
  const [multipleData, setMultipleData] = useState(calendarData);
  const [totalTimes, setTotalTimes] = useState("");
  const [printOpen, setPrintOpen] = useState(false);
  const [clear, setClear] = useState(false);
  const [displayCsseForm, setDisplayCaseForm] = useState(false);
  const [hideCloseIcon, setHideCloseIcon] = useState(true);
  const [individualList, setIndividualList] = useState([]);
  const [selectedIndividual, setSelectedIndividual] = useState({});
  const [authorizationData, setAuthorizationData] = useState({});
  const [caseworkers, setCaseworkers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [caseWorkForm, setCaseWorkForm] = useState({});
  const [selectedCaseWorker, setSelectedCaseWorker] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [diagnosisCodes, setDiagnosisCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");
  const [authorizations, setAuthorizations] = useState([]);
  const [weeklyScheduleData, setWeeklyScheduleData] = useState([]);
  const [taskNewData, setTaskNewData] = useState([]);
  const [newComplains, setNewComplains] = useState([]);
  const [newComments, setNewComments] = useState([]);
  const [caseInfosData, setCaseInfosData] = useState([]);
  const [authorizationsCopy, setAuthorizationsCopy] = useState([]);
  const [selectId, setSelectId] = useState("");
  const [selectedAuthId, setSelectedAuthId] = useState("");
  const [selectedAuthData, setSelectedAuthData] = useState({});
  const [responseError, setResponseError] = useState("");
  const [responseError_, setResponseError_] = useState("");
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false);
  const [isLoadingTask, setIsLoadingTask] = useState(false);
  const [tasksData, setTasksData] = useState(taskData);
  const [selectedIndividualData, setSelectedIndividualData] = useState({});
  const [formComment, setFormComment] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [is_loading, setIs_loading] = useState(false);
  const [is_Successful, setIs_Successful] = useState();
  const [isSuccessful_, setIsSuccessful_] = useState(false);
  const [isloading_, setIsloading_] = useState(false);
  const [comment, setComment] = useState("");
  const [_isSuccessful, _setIsSuccessful] = useState(false);
  const [_isloading, _setIsloading] = useState(false);
  const [individualId, setIndividualId] = useState("");
  const [selectedCurrentAuthId, setSelectedCurrentAuthId] = useState("");
  const [selectedCaseInfo, setSelectedCaseInfo] = useState({});
  const [openEditAuth, setOpenEditAuth] = useState(false);
  const [editAuthorizationData, setEditAuthorizationData] = useState({});
  const [getSchedule, setGetSchedule] = useState(false);
  const [authorizationList, setAuthorizationList] = useState([]);
  const [individual_id, set_individual_id] = useState("");
  const [selectedAuthID, setSelectedAuthID] = useState("");

  //Edit Authorzation, Weekly Calendar, Tasks, Complaints, etc starts here
  const handleCloseEdit = () => {
    setOpenEditAuth(!openEditAuth);
  };

  function a11yPropsEdit(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeEdit = (event, newValue) => {
    setValueEdit(newValue);
  };

  const handleAuth_ = (e) => {
    editAuthorizationData[e.target.name] = e.target.value;

    setEditAuthorizationData({ ...editAuthorizationData });
  };

  //update Authorization
  const handleUpdateAuthorization = () => {
    updateAuthorization(
      editAuthorizationData,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setRequestStatus,
      setIsLoading
    );
  };

  const updateNewTasks = () => {
    let data = {
      tasks: taskNewData,
      individual_id: taskNewData[0]?.individual_id,
    };

    updateTasks(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      data,
      setResponseError_,
      setIsLoadingTask
    );
  };

  const updateCaseInfo = () => {
    let data = {
      ...caseInfoData,
      individual_id: caseInfoData?.individual_id,
    };

    updateCaseInformation(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setIs_Successful,
      setIs_loading
    );
  };

  const updateComment = () => {
    let data = {
      newComments,
      individual_id: newComments[0]?.individual_id,
    };

    updateComments(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      _setIsSuccessful,
      _setIsloading
    );
  };

  const updateComplaint = () => {
    let data = {
      newComplaint: newComplains,
      individual_id: newComplains[0]?.individual_id,
    };

    updateComplainLog(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setIsSuccessful_,
      setIsloading_
    );
  };

  //Edit ends here

  const handleComplainant = (e, edit, index) => {
    // edit form
    if (edit) {
      if (
        e.target?.name === "agrees_with_resolution" ||
        e.target?.name === "disagrees_with_resolution"
      ) {
        newComplains[index][e.target?.name] = e.target?.checked;
      } else {
        newComplains[index][e.target?.name] = e.target?.value;
      }

      setNewComplains([...newComplains]);
    } else {
      if (
        e.target?.name === "agrees_with_resolution" ||
        e.target?.name === "disagrees_with_resolution"
      ) {
        complainantData[e.target?.name] = e.target?.checked;
      } else {
        complainantData[e.target?.name] = e.target?.value;
      }

      setComplaintData({ ...complainantData });
    }
  };

  const handleSubmitComplainant = () => {
    let data = {
      ...complainantData,
      individual_id: authorizationData?.individual_id,
      auth_id: currentAuthId,
    };

    createComplainLog(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setIsSuccessful_,
      setIsloading_
    );
  };

  const saveComment = () => {
    let data = {
      comment,
      individual_id: authorizationData?.individual_id,
      auth_id: currentAuthId,
    };

    createComments(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      _setIsSuccessful,
      _setIsloading
    );
  };

  useEffect(() => {
    if (_isSuccessful) {
      setComment("");
    }
  }, [_isSuccessful]);

  useEffect(() => {
    if (selectId) {
      let result = [];
      for (let i = 0; i < authorizations.length; i++) {
        if (authorizations[i]?.individual_id == selectId) {
          result.push(authorizations[i]);
        }
      }

      setAuthorizations([...result]);
    }
  }, [selectId]);

  useEffect(() => {
    getDiagnosisCodes(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setDiagnosisCodes
    );
  }, []);

  useEffect(() => {
    if (caseInfoData) {
      setIsDisabled(true);
    }
  }, [caseInfoData]);

  useEffect(() => {
    if (!clear) {
      setIsDisabled(true);
    }
  }, [clear]);

  useEffect(() => {
    if (selectedAuthId) {
      for (let i = 0; i < authorizations.length; i++) {
        if (authorizations[i]["id"] === selectedAuthId) {
          setSelectedAuthData(authorizations[1]);
        }
      }
    }
  }, [selectedAuthId]);

  useEffect(() => {
    getDoctors(loginDetails?.user[0]?.user_id, loginDetails.token, setDoctors);
  }, []);

  useEffect(() => {
    getCaseworkers(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setCaseworkers
    );
  }, []);

  useEffect(() => {
    getEmployee(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setEmployees
    );
  }, []);

  const caseInformationRef = useRef();
  const caseInformationRef2 = useRef();

  const handlePrint = useReactToPrint({
    onAfterPrint: () => setClear(false),
    content: () => caseInformationRef.current,
  });

  const handleSelectedIndividual = (id) => {
    for (let i = 0; i < individualList.length; i++) {
      if (individualList[i]["id"] === id) {
        setSelectedIndividual(individualList[i]);
        handleClose_();
      }
    }
  };

  const handlePrint2 = useReactToPrint({
    content: () => caseInformationRef2.current,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeSub = (event, newValueSub) => {
    setValueSub(newValueSub);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handle_Change = (event, newValue) => {
    setValue_(newValue);
  };

  function chunk(time, n) {
    let str = time.replace(/:/g, "");
    var ret = [];
    var i;
    var len;
    // if (str.length === 5) return;

    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }

    return ret;
  }

  const handleTaskChange = (e, edit, name, index) => {
    // this is used to edit tasks
    if (edit === "Yes") {
      taskNewData[index][e.target.name] = e.target.checked;
      setTaskNewData([...taskNewData]);
    } else {
      if (e.target.name === "others") {
        tasksData[e.target.name] = e.target.value;
      } else {
        tasksData[e.target.name] = e.target.checked;
      }

      setTasksData({ ...tasksData });
    }
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 100,
    //   // valueGetter: (params) => console.log("1"),
    // },
    {
      field: "firstname",
      headerName: "First Name",
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      headerClassName: "super-app-theme--header",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      // width: 160,
      // valueGetter: (params) =>
      // `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "city",
      headerName: "City",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phone",
      headerName: "Phone",
      headerClassName: "super-app-theme--header",
    },
    // { field: "zipCode", headerName: "Zip Code" },
    // { field: "dob", headerName: "DOB" },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "super-app-theme--header",
    },
    // { field: "maritalStatus", headerName: "Marital Status" },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params.row.status === 1 ? "Active" : "Inactive",
    },
    {
      field: "medicaid",
      headerName: "State ID",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stear",
      headerName: "S.T.E.A.R/211",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      width: 350,
      renderCell: (params) => {
        // console.log(params?.row?.id);
        return (
          <Typography>
            <Box flexDirection={"row"} display={"flex"}>
              <Button
                style={{ fontSize: 10 }}
                startIcon={<RemoveRedEyeOutlined />}
                variant="outlined"
                onClick={() => {
                  setSelectId("");
                  setSelectId(params?.row?.id);

                  handleSelectedIndividual(params?.row?.id);
                }}
              >
                View
              </Button>

              <Box mr={0.5} />
              <Button
                style={{ fontSize: 10 }}
                startIcon={<Add />}
                variant="outlined"
                onClick={() => {
                  //this is for filling the case form
                  setSelectedIndividualData({
                    firstname: params?.row?.firstname,
                    lastname: params?.row?.lastname,
                    address: params?.row?.address,
                    stateId: params?.row?.medicaid,
                    city: params?.row?.city,
                    zipCode: params?.row?.zip_code,
                    state: params?.row?.state,
                    phone: params?.row?.phone,
                    company_phone: params?.row?.company_phone,
                  });

                  setAuthorizationData({
                    ...data,
                    individual_id: params?.row?.id,
                  });

                  set_individual_id("0"); // for getting authorizations
                  setTimeout(() => {
                    set_individual_id(params?.row?.id); // for getting authorizations
                  }, 100);

                  handleClose();
                }}
              >
                Add Authorization
              </Button>
            </Box>
          </Typography>
        );
      },
    },
  ];

  const columns_ = [
    // { field: "id", headerName: "ID", width: 20 },
    {
      field: "service_group",
      headerClassName: "super-app-theme--header",
      headerName: "Service Group",
      width: 150,
    },
    {
      field: "service_code_description",
      headerName: "Service Code Description",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "case_worker",
      headerClassName: "super-app-theme--header",
      headerName: "Case-worker",
      align: "center",
      width: 150,
    },
    {
      field: "service_start_date",
      headerName: "Service Start Date",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        moment(params?.service_start_date).format("D-MM-YYYY"),
    },
    {
      field: "service_end_date",
      headerName: "Service End Date",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        moment(params?.service_end_date).format("D-MM-YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "authorization_end_date",
      headerName: "Authorization End Date",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        moment(params?.authorization_end_date).format("D-MM-YYYY"),
    },
    // { field: "authorization_start", headerName: "Auth Start" },
    // { field: "authorization_end", headerName: "Auth End" },
    {
      field: "actions",
      headerName: "Actions...",
      width: 250,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        // console.log(params?.row);
        return (
          <Typography>
            <Box flexDirection={"row"} display={"flex"}>
              <Button
                style={{ fontSize: 10 }}
                startIcon={<RemoveRedEyeOutlined />}
                variant="outlined"
                onClick={() => {
                  setSelectedCurrentAuthId("empty");
                  setTimeout(() => {
                    setSelectedCurrentAuthId(params?.row?.auth_id);
                  }, 10);
                  setIndividualId(params?.row?.individual_id);
                  setSelectedAuthId(params.row?.id);
                  handleModal();
                }}
              >
                View
              </Button>
              <Box width={10} />
              <Button
                style={{ fontSize: 10 }}
                startIcon={<Edit />}
                variant="outlined"
                onClick={() => {
                  setGetSchedule(false);
                  setTimeout(() => {
                    setGetSchedule(true);
                  }, 100);
                  /////
                  // console.log("Changed ID");
                  setIndividualId(params?.row?.individual_id);
                  // setSelectedAuthId(params.row?.id);
                  ////

                  //Set data for hour, minutes and seconds
                  let hour = params.row?.units?.split(":")[0]?.trim();
                  let minutes = params.row?.units
                    ?.split(":")[1]
                    ?.split("-")[0]
                    ?.trim();
                  let days = params.row?.units?.split("-")[1]?.trim();
                  let authId = params.row?.id;

                  setEditAuthorizationData({
                    ...params.row,
                    authId,
                    days,
                    hour,
                    minutes,
                  });
                  handleClose_();
                  setOpenEditAuth(true);
                  // setAuthorizationData(params.row);
                  // handleClose();
                }}
              >
                Edit
              </Button>
            </Box>
          </Typography>
        );
      },
    },
  ];

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (openModal) {
      setOpen__(false);
    } else {
      // setOpen__(true);
    }
  }, [openModal]);

  const handleOpenForm = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen_(!open_);
  };

  const handleClose_ = () => {
    setOpen__(!open__);
  };

  useEffect(() => {
    const userid = loginDetails?.user[0]?.user_id;
    getServiceCodes(
      userid,
      loginDetails?.token,
      setServiceTypeData,
      setIsSuccessful
    );
  }, []);

  const handleMultiInputs = (e, key, day, timeType, edit) => {
    // console.log(e?.target?.value, key, e?.target?.name, day, timeType);
    // console.log(weeklyScheduleData[key], "weeklyScheduleData");

    let currentValue = "";
    if (edit) {
      //for authorization status
      if (timeType === "status") {
        weeklyScheduleData[key]["status"] = e?.target?.value;
        setWeeklyScheduleData([...weeklyScheduleData]);
        return;
      }

      //For time
      //to format input to this format: 09:00am
      if (e.target.value?.length < 6) {
        currentValue = chunk(e.target.value, 2).join(":");
      } else {
        currentValue = e.target.value;
      }

      let splitedTimeIn = weeklyScheduleData[key][day]?.split("-")[0]?.trim();
      let splitedTimeOut = weeklyScheduleData[key][day]?.split("-")[1]?.trim();

      weeklyScheduleData[key][day] =
        timeType === "in"
          ? `${currentValue} - ${splitedTimeOut}`
          : `${splitedTimeIn} - ${currentValue}`;

      setWeeklyScheduleData([...weeklyScheduleData]);
      return;
    }

    if (timeType === "startDate" || timeType === "endDate") {
      const dateString = new Date(e).toLocaleDateString();
      if (timeType === "startDate") multipleData[key]["startDate"] = e;
      if (timeType === "endDate") multipleData[key]["endDate"] = e;

      setMultipleData([...multipleData]);
      return;
    }

    if (timeType === "status") {
      multipleData[key]["status"] = e?.target?.value;
      setMultipleData([...multipleData]);
      return;
    }

    let newObject = {};
    let subObject = {};

    if (multipleData[key][day]) {
      if (e.target.value?.length < 6) {
        multipleData[key][day][timeType] = chunk(e.target.value, 2).join(":");
      } else {
        multipleData[key][day][timeType] = e.target.value;
      }
    } else {
      subObject[timeType] = e.target.value;
      newObject[day] = subObject;
      multipleData[key] = newObject;
    }

    if (e.target.value) {
      const timeIn = moment(multipleData[key][day]["in"], "HH:mm:ss a").format(
        "HH:mm:ss a"
      );

      const timeOut = moment(
        multipleData[key][day]["out"],
        "HH:mm:ss a"
      ).format("HH:mm:ss a");

      const diff = moment.duration(
        moment(timeIn, "HH:mm:ss a").diff(moment(timeOut, "HH:mm:ss a"))
      );

      const hours = diff.hours();
      const minutes = diff.minutes();
      const seconds = diff.seconds();

      const result = `${hours} : ${minutes} : ${seconds}`;

      if (result) {
        multipleData[key][day]["total"] = moment(result, "HH:mm:ss").format(
          "HH:mm:ss"
        );
      }
    }

    setMultipleData([...multipleData]);
  };

  useEffect(() => {
    if (multipleData) {
      let result = [];

      for (let i of multipleData) {
        if (i["sunday"]["total"] && i["sunday"]["in"] && i["sunday"]["out"])
          result.push(i["sunday"]["total"]);
        if (i["monday"]["total"] && i["monday"]["in"] && i["monday"]["out"])
          result.push(i["monday"]["total"]);
        if (i["tuesday"]["total"] && i["tuesday"]["in"] && i["tuesday"]["out"])
          result.push(i["tuesday"]["total"]);
        if (
          i["wednesday"]["total"] &&
          i["wednesday"]["in"] &&
          i["wednesday"]["out"]
        )
          result.push(i["wednesday"]["total"]);
        if (
          i["thursday"]["total"] &&
          i["thursday"]["in"] &&
          i["thursday"]["out"]
        )
          result.push(i["thursday"]["total"]);
        if (i["friday"]["total"] && i["friday"]["in"] && i["friday"]["out"])
          result.push(i["friday"]["total"]);
        if (
          i["saturday"]["total"] &&
          i["saturday"]["in"] &&
          i["saturday"]["out"]
        )
          result.push(i["saturday"]["total"]);
      }

      const times = moment.duration();
      for (let a of result) {
        times.add(moment.duration(moment(a, "HH:mm:ss").format("HH:mm:ss")));
      }

      let fullTime = `${times.asHours()}:${times.minutes}:${times.seconds}`;

      setTotalTimes(moment(fullTime, "HH:mm:ss").format("HH:mm:ss"));
    }
  }, [multipleData]);

  useEffect(() => {
    multipleData[weeklyCalendar]["sunday"]["in"] = "";
    multipleData[weeklyCalendar]["sunday"]["out"] = "";
    multipleData[weeklyCalendar]["sunday"]["total"] = "";
    multipleData[weeklyCalendar]["monday"]["in"] = "";
    multipleData[weeklyCalendar]["monday"]["out"] = "";
    multipleData[weeklyCalendar]["monday"]["total"] = "";
    multipleData[weeklyCalendar]["tuesday"]["in"] = "";
    multipleData[weeklyCalendar]["tuesday"]["out"] = "";
    multipleData[weeklyCalendar]["tuesday"]["total"] = "";
    multipleData[weeklyCalendar]["wednesday"]["in"] = "";
    multipleData[weeklyCalendar]["wednesday"]["out"] = "";
    multipleData[weeklyCalendar]["wednesday"]["total"] = "";
    multipleData[weeklyCalendar]["thursday"]["in"] = "";
    multipleData[weeklyCalendar]["thursday"]["out"] = "";
    multipleData[weeklyCalendar]["thursday"]["total"] = "";
    multipleData[weeklyCalendar]["friday"]["in"] = "";
    multipleData[weeklyCalendar]["friday"]["out"] = "";
    multipleData[weeklyCalendar]["friday"]["total"] = "";
    multipleData[weeklyCalendar]["saturday"]["in"] = "";
    multipleData[weeklyCalendar]["saturday"]["out"] = "";
    multipleData[weeklyCalendar]["saturday"]["total"] = "";
    setMultipleData([...multipleData]);
  }, [weeklyCalendar]);

  useEffect(() => {
    getIndividualList(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setIndividualList
    );
  }, []);

  //to set authorization form inputs
  const handleAuth = (e) => {
    authorizationData[e.target.name] = e.target.value;

    setAuthorizationData({ ...authorizationData });
  };

  //send data to Database
  const handleAuthorization = () => {
    createAuthorization(
      authorizationData,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setRequestStatus,
      setIsLoading,
      setCurrentAuthId
    );
  };

  useEffect(() => {
    if (requestStatus === "success") {
      setAuthorizationData({
        individual_id: authorizationData?.individual_id,
        claim_frequency_type_code: "",
        medicare_assignment_code: "",
        provider_signature_file: "",
        patient_signature_code: "",
        release_information_code: "",
        assignment_benefits_indicator: "",
        place_service_id: "",
        authorization_number: "",
        unit_rate: "",
      });
    }
  }, [requestStatus]);

  useEffect(() => {
    getAuthorizations(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setAuthorizations,
      setAuthorizationsCopy
    );
  }, []);

  useEffect(() => {
    if (requestStatus === "success") {
      getAuthorizations(
        loginDetails?.user[0]?.user_id,
        loginDetails.token,
        setAuthorizations,
        setAuthorizationsCopy
      );
    }
  }, [requestStatus]);

  useEffect(() => {
    getAuthorizationSchedule(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setWeeklyScheduleData,
      individualId,
      selectedCurrentAuthId
    );
  }, [individualId, getSchedule, selectedCurrentAuthId]);

  useEffect(() => {
    getCaseInfos(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setCaseInfosData,
      individualId,
      selectedCurrentAuthId
    );
  }, [individualId, selectedCurrentAuthId]);

  useEffect(() => {
    getTasks(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setTaskNewData,
      individualId,
      selectedCurrentAuthId
    );
  }, [individualId, selectedCurrentAuthId]);

  useEffect(() => {
    getComplains(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setNewComplains,
      individualId,
      selectedCurrentAuthId
    );
  }, [individualId, isSuccessful_, selectedCurrentAuthId]);

  useEffect(() => {
    getComments(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setNewComments,
      individualId,
      selectedCurrentAuthId
    );
  }, [individualId, _isSuccessful, selectedCurrentAuthId]);

  const saveSchedule = () => {
    const newData = [];

    //filter the filled calendar
    for (let i = 0; i < multipleData.length; i++) {
      if (
        multipleData[i]["sunday"]["total"] !== "" ||
        multipleData[i]["monday"]["total"] !== "" ||
        multipleData[i]["tuesday"]["total"] !== "" ||
        multipleData[i]["wednesday"]["total"] !== "" ||
        multipleData[i]["thursday"]["total"] !== "" ||
        multipleData[i]["friday"]["total"] !== "" ||
        multipleData[i]["saturday"]["total"] !== ""
      ) {
        newData.push(multipleData[i]);
      }
    }

    let data = {
      schedules: JSON.stringify(newData),
      individual_id: authorizationData?.individual_id,
      auth_id: currentAuthId,
    };
    if (newData?.length === 0) {
      setResponseError("error");
      return;
    }

    saveCalendar(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      data,
      setResponseError,
      setIsLoadingCalendar
    );
  };

  const updateSchedule = () => {
    const newData = [];

    let data = {
      schedules: weeklyScheduleData,
      individual_id: weeklyScheduleData[0]?.individual_id,
    };

    updateCalendar(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      data,
      setResponseError,
      setIsLoadingCalendar
    );
  };

  useEffect(() => {
    if (responseError && responseError === "success") {
      for (let i = 0; i < multipleData.length; i++) {
        multipleData[i]["sunday"]["in"] = "";
        multipleData[i]["sunday"]["out"] = "";
        multipleData[i]["sunday"]["total"] = "";
        multipleData[i]["startDate"] = "";
        multipleData[i]["endDate"] = "";
        multipleData[i]["status"] = "";

        multipleData[i]["monday"]["in"] = "";
        multipleData[i]["monday"]["out"] = "";
        multipleData[i]["monday"]["total"] = "";
        multipleData[i]["startDate"] = "";
        multipleData[i]["endDate"] = "";
        multipleData[i]["status"] = "";

        multipleData[i]["tuesday"]["in"] = "";
        multipleData[i]["tuesday"]["out"] = "";
        multipleData[i]["tuesday"]["total"] = "";
        multipleData[i]["startDate"] = "";
        multipleData[i]["endDate"] = "";
        multipleData[i]["status"] = "";

        multipleData[i]["wednesday"]["in"] = "";
        multipleData[i]["wednesday"]["out"] = "";
        multipleData[i]["wednesday"]["total"] = "";
        multipleData[i]["startDate"] = "";
        multipleData[i]["endDate"] = "";
        multipleData[i]["status"] = "";

        multipleData[i]["thursday"]["in"] = "";
        multipleData[i]["thursday"]["out"] = "";
        multipleData[i]["thursday"]["total"] = "";
        multipleData[i]["startDate"] = "";
        multipleData[i]["endDate"] = "";
        multipleData[i]["status"] = "";

        multipleData[i]["friday"]["in"] = "";
        multipleData[i]["friday"]["out"] = "";
        multipleData[i]["friday"]["total"] = "";
        multipleData[i]["startDate"] = "";
        multipleData[i]["endDate"] = "";
        multipleData[i]["status"] = "";

        multipleData[i]["saturday"]["in"] = "";
        multipleData[i]["saturday"]["out"] = "";
        multipleData[i]["saturday"]["total"] = "";
        multipleData[i]["startDate"] = "";
        multipleData[i]["endDate"] = "";
        multipleData[i]["status"] = "";
      }

      setMultipleData([...multipleData]);
    }
  }, [responseError]);

  const saveTasks = () => {
    let data = {
      tasks: tasksData,
      individual_id: authorizationData?.individual_id,
      auth_id: currentAuthId,
    };

    createTasks(
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      data,
      setResponseError_,
      setIsLoadingTask
    );
  };

  useEffect(() => {
    if (responseError_ && responseError_ === "success") {
      setTasksData({
        bathing: false,
        dressing: false,
        excercising: false,
        feeding: false,
        shaving_oral_care: false,
        laundry: false,
        toilet: false,
        transfer: false,
        cleaning: false,
        routine_hair_skin_care: false,
        meal_preparation: false,
        escort: false,
        shopping: false,
        walking: false,
        ambulation: false,
        assistance_with_self_administered_medications: false,
        others: "",
      });
    }
  }, [responseError_]);

  const saveCaseInfoData = () => {
    let data = {
      ...caseInfoData,
      individual_id: authorizationData?.individual_id,
      auth_id: currentAuthId,
    };

    createCaseInfo(
      data,
      loginDetails?.user[0]?.user_id,
      loginDetails.token,
      setIs_Successful,
      setIs_loading
    );
  };

  useEffect(() => {
    if (is_Successful) setIsDisabled(false);
  }, [is_Successful]);

  const getTimeDifference = (inTime, outTime) => {
    if (!inTime?.trim() || !outTime?.trim()) return;

    const difference = moment.duration(
      moment(outTime, "HH:mm:ss a").diff(moment(inTime, "HH:mm:ss a"))
    );

    const hours = difference.hours();
    const minutes = difference.minutes();
    const seconds = difference.seconds();

    const result = `${hours} : ${minutes} : ${seconds}`;

    return moment(result, "HH:mm:ss").format("HH:mm:ss");
  };

  const TaskItem = ({ task, name, index, edit, label }) => {
    return (
      <FormControlLabel
        style={{ color: "white", fontWeight: "bold" }}
        control={
          <Checkbox
            {...label}
            style={{ color: "white", fontWeight: "bold" }}
            name={name}
            value={task == 0 ? false : true}
            checked={task == 0 ? false : true}
            onChange={(e) => edit && handleTaskChange(e, edit, name, index)}
          />
        }
        label={
          name === "Others" ? (
            "Others: (" + task + ")"
          ) : (
            <Typography
              variant="body2"
              color="white"
              fontWeight={"bold"}
              textTransform={"capitalize"}
            >
              {label ? label : name}
            </Typography>
          )
        }
      />
    );
  };

  const setCaseInfoSelected = (id) => {
    const result = caseInfosData.filter((item) => item?.id == id);
    setSelectedCaseInfo(result);
    setCaseInfoData(result[0]);
  };

  // this will get all the avaliable authorizations
  // under an individual
  const getAuthorization = () => {
    getAuthorizationsUnderIndividual(
      loginDetails?.user[0]?.user_id || loginDetails?.user[0]?.company_id,
      individual_id,
      loginDetails.token,
      setAuthorizationList
    );
  };

  useEffect(() => {
    if (individual_id) getAuthorization();
  }, [individual_id]);

  return (
    <Box mt={4}>
      <Box display={"flex"} justifyContent={"right"} mb={2}>
        <Button
          variant="contained"
          style={{ backgroundColor: colours.primary }}
          startIcon={<Add />}
          onClick={handleOpenForm}
        >
          Add Individual
        </Button>
      </Box>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            color: colours.primary,
          },
          "& .super-app-theme--header": {
            backgroundColor: colours.secondary,
          },
        }}
        rows={individualList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        // checkboxSelection
      />
      <FormDialog
        open={open}
        handleOpenForm={handleOpenForm}
        setIndividualList={setIndividualList}
      />
      <Modal
        open={open_}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" pl={2}>
            <Box
              justifyContent={"space-between"}
              flexDirection={"row"}
              display={"flex"}
              mt={2}
            >
              <Box>Authorization Details</Box>
              <Box>
                <Close
                  onClick={() => handleClose()}
                  style={{ cursor: "pointer", color: "red" }}
                />
              </Box>
            </Box>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: colours.secondary,
                }}
              >
                <Tabs
                  textColor="primary"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: colours.white,
                    },
                  }}
                >
                  <Tab label="Authorization" {...a11yProps(0)} />
                  {/* <Tab label="Billing Diagnosis Code" {...a11yProps(1)} /> */}
                  <Tab label="Weekly Calendar" {...a11yProps(1)} />
                  <Tab label="Tasks" {...a11yProps(2)} />
                  <Tab label="Case Information" {...a11yProps(3)} />
                  <Tab label="Complaint Log" {...a11yProps(4)} />
                  <Tab label="Comment" {...a11yProps(5)} />
                </Tabs>
              </Box>

              <Box>
                <CustomTabPanel value={value} index={0}>
                  <Box>
                    <Box sx={{ width: "100%" }}>
                      <Box
                        style={{
                          backgroundColor: colours.borderColour,
                        }}
                      >
                        <Tabs
                          textColor="secondary"
                          indicatorColor="primary"
                          value={valueSub}
                          onChange={handleChangeSub}
                          aria-label="basics tabsd exampleb"
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: colours.primary,
                            },
                          }}
                        >
                          <Tab label="Service Status" {...a11yProps2(0)} />
                          <Tab
                            label="Service Initiation Checklist"
                            {...a11yProps2(1)}
                          />
                          <Tab label="EVV" {...a11yProps2(2)} />
                          <Tab label="Billing" {...a11yProps2(3)} />
                        </Tabs>
                      </Box>
                    </Box>

                    <CustomTabPanel2 value={valueSub} index={0}>
                      <Box
                        flexDirection={"row"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"space-evenly"}
                      >
                        <Box width={"20%"}>
                          <Box mb={-1}>Assessment Date</Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={authorizationData?.assessment_date}
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      assessment_date: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  label="Assessment Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box mt={1.5} mb={-1} style={styles.inputLabel}>
                            Service Start Date
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={authorizationData?.service_start_date}
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      service_start_date: newValue,
                                    })
                                  }
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                    },
                                  }}
                                  label="Service Start Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box mt={1.5} mb={-1} style={styles.inputLabel}>
                            Service End Date
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={authorizationData?.service_end_date}
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      service_end_date: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  style={{ width: "100%" }}
                                  label="Service End Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box mt={1.5} mb={-1} style={styles.inputLabel}>
                            Last Sup Visit Date
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={
                                    authorizationData?.last_spervisor_visit_date
                                  }
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      last_spervisor_visit_date: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  label="Last Sup Visit Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box>
                            <Box mt={1.5}>Supervisory Visit Frequency</Box>
                            <TextField
                              size="small"
                              id="sup_visit_freq"
                              label="Sup Visit Frequency"
                              variant="outlined"
                              value={authorizationData?.sup_visit_freq}
                              onChange={handleAuth}
                              name="sup_visit_freq"
                              sx={{ width: 220 }}
                            />
                          </Box>
                        </Box>

                        <Box width={"20%"}>
                          <Box style={styles.inputLabel}>Service Type</Box>
                          <Box>
                            <Autocomplete
                              value={authorizationData?.service_type}
                              onChange={(event, newValue) => {
                                setAuthorizationData({
                                  ...authorizationData,
                                  service_type: newValue?.program,
                                  procedure_code_qualifier:
                                    newValue?.procedure_code_qualifier,
                                  service_group: newValue?.service_group,
                                  service_code: newValue?.service_code,
                                  service_code_description:
                                    newValue?.description,
                                  hcpcs: newValue?.HCPCS,
                                  bill_code: newValue?.bill_code,
                                  modifier_1: newValue?.modifier_1,
                                  modifier_2: newValue?.modifier_2,
                                  modifier_3: newValue?.modifier_3,
                                  modifier_4: newValue?.modifier_4,
                                });
                              }}
                              size="small"
                              style={{ width: "100%" }}
                              disablePortal
                              id="service_type"
                              options={serviceTypeData}
                              getOptionLabel={(option) =>
                                option?.service_code
                                  ? `${option?.service_group} - ${option?.service_code} - ${option?.description} `
                                  : "Select"
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => {
                                return (
                                  <TextField {...params} label="Service type" />
                                );
                              }}
                            />
                          </Box>
                          <Box mt={1.5}>Status</Box>
                          <Box>
                            <FormControl fullWidth size="small">
                              <InputLabel id="status">Status</InputLabel>
                              <Select
                                labelId="status"
                                id="status"
                                value={authorizationData?.status || "Select"}
                                label="Status"
                                onChange={handleAuth}
                                defaultValue={"Select"}
                                name="status"
                              >
                                <MenuItem value={"Active"}>Active</MenuItem>
                                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                                <MenuItem value={"Onhold"}>Onhold</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box mt={1.5} style={styles.inputLabel}>
                            Priority
                          </Box>
                          <Box>
                            <FormControl fullWidth size="small">
                              <InputLabel id="priority">Priority</InputLabel>
                              <Select
                                labelId="priority"
                                id="priority"
                                value={authorizationData?.priority || "Select"}
                                label="Priority"
                                onChange={handleAuth}
                                defaultValue={"Select"}
                                name="priority"
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box mt={1.5} style={styles.inputLabel}>
                            Discharge Reason
                          </Box>
                          <Box>
                            <FormControl fullWidth size="small">
                              <InputLabel id="discharge_reason">
                                Discharge Reason
                              </InputLabel>
                              <Select
                                placeholder="Discharge Reason"
                                labelId="discharge_reason"
                                id="discharge_reason"
                                value={
                                  authorizationData?.discharge_reason ||
                                  "Select"
                                }
                                label="Discharge Reason"
                                onChange={handleAuth}
                                defaultValue={"Select"}
                                name="discharge_reason"
                              >
                                <MenuItem value={7 + " - CBA"}>CBA</MenuItem>
                                <MenuItem value={6 + " - Deseased"}>
                                  Deseased
                                </MenuItem>
                                <MenuItem value={13 + " - Declined Service"}>
                                  Declined Service
                                </MenuItem>
                                <MenuItem value={16 + " - Discharged"}>
                                  Discharged
                                </MenuItem>
                                <MenuItem value={17 + " - Discontinued"}>
                                  Discontinued
                                </MenuItem>
                                <MenuItem value={12 + " - HMO"}>HMO</MenuItem>
                                <MenuItem value={4 + " - Lost Eligibility"}>
                                  Lost Eligibility
                                </MenuItem>
                                <MenuItem value={14 + " - NH"}>NH</MenuItem>
                                <MenuItem value={9 + " - Not Eligible"}>
                                  Not Eligible
                                </MenuItem>
                                <MenuItem value={3 + " - Nursing Home"}>
                                  Nursing Home
                                </MenuItem>
                                <MenuItem value={5 + " - Other Agency"}>
                                  Other Agency
                                </MenuItem>
                                <MenuItem value={2 + " - Other Program"}>
                                  Other Program
                                </MenuItem>
                                <MenuItem value={11 + " - Relocated"}>
                                  Relocated
                                </MenuItem>
                                <MenuItem value={10 + " - Terminated"}>
                                  Terminated
                                </MenuItem>
                                <MenuItem value={8 + " - Transferred"}>
                                  Transferred
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box mt={1.5} style={styles.inputLabel}>
                            Case Worker
                          </Box>
                          <Box>
                            <Autocomplete // defaultValue={""}
                              value={authorizationData?.case_worker}
                              onChange={(event, newValue) => {
                                setAuthorizationData({
                                  ...authorizationData,
                                  case_worker: `${newValue?.firstname} - ${newValue?.lastname}`,
                                });
                              }}
                              size="small"
                              style={{ width: "100%" }}
                              disablePortal
                              id="case_worker"
                              options={caseworkers}
                              getOptionLabel={(option) =>
                                option?.firstname
                                  ? `${option?.firstname} ${option?.lastname}`
                                  : "Select"
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => {
                                return (
                                  <TextField {...params} label="Caseworker" />
                                );
                              }}
                            />
                          </Box>
                        </Box>

                        <Box width={"20%"}>
                          <Box mb={-1} style={styles.inputLabel}>
                            Authorization Start Date
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={
                                    authorizationData?.authorization_start_date
                                  }
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      authorization_start_date: newValue,
                                    })
                                  }
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                    },
                                  }}
                                  label="Service Start Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box mt={1.5} mb={-1} style={styles.inputLabel}>
                            Authorization End Date
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={
                                    authorizationData?.authorization_end_date
                                  }
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      authorization_end_date: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  // style={{ width: "100%" }}
                                  label="Authorization End Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box mt={1.5}>Doctor</Box>
                          <Box>
                            <Autocomplete
                              // defaultValue={""}
                              value={authorizationData?.doctor}
                              onChange={(event, newValue) => {
                                setAuthorizationData({
                                  ...authorizationData,
                                  doctor: `${newValue?.firstname} - ${newValue?.lastname}`,
                                });
                              }}
                              size="small"
                              style={{ width: 220 }}
                              // disablePortal={false}
                              id="doctor"
                              options={doctors}
                              getOptionLabel={(option) =>
                                option?.firstname
                                  ? `${option?.firstname} ${option?.lastname}`
                                  : "Select"
                              }
                              // sx={{ width: 300 }}
                              renderInput={(params) => {
                                return <TextField {...params} label="Doctor" />;
                              }}
                            />
                          </Box>
                          <Box mt={1.5}>Authorization Ref. number</Box>
                          <TextField
                            size="small"
                            style={{ width: 220 }}
                            id="authorization_ref_number"
                            label="Authorization Ref. number"
                            variant="outlined"
                            value={authorizationData?.authorization_ref_number}
                            onChange={handleAuth}
                            name="authorization_ref_number"
                          />
                          <Box mt={1.5}>Units</Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <TextField
                              size="small"
                              style={{ width: "31%" }}
                              id="hour"
                              label="Hour(s)"
                              variant="outlined"
                              value={authorizationData?.hour}
                              onChange={handleAuth}
                              name="hour"
                            />
                            <TextField
                              size="small"
                              style={{ width: "31%" }}
                              id="minutes"
                              label="Minute(s)"
                              variant="outlined"
                              value={authorizationData?.minutes}
                              onChange={handleAuth}
                              name="minutes"
                            />
                            <TextField
                              size="small"
                              style={{ width: "31%" }}
                              id="days"
                              label="Days"
                              variant="outlined"
                              value={authorizationData?.days}
                              onChange={handleAuth}
                              name="days"
                            />
                          </Box>
                        </Box>

                        <Box width={"20%"}>
                          <Box
                            // mt={1.5}
                            component="fieldset"
                            style={{ borderColor: "lightgrey", borderWidth: 1 }}
                          >
                            <legend>Billing Diagnosis Code </legend>
                            <Box mb={2}>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  authorizationData?.billing_diagnosis_code_1
                                }
                                onChange={(event, newValue) => {
                                  setAuthorizationData({
                                    ...authorizationData,
                                    billing_diagnosis_code_1: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_1"
                                options={diagnosisCodes}
                                getOptionLabel={(option) =>
                                  option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : "Select"
                                }
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                            <Box mb={2}>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  authorizationData?.billing_diagnosis_code_2
                                }
                                onChange={(event, newValue) => {
                                  setAuthorizationData({
                                    ...authorizationData,
                                    billing_diagnosis_code_2: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_2"
                                options={diagnosisCodes}
                                getOptionLabel={(option) =>
                                  option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : "Select"
                                }
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                            <Box mb={2}>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  authorizationData?.billing_diagnosis_code_3
                                }
                                onChange={(event, newValue) => {
                                  setAuthorizationData({
                                    ...authorizationData,
                                    billing_diagnosis_code_3: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_3"
                                options={diagnosisCodes}
                                getOptionLabel={(option) =>
                                  option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : "Select"
                                }
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                            <Box>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  authorizationData?.billing_diagnosis_code_4
                                }
                                onChange={(event, newValue) => {
                                  setAuthorizationData({
                                    ...authorizationData,
                                    billing_diagnosis_code_4: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_4"
                                options={diagnosisCodes}
                                getOptionLabel={(option) =>
                                  option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : "Select"
                                }
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  console.log(params?.id, "--params");
                                  return (
                                    <TextField
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                          </Box>

                          <Box mt={1.5}>Supplies/Equipment</Box>
                          <Box>
                            <TextField
                              size="small"
                              style={{ width: "100%" }}
                              id="supplies_equipment"
                              label="Supplies/Equipment"
                              variant="outlined"
                              value={authorizationData?.supplies_equipment}
                              onChange={handleAuth}
                              name="supplies_equipment"
                            />
                          </Box>
                        </Box>
                      </Box>

                      <Box mt={3}>
                        <Box
                          component="fieldset"
                          style={{
                            borderColor: colours.primary,
                            borderWidth: 3,
                          }}
                        >
                          <legend>Service Details</legend>
                          <Box display={"flex"} justifyContent={"space-evenly"}>
                            <Box>
                              <Box>Service group</Box>
                              <TextField
                                size="small"
                                // style={{ width: "100%" }}
                                id="service_group"
                                placeholder="Service group"
                                variant="outlined"
                                value={authorizationData?.service_group}
                                onChange={() => console.log("Do nothing")}
                                name="service_group"
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>Service code</Box>
                              <TextField
                                size="small"
                                // style={{ width: "100%" }}
                                id="service_code"
                                placeholder="Service code"
                                variant="outlined"
                                value={authorizationData?.service_code}
                                onChange={() => console.log("Do nothing")}
                                name="service_code"
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>Service code description </Box>
                              <TextField
                                size="small"
                                // style={{ width: "100%" }}
                                id="service_decsription"
                                placeholder="Service code description"
                                variant="outlined"
                                value={
                                  authorizationData?.service_code_description
                                }
                                onChange={() => console.log("Do nothing")}
                                name="service_decsription"
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>HCPCS</Box>
                              <TextField
                                size="small"
                                // style={{ width: "100%" }}
                                id="hcpcs"
                                placeholder="HCPCS"
                                variant="outlined"
                                value={authorizationData?.hcpcs}
                                onChange={() => console.log("Do nothing")}
                                name="hcpcs"
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>Modifiers</Box>
                              <Box display={"flex"} flexDirection={"row"}>
                                <Box width={64} mr={1}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_1"
                                    placeholder="M-1"
                                    variant="outlined"
                                    value={authorizationData?.modifier_1}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_1"
                                  />
                                </Box>
                                <Box width={64} mr={1}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_2"
                                    placeholder="M-2"
                                    variant="outlined"
                                    value={authorizationData?.modifier_2}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_2"
                                  />
                                </Box>
                                <Box width={64} mr={1}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_3"
                                    placeholder="M-3"
                                    variant="outlined"
                                    value={authorizationData?.modifier_3}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_3"
                                  />
                                </Box>
                                <Box width={64}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_4"
                                    placeholder="M-4"
                                    variant="outlined"
                                    value={authorizationData?.modifier_4}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_4"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </CustomTabPanel2>
                    <CustomTabPanel2 value={valueSub} index={1}>
                      <Box
                        flexDirection={"row"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Box width={"25%"}>
                          <Box mb={-1} style={styles.inputLabel}>
                            Authorization Received Date
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={
                                    authorizationData?.authorization_received_date
                                  }
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      authorization_received_date: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  style={{ width: "100%" }}
                                  label="Authorization Received Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box mt={3} mb={-1} style={styles.inputLabel}>
                            Practitioner Statement Sent
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={
                                    authorizationData?.practioner_statement_sent
                                  }
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      practioner_statement_sent: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  style={{ width: "100%" }}
                                  label="Practitioner Statement Sent"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>

                          <Box mt={3} mb={-1} style={styles.inputLabel}>
                            Prac. Statement Received
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={
                                    authorizationData?.practioner_statement_received
                                  }
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      practioner_statement_received: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  style={{ width: "100%" }}
                                  label="Practitioner Statement Received"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                        </Box>
                        <Box width={"25%"}>
                          <Box style={styles.inputLabel}>Liason</Box>
                          <TextField
                            size="small"
                            style={{ width: 220 }}
                            id="liason"
                            label="Liason"
                            variant="outlined"
                            value={authorizationData?.liason}
                            onChange={handleAuth}
                            name="liason"
                          />
                          <Box mt={3} mb={-1} style={styles.inputLabel}>
                            Service Initial Reported to CW
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={
                                    authorizationData?.service_initial_reported_cw
                                  }
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      service_initial_reported_cw: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  style={{ width: "100%" }}
                                  label="Service Initial Reported to CW"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                          <Box mt={3} mb={-1}>
                            Assessment Date
                          </Box>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  value={authorizationData?.assessment_date}
                                  onChange={(newValue) =>
                                    setAuthorizationData({
                                      ...authorizationData,
                                      assessment_date: newValue,
                                    })
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  label="Assessment Date"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Box>
                        </Box>
                      </Box>
                    </CustomTabPanel2>
                    <CustomTabPanel2 value={valueSub} index={2}>
                      <Box display={"flex"} justifyContent={"center"}>
                        <Box mr={6}>
                          <Box style={styles.inputLabel}>
                            EVV Authorization ID
                          </Box>
                          <Box>
                            <TextField
                              size="small"
                              style={{ width: 220 }}
                              id="evv_authorization_id"
                              label="EVV Authorization ID"
                              variant="outlined"
                              value={authorizationData?.evv_authorization_id}
                              onChange={handleAuth}
                              name="evv_authorization_id"
                            />
                          </Box>
                          <Box mt={3} style={styles.inputLabel}>
                            Placement ID
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: 220 }}
                              size="small"
                              id="placement_id"
                              label="Placement ID"
                              variant="outlined"
                              value={authorizationData?.placement_id}
                              onChange={handleAuth}
                              name="placement_id"
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Box style={styles.inputLabel}>EVV Priority</Box>
                          <Box>
                            <FormControl fullWidth size="small">
                              <InputLabel id="priority">
                                EVV Priority
                              </InputLabel>
                              <Select
                                style={{ width: 220 }}
                                labelId="evv_priority"
                                id="evv_priority"
                                value={
                                  authorizationData?.evv_priority || "Select"
                                }
                                label="EVV Priority"
                                onChange={handleAuth}
                                defaultValue={"Select"}
                                name="evv_priority"
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box mt={3} style={styles.inputLabel}>
                            Land Phone
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: 220 }}
                              size="small"
                              id="land_phone"
                              label="Land Phone"
                              variant="outlined"
                              value={authorizationData?.land_phone}
                              onChange={handleAuth}
                              name="land_phone"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </CustomTabPanel2>
                    <CustomTabPanel2 value={valueSub} index={3}>
                      <Box display={"flex"} justifyContent={"center"}>
                        <Box>
                          <Box style={styles.inputLabel}>
                            Place of Service ID
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="place_service_id"
                              placeholder="Place of Service ID"
                              variant="outlined"
                              value={authorizationData?.place_service_id}
                              onChange={handleAuth}
                              name="place_service_id"
                            />
                          </Box>
                          <Box style={styles.inputLabel} mt={3}>
                            Authorization Number
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="authorization_number"
                              placeholder="Authorization Number"
                              variant="outlined"
                              value={authorizationData?.authorization_number}
                              onChange={handleAuth}
                              name="authorization_number"
                            />
                          </Box>
                          <Box style={styles.inputLabel} mt={3}>
                            Unit Rate
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="unit_rate"
                              placeholder="Unit Rate"
                              variant="outlined"
                              value={authorizationData?.unit_rate}
                              onChange={handleAuth}
                              name="unit_rate"
                            />
                          </Box>
                        </Box>
                        <Box ml={6}>
                          <Box style={styles.inputLabel}>
                            Assignment of Benefits Indicator
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="assignment_benefits_indicator"
                              placeholder="Assignment of Benefits Indicator"
                              variant="outlined"
                              value={
                                authorizationData?.assignment_benefits_indicator
                              }
                              onChange={handleAuth}
                              name="assignment_benefits_indicator"
                            />
                          </Box>
                          <Box style={styles.inputLabel} mt={3}>
                            Release of Information Code
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="release_information_code"
                              placeholder="Release of Information Code"
                              variant="outlined"
                              value={
                                authorizationData?.release_information_code
                              }
                              onChange={handleAuth}
                              name="release_information_code"
                            />
                          </Box>
                          <Box style={styles.inputLabel} mt={3}>
                            Patient Signature Code
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="patient_signature_code"
                              placeholder="Patient Signature Code"
                              variant="outlined"
                              value={authorizationData?.patient_signature_code}
                              onChange={handleAuth}
                              name="patient_signature_code"
                            />
                          </Box>
                        </Box>

                        <Box ml={6}>
                          <Box style={styles.inputLabel}>
                            Provider Signature on File
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="provider_signature_file"
                              placeholder="Provider Signature on File"
                              variant="outlined"
                              value={authorizationData?.provider_signature_file}
                              onChange={handleAuth}
                              name="provider_signature_file"
                            />
                          </Box>
                          <Box style={styles.inputLabel} mt={3}>
                            Medicare Assignment Code
                          </Box>
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              id="medicare_assignment_code"
                              placeholder="Medicare Assignment Code"
                              variant="outlined"
                              value={
                                authorizationData?.medicare_assignment_code
                              }
                              onChange={handleAuth}
                              name="medicare_assignment_code"
                            />
                          </Box>

                          <Box style={styles.inputLabel} mt={3}>
                            Claim Frequency Type Code
                          </Box>
                          <Box>
                            <Box>
                              <FormControl fullWidth size="small">
                                <InputLabel id="claim_frequency_type_code">
                                  Claim Frequency Type Code
                                </InputLabel>
                                <Select
                                  labelId="claim_frequency_type_code"
                                  id="claim_frequency_type_code"
                                  value={
                                    authorizationData?.claim_frequency_type_code ||
                                    "Select"
                                  }
                                  label="Claim Frequency Type Code"
                                  onChange={handleAuth}
                                  defaultValue={"Select"}
                                  name="claim_frequency_type_code"
                                >
                                  <MenuItem value={1}>Original - 1</MenuItem>
                                  <MenuItem value={7}>Corrected - 7</MenuItem>
                                  <MenuItem value={8}>Voided - 8</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      {requestStatus === "success" && (
                        <Box display={"flex"} justifyContent={"center"} mt={2}>
                          <Alert severity="success">
                            New Authorization Created Successfully.
                          </Alert>
                        </Box>
                      )}
                      {requestStatus === "error" && (
                        <Box display={"flex"} justifyContent={"center"} mt={2}>
                          <Alert severity="error">
                            An error occurred. Please review form
                          </Alert>
                        </Box>
                      )}

                      <Box display={"flex"} justifyContent={"center"} mt={4}>
                        <Button
                          onClick={() => handleAuthorization()}
                          variant="contained"
                          style={{
                            backgroundColor: "rgb(87, 37, 68)",
                            alignSelf: "center",
                          }}
                        >
                          {" "}
                          {isLoading ? "Please wait..." : "Save Authorization"}
                        </Button>
                      </Box>
                    </CustomTabPanel2>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box
                        // justifyContent={"center"}
                        display={"flex"}
                        // mt={1}
                        // border={1}
                        // borderColor={colours.borderColour}
                        // mb={2}
                      >
                        Total Hours:{" "}
                        <label
                          style={{
                            color: "dodgerblue",
                            fontWeight: "bold",
                            fontSize: 16,
                            marginLeft: 10,
                          }}
                        >
                          {" "}
                          {totalTimes !== "Invalid date" && totalTimes}
                        </label>
                      </Box>
                      <Box>
                        {/* <Box>
                          <Box mb={1.5}>Select Authorization</Box>
                          <Autocomplete
                            // defaultValue={""}
                            // value={selectedAuthID}
                            onChange={(event, newValue) => {
                              setSelectedAuthID(newValue?.id);
                            }}
                            size="small"
                            style={{ width: 400 }}
                            disablePortal
                            id="authorzation_id"
                            options={authorizationList}
                            getOptionLabel={(option) =>
                              option?.id
                                ? `${option?.service_code} - ${
                                    option?.service_type
                                  } - ${moment(
                                    option?.service_start_date
                                  ).format("MM-DD-YYYY")} => ${moment(
                                    option?.service_end_date
                                  ).format("MM-DD-YYYY")} `
                                : "Select"
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => {
                              return (
                                <TextField {...params} label="Authorizations" />
                              );
                            }}
                          />
                        </Box> */}
                      </Box>
                      <Box
                        justifyContent={"flex-end"}
                        display={"flex"}
                        // mt={-1}
                        // mb={2}
                      >
                        {weeklyCalendar > 1 && (
                          <Button
                            style={{ backgroundColor: colours.primary }}
                            startIcon={<Remove />}
                            variant="contained"
                            onClick={() =>
                              setWeeklyCalendar(weeklyCalendar - 1)
                            }
                          >
                            REMOVE
                          </Button>
                        )}

                        <Box width={20} />
                        <Button
                          style={{ backgroundColor: colours.primary }}
                          startIcon={<Add />}
                          variant="contained"
                          onClick={() => setWeeklyCalendar(weeklyCalendar + 1)}
                        >
                          ADD more
                        </Button>
                      </Box>
                    </Box>

                    {[...Array(weeklyCalendar).keys()].map((key, index) => {
                      return (
                        <Box mb={2}>
                          <Box
                            borderBottom={1}
                            borderColor={colours.borderColour}
                            flexDirection={"row"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={5}
                          >
                            <Box>
                              <Box
                                bgcolor={"dodgerblue"}
                                borderRadius={100}
                                height={30}
                                width={30}
                                justifyContent={"center"}
                                alignItems={"center"}
                                display={"flex"}
                              >
                                {index + 1}
                              </Box>
                              <Box display={"flex"} flexDirection={"row"}>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    In/Out
                                  </Box>
                                  <Box paddingY={1}>In</Box>
                                  <Box marginY={1}>Out</Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    SUN
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      name={"timeSundayIn"}
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "sunday",
                                          "in"
                                        )
                                      }
                                      value={
                                        multipleData[key]?.sunday?.in
                                          ? multipleData[key]["sunday"]["in"]
                                          : ""
                                      }
                                    />
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.sunday?.out
                                          ? multipleData[key]["sunday"]["out"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "sunday",
                                          "out"
                                        )
                                      }
                                      name={"timeSundayOut"}
                                    />
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    MON
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.monday?.in
                                          ? multipleData[key]["monday"]["in"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "monday",
                                          "in"
                                        )
                                      }
                                      name={"timeMondayIn"}
                                    />
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "monday",
                                          "out"
                                        )
                                      }
                                      name={"timeMondayOut"}
                                      value={
                                        multipleData[key]?.monday?.out
                                          ? multipleData[key]["monday"]["out"]
                                          : ""
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    TUE
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.tuesday?.in
                                          ? multipleData[key]["tuesday"]["in"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "tuesday",
                                          "in"
                                        )
                                      }
                                      name={"timeTuesdayIn"}
                                    />
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.tuesday?.out
                                          ? multipleData[key]["tuesday"]["out"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "tuesday",
                                          "out"
                                        )
                                      }
                                      name={"timeTuesdayOut"}
                                    />
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    WED
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.wednesday?.in
                                          ? multipleData[key]["wednesday"]["in"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "wednesday",
                                          "in"
                                        )
                                      }
                                      name={"timeWednesdayIn"}
                                    />
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.wednesday?.out
                                          ? multipleData[key]["wednesday"][
                                              "out"
                                            ]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "wednesday",
                                          "out"
                                        )
                                      }
                                      name={"timeWednesdayOut"}
                                    />
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    THU
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.thursday?.in
                                          ? multipleData[key]["thursday"]["in"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "thursday",
                                          "in"
                                        )
                                      }
                                      name={"timeThursdayIn"}
                                    />
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.thursday?.out
                                          ? multipleData[key]["thursday"]["out"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "thursday",
                                          "out"
                                        )
                                      }
                                      name={"timeThursdayOut"}
                                    />
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    FRI
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.friday?.in
                                          ? multipleData[key]["friday"]["in"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "friday",
                                          "in"
                                        )
                                      }
                                      name={"timeFridayIn"}
                                    />
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.friday?.out
                                          ? multipleData[key]["friday"]["out"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "friday",
                                          "out"
                                        )
                                      }
                                      name={"timeFridayOut"}
                                    />
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={"white"}
                                    bgcolor={colours.primary}
                                  >
                                    SAT
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.saturday?.in
                                          ? multipleData[key]["saturday"]["in"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "saturday",
                                          "in"
                                        )
                                      }
                                      name={"timeSaturdayIn"}
                                    />
                                  </Box>
                                  <Box paddingY={1}>
                                    <TextField
                                      value={
                                        multipleData[key]?.saturday?.out
                                          ? multipleData[key]["saturday"]["out"]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }} // the change is here
                                      style={{
                                        width: 63,
                                      }}
                                      size="small"
                                      label=""
                                      variant="standard"
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "saturday",
                                          "out"
                                        )
                                      }
                                      name={"timeSaturdayOut"}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                paddingBottom={2}
                                mt={1}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    color={colours.primary}
                                    bgcolor={colours.borderColour}
                                  >
                                    In/Out
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {" "}
                                    Total Hours
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    bgcolor={colours.borderColour}
                                    color={colours.primary}
                                  >
                                    SUN
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {multipleData[key]?.sunday?.total !==
                                      "Invalid date" &&
                                      multipleData[key]?.sunday?.in &&
                                      multipleData[key]?.sunday?.out &&
                                      multipleData[key]?.sunday?.total}
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    bgcolor={colours.borderColour}
                                    color={colours.primary}
                                  >
                                    MON
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {multipleData[key]?.monday?.total !==
                                      "Invalid date" &&
                                      multipleData[key]?.monday?.in &&
                                      multipleData[key]?.monday?.out &&
                                      multipleData[key]?.monday?.total}
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    bgcolor={colours.borderColour}
                                    color={colours.primary}
                                  >
                                    TUE
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {multipleData[key]?.tuesday?.total !==
                                      "Invalid date" &&
                                      multipleData[key]?.tuesday?.in &&
                                      multipleData[key]?.tuesday?.out &&
                                      multipleData[key]?.tuesday?.total}
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    bgcolor={colours.borderColour}
                                    color={colours.primary}
                                  >
                                    WED
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {multipleData[key]?.wednesday?.total !==
                                      "Invalid date" &&
                                      multipleData[key]?.wednesday?.in &&
                                      multipleData[key]?.wednesday?.out &&
                                      multipleData[key]?.wednesday?.total}
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    bgcolor={colours.borderColour}
                                    color={colours.primary}
                                  >
                                    THU
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {multipleData[key]?.thursday?.total !==
                                      "Invalid date" &&
                                      multipleData[key]?.thursday?.in &&
                                      multipleData[key]?.thursday?.out &&
                                      multipleData[key]?.thursday?.total}
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    bgcolor={colours.borderColour}
                                    color={colours.primary}
                                  >
                                    FRI
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {multipleData[key]?.friday?.total !==
                                      "Invalid date" &&
                                      multipleData[key]?.friday?.in &&
                                      multipleData[key]?.friday?.out &&
                                      multipleData[key]?.friday?.total}
                                  </Box>
                                </Box>
                                <Box textAlign={"center"}>
                                  <Box
                                    paddingX={5}
                                    paddingY={1}
                                    textAlign={"center"}
                                    bgcolor={colours.borderColour}
                                    color={colours.primary}
                                  >
                                    SAT
                                  </Box>
                                  <Box
                                    border={1}
                                    borderColor={"lightgrey"}
                                    paddingY={1}
                                  >
                                    {multipleData[key]?.saturday?.total !==
                                      "Invalid date" &&
                                      multipleData[key]?.saturday?.in &&
                                      multipleData[key]?.saturday?.out &&
                                      multipleData[key]?.saturday?.total}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Box mb={1}>Status</Box>
                              <Box>
                                <FormControl fullWidth size="small">
                                  <InputLabel id="discharge_reason">
                                    Status
                                  </InputLabel>
                                  <Select
                                    placeholder="Status"
                                    labelId="status"
                                    id="status"
                                    value={
                                      multipleData[key]?.status || "Select"
                                    }
                                    label="Status"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "nothing",
                                        "status"
                                      )
                                    }
                                    defaultValue={"Select"}
                                    name="status"
                                    // style={{ width: "83%" }}
                                  >
                                    <MenuItem value={"active"}>Active</MenuItem>
                                    <MenuItem value={"inactive"}>
                                      Inactive
                                    </MenuItem>
                                    <MenuItem value={"onhold"}>Onhold</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                              <Box mt={3}>Schedule Start Date</Box>
                              <Box>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "nothing",
                                          "startDate"
                                        )
                                      }
                                      value={multipleData[key]?.startDate}
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          size: "small",
                                        },
                                      }}
                                      label="Schedule Start Date"
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Box>
                              <Box mt={3}>Schedule End Date</Box>
                              <Box>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      onChange={(e) =>
                                        handleMultiInputs(
                                          e,
                                          key,
                                          "nothing",
                                          "endDate"
                                        )
                                      }
                                      value={multipleData[key]?.endDate}
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          size: "small",
                                        },
                                      }}
                                      label="Schedule End Date"
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box>
                    {responseError && (
                      <Box display={"flex"} justifyContent={"center"} mt={2}>
                        {responseError === "success" ? (
                          <Alert severity="success">
                            New Schedule Created Successfully!
                          </Alert>
                        ) : (
                          <Alert severity="error">
                            Schedule submission failed!
                          </Alert>
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box
                    justifyContent={"center"}
                    display={"flex"}
                    mt={4}
                    // mr={9}
                    mb={2}
                  >
                    <Button
                      onClick={() => saveSchedule()}
                      variant="contained"
                      style={{ backgroundColor: "rgb(87, 37, 68)" }}
                    >
                      {isLoadingCalendar ? "Please wait..." : "Save Schedule"}
                    </Button>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Box>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="bathing"
                                value={tasksData?.bathing}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.bathing}
                              />
                            }
                            label="Bathing"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="dressing"
                                value={tasksData?.dressing}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.dressing}
                              />
                            }
                            label="Dressing"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="excercising"
                                value={tasksData?.excercising}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.excercising}
                              />
                            }
                            label="Excercising"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="feeding"
                                value={tasksData?.feeding}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.feeding}
                              />
                            }
                            label="Feeding"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="shaving_oral_care"
                                value={tasksData?.shaving_oral_care}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.shaving_oral_care}
                              />
                            }
                            label="Shaving/Oral Care"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="laundry"
                                value={tasksData?.laundry}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.laundry}
                              />
                            }
                            label="Laundry"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="toilet"
                                value={tasksData?.toilet}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.toilet}
                              />
                            }
                            label="Toilet"
                          />
                        </FormGroup>
                      </Box>
                      <Box ml={4}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="transfer"
                                value={tasksData?.transfer}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.transfer}
                              />
                            }
                            label="Transfer"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="cleaning"
                                value={tasksData?.cleaning}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.cleaning}
                              />
                            }
                            label="Cleaning"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="routine_hair_skin_care"
                                value={tasksData?.routine_hair_skin_care}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.routine_hair_skin_care}
                              />
                            }
                            label="Routine Hair Skin Care"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="meal_preparation"
                                value={tasksData?.meal_preparation}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.meal_preparation}
                              />
                            }
                            label="Meal Preparation"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="escort"
                                value={tasksData?.escort}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.escort}
                              />
                            }
                            label="Escort"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="shopping"
                                value={tasksData?.shopping}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.shopping}
                              />
                            }
                            label="Shopping"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="walking"
                                value={tasksData?.walking}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.walking}
                              />
                            }
                            label="Walking"
                          />
                        </FormGroup>
                      </Box>
                      <Box ml={4}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="ambulation"
                                value={tasksData?.ambulation}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.ambulation}
                              />
                            }
                            label="Ambulation"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="assistance_with_self_administered_medications"
                                value={
                                  tasksData?.assistance_with_self_administered_medications
                                }
                                onChange={(e) => handleTaskChange(e)}
                                checked={
                                  tasksData?.assistance_with_self_administered_medications
                                }
                              />
                            }
                            label="Assistance with Self-Administered Medications"
                          />
                          {/* <FormControlLabel
                            control={
                              <Checkbox
                                {...label}
                                name="others"
                                value={tasksData?.others}
                                onChange={(e) => handleTaskChange(e)}
                                checked={tasksData?.others}
                              />
                            }
                            label="Others (Specify)"
                          /> */}
                          <Box>Others (Specify)</Box>
                          <TextField
                            size="small"
                            style={{ width: "100%" }}
                            id="others"
                            label="others"
                            variant="outlined"
                            value={tasksData?.others}
                            onChange={(e) => handleTaskChange(e)}
                            name="others"
                            multiline
                            rows={5}
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Box>
                  {responseError_ && (
                    <Box display={"flex"} justifyContent={"center"} mt={2}>
                      {responseError_ === "success" ? (
                        <Alert severity="success">
                          New Task Created Successfully!
                        </Alert>
                      ) : (
                        <Alert severity="error">
                          Task(s) submission failed!
                        </Alert>
                      )}
                    </Box>
                  )}
                  <Box
                    justifyContent={"center"}
                    display={"flex"}
                    mt={4}
                    // mr={9}
                    mb={2}
                  >
                    <Button
                      onClick={() => saveTasks()}
                      variant="contained"
                      style={{ backgroundColor: "rgb(87, 37, 68)" }}
                    >
                      {isLoadingTask ? "Please wait..." : "Save Tasks"}
                    </Button>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <Box
                    bgcolor={"#d3d3d3"}
                    p={1}
                    height={"100%"}
                    width={"100%"}
                    borderRadius={5}
                  >
                    <Box bgcolor={"white"}>
                      <Box justifyContent={"center"} display={"flex"} pt={2}>
                        {is_Successful && (
                          <Alert severity="success">
                            Case Information Form Saved Successfully!
                          </Alert>
                        )}
                      </Box>

                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        mr={9}
                        ml={9}
                        p={1}
                      >
                        <Box>
                          <Box mb={1}>Caseworker</Box>
                          <Box>
                            <Autocomplete // defaultValue={""}
                              value={caseWorkForm?.case_worker}
                              onChange={(event, newValue) => {
                                setSelectedCaseWorker(newValue);
                                setCaseWorkForm({
                                  ...caseWorkForm,
                                  case_worker: `${newValue?.firstname} - ${newValue?.lastname}`,
                                });
                              }}
                              size="small"
                              // style={{ width: "100 }}
                              disablePortal
                              id="case_worker"
                              options={caseworkers}
                              getOptionLabel={(option) =>
                                option?.firstname
                                  ? `${option?.firstname} ${option?.lastname}`
                                  : "Select"
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => {
                                return (
                                  <TextField {...params} label="Caseworker" />
                                );
                              }}
                            />
                          </Box>
                        </Box>

                        <Box ml={2}>
                          <Box mb={1}>Employee</Box>
                          <Box>
                            <Autocomplete // defaultValue={""}
                              value={caseWorkForm?.employee}
                              onChange={(event, newValue) => {
                                console.log(newValue, "----New value");
                                setSelectedEmployee(newValue);
                                setCaseWorkForm({
                                  ...caseWorkForm,
                                  employee: `${newValue?.firstname} - ${newValue?.lastname}`,
                                });
                              }}
                              size="small"
                              // style={{ width: "100 }}
                              // disablePortal
                              id="employee"
                              options={employees}
                              getOptionLabel={(option) =>
                                option?.firstname
                                  ? `${option?.firstname} ${option?.lastname}`
                                  : "Select"
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => {
                                return (
                                  <TextField {...params} label="Employee" />
                                );
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box pb={1} ml={"25%"} display={"flex"}>
                        <Box>
                          <Box>Add comment</Box>
                          <TextField
                            value={formComment}
                            sx={{ width: 300 }}
                            placeholder="Add comments"
                            onChange={(e) => setFormComment(e.target.value)}
                          />
                        </Box>
                        {caseWorkForm?.employee &&
                          caseWorkForm?.case_worker && (
                            <Box mt={4} ml={2}>
                              {printOpen ? (
                                <Box
                                  justifyContent={"space-between"}
                                  flexDirection={"row"}
                                  display={"flex"}
                                >
                                  <Button
                                    onClick={() => {
                                      setClear(true);
                                      setTimeout(() => {
                                        setClear(true);
                                        saveCaseInfoData();
                                        // handlePrint();
                                      }, 100);
                                    }}
                                    variant="contained"
                                    style={{
                                      backgroundColor: colours.primary,
                                    }}
                                  >
                                    {is_loading
                                      ? "Please wait..."
                                      : "Save Form"}
                                  </Button>
                                  <Box mr={2} />
                                  <Button
                                    disabled={isDisabled}
                                    onClick={() => {
                                      setTimeout(() => {
                                        handlePrint();
                                      }, 100);
                                    }}
                                    variant="contained"
                                    style={{
                                      backgroundColor: "Green",
                                    }}
                                  >
                                    Print Now
                                  </Button>
                                </Box>
                              ) : (
                                <Button
                                  onClick={() => setPrintOpen(!printOpen)}
                                  variant="contained"
                                  style={{
                                    backgroundColor: "rgb(87, 37, 68)",
                                  }}
                                >
                                  Open Report
                                </Button>
                              )}
                            </Box>
                          )}
                      </Box>
                    </Box>

                    {printOpen && (
                      <Box ml={9} mb={-1}>
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          Tips{" "}
                        </span>

                        <span
                          style={{
                            fontStyle: "italic",
                            fontSize: 12,
                            color: "red",
                          }}
                        >
                          - This form can filled online{" "}
                        </span>

                        <span
                          style={{
                            fontStyle: "italic",
                            fontSize: 12,
                            color: "red",
                          }}
                        >
                          - Signature can be signed online
                        </span>
                      </Box>
                    )}

                    <Box display={"flex"} mt={2} justifyContent={"center"}>
                      {printOpen && (
                        <CaseInformation
                          clear={clear}
                          setClear={setClear}
                          ref={caseInformationRef}
                          selectedCaseWorker={selectedCaseWorker}
                          selectedEmployee={selectedEmployee}
                          selectedIndividual={selectedIndividualData}
                          selectedAuthorization={authorizationData}
                          formComment={formComment}
                          printOpen={printOpen}
                        />
                      )}
                    </Box>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                  <Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-evenly"}
                    >
                      <Box width={"40%"}>
                        <Box mb={0.5}>Complainant</Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="complainant"
                          label="Complainant"
                          variant="outlined"
                          value={complainantData?.complainant}
                          onChange={handleComplainant}
                          name="complainant"
                        />

                        <Box mb={0.5} mt={2}>
                          Regarding
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="regarding"
                          label="Regarding"
                          variant="outlined"
                          value={complainantData?.regarding}
                          onChange={(e) => handleComplainant(e)}
                          name="regarding"
                        />

                        <Box mb={0.5} mt={2}>
                          Others Involved (If Applicable)
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="others_involved"
                          label="Others Involved"
                          variant="outlined"
                          value={complainantData?.others_involved}
                          onChange={(e) => handleComplainant(e)}
                          name="others_involved"
                        />
                        <Box mb={0.5} mt={2}>
                          Reported By
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="reported_by"
                          label="Reported By"
                          variant="outlined"
                          value={complainantData?.reported_by}
                          onChange={(e) => handleComplainant(e)}
                          name="reported_by"
                        />
                        <Box mb={0.5} mt={2}>
                          Nature of Problem
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="nature_of_problem"
                          label="Problem Nature"
                          variant="outlined"
                          value={complainantData?.nature_of_problem}
                          onChange={(e) => handleComplainant(e)}
                          name="nature_of_problem"
                        />
                      </Box>
                      <Box width={"40%"}>
                        <Box>Person Receiving Complainant</Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="person_receiving_complainant"
                          label="Complainant Receiver"
                          variant="outlined"
                          value={complainantData?.person_receiving_complainant}
                          onChange={(e) => handleComplainant(e)}
                          name="person_receiving_complainant"
                        />
                        <Box mb={0.5} mt={2}>
                          Action Taken
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="action_taken"
                          label="Action Taken"
                          variant="outlined"
                          value={complainantData?.action_taken}
                          onChange={(e) => handleComplainant(e)}
                          name="action_taken"
                          multiline
                        />
                        <Box mb={0.5} mt={2}>
                          Person Completing Report
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          id="person_completing_report"
                          label="Person Completing Report"
                          variant="outlined"
                          value={complainantData?.person_completing_report}
                          onChange={(e) => handleComplainant(e)}
                          name="person_completing_report"
                        />
                        <Box mb={0.5} mt={4}>
                          The nature of the complaint and it's resolution have
                          been discussed with complainant who
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...label}
                              name="agrees_with_resolution"
                              value={complainantData?.agrees_with_resolution}
                              onChange={(e) => handleComplainant(e)}
                              checked={complainantData?.agrees_with_resolution}
                            />
                          }
                          label="Agrees with it's resolution"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...label}
                              name="disagrees_with_resolution"
                              value={complainantData?.disagrees_with_resolution}
                              onChange={(e) => handleComplainant(e)}
                              checked={
                                complainantData?.disagrees_with_resolution
                              }
                            />
                          }
                          label="Disagrees with it's resolution"
                        />
                        {isSuccessful_ && (
                          <Alert severity="success">
                            Complain Submitted successfully
                          </Alert>
                        )}
                        <Box
                          justifyContent={"center"}
                          display={"flex"}
                          mt={isSuccessful_ ? 2 : 4}
                          mb={2}
                        >
                          <Button
                            onClick={() => handleSubmitComplainant()}
                            variant="contained"
                            style={{ backgroundColor: "rgb(87, 37, 68)" }}
                          >
                            {isloading_ ? "Please wait..." : "Submit Complaint"}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                  <Box mb={0.5} mt={2}>
                    Comment
                  </Box>
                  <TextField
                    size="small"
                    style={{ width: "100%" }}
                    id="comment"
                    label="Comment"
                    variant="outlined"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    name="comment"
                    multiline
                    rows={6}
                  />
                  {_isSuccessful && (
                    <Box mt={1} display={"flex"} justifyContent={"center"}>
                      <Alert severity="success">
                        Complain Submitted successfully
                      </Alert>
                    </Box>
                  )}
                  <Box
                    justifyContent={"center"}
                    display={"flex"}
                    mt={_isSuccessful ? 2 : 4}
                    mb={2}
                  >
                    <Button
                      onClick={() => saveComment()}
                      variant="contained"
                      style={{ backgroundColor: "rgb(87, 37, 68)" }}
                    >
                      {_isloading ? "Please wait..." : "Save Comment"}
                    </Button>
                  </Box>
                </CustomTabPanel>{" "}
                <NotificationContainer />
              </Box>
            </Box>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={open__}
        onClose={handleClose_}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" pl={2}>
            <Box justifyContent={"flex-end"} display={"flex"} mt={2}>
              <Close
                onClick={() => {
                  setAuthorizations(authorizationsCopy);
                  handleClose_();
                }}
                style={{ cursor: "pointer", color: "red" }}
              />
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} mt={2}>
              <Tabs
                value={value2}
                onChange={handleChange2}
                aria-label="View Authorizations"
              >
                <Tab label="Information" {...a11yProps(0)} />
                <Tab label="Authorizations" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <Box>
              <CustomTabPanel value={value2} index={0}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-around"}
                >
                  <Box
                    component={"fieldset"}
                    style={{
                      borderColor: "lightgrey",
                      borderRadius: 7,
                      borderWidth: 1,
                      boxShadow: "2px 2px #888888",
                    }}
                  >
                    <Box
                      p={1}
                      textAlign={"center"}
                      bgcolor={colours.primary}
                      color={"white"}
                      mb={1}
                    >
                      Personal info
                    </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Box>
                        <Box color={"grey"}>First name</Box>
                        <Box>{selectedIndividual?.firstname}</Box>
                        <Box mt={2} color={"grey"}>
                          Last name
                        </Box>
                        <Box>{selectedIndividual?.lastname}</Box>
                        <Box mt={2} color={"grey"}>
                          State ID (Medicaid)
                        </Box>
                        <Box>{selectedIndividual?.medicaid}</Box>
                        <Box mt={2} color={"grey"}>
                          Address
                        </Box>
                        <Box>{selectedIndividual?.address}</Box>
                        <Box mt={2} color={"grey"}>
                          City
                        </Box>
                        <Box>{selectedIndividual?.city}</Box>
                        <Box mt={2} color={"grey"}>
                          State
                        </Box>
                        <Box>{selectedIndividual?.state}</Box>
                        <Box mt={2} color={"grey"}>
                          Country
                        </Box>
                        <Box>{selectedIndividual?.country}</Box>

                        <Box mt={2} color={"grey"}>
                          Phone
                        </Box>
                        <Box>{selectedIndividual?.phone}</Box>
                      </Box>
                      <Box ml={5}>
                        <Box color={"grey"}>Date of Birth</Box>
                        <Box>{selectedIndividual?.dob}</Box>
                        <Box color={"grey"} mt={2}>
                          Gender
                        </Box>
                        <Box>{selectedIndividual?.gender}</Box>
                        <Box color={"grey"} mt={2}>
                          Marital Status
                        </Box>
                        <Box>{selectedIndividual?.marital_status}</Box>
                        <Box color={"grey"} mt={2}>
                          S.T.E.A.R / 211
                        </Box>
                        <Box>{selectedIndividual?.stear}</Box>
                        <Box color={"grey"} mt={2}>
                          Supervisor
                        </Box>
                        <Box>{selectedIndividual?.supervisor}</Box>
                        <Box color={"grey"} mt={2}>
                          Insurance number
                        </Box>
                        <Box>{selectedIndividual?.insurance_number}</Box>
                        <Box mt={2} color={"grey"}>
                          Other Phone
                        </Box>
                        <Box>{selectedIndividual?.other_phone}</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    component={"fieldset"}
                    style={{
                      borderColor: "lightgrey",
                      borderRadius: 7,
                      borderWidth: 1,
                      boxShadow: "2px 2px #888888",
                    }}
                  >
                    <Box
                      p={1}
                      textAlign={"center"}
                      bgcolor={colours.secondary}
                      color={"white"}
                      mb={1}
                    >
                      Emergency Contact
                    </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Box>
                        <Box color={"grey"}>Name</Box>
                        <Box>{selectedIndividual?.name_1}</Box>

                        <Box mt={2} color={"grey"}>
                          Phone
                        </Box>
                        <Box>{selectedIndividual?.phone_1}</Box>
                        <Box mt={2} color={"grey"}>
                          Relationship
                        </Box>
                        <Box>{selectedIndividual?.relationship_1}</Box>
                      </Box>
                      <Box ml={5}>
                        <Box color={"grey"}>Name</Box>
                        <Box>{selectedIndividual?.name_2}</Box>
                        <Box mt={2} color={"grey"}>
                          Phone
                        </Box>
                        <Box>{selectedIndividual?.phone_2}</Box>
                        <Box color={"grey"} mt={2}>
                          Relationship
                        </Box>
                        <Box>{selectedIndividual?.relationship_2}</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    component={"fieldset"}
                    style={{
                      borderColor: "lightgrey",
                      borderRadius: 7,
                      borderWidth: 1,
                      boxShadow: "2px 2px #888888",
                    }}
                  >
                    <Box
                      p={1}
                      textAlign={"center"}
                      bgcolor={colours.primary}
                      color={"white"}
                      mb={1}
                    >
                      EVV
                    </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Box>
                        <Box color={"grey"}>AR Number</Box>
                        <Box>{selectedIndividual?.ar_number}</Box>

                        <Box mt={2} color={"grey"}>
                          EVV Priority
                        </Box>
                        <Box>{selectedIndividual?.evv_priority}</Box>
                        <Box mt={2} color={"grey"}>
                          Bill Code
                        </Box>
                        <Box>{selectedIndividual?.bill_code}</Box>
                        <Box color={"grey"} mt={2}>
                          Proc Code Qualifier
                        </Box>
                        <Box>{selectedIndividual?.proc_code_qualifier}</Box>
                      </Box>
                      <Box ml={5}>
                        <Box color={"grey"}>Land Phone</Box>
                        <Box>{selectedIndividual?.land_phone}</Box>
                        <Box mt={2} color={"grey"}>
                          Vesta Client ID
                        </Box>
                        <Box>{selectedIndividual?.vesta_client_id}</Box>
                        <Box mt={2} color={"grey"}>
                          Member Unique ID
                        </Box>
                        <Box>{selectedIndividual?.member_unique_id}</Box>
                        <Box mt={2} color={"grey"}>
                          Individual EVV ID
                        </Box>
                        <Box>{selectedIndividual?.individual_evv_id}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value2} index={1}>
                <DataGrid
                  sx={{
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold !important",
                      color: colours.primary,
                    },
                    "& .super-app-theme--header": {
                      backgroundColor: colours.secondary,
                    },
                  }}
                  rows={authorizations}
                  columns={columns_}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }}
                  pageSizeOptions={[20, 40, 100]}
                  // checkboxSelection
                />
              </CustomTabPanel>
            </Box>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openModal}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" pl={2}>
            <Box justifyContent={"flex-end"} display={"flex"} mt={2}>
              {hideCloseIcon && (
                <Close
                  onClick={() => {
                    handleModal();
                    handleClose_();
                  }}
                  style={{ cursor: "pointer", color: "red" }}
                />
              )}
            </Box>
            <Box>
              <Tabs
                value={value_}
                onChange={handle_Change}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Authorization"
                  {...a11yProps(0)}
                  onClick={() => setHideCloseIcon(true)}
                />
                <Tab
                  label="Weekly Calendar"
                  {...a11yProps(1)}
                  onClick={() => setHideCloseIcon(true)}
                />
                <Tab
                  label="Tasks"
                  {...a11yProps(2)}
                  onClick={() => setHideCloseIcon(true)}
                />
                <Tab
                  label="Case Information"
                  {...a11yProps(3)}
                  onClick={() => setHideCloseIcon(false)}
                />
                <Tab
                  label="Complaint(s)"
                  {...a11yProps(4)}
                  onClick={() => setHideCloseIcon(true)}
                />
                <Tab
                  label="Comment(s)"
                  {...a11yProps(5)}
                  onClick={() => setHideCloseIcon(true)}
                />
              </Tabs>
            </Box>

            <CustomTabPanel value={value_} index={0}>
              <Box display={"flex"} flexDirection={"row"}>
                <Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Service Start Date</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.service_start_date &&
                        moment(selectedAuthData?.service_start_date).format(
                          "D-MM-YYYY"
                        )}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Service End Date</Box>
                    <Box style={styles.value}>
                      {moment(selectedAuthData?.service_end_date).format(
                        "D-MM-YYYY"
                      )}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Last Sup Visit Date</Box>
                    <Box style={styles.value}>
                      {" "}
                      {moment(
                        selectedAuthData?.last_spervisor_visit_date
                      ).format("D-MM-YYYY")}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Authorization Received Date</Box>
                    <Box style={styles.value}>
                      {moment(
                        selectedAuthData?.authorization_received_date
                      ).format("D-MM-YYYY")}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Practitioner Statement Sent</Box>
                    <Box style={styles.value}>
                      {moment(
                        selectedAuthData?.practioner_statement_sent
                      ).format("D-MM-YYYY")}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Service Type</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.service_type}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Service Code</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.service_code}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Place of Service ID</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.place_service_id}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Liason</Box>
                    <Box style={styles.value}>{selectedAuthData?.liason}</Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Discharge Reason</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.discharge_reason}
                    </Box>
                  </Box>
                </Box>
                <Box ml={4}>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Case Worker</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.case_worker}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Service Group</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.service_group}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>
                      Practitioner Statement Received
                    </Box>
                    <Box style={styles.value}>
                      {moment(
                        selectedAuthData?.practioner_statement_received
                      ).format("D-MM-YYYY")}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Authorization End Date</Box>
                    <Box style={styles.value}>
                      {moment(selectedAuthData?.authorization_end_date).format(
                        "D-MM-YYYY"
                      )}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Priority</Box>
                    <Box style={styles.value}>{selectedAuthData?.priority}</Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Date of Birth</Box>
                    <Box style={styles.value}>
                      {moment(selectedAuthData?.dob).format("D-MM-YYYY")}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Assessment Date</Box>
                    <Box style={styles.value}>
                      {moment(selectedAuthData?.assessment_date).format(
                        "D-MM-YYYY"
                      )}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Sup Visit Freq.</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.sup_visit_freq}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Bill Code</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.bill_code}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>
                      Service Initial Reported to CW
                    </Box>
                    <Box style={styles.value}>
                      {moment(
                        selectedAuthData?.service_initial_reported_cw
                      ).format("D-MM-YYYY")}
                    </Box>
                  </Box>
                </Box>

                <Box ml={4}>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Authorization Start Date</Box>
                    <Box style={styles.value}>
                      {moment(
                        selectedAuthData?.authorization_start_date
                      ).format("D-MM-YYYY")}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Procedure Code ID</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.procedure_code_id}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Diagnosis</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.diagnosis}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Procedure Code Qualifier</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.procedure_code_qualifier}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Units</Box>
                    <Box style={styles.value}>{selectedAuthData?.units}</Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Status</Box>
                    <Box style={styles.value}>{selectedAuthData?.status}</Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Authorization Ref. number</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.authorization_ref_number}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Supplies/Equipment</Box>
                    <Box style={styles.value}>
                      {selectedAuthData?.supplies_equipment}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Doctor</Box>
                    <Box style={styles.value}>{selectedAuthData?.dictor}</Box>
                  </Box>
                </Box>

                <Box ml={4}>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Billing Diagnosis Codes</Box>
                    <Box style={styles.value}>
                      Code - 1: {selectedAuthData?.billing_diagnosis_code_1}
                    </Box>
                    <Box style={styles.value}>
                      Code - 2: {selectedAuthData?.billing_diagnosis_code_2}
                    </Box>
                    <Box style={styles.value}>
                      Code - 3: {selectedAuthData?.billing_diagnosis_code_3}
                    </Box>
                    <Box style={styles.value}>
                      Code - 4: {selectedAuthData?.billing_diagnosis_code_4}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>Modifiers</Box>
                    <Box style={styles.value}>
                      Mod1 - {selectedAuthData?.modifier_1}
                    </Box>
                    <Box style={styles.value}>
                      Mod2 - {selectedAuthData?.modifier_2}
                    </Box>
                    <Box style={styles.value}>
                      Mod3 - {selectedAuthData?.modifier_3}
                    </Box>
                    <Box style={styles.value}>
                      Mod4 - {selectedAuthData?.modifier_4}
                    </Box>
                  </Box>
                  <Box style={styles.margin}>
                    <Box style={styles.label}>HCPCS</Box>
                    <Box style={styles.value}>{selectedAuthData?.hcpcs}</Box>
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value_} index={1}>
              <Box>
                {[...Array(weeklyScheduleData.length).keys()].map(
                  (key, index) => {
                    return (
                      <Box mb={2}>
                        <Box
                          borderBottom={1}
                          borderColor={colours.borderColour}
                          flexDirection={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          mb={5}
                        >
                          <Box>
                            <Box
                              bgcolor={"dodgerblue"}
                              borderRadius={100}
                              height={30}
                              width={30}
                              justifyContent={"center"}
                              alignItems={"center"}
                              display={"flex"}
                            >
                              {index + 1}
                            </Box>
                            <Box display={"flex"} flexDirection={"row"}>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  In/Out
                                </Box>
                                <Box paddingY={1}>In</Box>
                                <Box marginY={1}>Out</Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  SUN
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    name={"timeSundayIn"}
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(e, key, "sunday", "in")
                                    }
                                    value={
                                      weeklyScheduleData[key]?.sunday &&
                                      weeklyScheduleData[key]?.sunday?.split(
                                        "-"
                                      )[0]
                                    }
                                  />
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.sunday &&
                                      weeklyScheduleData[key]?.sunday?.split(
                                        "-"
                                      )[1]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(e, key, "sunday", "out")
                                    }
                                    name={"timeSundayOut"}
                                  />
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  MON
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.monday &&
                                      weeklyScheduleData[key]?.monday?.split(
                                        "-"
                                      )[0]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(e, key, "monday", "in")
                                    }
                                    name={"timeMondayIn"}
                                  />
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(e, key, "monday", "out")
                                    }
                                    name={"timeMondayOut"}
                                    value={
                                      weeklyScheduleData[key]?.monday &&
                                      weeklyScheduleData[key]?.monday?.split(
                                        "-"
                                      )[1]
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  TUE
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.tuesday &&
                                      weeklyScheduleData[key]?.tuesday?.split(
                                        "-"
                                      )[0]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(e, key, "tuesday", "in")
                                    }
                                    name={"timeTuesdayIn"}
                                  />
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.tuesday &&
                                      weeklyScheduleData[key]?.tuesday?.split(
                                        "-"
                                      )[1]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "tuesday",
                                        "out"
                                      )
                                    }
                                    name={"timeTuesdayOut"}
                                  />
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  WED
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.wednesday &&
                                      weeklyScheduleData[key]?.wednesday?.split(
                                        "-"
                                      )[0]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "wednesday",
                                        "in"
                                      )
                                    }
                                    name={"timeWednesdayIn"}
                                  />
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.wednesday &&
                                      weeklyScheduleData[key]?.wednesday?.split(
                                        "-"
                                      )[1]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "wednesday",
                                        "out"
                                      )
                                    }
                                    name={"timeWednesdayOut"}
                                  />
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  THU
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.thursday &&
                                      weeklyScheduleData[key]?.thursday?.split(
                                        "-"
                                      )[0]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "thursday",
                                        "in"
                                      )
                                    }
                                    name={"timeThursdayIn"}
                                  />
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.thursday &&
                                      weeklyScheduleData[key]?.thursday?.split(
                                        "-"
                                      )[1]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "thursday",
                                        "out"
                                      )
                                    }
                                    name={"timeThursdayOut"}
                                  />
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  FRI
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.friday &&
                                      weeklyScheduleData[key]?.friday?.split(
                                        "-"
                                      )[0]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(e, key, "friday", "in")
                                    }
                                    name={"timeFridayIn"}
                                  />
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.friday &&
                                      weeklyScheduleData[key]?.friday?.split(
                                        "-"
                                      )[1]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(e, key, "friday", "out")
                                    }
                                    name={"timeFridayOut"}
                                  />
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.primary}
                                >
                                  SAT
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.saturday &&
                                      weeklyScheduleData[key]?.saturday?.split(
                                        "-"
                                      )[0]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "saturday",
                                        "in"
                                      )
                                    }
                                    name={"timeSaturdayIn"}
                                  />
                                </Box>
                                <Box paddingY={1}>
                                  <TextField
                                    value={
                                      weeklyScheduleData[key]?.saturday &&
                                      weeklyScheduleData[key]?.saturday?.split(
                                        "-"
                                      )[1]
                                    }
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "center" },
                                    }} // the change is here
                                    style={{
                                      width: 63,
                                    }}
                                    size="small"
                                    label=""
                                    variant="standard"
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "saturday",
                                        "out"
                                      )
                                    }
                                    name={"timeSaturdayOut"}
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              paddingBottom={2}
                              mt={1}
                              display={"flex"}
                              flexDirection={"row"}
                            >
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  In/Out
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {" "}
                                  Total Hours
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  SUN
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {weeklyScheduleData[key]?.sunday?.split(
                                    "-"
                                  )[0] &&
                                    getTimeDifference(
                                      weeklyScheduleData[key]?.sunday?.split(
                                        "-"
                                      )[0],
                                      weeklyScheduleData[key]?.sunday?.split(
                                        "-"
                                      )[1]
                                    )}
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  MON
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {weeklyScheduleData[key]?.monday?.split(
                                    "-"
                                  )[0] &&
                                    getTimeDifference(
                                      weeklyScheduleData[key]?.monday?.split(
                                        "-"
                                      )[0],
                                      weeklyScheduleData[key]?.monday?.split(
                                        "-"
                                      )[1]
                                    )}
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  TUE
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {weeklyScheduleData[key]?.tuesday?.split(
                                    "-"
                                  )[0] &&
                                    getTimeDifference(
                                      weeklyScheduleData[key]?.tuesday?.split(
                                        "-"
                                      )[0],
                                      weeklyScheduleData[key]?.tuesday?.split(
                                        "-"
                                      )[1]
                                    )}
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  WED
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {weeklyScheduleData[key]?.wednesday?.split(
                                    "-"
                                  )[0] &&
                                    getTimeDifference(
                                      weeklyScheduleData[key]?.wednesday?.split(
                                        "-"
                                      )[0],
                                      weeklyScheduleData[key]?.wednesday?.split(
                                        "-"
                                      )[1]
                                    )}
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  THU
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {weeklyScheduleData[key]?.thursday?.split(
                                    "-"
                                  )[0] &&
                                    getTimeDifference(
                                      weeklyScheduleData[key]?.thursday?.split(
                                        "-"
                                      )[0],
                                      weeklyScheduleData[key]?.thursday?.split(
                                        "-"
                                      )[1]
                                    )}
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  FRI
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {weeklyScheduleData[key]?.friday?.split(
                                    "-"
                                  )[0] &&
                                    getTimeDifference(
                                      weeklyScheduleData[key]?.friday?.split(
                                        "-"
                                      )[0],
                                      weeklyScheduleData[key]?.friday?.split(
                                        "-"
                                      )[1]
                                    )}
                                </Box>
                              </Box>
                              <Box textAlign={"center"}>
                                <Box
                                  paddingX={5}
                                  paddingY={1}
                                  textAlign={"center"}
                                  color={"white"}
                                  bgcolor={colours.secondary}
                                >
                                  SAT
                                </Box>
                                <Box
                                  border={1}
                                  borderColor={"lightgrey"}
                                  paddingY={1}
                                >
                                  {weeklyScheduleData[key]?.saturday?.split(
                                    "-"
                                  )[0] &&
                                    getTimeDifference(
                                      weeklyScheduleData[key]?.saturday?.split(
                                        "-"
                                      )[0],
                                      weeklyScheduleData[key]?.saturday?.split(
                                        "-"
                                      )[1]
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box mb={1}>Status</Box>
                            <Box>
                              <FormControl fullWidth size="small">
                                <InputLabel id="discharge_reason">
                                  Status
                                </InputLabel>
                                <Select
                                  placeholder="Status"
                                  labelId="status"
                                  id="status"
                                  value={
                                    weeklyScheduleData[key]["status"] || ""
                                  }
                                  label="Status"
                                  onChange={(e) =>
                                    handleMultiInputs(
                                      e,
                                      key,
                                      "nothing",
                                      "status"
                                    )
                                  }
                                  defaultValue={"Select"}
                                  name="status"
                                  // style={{ width: "83%" }}
                                >
                                  <MenuItem value={"active"}>Active</MenuItem>
                                  <MenuItem value={"inactive"}>
                                    Inactive
                                  </MenuItem>
                                  <MenuItem value={"onhold"}>Onhold</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            <Box mt={3}>Schedule Start Date</Box>
                            <Box>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "nothing",
                                        "startDate"
                                      )
                                    }
                                    value={moment(
                                      weeklyScheduleData[key]["start_date"]
                                    )}
                                    // value={multipleData[key]["startDate"]}
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        size: "small",
                                      },
                                    }}
                                    label="Schedule Start Date"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Box>
                            <Box mt={3}>Schedule End Date</Box>
                            <Box>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    onChange={(e) =>
                                      handleMultiInputs(
                                        e,
                                        key,
                                        "nothing",
                                        "endDate"
                                      )
                                    }
                                    value={moment(
                                      weeklyScheduleData[key]["end_date"]
                                    )}
                                    // value={multipleData[key]["endDate"]}
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        size: "small",
                                      },
                                    }}
                                    label="Schedule End Date"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value_} index={2}>
              <Box>
                <Box>
                  {taskNewData.map((task, index) => {
                    return (
                      <FormGroup>
                        <Box
                          padding={2}
                          bgcolor={colours.secondary}
                          maxWidth={"95%"}
                          borderRadius={3}
                        >
                          <TaskItem
                            index={index}
                            task={task?.bathing}
                            name={"bathing"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.dressing}
                            name={"dressing"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.excercising}
                            name={"excercising"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.feeding}
                            name={"feeding"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.shaving_oral_care}
                            name={"shaving/Oral Care"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.laundry}
                            name={"laundry"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.toilet}
                            name={"toileting"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.Transfer}
                            name={"transfer"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.cleaning}
                            name={"cleaning"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.routine_hair_skin_care}
                            name={"routine Hair Skin Care"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.meal_preparation}
                            name={"meal Preparation"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.escort}
                            name={"escort"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.shopping}
                            name={"shopping"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.Walking}
                            name={"walking"}
                          />
                          <TaskItem
                            index={index}
                            task={task?.ambulation}
                            name={"ambulation"}
                          />
                          <TaskItem
                            index={index}
                            task={
                              task?.assistance_with_self_administered_medications
                            }
                            name={
                              "assistance with self administered medications"
                            }
                          />
                          <TaskItem
                            index={index}
                            task={task?.others}
                            name={"Others"}
                          />
                        </Box>
                      </FormGroup>
                    );
                  })}
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value_} index={3}>
              {!displayCsseForm ? (
                <Box style={{ float: "left", width: "100%" }}>
                  {caseInfosData.map((caseInfo) => {
                    return (
                      <Box
                        display={"inline-grid"}
                        m={2}
                        border={"1px solid lightgrey"}
                        width={"20%"}
                        p={1}
                        borderRadius={5}
                      >
                        <Box>
                          <Box
                            textAlign={"center"}
                            lineHeight={1}
                            height={50}
                            overflow={"hidden"}
                            mb={1}
                          >
                            {caseInfo?.comment_response}
                          </Box>
                          <Box display={"flex"} flexDirection={"row"}>
                            Caseworker:{" "}
                            <Box
                              color={colours.secondary}
                              fontWeight={"bold"}
                              pl={1}
                            >
                              {" "}
                              {caseInfo?.sent_to
                                ?.split(":")[1]
                                ?.split("\n")[0]
                                ?.replace(",", " ")}
                            </Box>
                          </Box>
                          <Box display={"flex"} flexDirection={"row"}>
                            Employee:{" "}
                            <Box
                              color={colours.secondary}
                              fontWeight={"bold"}
                              pl={1}
                            >
                              {" "}
                              {caseInfo?.sent_from
                                ?.split(" \n")[0]
                                ?.replace(",", " ")}
                            </Box>
                          </Box>
                          <Box display={"flex"} flexDirection={"row"}>
                            Date:{" "}
                            <Box
                              ml={1}
                              color={colours.secondary}
                              fontWeight={"bold"}
                            >
                              {" "}
                              {caseInfo?.authorization_start_date}
                            </Box>
                          </Box>
                          <Box
                            mt={1}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setHideCloseIcon(false);
                                setDisplayCaseForm(true);
                                setCaseInfoSelected(caseInfo?.id);
                              }}
                            >
                              View Details
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box
                  bgcolor={"lightgrey"}
                  display={"flex"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"100%"}
                  p={2}
                  borderRadius={3}
                >
                  <Box>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Button
                        onClick={() => handlePrint2()}
                        style={{
                          backgroundColor: "green",
                          marginRight: 10,
                        }}
                        variant="contained"
                      >
                        Print Form
                      </Button>
                      <Button
                        onClick={() => {
                          setHideCloseIcon(true);
                          setDisplayCaseForm(false);
                        }}
                        style={{
                          backgroundColor: "red",
                        }}
                        variant="contained"
                      >
                        Close Form
                      </Button>
                    </Box>

                    <CaseInformation ref={caseInformationRef2} view={true} />
                  </Box>
                </Box>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value_} index={4}>
              {newComplains?.map((complain, index) => {
                return (
                  <Box mb={3}>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Box
                        color={colours.secondary}
                        fontSize={20}
                        bgcolor={colours.primary}
                        mr={1}
                        p={1}
                      >
                        {index + 1}
                      </Box>
                      <Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>Complainant</Box>
                          <Box style={styles.value}>
                            {complain?.complainant}
                          </Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>Regarding</Box>
                          <Box style={styles.value}>{complain?.regarding}</Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>
                            Others Involved (If Applicable)
                          </Box>
                          <Box style={styles.value}>
                            {complain?.others_involved}
                          </Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>Reported By</Box>
                          <Box style={styles.value}>
                            {complain?.reported_by}
                          </Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>Nature of Problem</Box>
                          <Box style={styles.value}>
                            {complain?.nature_of_problem}
                          </Box>
                        </Box>
                      </Box>
                      <Box ml={4}>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>
                            Person Receiving Complainant
                          </Box>
                          <Box style={styles.value}>
                            {complain?.person_receiving_complainant}
                          </Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>Action Taken</Box>
                          <Box style={styles.value}>
                            {complain?.action_taken}
                          </Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>
                            Person Completing Report
                          </Box>
                          <Box style={styles.value}>
                            {complain?.person_completing_report}
                          </Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>
                            Agreed with it's resolution
                          </Box>
                          <Box style={styles.value}>
                            {complain?.agrees_with_resolution == 1 ||
                            complain?.agrees_with_resolution == true
                              ? "Yes"
                              : "No"}
                          </Box>
                        </Box>
                        <Box style={styles.margin}>
                          <Box style={styles.label}>
                            Disagreed with it's resolution
                          </Box>
                          <Box style={styles.value}>
                            {complain?.disagrees_with_resolution == 1 ||
                            complain?.disagrees_with_resolution == true
                              ? "Yes"
                              : "No"}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </CustomTabPanel>
            <CustomTabPanel value={value_} index={5}>
              <Box>
                {newComments.map((comment, index) => {
                  return (
                    <Box style={styles.comment}>
                      <Box style={styles.bold}>Comment {`(${index + 1})`}</Box>
                      <Box>{comment?.comment}</Box>
                    </Box>
                  );
                })}
              </Box>
            </CustomTabPanel>
          </Typography>
        </Box>
      </Modal>

      <Box>
        <Modal
          open={openEditAuth}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              pl={2}
            >
              <Box
                justifyContent={"space-between"}
                flexDirection={"row"}
                display={"flex"}
                mt={2}
              >
                <Box>Edit Authorization</Box>
                <Box>
                  <Close
                    onClick={() => {
                      handleCloseEdit();
                      handleClose_();
                    }}
                    style={{ cursor: "pointer", color: "red" }}
                  />
                </Box>
              </Box>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueEdit}
                    onChange={handleChangeEdit}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Authorization" {...a11yPropsEdit(0)} />
                    {/* <Tab label="Billing Diagnosis Code" {...a11yProps(1)} /> */}
                    <Tab label="Weekly Calendar" {...a11yPropsEdit(1)} />
                    <Tab label="Tasks" {...a11yPropsEdit(2)} />
                    <Tab label="Case Information" {...a11yPropsEdit(3)} />
                    <Tab label="Complaint Log" {...a11yPropsEdit(4)} />
                    <Tab label="Comment" {...a11yPropsEdit(5)} />
                  </Tabs>
                </Box>

                <Box>
                  <CustomTabPanel value={valueEdit} index={0}>
                    <Box>
                      <Box
                        flexDirection={"row"}
                        justifyContent={"space-evenly"}
                        display={"flex"}
                        alignSelf={"center"}
                      >
                        <Box width={"18%"}>
                          <Box mb={1} style={styles.inputLabel}>
                            Service Start Date
                          </Box>

                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.service_start_date
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  service_start_date: date,
                                })
                              }
                            />
                          </Box>
                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Service End Date
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={editAuthorizationData?.service_end_date}
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  service_end_date: date,
                                })
                              }
                            />
                          </Box>
                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Last Sup Visit Date
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.last_spervisor_visit_date
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  last_spervisor_visit_date: date,
                                })
                              }
                            />
                          </Box>
                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Authorization Received Date
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.authorization_received_date
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  authorization_received_date: date,
                                })
                              }
                            />
                          </Box>
                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Practitioner Statement Sent
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.practioner_statement_sent
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  practioner_statement_sent: date,
                                })
                              }
                            />
                          </Box>
                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Service Type
                          </Box>
                          <Box>
                            <Autocomplete
                              value={editAuthorizationData?.service_type}
                              onChange={(event, newValue) => {
                                console.log(newValue, "====");
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  service_type: newValue?.program,
                                  procedure_code_qualifier:
                                    newValue?.procedure_code_qualifier,
                                  service_group: newValue?.service_group,
                                  service_code: newValue?.service_code,
                                  service_code_description:
                                    newValue?.description,
                                  hcpcs: newValue?.HCPCS,
                                  bill_code: newValue?.bill_code,
                                  modifier_1: newValue?.modifier_1,
                                  modifier_2: newValue?.modifier_2,
                                  modifier_3: newValue?.modifier_3,
                                  modifier_4: newValue?.modifier_4,
                                });
                              }}
                              size="small"
                              style={{ width: "100%" }}
                              disablePortal
                              id="service_type"
                              options={serviceTypeData}
                              getOptionLabel={(option) => {
                                return option?.service_code
                                  ? `${option?.service_group} - ${option?.service_code} - ${option?.description} `
                                  : option;
                              }}
                              sx={{ width: 300 }}
                              renderInput={(params) => {
                                // console.log(params?.id, "--params");
                                return (
                                  <TextField {...params} label="Service type" />
                                );
                              }}
                            />
                          </Box>
                        </Box>

                        <Box width={"18%"}>
                          <Box mb={1} style={styles.inputLabel}>
                            Place of Service ID
                          </Box>
                          <Box>
                            <TextField
                              inputProps={{ "aria-label": "theme" }}
                              style={{ width: "100%" }}
                              size="small"
                              id="place_service_id"
                              placeholder="Place of Service ID"
                              variant="outlined"
                              value={editAuthorizationData?.place_service_id}
                              onChange={handleAuth_}
                              name="place_service_id"
                            />
                          </Box>

                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Liason
                          </Box>
                          <TextField
                            inputProps={{ "aria-label": "theme" }}
                            size="small"
                            style={{ width: "100%" }}
                            id="liason"
                            placeholder="Liason"
                            variant="outlined"
                            value={editAuthorizationData?.liason}
                            onChange={handleAuth_}
                            name="liason"
                          />
                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Discharge Reason
                          </Box>
                          <Box>
                            <FormControl fullWidth size="small">
                              <InputLabel id="discharge_reason">
                                Discharge Reason
                              </InputLabel>
                              <Select
                                inputProps={{ "aria-label": "theme" }}
                                placeholder="Discharge Reason"
                                labelId="discharge_reason"
                                id="discharge_reason"
                                value={
                                  editAuthorizationData?.discharge_reason ||
                                  "Select"
                                }
                                label="Discharge Reason"
                                onChange={handleAuth_}
                                defaultValue={
                                  editAuthorizationData?.discharge_reason
                                }
                                name="discharge_reason"
                              >
                                <MenuItem value={7 + " - CBA"}>CBA</MenuItem>
                                <MenuItem value={6 + " - Deseased"}>
                                  Deseased
                                </MenuItem>
                                <MenuItem value={13 + " - Declined Service"}>
                                  Declined Service
                                </MenuItem>
                                <MenuItem value={16 + " - Discharged"}>
                                  Discharged
                                </MenuItem>
                                <MenuItem value={17 + " - Discontinued"}>
                                  Discontinued
                                </MenuItem>
                                <MenuItem value={12 + " - HMO"}>HMO</MenuItem>
                                <MenuItem value={4 + " - Lost Eligibility"}>
                                  Lost Eligibility
                                </MenuItem>
                                <MenuItem value={14 + " - NH"}>NH</MenuItem>
                                <MenuItem value={9 + " - Not Eligible"}>
                                  Not Eligible
                                </MenuItem>
                                <MenuItem value={3 + " - Nursing Home"}>
                                  Nursing Home
                                </MenuItem>
                                <MenuItem value={5 + " - Other Agency"}>
                                  Other Agency
                                </MenuItem>
                                <MenuItem value={2 + " - Other Program"}>
                                  Other Program
                                </MenuItem>
                                <MenuItem value={11 + " - Relocated"}>
                                  Relocated
                                </MenuItem>
                                <MenuItem value={10 + " - Terminated"}>
                                  Terminated
                                </MenuItem>
                                <MenuItem value={8 + " - Transferred"}>
                                  Transferred
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box mt={1.5} mb={1} style={styles.inputLabel}>
                            Case Worker
                          </Box>
                          <Box>
                            <Autocomplete // defaultValue={""}
                              value={editAuthorizationData?.case_worker}
                              onChange={(event, newValue) => {
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  case_worker: `${newValue?.firstname} - ${newValue?.lastname}`,
                                });
                              }}
                              size="small"
                              style={{ width: "100%" }}
                              disablePortal
                              id="case_worker"
                              options={caseworkers}
                              getOptionLabel={(option) => {
                                return option?.firstname
                                  ? `${option?.firstname} ${option?.lastname}`
                                  : option;
                              }}
                              sx={{ width: 300 }}
                              renderInput={(params) => {
                                return (
                                  <TextField {...params} label="Caseworker" />
                                );
                              }}
                            />
                          </Box>

                          <Box mt={1.5} style={styles.inputLabel}>
                            Prac. Statement Received
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.practioner_statement_received
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  practioner_statement_received: date,
                                })
                              }
                            />
                          </Box>

                          <Box mt={1} style={styles.inputLabel}>
                            Authorization End Date
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.authorization_end_date
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  authorization_end_date: date,
                                })
                              }
                            />
                          </Box>
                        </Box>

                        <Box width={"18%"}>
                          <Box mb={1} style={styles.inputLabel}>
                            Priority
                          </Box>
                          <Box>
                            <FormControl fullWidth size="small">
                              <InputLabel id="priority">Priority</InputLabel>
                              <Select
                                inputProps={{ "aria-label": "theme" }}
                                labelId="priority"
                                id="priority"
                                value={
                                  editAuthorizationData?.priority || "Select"
                                }
                                label="Priority"
                                onChange={handleAuth_}
                                defaultValue={"Select"}
                                name="priority"
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box mt={1.5} mb={1}>
                            Date of Birth
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={editAuthorizationData?.dob}
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  dob: date,
                                })
                              }
                            />
                          </Box>

                          <Box mt={1.5}>Assessment Date</Box>
                          <Box>
                            <DatePickerNew
                              selected={editAuthorizationData?.assessment_date}
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  assessment_date: date,
                                })
                              }
                            />
                          </Box>

                          <Box display={"flex"} flexDirection={"row"}>
                            <Box>
                              <Box mt={2}>Sup Visit Freq.</Box>
                              <TextField
                                inputProps={{ "aria-label": "theme" }}
                                size="small"
                                id="sup_visit_freq"
                                placeholder="Sup Visit Frequency"
                                variant="outlined"
                                value={editAuthorizationData?.sup_visit_freq}
                                onChange={handleAuth_}
                                name="sup_visit_freq"
                              />
                            </Box>
                            <Box width={10} />
                            <Box>
                              <Box mt={2}>Bill Code</Box>
                              <TextField
                                inputProps={{ "aria-label": "theme" }}
                                size="small"
                                // label="Bill Code"
                                // style={{ width: "100%" }}
                                id="bill_code"
                                placeholder="Bill Code"
                                variant="outlined"
                                value={editAuthorizationData?.bill_code}
                                // onChange={handleAuth_}
                                onChange={() => console.log("Do nothing")}
                                name="bill_code"
                              />
                            </Box>
                          </Box>
                          <Box mt={2} mb={1} style={styles.inputLabel}>
                            Service Initial Reported to CW
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.service_initial_reported_cw
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  service_initial_reported_cw: date,
                                })
                              }
                            />
                          </Box>

                          <Box mt={1.5} style={styles.inputLabel}>
                            Authorization Start Date
                          </Box>
                          <Box>
                            <DatePickerNew
                              selected={
                                editAuthorizationData?.authorization_start_date
                              }
                              onChange={(date) =>
                                setEditAuthorizationData({
                                  ...editAuthorizationData,
                                  authorization_start_date: date,
                                })
                              }
                            />
                          </Box>
                        </Box>

                        <Box width={"18%"}>
                          <Box mb={1}>Procedure Code ID</Box>
                          <Box>
                            <TextField
                              inputProps={{ "aria-label": "theme" }}
                              size="small"
                              style={{ width: "100%" }}
                              id="procedure_code_id"
                              placeholder="Procedure Code ID"
                              variant="outlined"
                              value={editAuthorizationData?.procedure_code_id}
                              onChange={handleAuth_}
                              name="procedure_code_id"
                            />
                          </Box>
                          <Box mt={1} mb={1}>
                            Diagnosis
                          </Box>
                          <TextField
                            inputProps={{ "aria-label": "theme" }}
                            size="small"
                            style={{ width: "100%" }}
                            id="diagnosis"
                            placeholder="Diagnosis"
                            variant="outlined"
                            value={editAuthorizationData?.diagnosis}
                            onChange={handleAuth_}
                            name="diagnosis"
                          />
                          <Box mt={1.5} mb={1}>
                            Procedure Code Qualifier
                          </Box>
                          <TextField
                            inputProps={{ "aria-label": "theme" }}
                            style={{ width: "100%" }}
                            size="small"
                            // label="Procedure Code Qualifier"
                            placeholder="Procedure Code Qualifier"
                            variant="outlined"
                            value={
                              editAuthorizationData?.procedure_code_qualifier
                            }
                            onChange={handleAuth_}
                            name="procedure_code_qualifier"
                          />
                          <Box mt={1.7} mb={0.5}>
                            Units
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <TextField
                              inputProps={{ "aria-label": "theme" }}
                              size="small"
                              style={{ width: "31%" }}
                              id="hour"
                              placeholder="Hour(s)"
                              variant="outlined"
                              value={editAuthorizationData?.hour}
                              onChange={handleAuth_}
                              name="hour"
                            />
                            <TextField
                              inputProps={{ "aria-label": "theme" }}
                              size="small"
                              style={{ width: "31%" }}
                              id="minutes"
                              placeholder="Minute(s)"
                              variant="outlined"
                              value={editAuthorizationData?.minutes}
                              onChange={handleAuth_}
                              name="minutes"
                            />
                            <TextField
                              inputProps={{ "aria-label": "theme" }}
                              size="small"
                              style={{ width: "31%" }}
                              id="days"
                              placeholder="Days"
                              variant="outlined"
                              value={editAuthorizationData?.days}
                              onChange={handleAuth_}
                              name="days"
                            />
                          </Box>

                          <Box mt={2} mb={1}>
                            Status
                          </Box>
                          <Box>
                            <FormControl fullWidth size="small">
                              <InputLabel id="status">Status</InputLabel>
                              <Select
                                inputProps={{ "aria-label": "theme" }}
                                labelId="status"
                                id="status"
                                value={
                                  editAuthorizationData?.status || "Select"
                                }
                                label="Status"
                                placeholder="Status"
                                onChange={handleAuth_}
                                defaultValue={"Select"}
                                name="status"
                              >
                                <MenuItem value={"Active"}>Active</MenuItem>
                                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                                <MenuItem value={"Onhold"}>Onhold</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box mt={1} mb={1}>
                            Authorization Ref. number
                          </Box>
                          <TextField
                            inputProps={{ "aria-label": "theme" }}
                            size="small"
                            style={{ width: "100%" }}
                            id="authorization_ref_number"
                            placeholder="Authorization Ref. number"
                            variant="outlined"
                            value={
                              editAuthorizationData?.authorization_ref_number
                            }
                            onChange={handleAuth_}
                            name="authorization_ref_number"
                          />
                        </Box>

                        <Box width={"18%"}>
                          <Box mb={1}>Supplies/Equipment</Box>
                          <Box>
                            <TextField
                              inputProps={{ "aria-label": "theme" }}
                              size="small"
                              style={{ width: "100%" }}
                              id="supplies_equipment"
                              placeholder="Supplies/Equipment"
                              variant="outlined"
                              value={editAuthorizationData?.supplies_equipment}
                              onChange={handleAuth_}
                              name="supplies_equipment"
                            />
                          </Box>
                          <Box mt={1} mb={1}>
                            Doctor
                          </Box>
                          <Box>
                            <Autocomplete
                              // defaultValue={""}
                              isOptionEqualToValue={(option, value) => {
                                return (
                                  `${option?.firstname} - ${option?.lastname}` ===
                                  value
                                );
                              }}
                              value={editAuthorizationData?.doctor}
                              onChange={(event, newValue) => {
                                setAuthorizationData({
                                  ...editAuthorizationData,
                                  doctor: `${newValue?.firstname} - ${newValue?.lastname}`,
                                });
                              }}
                              size="small"
                              style={{ width: "100%" }}
                              // disablePortal={false}
                              id="doctor"
                              options={doctors}
                              getOptionLabel={(option) => {
                                return option?.firstname
                                  ? `${option?.firstname} ${option?.lastname}`
                                  : option;
                              }}
                              sx={{ width: 300 }}
                              renderInput={(params) => {
                                // console.log(params?.id, "--params");
                                return <TextField {...params} label="Doctor" />;
                              }}
                            />
                          </Box>
                          <Box
                            mt={1.5}
                            component="fieldset"
                            style={{ borderColor: "lightgrey", borderWidth: 1 }}
                          >
                            <legend>Billing Diagnosis Code </legend>
                            <Box mb={2}>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  editAuthorizationData?.billing_diagnosis_code_1
                                }
                                onChange={(event, newValue) => {
                                  setEditAuthorizationData({
                                    ...editAuthorizationData,
                                    billing_diagnosis_code_1: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_1"
                                options={diagnosisCodes}
                                getOptionLabel={(option) => {
                                  return option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : option;
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  // console.log(params?.id, "--params");
                                  return (
                                    <TextField
                                      inputProps={{ "aria-label": "theme" }}
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                            <Box mb={2}>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  editAuthorizationData?.billing_diagnosis_code_2
                                }
                                onChange={(event, newValue) => {
                                  setEditAuthorizationData({
                                    ...editAuthorizationData,
                                    billing_diagnosis_code_2: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_2"
                                options={diagnosisCodes}
                                getOptionLabel={(option) => {
                                  return option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : option;
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  // console.log(params?.id, "--params");
                                  return (
                                    <TextField
                                      inputProps={{ "aria-label": "theme" }}
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                            <Box mb={2}>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  editAuthorizationData?.billing_diagnosis_code_3
                                }
                                onChange={(event, newValue) => {
                                  setEditAuthorizationData({
                                    ...editAuthorizationData,
                                    billing_diagnosis_code_3: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_3"
                                options={diagnosisCodes}
                                getOptionLabel={(option) => {
                                  return option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : option;
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  // console.log(params?.id, "--params");
                                  return (
                                    <TextField
                                      inputProps={{ "aria-label": "theme" }}
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                            <Box>
                              <Autocomplete
                                // defaultValue={""}
                                value={
                                  editAuthorizationData?.billing_diagnosis_code_4
                                }
                                onChange={(event, newValue) => {
                                  setEditAuthorizationData({
                                    ...editAuthorizationData,
                                    billing_diagnosis_code_4: newValue?.code,
                                  });
                                }}
                                size="small"
                                style={{ width: "100%" }}
                                disablePortal
                                id="billing_diagnosis_code_4"
                                options={diagnosisCodes}
                                getOptionLabel={(option) => {
                                  return option?.code
                                    ? `${option?.code} - ${option?.description}`
                                    : option;
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  // console.log(params?.id, "--params");
                                  return (
                                    <TextField
                                      inputProps={{ "aria-label": "theme" }}
                                      {...params}
                                      label="Diagnosis Codes"
                                    />
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box mt={3}>
                        <Box
                          component="fieldset"
                          style={{ borderColor: "lightgrey", borderWidth: 1 }}
                        >
                          <legend>Service Details</legend>
                          <Box display={"flex"} justifyContent={"space-evenly"}>
                            <Box>
                              <Box>Service group</Box>
                              <TextField
                                size="small"
                                // style={{ width: "100%" }}
                                id="service_group"
                                // label="Service group"
                                placeholder="Service group"
                                variant="outlined"
                                value={editAuthorizationData?.service_group}
                                onChange={() => console.log("Do nothing")}
                                name="service_group"
                                inputProps={{ "aria-label": "theme" }}
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>Service code</Box>
                              <TextField
                                size="small"
                                // style={{ width: "100%" }}
                                id="service_code"
                                // label="Service code"
                                placeholder="Service code"
                                variant="outlined"
                                value={editAuthorizationData?.service_code}
                                onChange={() => console.log("Do nothing")}
                                name="service_code"
                                inputProps={{ "aria-label": "theme" }}
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>Service code description </Box>
                              <TextField
                                size="small"
                                // label="Service code description"
                                // style={{ width: "100%" }}
                                id="service_decsription"
                                placeholder="Service code description"
                                variant="outlined"
                                value={
                                  editAuthorizationData?.service_code_description
                                }
                                onChange={() => console.log("Do nothing")}
                                name="service_decsription"
                                inputProps={{ "aria-label": "theme" }}
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>HCPCS</Box>
                              <TextField
                                size="small"
                                // label={"HCPCS"}
                                // style={{ width: "100%" }}
                                id="hcpcs"
                                placeholder="HCPCS"
                                variant="outlined"
                                value={editAuthorizationData?.hcpcs}
                                onChange={() => console.log("Do nothing")}
                                name="hcpcs"
                                inputProps={{ "aria-label": "theme" }}
                              />
                            </Box>
                            <Box ml={1}>
                              <Box>Modifiers</Box>
                              <Box display={"flex"} flexDirection={"row"}>
                                <Box width={64} mr={1}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_1"
                                    // label={"M-1"}
                                    placeholder="M-1"
                                    variant="outlined"
                                    value={editAuthorizationData?.modifier_1}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_1"
                                    inputProps={{ "aria-label": "theme" }}
                                  />
                                </Box>
                                <Box width={64} mr={1}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_2"
                                    // label={"M-2"}
                                    placeholder="M-2"
                                    variant="outlined"
                                    value={editAuthorizationData?.modifier_2}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_2"
                                    inputProps={{ "aria-label": "theme" }}
                                  />
                                </Box>
                                <Box width={64} mr={1}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_3"
                                    placeholder="M-3"
                                    variant="outlined"
                                    value={editAuthorizationData?.modifier_3}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_3"
                                    // label={"M-3"}
                                    inputProps={{ "aria-label": "theme" }}
                                  />
                                </Box>
                                <Box width={64}>
                                  <TextField
                                    size="small"
                                    // style={{ width: "10%" }}
                                    id="mod_4"
                                    placeholder="M-4"
                                    variant="outlined"
                                    value={editAuthorizationData?.modifier_4}
                                    onChange={() => console.log("Do nothing")}
                                    name="modifier_4"
                                    label="M-4"
                                    inputProps={{ "aria-label": "theme" }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      justifyContent={"center"}
                      // display={"flex"}
                      mt={4}
                      mr={9}
                      mb={2}
                    >
                      {requestStatus === "success" && (
                        <Box display={"flex"} justifyContent={"center"} mt={2}>
                          <Alert severity="success">
                            Authorization Updated Successfully.
                          </Alert>
                        </Box>
                      )}
                      {requestStatus === "error" && (
                        <Box display={"flex"} justifyContent={"center"} mt={2}>
                          <Alert severity="error">
                            An error occurred. Please review form
                          </Alert>
                        </Box>
                      )}
                      <Box display={"flex"} justifyContent={"center"} mt={1}>
                        <Button
                          onClick={() => handleUpdateAuthorization()}
                          variant="contained"
                          style={{
                            backgroundColor: "rgb(87, 37, 68)",
                            alignSelf: "center",
                          }}
                        >
                          {" "}
                          {isLoading
                            ? "Please wait..."
                            : "Update Authorization"}
                        </Button>
                      </Box>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={valueEdit} index={1}>
                    <Box>
                      {[...Array(weeklyScheduleData.length).keys()].map(
                        (key, index) => {
                          return (
                            <Box mb={2}>
                              <Box
                                borderBottom={1}
                                borderColor={colours.borderColour}
                                flexDirection={"row"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                mb={5}
                              >
                                <Box>
                                  <Box
                                    bgcolor={"dodgerblue"}
                                    borderRadius={100}
                                    height={30}
                                    width={30}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    display={"flex"}
                                  >
                                    {index + 1}
                                  </Box>
                                  <Box display={"flex"} flexDirection={"row"}>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        In/Out
                                      </Box>
                                      <Box paddingY={1}>In</Box>
                                      <Box marginY={1}>Out</Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        SUN
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 65,
                                          }}
                                          size="small"
                                          label=""
                                          name={"timeSundayIn"}
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "sunday",
                                              "in",
                                              true
                                            )
                                          }
                                          value={
                                            weeklyScheduleData[key]?.sunday &&
                                            weeklyScheduleData[key]?.sunday
                                              ?.split("-")[0]
                                              ?.trim()
                                          }
                                        />
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.sunday &&
                                            weeklyScheduleData[key]?.sunday
                                              ?.split("-")[1]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 65,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "sunday",
                                              "out",
                                              true
                                            )
                                          }
                                          name={"timeSundayOut"}
                                        />
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        MON
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.monday &&
                                            weeklyScheduleData[key]?.monday
                                              ?.split("-")[0]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 65,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "monday",
                                              "in",
                                              true
                                            )
                                          }
                                          name={"timeMondayIn"}
                                        />
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 65,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "monday",
                                              "out",
                                              true
                                            )
                                          }
                                          name={"timeMondayOut"}
                                          value={
                                            weeklyScheduleData[key]?.monday &&
                                            weeklyScheduleData[key]?.monday
                                              ?.split("-")[1]
                                              ?.trim()
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        TUE
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.tuesday &&
                                            weeklyScheduleData[key]?.tuesday
                                              ?.split("-")[0]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "tuesday",
                                              "in",
                                              true
                                            )
                                          }
                                          name={"timeTuesdayIn"}
                                        />
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.tuesday &&
                                            weeklyScheduleData[key]?.tuesday
                                              ?.split("-")[1]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "tuesday",
                                              "out",
                                              true
                                            )
                                          }
                                          name={"timeTuesdayOut"}
                                        />
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        WED
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]
                                              ?.wednesday &&
                                            weeklyScheduleData[key]?.wednesday
                                              ?.split("-")[0]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "wednesday",
                                              "in",
                                              true
                                            )
                                          }
                                          name={"timeWednesdayIn"}
                                        />
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]
                                              ?.wednesday &&
                                            weeklyScheduleData[key]?.wednesday
                                              ?.split("-")[1]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "wednesday",
                                              "out",
                                              true
                                            )
                                          }
                                          name={"timeWednesdayOut"}
                                        />
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        THU
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.thursday &&
                                            weeklyScheduleData[key]?.thursday
                                              ?.split("-")[0]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "thursday",
                                              "in",
                                              true
                                            )
                                          }
                                          name={"timeThursdayIn"}
                                        />
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.thursday &&
                                            weeklyScheduleData[key]?.thursday
                                              ?.split("-")[1]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "thursday",
                                              "out",
                                              true
                                            )
                                          }
                                          name={"timeThursdayOut"}
                                        />
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        FRI
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.friday &&
                                            weeklyScheduleData[key]?.friday
                                              ?.split("-")[0]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "friday",
                                              "in",
                                              true
                                            )
                                          }
                                          name={"timeFridayIn"}
                                        />
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.friday &&
                                            weeklyScheduleData[key]?.friday
                                              ?.split("-")[1]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "friday",
                                              "out",
                                              true
                                            )
                                          }
                                          name={"timeFridayOut"}
                                        />
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.primary}
                                      >
                                        SAT
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.saturday &&
                                            weeklyScheduleData[key]?.saturday
                                              ?.split("-")[0]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "saturday",
                                              "in",
                                              true
                                            )
                                          }
                                          name={"timeSaturdayIn"}
                                        />
                                      </Box>
                                      <Box paddingY={1}>
                                        <TextField
                                          value={
                                            weeklyScheduleData[key]?.saturday &&
                                            weeklyScheduleData[key]?.saturday
                                              ?.split("-")[1]
                                              ?.trim()
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }} // the change is here
                                          style={{
                                            width: 63,
                                          }}
                                          size="small"
                                          label=""
                                          variant="standard"
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "saturday",
                                              "out",
                                              true
                                            )
                                          }
                                          name={"timeSaturdayOut"}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    paddingBottom={2}
                                    mt={1}
                                    display={"flex"}
                                    flexDirection={"row"}
                                  >
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        In/Out
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {" "}
                                        Total Hours
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        SUN
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {weeklyScheduleData[key]?.sunday?.split(
                                          "-"
                                        )[0] &&
                                          getTimeDifference(
                                            weeklyScheduleData[
                                              key
                                            ]?.sunday?.split("-")[0],
                                            weeklyScheduleData[
                                              key
                                            ]?.sunday?.split("-")[1]
                                          )}
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        MON
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {weeklyScheduleData[key]?.monday?.split(
                                          "-"
                                        )[0] &&
                                          getTimeDifference(
                                            weeklyScheduleData[
                                              key
                                            ]?.monday?.split("-")[0],
                                            weeklyScheduleData[
                                              key
                                            ]?.monday?.split("-")[1]
                                          )}
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        TUE
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {weeklyScheduleData[
                                          key
                                        ]?.tuesday?.split("-")[0] &&
                                          getTimeDifference(
                                            weeklyScheduleData[
                                              key
                                            ]?.tuesday?.split("-")[0],
                                            weeklyScheduleData[
                                              key
                                            ]?.tuesday?.split("-")[1]
                                          )}
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        WED
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {weeklyScheduleData[
                                          key
                                        ]?.wednesday?.split("-")[0] &&
                                          getTimeDifference(
                                            weeklyScheduleData[
                                              key
                                            ]?.wednesday?.split("-")[0],
                                            weeklyScheduleData[
                                              key
                                            ]?.wednesday?.split("-")[1]
                                          )}
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        THU
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {weeklyScheduleData[
                                          key
                                        ]?.thursday?.split("-")[0] &&
                                          getTimeDifference(
                                            weeklyScheduleData[
                                              key
                                            ]?.thursday?.split("-")[0],
                                            weeklyScheduleData[
                                              key
                                            ]?.thursday?.split("-")[1]
                                          )}
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        FRI
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {weeklyScheduleData[key]?.friday?.split(
                                          "-"
                                        )[0] &&
                                          getTimeDifference(
                                            weeklyScheduleData[
                                              key
                                            ]?.friday?.split("-")[0],
                                            weeklyScheduleData[
                                              key
                                            ]?.friday?.split("-")[1]
                                          )}
                                      </Box>
                                    </Box>
                                    <Box textAlign={"center"}>
                                      <Box
                                        paddingX={5}
                                        paddingY={1}
                                        textAlign={"center"}
                                        color={"white"}
                                        bgcolor={colours.secondary}
                                      >
                                        SAT
                                      </Box>
                                      <Box
                                        border={1}
                                        borderColor={"lightgrey"}
                                        paddingY={1}
                                      >
                                        {weeklyScheduleData[
                                          key
                                        ]?.saturday?.split("-")[0] &&
                                          getTimeDifference(
                                            weeklyScheduleData[
                                              key
                                            ]?.saturday?.split("-")[0],
                                            weeklyScheduleData[
                                              key
                                            ]?.saturday?.split("-")[1]
                                          )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box mb={1}>Status</Box>
                                  <Box>
                                    <FormControl fullWidth size="small">
                                      <InputLabel id="discharge_reason">
                                        Status
                                      </InputLabel>
                                      <Select
                                        placeholder="Status"
                                        labelId="status"
                                        id="status"
                                        value={
                                          weeklyScheduleData[key]["status"] ||
                                          ""
                                        }
                                        label="Status"
                                        onChange={(e) =>
                                          handleMultiInputs(
                                            e,
                                            key,
                                            "nothing",
                                            "status",
                                            true
                                          )
                                        }
                                        defaultValue={"Select"}
                                        name="status"
                                        // style={{ width: "83%" }}
                                      >
                                        <MenuItem value={"active"}>
                                          Active
                                        </MenuItem>
                                        <MenuItem value={"inactive"}>
                                          Inactive
                                        </MenuItem>
                                        <MenuItem value={"onhold"}>
                                          Onhold
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                  <Box mt={3}>Schedule Start Date</Box>
                                  <Box>
                                    <DatePickerNew
                                      selected={
                                        weeklyScheduleData[key]["start_date"]
                                      }
                                      onChange={(date) => {
                                        weeklyScheduleData[key]["start_date"] =
                                          date;
                                        setWeeklyScheduleData([
                                          ...weeklyScheduleData,
                                        ]);
                                      }}
                                    />
                                    {/* <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          onChange={(e) =>
                                            handleMultiInputs(
                                              e,
                                              key,
                                              "nothing",
                                              "startDate"
                                            )
                                          }
                                          value={moment(
                                            weeklyScheduleData[key][
                                              "start_date"
                                            ]
                                          )}
                                          // value={multipleData[key]["startDate"]}
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                              size: "small",
                                            },
                                          }}
                                          label="Schedule Start Date"
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider> */}
                                  </Box>
                                  <Box mt={3}>Schedule End Date</Box>
                                  <Box>
                                    <DatePickerNew
                                      selected={
                                        weeklyScheduleData[key]["end_date"]
                                      }
                                      onChange={(date) => {
                                        weeklyScheduleData[key]["end_date"] =
                                          date;
                                        setWeeklyScheduleData([
                                          ...weeklyScheduleData,
                                        ]);
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                    <Box>
                      {responseError && (
                        <Box display={"flex"} justifyContent={"center"} mt={2}>
                          {responseError === "success" ? (
                            <Alert severity="success">
                              Schedule Updated Successfully!
                            </Alert>
                          ) : (
                            <Alert severity="error">
                              Schedule submission failed!
                            </Alert>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box
                      justifyContent={"center"}
                      display={"flex"}
                      mt={4}
                      // mr={9}
                      mb={2}
                    >
                      <Button
                        onClick={() => updateSchedule()}
                        variant="contained"
                        style={{ backgroundColor: "rgb(87, 37, 68)" }}
                      >
                        {isLoadingCalendar
                          ? "Please wait..."
                          : "Update Schedule"}
                      </Button>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={valueEdit} index={2}>
                    <Box>
                      {taskNewData.map((task, index) => {
                        //edit task
                        return (
                          <FormGroup>
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              alignItems={"center"}
                              bgcolor={colours.secondary}
                              maxWidth={"95%"}
                              overflow={"auto"}
                              whiteSpace={"break-spaces"}
                            >
                              <Box
                                color={colours.primary}
                                fontWeight={"bold"}
                                fontSize={20}
                                mr={1}
                                ml={1}
                              >
                                {index + 1}
                              </Box>
                              <TaskItem
                                index={index}
                                task={task?.bathing}
                                name={"bathing"}
                                edit={"Yes"}
                                label={"Bathing"}
                              />
                              <TaskItem
                                task={task?.dressing}
                                name={"dressing"}
                                index={index}
                                edit={"Yes"}
                                label={"Dressing"}
                              />
                              <TaskItem
                                task={task?.excercising}
                                name={"excercising"}
                                label={"Excercising"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                edit={"Yes"}
                                task={task?.feeding}
                                name={"feeding"}
                                index={index}
                                label={"Feeding"}
                              />
                              <TaskItem
                                task={task?.shaving_oral_care}
                                label={"Shaving/oral care"}
                                name={"shaving_oral_care"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                edit={"Yes"}
                                task={task?.laundry}
                                name={"laundry"}
                                label={"Laundry"}
                                index={index}
                              />
                              <TaskItem
                                task={task?.toilet}
                                label={"Toileting"}
                                name={"toilet"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                task={task?.transfer}
                                label={"Transfer"}
                                name={"transfer"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                task={task?.cleaning}
                                name={"cleaning"}
                                label={"Cleaning"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                task={task?.routine_hair_skin_care}
                                name={"routine_hair_skin_care"}
                                label={"Routine hair skin care"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                task={task?.meal_preparation}
                                label={"Meal Preparation"}
                                name={"meal_preparation"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                edit={"Yes"}
                                task={task?.escort}
                                name={"escort"}
                                label={"Escort"}
                                index={index}
                              />
                              <TaskItem
                                edit={"Yes"}
                                task={task?.shopping}
                                name={"shopping"}
                                label={"Shopping"}
                                index={index}
                              />
                              <TaskItem
                                edit={"Yes"}
                                task={task?.walking}
                                name={"walking"}
                                label={"Walking"}
                                index={index}
                              />
                              <TaskItem
                                task={task?.ambulation}
                                name={"ambulation"}
                                label={"Ambulation"}
                                index={index}
                                edit={"Yes"}
                              />
                              <TaskItem
                                edit={"Yes"}
                                index={index}
                                task={
                                  task?.assistance_with_self_administered_medications
                                }
                                name={
                                  "assistance_with_self_administered_medications"
                                }
                                label={
                                  "Assistance with self administered medications"
                                }
                              />
                              <TaskItem
                                edit={"Yes"}
                                index={index}
                                task={task?.others}
                                name={"others"}
                                label={"Others"}
                              />
                            </Box>
                            <Divider
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                width: "95%",
                              }}
                            />
                          </FormGroup>
                        );
                      })}
                    </Box>

                    {responseError_ && (
                      <Box display={"flex"} justifyContent={"center"} mt={2}>
                        {responseError_ === "success" ? (
                          <Alert severity="success">
                            New Task Created Successfully!
                          </Alert>
                        ) : (
                          <Alert severity="error">
                            Task(s) submission failed!
                          </Alert>
                        )}
                      </Box>
                    )}
                    <Box
                      justifyContent={"center"}
                      display={"flex"}
                      mt={4}
                      // mr={9}
                      mb={2}
                    >
                      <Button
                        onClick={() => updateNewTasks()}
                        variant="contained"
                        style={{ backgroundColor: "rgb(87, 37, 68)" }}
                      >
                        {isLoadingTask ? "Please wait..." : "Update Tasks"}
                      </Button>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={valueEdit} index={3}>
                    {!displayCsseForm ? (
                      <Box style={{ float: "left", width: "100%" }}>
                        {caseInfosData.map((caseInfo) => {
                          return (
                            <Box
                              display={"inline-grid"}
                              m={2}
                              border={"1px solid lightgrey"}
                              width={"20%"}
                              p={1}
                              borderRadius={5}
                            >
                              <Box>
                                <Box
                                  textAlign={"center"}
                                  lineHeight={1}
                                  height={50}
                                  overflow={"hidden"}
                                  mb={1}
                                >
                                  {caseInfo?.comment_response}
                                </Box>
                                <Box display={"flex"} flexDirection={"row"}>
                                  Caseworker:{" "}
                                  <Box
                                    color={colours.secondary}
                                    fontWeight={"bold"}
                                    pl={1}
                                  >
                                    {" "}
                                    {caseInfo?.sent_to
                                      ?.split(":")[1]
                                      ?.split("\n")[0]
                                      ?.replace(",", " ")}
                                  </Box>
                                </Box>
                                <Box display={"flex"} flexDirection={"row"}>
                                  Employee:{" "}
                                  <Box
                                    color={colours.secondary}
                                    fontWeight={"bold"}
                                    pl={1}
                                  >
                                    {" "}
                                    {caseInfo?.sent_from
                                      ?.split(" \n")[0]
                                      ?.replace(",", " ")}
                                  </Box>
                                </Box>
                                <Box display={"flex"} flexDirection={"row"}>
                                  Date:{" "}
                                  <Box
                                    ml={1}
                                    color={colours.secondary}
                                    fontWeight={"bold"}
                                  >
                                    {" "}
                                    {caseInfo?.authorization_start_date}
                                  </Box>
                                </Box>
                                <Box
                                  mt={1}
                                  display={"flex"}
                                  justifyContent={"center"}
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      setHideCloseIcon(false);
                                      setDisplayCaseForm(true);
                                      setCaseInfoSelected(caseInfo?.id);
                                    }}
                                  >
                                    View Details
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box
                        bgcolor={"lightgrey"}
                        display={"flex"}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"100%"}
                        p={2}
                        borderRadius={3}
                      >
                        <Box>
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              onClick={() => updateCaseInfo()}
                              style={{
                                backgroundColor: colours.primary,
                                marginRight: 10,
                              }}
                              variant="contained"
                            >
                              {is_loading ? "Please wait..." : "Update Form"}
                            </Button>
                            <Button
                              onClick={() => handlePrint2()}
                              style={{
                                backgroundColor: "green",
                                marginRight: 10,
                              }}
                              variant="contained"
                            >
                              Print Form
                            </Button>
                            <Button
                              onClick={() => {
                                setHideCloseIcon(true);
                                setDisplayCaseForm(false);
                              }}
                              style={{
                                backgroundColor: "red",
                              }}
                              variant="contained"
                            >
                              Close Form
                            </Button>
                          </Box>

                          <CaseInformation
                            ref={caseInformationRef2}
                            view={true}
                          />
                        </Box>
                      </Box>
                    )}
                  </CustomTabPanel>
                  <CustomTabPanel value={valueEdit} index={4}>
                    {newComplains?.map((complain, index) => {
                      return (
                        <Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-evenly"}
                          >
                            <Box width={"40%"}>
                              <Box mb={0.5}>Complainant</Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="complainant"
                                label="Complainant"
                                variant="outlined"
                                value={complain?.complainant}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="complainant"
                              />

                              <Box mb={0.5} mt={2}>
                                Regarding
                              </Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="regarding"
                                label="Regarding"
                                variant="outlined"
                                value={complain?.regarding}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="regarding"
                              />

                              <Box mb={0.5} mt={2}>
                                Others Involved (If Applicable)
                              </Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="others_involved"
                                label="Others Involved"
                                variant="outlined"
                                value={complain?.others_involved}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="others_involved"
                              />
                              <Box mb={0.5} mt={2}>
                                Reported By
                              </Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="reported_by"
                                label="Reported By"
                                variant="outlined"
                                value={complain?.reported_by}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="reported_by"
                              />
                              <Box mb={0.5} mt={2}>
                                Nature of Problem
                              </Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="nature_of_problem"
                                label="Problem Nature"
                                variant="outlined"
                                value={complain?.nature_of_problem}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="nature_of_problem"
                              />
                            </Box>
                            <Box width={"40%"}>
                              <Box mb={0.5}>Person Receiving Complainant</Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="person_receiving_complainant"
                                label="Complainant Receiver"
                                variant="outlined"
                                value={complain?.person_receiving_complainant}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="person_receiving_complainant"
                              />
                              <Box mb={0.5} mt={2}>
                                Action Taken
                              </Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="action_taken"
                                label="Action Taken"
                                variant="outlined"
                                value={complain?.action_taken}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="action_taken"
                                multiline
                              />
                              <Box mb={0.5} mt={2}>
                                Person Completing Report
                              </Box>
                              <TextField
                                size="small"
                                style={{ width: "100%" }}
                                id="person_completing_report"
                                label="Person Completing Report"
                                variant="outlined"
                                value={complain?.person_completing_report}
                                onChange={(e) =>
                                  handleComplainant(e, true, index)
                                }
                                name="person_completing_report"
                              />
                              <Box mb={0.5} mt={4}>
                                The nature of the complaint and it's resolution
                                have been discussed with complainant who
                              </Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...label}
                                    name="agrees_with_resolution"
                                    value={complain?.agrees_with_resolution}
                                    onChange={(e) =>
                                      handleComplainant(e, true, index)
                                    }
                                    checked={complain?.agrees_with_resolution}
                                  />
                                }
                                label="Agrees with it's resolution"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...label}
                                    name="disagrees_with_resolution"
                                    value={complain?.disagrees_with_resolution}
                                    onChange={(e) =>
                                      handleComplainant(e, true, index)
                                    }
                                    checked={
                                      complain?.disagrees_with_resolution
                                    }
                                  />
                                }
                                label="Disagrees with it's resolution"
                              />
                            </Box>
                          </Box>
                          <Divider
                            style={{ marginTop: 20, marginBottom: 20 }}
                          />
                        </Box>
                      );
                    })}
                    <Box justifyContent={"center"} display={"flex"}>
                      {isSuccessful_ && (
                        <Alert severity="success">
                          Complain Updated Successfully
                        </Alert>
                      )}
                    </Box>

                    <Box
                      justifyContent={"center"}
                      display={"flex"}
                      mt={isSuccessful_ ? 2 : 4}
                      mb={2}
                    >
                      <Button
                        onClick={() => updateComplaint()}
                        variant="contained"
                        style={{
                          backgroundColor: "rgb(87, 37, 68)",
                        }}
                      >
                        {isloading_ ? "Please wait..." : "Update Complaint"}
                      </Button>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={valueEdit} index={5}>
                    {newComments.map((comment, index) => {
                      return (
                        <Box style={styles.comment}>
                          <Box style={styles.bold}>
                            Comment {`(${index + 1})`}
                          </Box>
                          <TextField
                            size="small"
                            style={{ width: "100%" }}
                            id="comment"
                            label="Comment"
                            variant="outlined"
                            value={comment?.comment}
                            onChange={(e) => {
                              newComments[index][e.target.name] =
                                e.target.value;
                              setNewComments([...newComments]);
                            }}
                            name="comment"
                            multiline
                            rows={6}
                          />
                          <Divider
                            style={{ marginTop: 20, marginBottom: 20 }}
                          />
                        </Box>
                      );
                    })}

                    {_isSuccessful && (
                      <Box mt={1} display={"flex"} justifyContent={"center"}>
                        <Alert severity="success">
                          Comment Updated successfully
                        </Alert>
                      </Box>
                    )}
                    <Box
                      justifyContent={"center"}
                      display={"flex"}
                      mt={_isSuccessful ? 2 : 4}
                      mb={2}
                    >
                      <Button
                        onClick={() => updateComment()}
                        variant="contained"
                        style={{ backgroundColor: "rgb(87, 37, 68)" }}
                      >
                        {_isloading ? "Please wait..." : "Update Comment"}
                      </Button>
                    </Box>
                  </CustomTabPanel>
                  <NotificationContainer />
                </Box>
              </Box>
            </Typography>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "100%",
};

const styles = {
  bold: { fontWeight: "bold" },
  comment: { marginBottom: 20 },
  label: { color: colours.primary },
  value: { color: colours.secondary },
  margin: { marginBottom: 10 },
  inputLabel: {
    // marginBottom: -5,
  },
};

export default IndividualForm;
