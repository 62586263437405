import { Box } from "@mui/material";
import React from "react";
import { formatPhoneNumber } from "../FormatPhoneNumber";
import moment from "moment";

const AttendantIndividual = ({ data }) => {
  console.log(data);
  return (
    <Box fontFamily={"Times New Roman"}>
      <Box
        textAlign={"center"}
        fontWeight={"bold"}
        fontSize={26}
        fontFamily={"Times New Roman"}
        mb={1}
      >
        Fidelity Home Health Services, LLC
      </Box>
      <Box style={styles.splitRow} mb={1}>
        <Box style={styles.row}>
          <Box style={styles.fontFamily} fontWeight={"bold"}>
            Individual:
          </Box>
          <Box fontFamily={"Times New Roman"}> All</Box>
        </Box>
        <Box style={styles.row} width={"30%"}>
          <Box fontWeight={"bold"} fontFamily={"Times New Roman"}>
            Individual Status:
          </Box>
          <Box ml={2} fontFamily={"Times New Roman"}>
            Active
          </Box>
        </Box>
      </Box>
      <Box style={styles.splitRow} mb={1}>
        <Box style={styles.row}>
          <Box style={styles.fontFamily} fontWeight={"bold"}>
            Attendant:
          </Box>
          <Box fontFamily={"Times New Roman"}>All</Box>
        </Box>
        <Box style={styles.row} width={"30%"}>
          <Box fontWeight={"bold"} fontFamily={"Times New Roman"}>
            Type:
          </Box>
          <Box ml={2} fontFamily={"Times New Roman"}>
            All
          </Box>
        </Box>
      </Box>
      <Box style={styles.splitRow} mb={1}>
        <Box style={styles.row}>
          <Box style={styles.fontFamily} fontWeight={"bold"}>
            Priority:
          </Box>
          <Box fontFamily={"Times New Roman"}>All</Box>
        </Box>
        <Box style={styles.row} width={"30%"}>
          <Box fontWeight={"bold"} fontFamily={"Times New Roman"}>
            Individual Group:
          </Box>
          <Box ml={2} fontFamily={"Times New Roman"}>
            All
          </Box>
        </Box>
      </Box>

      <Box display={"flex"} alignItems={"center"} mb={2}>
        <Box
          fontWeight={"bold"}
          fontStyle={"italic"}
          style={{ ...styles.fontFamily }}
          border={"1px solid black"}
          width={"30%"}
          p={0.5}
          textAlign={"center"}
          fontSize={12}
          mr={1}
        >
          Note: The top bolded line is Attendant Info...
        </Box>
        <Box
          style={{ ...styles.fontFamily }}
          fontSize={22}
          fontWeight={"bold"}
          textAlign={"center"}
        >
          Attendant's Individuals
        </Box>
      </Box>

      <Box
        borderBottom={"1px solid black"}
        fontWeight={"bold"}
        style={{ ...styles.fontFamily, ...styles.row }}
      >
        <Box width={"10%"} mr={0.5}>
          Proirity
        </Box>
        <Box width={"15%"} mr={0.5}>
          Name
        </Box>
        <Box width={"40%"} mr={0.5}>
          Address
        </Box>
        <Box width={"10%"} mr={0.5}>
          Start
        </Box>
        <Box width={"14%"} mr={0.5}>
          Phone
        </Box>
        <Box width={"14%"}>Alt Phone</Box>
      </Box>

      <Box mb={3}>
        {/* This below will be used for Attendants/Employee */}
        <Box
          display={"flex"}
          fontWeight={"bold"}
          mb={0.5}
          mt={0.5}
          style={{ ...styles.fontFamily, ...styles.row, ...styles.fontSize }}
        >
          <Box width={"10%"} mr={0.5}>
            {""}
          </Box>
          <Box width={"15%"} mr={0.5}>
            {data?.employee?.firstname}, {data?.employee?.lastname}
          </Box>
          <Box width={"40%"} mr={0.5}>
            {data?.employee?.mail_code}
            {data?.employee?.address}
          </Box>
          <Box width={"10%"} mr={0.5}>
            {""}
          </Box>
          <Box width={"14%"} mr={0.5}>
            {formatPhoneNumber(data?.employee?.phone)}
          </Box>
          <Box width={"14%"}> {formatPhoneNumber(data?.employee?.phone)}</Box>
        </Box>

        {/* This below will be used for individuals */}
        <Box
          display={"flex"}
          style={{ ...styles.fontFamily, ...styles.row, ...styles.fontSize }}
        >
          <Box width={"10%"} mr={0.5}>
            NON
          </Box>
          <Box width={"15%"} mr={0.5}>
            {data?.individual?.firstname}, {data?.individual?.lastname}
          </Box>
          <Box width={"40%"} mr={0.5}>
            {data?.individual?.address}
            {data?.individual?.city}
            {data?.individual?.state}
            {data?.individual?.country}
            {data?.individual?.zip_code}
          </Box>
          <Box width={"10%"} mr={0.5}>
            {moment(data?.authorization_start_date?.split("T")[0]).format(
              "MM/DD/YY"
            )}
          </Box>
          <Box width={"14%"} mr={0.5}>
            {formatPhoneNumber(data?.individual?.phone)}
          </Box>
          <Box width={"14%"}>
            {formatPhoneNumber(data?.individual?.other_phone)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AttendantIndividual;

const styles = {
  fontSize: {
    fontSize: 13.3,
  },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  splitRow: { justifyContent: "space-between", display: "flex" },
  fontFamily: {
    fontFamily: "Times New Roman",
  },
};
