import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { formatPhoneNumber } from "../FormatPhoneNumber";

const IndividualEvaluation = ({ data }) => {
  const [sign, setSign] = useState("");
  const signature = useRef();

  return (
    <Box>
      <Box
        fontSize={24}
        textAlign={"center"}
        fontFamily={"Times New Roman"}
        fontWeight={"bold"}
      >
        PRIMARY HOME CARE
      </Box>
      <Box
        textAlign={"center"}
        mt={1}
        fontFamily={"Times New Roman"}
        fontSize={19}
      >
        Individual Evaluation
      </Box>
      <Box
        mt={2}
        textAlign={"center"}
        fontFamily={"Times New Roman"}
        fontSize={13}
      >
        INITIAL CASE TRANSFER EXPEDITED REFERRAL
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <table width={"100%"} border={1} style={{ borderCollapse: "collapse" }}>
          <tr>
            <td style={styles.format}>
              Individual Name (Last, First, MI){" "}
              <Box>
                {data?.individual?.lastname}
                {data?.individual?.lastname && ","}{" "}
                {data?.individual?.firstname}
              </Box>
            </td>
            <td style={styles.format}>
              Individual No. <Box> {data?.individual?.medicaid}</Box>
            </td>
            <td colSpan={2}>
              <Box style={styles.format}>
                Telephone No. {formatPhoneNumber(data?.individual?.phone)}
              </Box>
              <Box borderTop={"1px solid black"} style={styles.format}>
                Emergency #
              </Box>
            </td>
          </tr>
          <tr>
            <td style={styles.format}>Address: {data?.individual?.address}</td>
            <td style={styles.format}>County: {data?.individual?.country}</td>
            <td style={styles.format}>Sex {data?.individual?.gender}</td>
            <td style={styles.format}>
              Date of Birth: {data?.individual?.dob}
            </td>
          </tr>
          <tr>
            <td style={styles.format}>
              Name of Physician Individual Sees Regularly
            </td>
            <td style={styles.format}>Physician’s Address:</td>
            <td style={styles.format} colSpan={2}>
              Telephone No.
            </td>
          </tr>
          <tr>
            <td style={styles.format}>Provider Agency Name</td>
            <td style={styles.format}>Vendor No</td>
            <td style={styles.format}>Provider Agency Address</td>
            <td style={styles.format}>Telephone No.</td>
          </tr>
        </table>
      </Box>

      <Box mt={2}>
        <Box fontSize={15} fontFamily={"Times New Roman"}>
          Describe living conditions; identify safety or health hazards:
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              height: 30,
              fontSize: 16,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
      </Box>

      <Box mt={3}>
        <Box fontSize={15} fontFamily={"Times New Roman"}>
          Identify assistance needed to achieve ADLs including any assistive
          devices or medical equipments in use:
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              height: 30,
              fontSize: 16,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              height: 30,
              fontSize: 16,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
      </Box>

      <Box mt={3}>
        <Box fontSize={15} fontFamily={"Times New Roman"}>
          Give dates and reasons for any hospitalization within the last three
          months:
        </Box>

        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Box fontSize={15} fontFamily={"Times New Roman"}>
          Describe Primary Home Care individual’s symptoms and functional
          limitations which cause need for personal care.
        </Box>

        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
        <Box width={"100%"} borderBottom={"1px solid black"}>
          <input
            style={{
              border: "0",
              width: "100%",
              fontSize: 16,
              height: 30,
              fontFamily: "Times New Roman",
            }}
            type="text"
            // value={data?.date}
            // onChange={(e) => setData({ ...data, date: e.target.value })}
            name="more"
          />
        </Box>
      </Box>
      <Box mt={4}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <SignatureCanvas
              onEnd={(data) =>
                setSign(
                  signature.current.getTrimmedCanvas().toDataURL("image/png")
                )
              }
              ref={signature}
              penColor="black"
              canvasProps={{
                width: 400,
                height: 50,
                className: "sigCanvas",
              }}
            />
            <Box
              width={400}
              borderTop={1}
              borderColor={"FAFAFA"}
              fontFamily={"Times New Roman"}
              textAlign={"center"}
              fontSize={16}
            >
              Signature- Individual or Responsible Person
            </Box>
          </Box>
          <Box mt={1}>
            <input
              style={{
                border: "0",
                // width: "100%",
                fontSize: 16,
                height: 30,
                fontFamily: "Times New Roman",
              }}
              type="text"
              // value={data?.date}
              // onChange={(e) => setData({ ...data, date: e.target.value })}
              name="more"
            />
            <Box
              // width={400}
              borderTop={1}
              borderColor={"FAFAFA"}
              fontFamily={"Times New Roman"}
              textAlign={"center"}
              fontSize={16}
            >
              Date
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <SignatureCanvas
              onEnd={(data) =>
                setSign(
                  signature.current.getTrimmedCanvas().toDataURL("image/png")
                )
              }
              ref={signature}
              penColor="black"
              canvasProps={{
                width: 400,
                height: 50,
                className: "sigCanvas",
              }}
            />
            <Box
              width={400}
              borderTop={1}
              borderColor={"FAFAFA"}
              fontFamily={"Times New Roman"}
              textAlign={"center"}
              fontSize={16}
            >
              Signature – Supervisor
            </Box>
          </Box>
          <Box mt={1}>
            <input
              style={{
                border: "0",
                // width: "100%",
                fontSize: 16,
                height: 30,
                fontFamily: "Times New Roman",
              }}
              type="text"
              // value={data?.date}
              // onChange={(e) => setData({ ...data, date: e.target.value })}
              name="more"
            />
            <Box
              // width={400}
              borderTop={1}
              borderColor={"FAFAFA"}
              fontFamily={"Times New Roman"}
              textAlign={"center"}
              fontSize={16}
            >
              Date of Assessment
            </Box>
          </Box>
        </Box>
      </Box>
      <Box height={100} />
    </Box>
  );
};

export default IndividualEvaluation;

const styles = {
  format: {
    fontFamily: "Times New Roman",
    fontSize: 13,
    fontWeight: "bold",
    padding: 5,
  },
};
