import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, TextField } from "@mui/material";

import colours from "../../constants/colours";
import { getServiceCodes } from "../../pages/main/Dashboard/business";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ServiceCodeForm = ({ open, handleOpenForm }) => {
  const [data, setData] = useState({});

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleOpenForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: colours.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOpenForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Service Code
            </Typography>
            <Button autoFocus color="inherit" onClick={handleOpenForm}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          padding={4}
          flexDirection={"row"}
          justifyContent={"space-around"}
          display={"flex"}
        >
          <Box width={"20%"}>
            <Box mb={1}>ID</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="id"
              label="ID"
              variant="outlined"
              onChange={handleChange}
              name="ID"
              value={data?.ID}
            />
            <Box mt={3} mb={1}>
              Program Group
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="programGroup"
              label="Program Group"
              variant="outlined"
              value={data?.programGroup}
              onChange={handleChange}
              name="programGroup"
            />
            <Box mt={3} mb={1}>
              Program
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="program"
              label="Program"
              variant="outlined"
              value={data?.program}
              onChange={handleChange}
              name="program"
            />
            <Box mt={3} mb={1}>
              Program Service
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="programService"
              label="Program Service"
              variant="outlined"
              name="programService"
              onChange={handleChange}
            />
            <Box mt={3} mb={1}>
              Service Group
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="serviceGroup"
              label="Service Group"
              variant="outlined"
              name="serviceGroup"
              onChange={handleChange}
              value={data?.serviceGroup}
            />
          </Box>

          <Box width={"20%"}>
            <Box mb={1}>Bill Code</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="billCode"
              label="Bill Code"
              variant="outlined"
              name="billCode"
              onChange={handleChange}
              value={data?.billCode}
            />

            <Box mt={3} mb={1}>
              Description
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="description"
              label="Description"
              variant="outlined"
              name="description"
              onChange={handleChange}
              value={data?.description}
            />
            <Box mt={3} mb={1}>
              Service Code
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="serviceCode"
              label="Service Code"
              variant="outlined"
              name="serviceCode"
              onChange={handleChange}
              value={data?.serviceCode}
            />
            <Box mt={3} mb={1}>
              EVV Code
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="evvCode"
              label="EVV Code"
              variant="outlined"
              name="evvCode"
              onChange={handleChange}
              value={data?.evvCode}
            />
            <Box mt={3} mb={1}>
              Procedure Code Qualifier
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="procedureCode"
              label="Procedure Code Qualifier"
              variant="outlined"
              name="procedureCode"
              onChange={handleChange}
              value={data?.evvCode}
            />
          </Box>
          <Box width={"20%"}>
            <Box mb={1}>HCPCS</Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="hcpcs"
              label="HCPCS"
              variant="outlined"
              name="hcpcs"
              onChange={handleChange}
              value={data?.hcpcs}
            />
            <Box mt={3} mb={1}>
              Modifier - 1
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="mod1"
              label="Modifier - 1"
              variant="outlined"
              name="mod1"
              onChange={handleChange}
              value={data?.mod1}
            />
            <Box mt={3} mb={1}>
              Modifier - 2
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="mod2"
              label="Modifier - 2"
              variant="outlined"
              name="mod2"
              onChange={handleChange}
              value={data?.mod2}
            />
            <Box mt={3} mb={1}>
              Modifier - 3
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="mod3"
              label="Modifier - 3"
              variant="outlined"
              name="mod3"
              onChange={handleChange}
              value={data?.mod3}
            />
            <Box mt={3} mb={1}>
              Modifier - 4
            </Box>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="mod4"
              label="Modifier - 4"
              variant="outlined"
              name="mod4"
              onChange={handleChange}
              value={data?.mod4}
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default ServiceCodeForm;

const styles = {
  addIcon: {
    color: colours.primary,
    cursor: "pointer",
    position: "absolute",
    marginTop: 15,
    marginLeft: 3,
  },
};
